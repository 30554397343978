import API from '../../stores/API';

export const SET_CALL_ROUTES = 'SET_CALL_ROUTS';

function recieveCallRoutes (payload) {
  return {
    type: SET_CALL_ROUTES,
    payload
  };
}

export function getCallRoutes (countryId) {
  return async (dispatch) => {
    const { data } = await API.GET(`/callrouting/${countryId}?limit=0`);
    return dispatch(recieveCallRoutes(data));
  };
}

export function saveCallRoutes (countryId, callRoutes) {
  return async (dispatch) => {
    const { data } = await API.POST(`/callrouting/${countryId}`, callRoutes);

    return dispatch(recieveCallRoutes(data));
  };
}
