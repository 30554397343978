import { SET_BONUS_REPORT, SET_RESOLVED_REPORT, SET_BONUS_REPORTS, SET_REPORT_TOTALS } from '../actions/bonusReports';

const initialState = {
  bonusReport: null,
  bonusReports: [],
  resolvedReport: null,
  totals: null
};

export default function bonusReports (state = initialState, { type, payload }) {
  switch (type) {
    case SET_BONUS_REPORT: {
      return {
        ...state,
        bonusReport: payload
      };
    }

    case SET_BONUS_REPORTS: {
      return {
        ...state,
        bonusReports: payload
      };
    }

    case SET_RESOLVED_REPORT: {
      return {
        ...state,
        resolvedReport: payload
      };
    }

    case SET_REPORT_TOTALS: {
      return {
        ...state,
        totals: payload
      };
    }

    default:
      return state;
  }
}
