import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { getDisabledMinutesFortTimePicker } from '../library/functions';

class LavendlaTimePicker extends Component {
  constructor (props) {
    super(props);

    let timeProp;
    if (props.time) {
      timeProp = moment(props.time, 'HH:mm');
    }

    this.state = {
      time: timeProp
    };
  }

  handleChange (time) {
    this.setState({ time });
  }

  callParent () {
    this.props.onChange(this.state.time);
  }

  setTime (time) {
    this.handleChange(moment(time, 'HH:mm'));
  }

  getTime (format = 'HH:mm') {
    if (this.state.time) {
      return moment(this.state.time).format(format);
    }

    return '';
  }

  render () {
    return (
      <TimePicker
        showSecond={false}
        value={this.state.time}
        onChange={this.handleChange.bind(this)}
        onClose={this.callParent.bind(this)}
        disabledMinutes={getDisabledMinutesFortTimePicker}
        hideDisabledOptions
        defaultOpenValue={moment('1000', 'hmm')}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
      />
    );
  }
}

LavendlaTimePicker.propTypes = {
  time: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default LavendlaTimePicker;
