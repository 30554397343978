import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1399);

  useEffect(() => {
    document.body.classList.add('sidebar-open');
    // dismount
    return () => {
      document.body.classList.remove('sidebar-open');
      document.body.classList.remove('sidebar-closed');
    };
  }, []);

  useEffect(() => {
    // set body classes
    if (isOpen) {
      document.body.classList.add('sidebar-open');
      document.body.classList.remove('sidebar-closed');
    } else {
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  }, [isOpen]);

  return (
    <div className={isOpen ? 'sidebar__container' : 'sidebar__container hidden'}>
      <div className='sidebar__inner'>
        {children}
      </div>
      <button className='sidebar__toggle' onClick={() => setIsOpen(!isOpen)} aria-label={isOpen ? t('Close') : t('Open')} />
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired
};

export default Sidebar;
