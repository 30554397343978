import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useCallRoutes from 'ReduxHooks/useCallRoutes';
import { LoadingButton } from 'Components/FormElements';
import { getFormattedLocationById } from '../formatters';
import { formatUser } from 'Library/functions';
import Select from 'react-select';
import useLocations from 'ReduxHooks/useLocations';
import useUsers from 'ReduxHooks/useUsers';

function CallRoutingSettings ({ country, setParentRoutes }) {
  const { t } = useTranslation();
  const { callRoutes, getCallRoutes } = useCallRoutes();
  const [editedCallRoutes, setEditedCallRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { locations, getLocations } = useLocations();
  const [newRoute, setNewRoute] = useState(null);
  const { dropdownUsers, loadDropdownUsers } = useUsers();

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true);
      await getLocations({ countryId: country.id });
      await loadDropdownUsers({ overrideCountry: country });
      await getCallRoutes(country.id);
      setIsLoading(false);
    }
    fetchData();
  }, [country]);

  useEffect(() => {
    setEditedCallRoutes(callRoutes);
  }, [callRoutes, country]);

  useEffect(() => {
    setParentRoutes(editedCallRoutes);
  }, [editedCallRoutes]);

  const getAvailableRoutes = () => {
    const usedRoutes = editedCallRoutes ? editedCallRoutes.map(route => route.number) : [];
    const availableRoutes = [];
    for (let i = 1; i <= 999; i++) {
      if (!usedRoutes.includes(i)) {
        availableRoutes.push({ value: i, label: `${i}`.padStart(3, '0') });
      }
    }
    return availableRoutes;
  };

  const handleAddNewRoute = () => {
    setEditedCallRoutes([...editedCallRoutes, { number: newRoute.value, location_id: null, user_id: null }]);
    setNewRoute(null);
  };

  const handleDeleteRow = (route) => {
    setEditedCallRoutes(editedCallRoutes.map((editedCallRoute) => {
      if (editedCallRoute.number === route) {
        return { ...editedCallRoute, delete: true };
      }
      return editedCallRoute;
    }));
  };

  if (isLoading || !locations || !locations.length || !dropdownUsers || !dropdownUsers.length) {
    return null;
  }

  const handleSelectChange = (type, index, value) => {
    setEditedCallRoutes(
      editedCallRoutes.map((editedCallRoute, editedCallRouteIndex) => {
        if (editedCallRouteIndex === index) {
          return { ...editedCallRoute, [type]: value };
        }
        return editedCallRoute;
      })
    );
  };

  return (
    <div>
      <h1>{t('Call Routing Settings')}</h1>
      <div className='row new-form'>
        <div className='col-12'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>{t('Routing ID')}</th>
                <th>{t('Location')}</th>
                <th>{t('User')}</th>
                <th>{t('Remove')}</th>
              </tr>
            </thead>
            <tbody>
              {editedCallRoutes ? editedCallRoutes.sort((a, b) => a.number - b.number).filter(route => !route.delete).map((callRoute, index) => (
                <tr key={callRoute.number}>
                  <td>{`${callRoute.number}`.padStart(3, '0')}</td>
                  <td>
                    <Select
                      defaultValue={getFormattedLocationById(callRoute.location_id, locations)}
                      isDisabled={callRoute.number > 799}
                      options={locations.map(location => ({ value: location.id, label: location.name }))}
                      className='lavendla-select location-dropdown'
                      classNamePrefix='lavendla-select'
                      onChange={location => handleSelectChange('location_id', index, location.value)}
                      placeholder={t('-- Select a location --')}
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={callRoute.user_id ? formatUser(dropdownUsers.find(({ id }) => id === parseInt(callRoute.user_id))) : null}
                      isDisabled={callRoute.number < 800}
                      options={dropdownUsers.map(user => ({ value: user.id, label: user.name }))}
                      onChange={user => handleSelectChange('user_id', index, user.value)}
                      className='lavendla-select location-dropdown'
                      classNamePrefix='lavendla-select'
                      placeholder={t('-- Select a user --')}
                    />
                  </td>
                  <td>
                    <LoadingButton className='btn small' onClick={() => handleDeleteRow(callRoute.number)}>x</LoadingButton>
                  </td>
                </tr>
              )) : null}
              <tr>
                <td colSpan='2'>
                  <label>{t('Add new rote')}
                    <Select
                      value={newRoute}
                      options={getAvailableRoutes()}
                      className='lavendla-select location-dropdown'
                      classNamePrefix='lavendla-select'
                      onChange={route => setNewRoute(route)}
                      placeholder={t('-- Select a route --')}
                    />
                  </label>
                </td>
                <td colSpan='4'>
                  <div className='btn-group'>
                    <LoadingButton className='btn btn-primary' disabled={!newRoute} onClick={handleAddNewRoute}>{t('Add')}</LoadingButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
  );
}

CallRoutingSettings.propTypes = {
  country: PropTypes.object.isRequired,
  setParentRoutes: PropTypes.func.isRequired
};

export default CallRoutingSettings;
