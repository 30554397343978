import API from '../stores/API';
import { useTranslation } from 'react-i18next';
import { showNotice, showWarningNotice } from 'Library/notifications';

function useOfferActions (offer) {
  const { t } = useTranslation();

  const generateReceipt = async () => {
    const myWindow = window.open('');
    myWindow.document.write('\n\n<h1>' + t('Loading') + '...</h1>');
    const { status, url, message } = await API.GET(`/invoice/${offer.id}/receipt`);

    if (status === 'ok') {
      myWindow.location = url;
      myWindow.focus();

      return true;
    }

    showWarningNotice(t('An error occurred') + `: ${message}`);
    return false;
  };

  const refundPayment = async () => {
    const { status, message } = await API.POST(`/invoice/${offer.id}/refund`);

    if (status === 'ok') {
      window.location.reload();

      return true;
    }

    showWarningNotice(t('An error occurred') + `: ${message}`);
    return false;
  };

  const updateDeadline = async (deadline) => {
    const { newDeadline, message } = await API.POST(`/offer/update-deadline/${offer.id}`, { deadline });

    if (!newDeadline) {
      showWarningNotice(t('An error occurred') + `: ${message}`);
      return { success: false };
    }
    showNotice(t('Deadline updated') + `: ${newDeadline}`);
    return { success: true, newDate: newDeadline };
  };

  return {
    generateReceipt,
    refundPayment,
    updateDeadline
  };
}

export default useOfferActions;
