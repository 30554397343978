import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Translation from './Translation';
import Pagination from './Pagination';

const FrontendTranslations = ({ fileName, hideTranslated, translations }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const keyList = Object.keys(translations).filter(key => {
    if (hideTranslated && translations[key].translation && translations[key].translation !== key) {
      return;
    }
    return key;
  });

  const perPage = 500;
  const last = currentPage * perPage;
  const first = last - perPage;
  const currentKeyList = keyList.slice(first, last);

  return (
    <div>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} numberOfPages={Math.ceil(keyList.length / perPage)} isAtTop />
      <ul className='translations-list'>
        {currentKeyList.map((key) => {
          const { translation, context } = translations[key];
          return (<Translation key={key} fileName={fileName} original={key} contex={context} translation={translation} />);
        }
        )}
      </ul>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} numberOfPages={Math.ceil(keyList.length / perPage)} />
    </div>
  );
};

FrontendTranslations.propTypes = {
  fileName: PropTypes.string.isRequired,
  hideTranslated: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired
};

export default FrontendTranslations;
