import React from 'react';
import PropTypes from 'prop-types';
import { isCreateLawyerOrderTask, isCreateTombstoneOrderTask, isInvoiceTask } from 'Library/Tasks';
import { Notice } from 'Layout';

const IncompleteTasksWarning = ({ order, task }) => {
  if (!isInvoiceTask(task)) {
    return null;
  }

  const tasks = order.tasks.filter(task => isCreateLawyerOrderTask(task) || isCreateTombstoneOrderTask(task));
  if (tasks.every(task => !!task.pivot.completed)) {
    return null;
  }

  return (
    <Notice>
      <p>Du har inte skickat bouppteckningsärendet och/eller gravsättningsärendet vidare till juridik resp gravstenshandläggare.</p>
      <p>För att du ska få provision på dessa tjänster behöver dessa uppgifter slutföras.</p>
    </Notice>
  );
};

IncompleteTasksWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default IncompleteTasksWarning;
