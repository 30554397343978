import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOnly } from 'Conditionals';
import { LoadingButton } from 'FormElements';
import SupplierCustomColorsPreview from './SupplierCustomColorsPreview';
import useSupplier from 'ReduxHooks/useSupplier';

const SupplierCustomColors = () => {
  const { t } = useTranslation();
  const { supplier, updateField, persist } = useSupplier();
  const [isLoading, setIsLoading] = useState(true);
  const [colors, setColors] = useState({});

  useEffect(() => {
    if (supplier) {
      setColors(JSON.parse(supplier.custom_colors || null) || {});
      setIsLoading(false);
    }
  }, [supplier]);

  const updateSingleColor = (key, value) => {
    updateField('custom_colors', JSON.stringify({ ...colors, ...{ [key]: value } }));
  };

  const colorPickers = [
    [
      t('Primary color'),
      colors?.primary,
      (e) => { updateSingleColor('primary', e.target.value); }
    ],
    [
      t('Secondary color'),
      colors?.secondary,
      (e) => { updateSingleColor('secondary', e.target.value); }
    ],
    [
      t('Tertiary color'),
      colors?.tertiary,
      (e) => { updateSingleColor('tertiary', e.target.value); }
    ]
  ];

  const colorsCanBeSaved = () => {
    return (colors.primary || colors.secondary || colors.tertiary);
  };

  async function save () {
    setIsLoading(true);
    if (colorsCanBeSaved()) {
      await persist();
    }
    setIsLoading(false);
  }

  return (
    <div className='supplier-custom-colors'>
      <h4>{t('Color pickers')}</h4>
      <div className='supplier-custom-colors__pickers'>
        {colorPickers.map(([label, color, handler], index) => (
          <div key={index} className='supplier-custom-colors__picker'>
            <label
              style={{ '--color-picker-color': color }}
              htmlFor={`color-${index}`}
            >
              {label}:
            </label>
            <input
              type='color'
              id={`color-${index}`}
              value={color}
              onChange={handler}
            />
            <input type='text' value={color} onChange={handler} placeholder='#ffffff' />
          </div>
        ))}
      </div>

      <AdminOnly>
        <LoadingButton
          data-cy='submit'
          disabled={!colorsCanBeSaved()}
          isLoading={isLoading}
          onClick={() => save()}
        >
          {t('Save')}
        </LoadingButton>
      </AdminOnly>

      <SupplierCustomColorsPreview
        primaryColor={colors?.primary}
        secondaryColor={colors?.secondary}
        tertiaryColor={colors?.tertiary}
      />

    </div>
  );
};

export default SupplierCustomColors;
