import React from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox } from 'FormElements';
import { useTranslation } from 'react-i18next';

const ScheduledPhoneHours = ({ scheduledPhoneHours, index, update, remove, isActive, user }) => {
  const { t } = useTranslation();
  const roleTypes = ['funeral_advisor', 'legal_advisor', 'tombstone_advisor', 'therapy_user'].filter(roleType => user.roles.includes(roleType));

  const roleLabels = {
    funeral_advisor: t('Funeral'),
    legal_advisor: t('Law'),
    tombstone_advisor: t('Tombstone'),
    officiant_user: t('Officiant'),
    therapy_user: t('Therapy')
  };

  const updateExcludedRoles = (role) => {
    let excludedRoles = scheduledPhoneHours.excludedRoles ? [...scheduledPhoneHours.excludedRoles] : [];
    const roleIndex = excludedRoles.indexOf(role);

    if (roleIndex > -1) {
      excludedRoles.splice(roleIndex, 1);
    } else {
      excludedRoles.push(role);
    }

    if (excludedRoles.length === roleTypes.length) {
      excludedRoles = [];
    }

    update(index, { excludedRoles }, true);
  };

  return (
    <li className='scheduled-time'>
      <span className='scheduled-time__inputs-wrapper'>
        <strong className='scheduled-time__label'>{t('Phone hours')} {index + 1}:</strong>
        <Input
          type='time'
          disabled={!isActive}
          className='scheduled-time__time-input'
          value={scheduledPhoneHours.startTime || ''}
          onChange={value => update(index, { startTime: value }, true)}
        />
        <span className='scheduled-time__time-spacing'>{t('until')}</span>
        <Input
          type='time'
          disabled={!isActive}
          className='scheduled-time__time-input'
          value={scheduledPhoneHours.endTime || ''}
          onChange={value => update(index, { endTime: value }, true)}
        />
      </span>
      {roleTypes.length > 1 && (
        <span className='scheduled-time__options-labels-wrapper'>
          <span className='scheduled-time__checkbox-labels'>
            {roleTypes.map((roleType, index) => (
              <Checkbox
                key={index}
                label={roleLabels[roleType]}
                disabled={!isActive || (!scheduledPhoneHours.excludedRoles.includes(roleType) && scheduledPhoneHours.excludedRoles.length === roleTypes.length - 1)}
                checked={!(scheduledPhoneHours.excludedRoles && scheduledPhoneHours.excludedRoles.includes(roleType))}
                onChange={value => updateExcludedRoles(roleType)}
              />
            ))}
          </span>
        </span>
      )}
      <span className='scheduled-time__radio-labels-wrapper'>
        <button
          className='scheduled-time__remove-time'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            remove(index, true);
          }}
          disabled={!isActive}
        >
          {t('Remove')}
        </button>
      </span>
    </li>
  );
};

ScheduledPhoneHours.propTypes = {
  scheduledPhoneHours: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

export default ScheduledPhoneHours;
