import { SET_FLOWER_FEE, RESET_FLOWER_FEE } from '../actions/flowerFee';

const initialState = {
  flowerFee: {
    id: null,
    name: '',
    total: 0,
    totalTax: 0
  }
};

export default function flowerFee (state = initialState, { type, payload }) {
  switch (type) {
    case SET_FLOWER_FEE:
      return {
        ...state,
        flowerFee: payload
      };

    case RESET_FLOWER_FEE:
      return initialState;

    default:
      return state;
  }
}
