import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';

function File ({ updatedTask, removeFile, refProp }) {
  const { t } = useTranslation();
  const files = (updatedTask.files && updatedTask.files.length) ? updatedTask.files : [];

  return (
    <>
      <p className='input'><strong>
        {t('Attach a file that will always be sent with the task')}
      </strong></p>

      {files.map(file => (
        <div key={file.id} className='input'>
          {file.src.split('/').pop()}
          &nbsp;&nbsp;
          <LoadingButton
            className='small light'
            onClick={() => removeFile(file.id)}
          >
            {t('Remove the file')}
          </LoadingButton>
        </div>
      ))}

      <div className='input'>
        <input
          type='file'
          ref={refProp}
        />
      </div>
    </>
  );
}

File.propTypes = {
  updatedTask: PropTypes.object,
  removeFile: PropTypes.func,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
  ])
};

export default File;
