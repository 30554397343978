import React, { useState } from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useUsers from 'ReduxHooks/useUsers';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleUser from './SingleUser';
import Modal from 'Components/Modal';
import Spinner from 'Components/Spinner';

const OrderUsers = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { order, isFuneralOrder, isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder } = useSingleOrder();
  const { dropdownUsers, loadDropdownUsers } = useUsers();

  const getUserType = () => {
    switch (true) {
      case isFuneralOrder:
        return 'funeral_advisor';
      case isLawyerOrder:
        return 'legal_advisor';
      case isTombstoneOrder:
        return 'tombstone_advisor';
      case isTherapyOrder:
        return 'therapy_user';
      case isCeremonyOrder:
        return 'officiant_user';
      default:
        return '';
    }
  };

  const openModal = async () => {
    setIsLoading(true);
    setModalOpen(true);
    await loadDropdownUsers({ role: getUserType() });
    setIsLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <div className="order-users" id="order-users-wrapper">
        <button
          onClick={() => openModal()}
          className="order-users__list-item order-users__list__add-item"
        >
          <span className="icon">
            <FontAwesomeIcon
              icon={['fal', 'users']}
            />

            { order.users && order.users.length > 0 ? <span>{order.users.length}</span> : null }
          </span>
        </button>
      </div>
      <Modal
        close={closeModal}
        className="users-modal"
        headerTitle={t('Manage additional users')}
        isOpen={modalOpen}
        parentSelector={() => document.querySelector('#order-users-wrapper')}
      >
        <div>
          <div className="users-modal-info">
            <p>{t('This list contains users added to this order. Users included here can access and edit the order in the same manner as the assigned user. You may add or remove users from the list below.')}</p>
          </div>

          {isLoading && <Spinner/>}
          <h3>{t('Users added')}:</h3>

          {!isLoading && order.users && order.users.length ? (
            <ul className='modal-users-list order-users__list'>
              {order.users.map((user, index) => (
                <SingleUser key={index} user={user} removeItem/>
              ))}
            </ul>
          ) : (<p>{t('No users')}</p>)}
          <h3>{t('Available users')}:</h3>
          {!isLoading && dropdownUsers && dropdownUsers.length ? (
            <ul className='modal-users-list order-users__list'>
              {dropdownUsers.map((user, index) => (
                <SingleUser key={index} user={user} addItem disabled={order.users.map(t => t.id).includes(user.id)}/>
              ))}
            </ul>
          ) : (<p>{t('No available users')}</p>)}
        </div>
      </Modal>
    </>
  );
};

export default OrderUsers;
