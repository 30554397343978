import React from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import PersistFooter from './PersistFooter';
import OrderTasks from '../Tasks/OrderTasks';
import RemainingMandatoryFields from '../RemainingMandatoryFields';
import { If } from 'Conditionals';

function OrderTab ({
  children,
  tab = 'customer',
  header = null,
  afterTasks
}) {
  const { order } = useSingleOrder();

  if (!order) {
    return (
      <Spinner />
    );
  }

  return (
    <form className='order-tab__form' autoComplete='off'>
      <div className='order-tab__form__header'>
        <If condition={header}>
          <h3>{header}</h3>
        </If>
        <RemainingMandatoryFields
          tab={tab}
        />
      </div>
      {children}
      <OrderTasks
        tab={tab}
      />
      {afterTasks}
      <PersistFooter
        tab={tab}
      />
    </form>
  );
}

OrderTab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  tab: PropTypes.string.isRequired,
  header: PropTypes.string,
  afterTasks: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default OrderTab;
