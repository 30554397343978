import NoticeStore from '../stores/NoticeStore';

const store = new NoticeStore();

export function showNotice (notice = '', notificaionType = 'info', autoClose = 5000) {
  store.showNotice(notice, notificaionType, autoClose);
}

export function showWarningNotice (notice = '', autoClose = 5000) {
  store.showWarningNotice(notice, autoClose);
}
