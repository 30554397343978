import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getToken } from 'Components/localstorage';

const host = window && window.location && window.location.host;

let apiUrlParam = API_URL;

if (host.includes('netlify.app') || host.includes('localhost')) {
  apiUrlParam = localStorage.getItem('backend') || 'https://stage.api.lavendla.com/v1';
}

const backendOptions = {
  loadPath: `${apiUrlParam}/translations/{{lng}}`,
  crossDomain: true,
  customHeaders: {
    Accept: 'application/json',
    Authorization: 'Bearer ' + getToken()
  },
  parse: response => {
    const parsedResponse = JSON.parse(response);
    const parsedData = JSON.parse(parsedResponse.data);

    for (const key in parsedData) {
      parsedData[key] = parsedData[key].translation;
    }

    return parsedData;
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en_us',
    ns: ['general'],
    defaultNS: 'general',
    interpolation: {
      escapeValue: false
    },
    backend: backendOptions,
    updateMissing: false,
    saveMissing: false
  });

export default i18n;
