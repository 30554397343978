import { SET_NOTES } from '../actions/notes';

const initialState = {
  notes: []
};

export default function notes (state = initialState, { type, payload }) {
  switch (type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: payload
      };
    }

    default:
      return state;
  }
}
