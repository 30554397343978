import React from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useOrderAttendees from 'ReduxHooks/useOrderAttendees';
import { Trans, useTranslation } from 'react-i18next';

function NumAttendeesHeader () {
  const { getNumAttendees } = useOrderAttendees();
  const {
    order: {
      memorial: {
        max_attendees: maxAttendees
      }
    }
  } = useSingleOrder();
  const { t } = useTranslation();
  const numAttendees = getNumAttendees();
  const numQueued = getNumAttendees(true);

  if (!maxAttendees) {
    return (
      <h3>
        <Trans numAttendees={numAttendees}>
          Registered ({{ numAttendees }} total)
        </Trans>
      </h3>
    );
  }

  const str = (
    <Trans numAttendees={numAttendees} maxAttendees={maxAttendees}>
      Registered ({{ numAttendees }} of {{ maxAttendees }} total)
    </Trans>
  );

  return (
    <h3>
      {numQueued
        ? <span>{str} - <em>{numQueued} {t('in the queue')}</em></span>
        : <span>{str}</span>
      }
    </h3>
  );
}

export default NumAttendeesHeader;
