import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'FormElements';
import { useTranslation } from 'react-i18next';

const ToggleMemorialVisibility = ({ memorialPage, updateVisibility }) => {
  const { t } = useTranslation();
  const isVisible = memorialPage.lavendla_visibility === 'visible';

  const desc = (
    <span className='memorial-desc' onClick={e => e.stopPropagation()}>
      ({t('the page is visible to everyone on')}&nbsp;
      <a
        target='_blank'
        onClick={e => e.stopPropagation()}
        rel='noopener noreferrer'
        href={memorialPage.lavendla_post_url}
      >
        {memorialPage.lavendla_post_url}
      </a>)
    </span>
  );

  return (
    <div className='toggle-memorial-visiblity'>
      <Toggle
        desc={desc}
        toggleLabel={false}
        label={t('Visible in the archive')}
        defaultChecked={isVisible}
        onChange={() => updateVisibility(!isVisible)}
      />
    </div>
  );
};

ToggleMemorialVisibility.propTypes = {
  memorialPage: PropTypes.object.isRequired,
  updateVisibility: PropTypes.func.isRequired
};

export default ToggleMemorialVisibility;
