import useSingleOrder from '../Redux/hooks/useSingleOrder';
import store from '../stores/RootStore';
import API from '../stores/API';
import { useTranslation } from 'react-i18next';

function useDuplicateCustomer () {
  const { order } = useSingleOrder();
  const { t } = useTranslation();

  async function isDuplicated (customerPhone) {
    if (!customerPhone) {
      return;
    }

    const response = await API.POST('/orders/check-if-duplicated', {
      customer: {
        phone: customerPhone
      },
      order_class: order.class,
      country_code: order.country.code
    });

    if (!response || !response.data) {
      return;
    }

    let notice = t('It seems that the same customer has created several orders, please check these');
    let count = 0;

    for (const [, otherOrder] of response.data.entries()) {
      if (order.id === otherOrder.id) {
        continue;
      }

      if (order.class === 'App\\TombstoneOrder') {
        notice += `<br /><a href='/gravstenar/${otherOrder.id}' target='_blank'>#${otherOrder.number}</a>`;
      }

      if (order.class === 'App\\LawyerOrder') {
        notice += `<br /><a href='/juridik/${otherOrder.id}' target='_blank'>#${otherOrder.number}</a>`;
      }

      count++;
    }

    if (count > 0) {
      store.noticeStore.showNotice(notice, '', 10000);
    }
  }

  return {
    isDuplicated
  };
}

export default useDuplicateCustomer;
