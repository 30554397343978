import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSingleOrder from '../Redux/hooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const RemoveOrderForm = ({ submitResponse }) => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const [comment, updatecomment] = useState('');
  const [duplicate, updateDuplicate] = useState(order.duplicate);

  return (
    <div className='questions-form'>
      <h4>{t('Why do you want to delete the order?')}</h4>

      <p>{t('Feel free to explain in more detail than "they chose another agency" so we can learn lessons for the future. Thanks!')}</p>

      <form onSubmit={e => {
        e.preventDefault();

        submitResponse({
          comment,
          duplicate
        });
      }}
      >
        <p>
          <label htmlFor='comment-field'>{t('Comment')}</label>
          <textarea
            onChange={({ target }) => updatecomment(target.value)}
            id='comment-field'
            name='comment-field'
            rows={5}
            cols={100}
            value={comment}
          />
        </p>
        <p>
          <input
            id='duplicate'
            name='duplicate'
            type='checkbox'
            checked={duplicate}
            onChange={() => updateDuplicate(!duplicate)}
          />
          <label htmlFor='duplicate'>&nbsp;&nbsp;{t('Check this box if you delete because there is already another ongoing order with the same customer.')}</label>
        </p>
        <p>
          <button className='btn' disabled={comment === ''}>{t('Delete order')}</button>
        </p>
      </form>
    </div>
  );
};

RemoveOrderForm.propTypes = {
  submitResponse: PropTypes.func.isRequired
};

export default RemoveOrderForm;
