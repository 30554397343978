import React from 'react';
import MiniCalendar from '../MiniCalendar';
import Sidebar from '../Sidebar';

const DashboardSidebar = () => (
  <Sidebar>
    <div className='dashboard__sidebar'>
      <MiniCalendar />
    </div>
  </Sidebar>
);

export default DashboardSidebar;
