import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getSupplierTypes } from 'Library/functions';
import { useTranslation } from 'react-i18next';

function TypeDropdown ({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <Select
      options={getSupplierTypes()}
      defaltValue={value}
      onChange={type => onChange(type ? type.value : null)}
      placeholder={t('Choose typ')}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      isClearable
    />
  );
}

TypeDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default TypeDropdown;
