import { useState } from 'react';
import API from '../stores/API';
import { getEndpoint } from 'Library/OrderHelperFuntions';
import { showWarningNotice } from 'Library/notifications';
import useCountries from 'ReduxHooks/useCountries';

function useOrderArchives ({
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false,
  available = false,
  archives = false,
  deleted = false
}) {
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [percentageComplete, setPercentageComplete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { country } = useCountries();

  function getIncludes () {
    switch (true) {
      case isLawyerOrder:
      case isTombstoneOrder:
        return [
          'deceased',
          'appointments',
          'customer',
          'location',
          'user',
          'offers',
          'country',
          'tags'
        ];
      case isTherapyOrder:
        return [
          'appointments',
          'customer',
          'location',
          'user',
          'offers',
          'country',
          'tags'
        ];
      case isCeremonyOrder:
        return [
          'appointments',
          'customer',
          'location',
          'user',
          'offers',
          'country',
          'tags'
        ];
      default:
        return [
          'appointments',
          'funeral',
          'deceased',
          'customer',
          'location',
          'user',
          'memorial_page',
          'offers',
          'country',
          'tags'
        ];
    }
  }

  function getURI ({ status, userId, page, search, locationIds = [], tagIds = [], competenceAreas = [], favorite = false, unpaid = false }) {
    let uri = getEndpoint({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder });

    if (available) {
      uri += '/available';
    }

    uri += `?include=${getIncludes().join(',')}`;

    if (available) {
      uri += '&limit=100';
    }

    if (search) {
      uri += `&search=${search}`;
    }

    if (archives) {
      uri += '&with_archived=1';
    }

    if (deleted) {
      uri += '&trashed=1';
    }

    if (status.length) {
      uri += `&status=${status.join(',')}`;
    }

    if (locationIds.length) {
      uri += `&location_ids=${locationIds.join(',')}`;
    }

    if (tagIds.length) {
      uri += `&tag_ids=${tagIds.join(',')}`;
    }

    if (competenceAreas.length) {
      uri += `&competenceAreas=${competenceAreas.join(',')}`;
    }

    if (country.id) {
      uri += `&country_id=${country.id}`;
    }

    if (favorite === 'true' || favorite === true) {
      uri += '&favorite=1';
    }

    if (unpaid === 'true' || unpaid === true) {
      uri += '&unpaid=1';
    }

    uri += `&user_id=${userId}&page=${page}`;

    return uri;
  }

  async function getOrders (params) {
    setOrders([]);
    setPagination(null);
    setIsLoading(true);
    setPercentageComplete(null);

    const response = await API.GET(getURI(params));
    setIsLoading(false);

    if (response.status !== 'ok') {
      showWarningNotice('Det uppstod ett fel!');
      return;
    }

    const { data: orders, meta } = response;
    setOrders(orders);

    if (meta && meta.pagination) {
      setPagination(meta.pagination);
    }

    const ids = orders.map(({ id }) => id);
    await getPercentageComplete(ids);
  }

  async function getPercentageComplete (ids = []) {
    if (!ids.length) {
      return;
    }

    setPercentageComplete(ids.map(id => {
      return {
        id,
        percentage: 'loading'
      };
    }));

    let response;
    switch (true) {
      case isLawyerOrder:
        response = await API.GET(`/order-status/lawyer-orders?ids=${ids.join(',')}`);
        break;
      case isTombstoneOrder:
        response = await API.GET(`/order-status/tombstone-orders?ids=${ids.join(',')}`);
        break;
      case isTherapyOrder:
        response = await API.GET(`/order-status/therapy-orders?ids=${ids.join(',')}`);
        break;
      case isCeremonyOrder:
        response = await API.GET(`/order-status/ceremony-orders?ids=${ids.join(',')}`);
        break;
      default:
        response = await API.GET(`/order-status/orders?ids=${ids.join(',')}`);
        break;
    }

    if (response.status !== 'ok') {
      showWarningNotice('Det uppstod ett fel!');
      return;
    }

    setPercentageComplete(response.data);
  }

  function getPercentageCompleteForOrder (order) {
    if (!percentageComplete) {
      return null;
    }

    return percentageComplete.find(({ id }) => id === order.id);
  }

  return {
    orders,
    pagination,
    isLoading,
    getOrders,
    getPercentageCompleteForOrder
  };
}

export default useOrderArchives;
