import React from 'react';
import FlowerEditor from '../FlowerEditor';
import OrderTab from './OrderTab';
import OrderSupplierDropdown from '../OrderSupplierDropdown';
import { formatCurrency } from 'Library/functions';
import FlowerShopOrders from '../FlowerShopOrders';
import useFlowerOrderProducts from 'ReduxHooks/useFlowerOrderProducts';
import { useTranslation } from 'react-i18next';

function FlowersForm () {
  const { t } = useTranslation();
  const { order, flowers, getFlowersTotal } = useFlowerOrderProducts();

  return (
    <OrderTab
      header={t('Flowers')}
      tab='flowers'
    >
      <div className='row'>
        <div className='col-12'>
          {flowers.map(product => (
            <FlowerEditor
              key={product.pivot.product_resource_id}
              product={product}
            />
          ))}
        </div>
      </div>

      <p><strong>{t('Total:')} {formatCurrency(getFlowersTotal(), null, order.country.code, order?.currency)}</strong></p>

      <div className='row'>
        <OrderSupplierDropdown
          className='col-8'
          label={t('Florist')}
          type='blomleverantoer'
          noValueLabel={t('-- No florist selected --')}
        />
      </div>
      <FlowerShopOrders />
    </OrderTab>
  );
}

export default FlowersForm;
