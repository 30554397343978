import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isOfferTask } from 'Library/Tasks';

const TaskConfirmOffer = ({ task, order, confirmOrder }) => {
  const { t } = useTranslation();

  if (!isOfferTask(task) || order.status !== 'offer') {
    return null;
  }

  return (
    <div className='task-confirm-offer'>
      <a href='#'
        onClick={e => {
          e.preventDefault();
          confirmOrder();
        }}
      >{t('Confirm offer')}</a>
      <span>{t('Following consultation with the client')}</span>
    </div>
  );
};

TaskConfirmOffer.propTypes = {
  task: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  confirmOrder: PropTypes.func.isRequired
};

export default TaskConfirmOffer;
