import { useEffect, useState } from 'react';
import API from '../stores/API';
import useCountries from 'ReduxHooks/useCountries';
import { showWarningNotice } from 'Library/notifications';

function useGraphs (url, defaultParams = {}) {
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { country } = useCountries();

  useEffect(() => {
    getData();
  }, [params]);

  async function getData () {
    if (country.id) {
      params.country_id = country.id;
    }

    const args = Object.entries(params).map(([key, value]) => {
      return (value) ? `${key}=${value}` : null;
    }).filter(value => value).join('&');

    setIsLoading(true);
    const response = await API.GET(`${url}?${args}`);
    setIsLoading(false);

    if (response.status !== 'ok') {
      showWarningNotice(`💔 ${response.errors.error_message}`);
      return;
    }

    setData(response.data.graphData);
  }

  const setParam = (args = {}) => {
    setParams({
      ...params,
      ...args
    });
  };

  return {
    data,
    isLoading,
    params,
    setParam
  };
}

export default useGraphs;
