import React from 'react';
import PropTypes from 'prop-types';

const ImageListItem = ({
  image,
  setWpBgImage,
  wpBgImage,
  id: itemId,
  name
}) => {
  const { id, sizes } = image;

  return (
    <li className='image-list-item'>
      <input
        type='radio'
        name={name}
        id={itemId}
        value={id}
        onChange={() => setWpBgImage(image)}
        checked={id === wpBgImage}
      />
      <label htmlFor={itemId}>
        <img src={sizes.archive} />
      </label>
    </li>
  );
};

ImageListItem.propTypes = {
  image: PropTypes.object.isRequired,
  setWpBgImage: PropTypes.func.isRequired,
  wpBgImage: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  name: PropTypes.string.isRequired
};

export default ImageListItem;
