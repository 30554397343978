import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function FavoriteButton ({ order }) {
  const { toggleFavorite } = useSingleOrder();
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(order.favorite);

  async function toggle () {
    setIsLoading(true);
    const success = await toggleFavorite(order);
    if (success) {
      setIsFavorite(!isFavorite);
    }
    setIsLoading(false);
  }

  const icon = () => {
    if (isLoading) {
      return <FontAwesomeIcon icon={['fas', 'spinner']} spin />;
    }

    if (isFavorite) {
      return <FontAwesomeIcon icon={['fas', 'star']} />;
    }

    return <FontAwesomeIcon icon={['fal', 'star']} />;
  };

  return (
    <button
      className='favorite-button'
      disabled={isLoading}
      onClick={() => toggle()}
    >
      {icon()}
    </button>
  );
}

FavoriteButton.propTypes = {
  order: PropTypes.object
};

export default FavoriteButton;
