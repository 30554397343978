import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Checkbox, Input, Select, Textarea } from 'FormElements';
import OrderTab from './OrderTab';
import SocialSecurityNumber from './SocialSecurityNumber';
import YearPicker from '../YearPicker';
import OrderAppointments from '../Appointments/OrderAppointments';
import OrderSupplierDropdown from '../OrderSupplierDropdown';
import OrderDatePicker from '../OrderDatePicker';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import { If } from 'Conditionals';
import { getOrderFuneralPlace } from 'Library/OrderHelperFuntions';
import { useTranslation } from 'react-i18next';

/**
 * This is a bit complicated.
 *
 * We share as many fields as possible between the different order types,
 * but order.deceased is just more complicated than for the order case types.
 */
function DeceasedForm () {
  const { t } = useTranslation();
  const { order, updateField, updateFields, persist, isFuneralOrder, isTombstoneOrder, getCountryCode } = useSingleOrder();
  const { toggleCleanUpProduct, toggleEstateAgentEvaluationProduct, toggleEstateAgentSaleProduct, toggleOrderViewing } = useOrderProducts();
  const { deceased } = order;

  function getFuneralDate () {
    if (!order.order) {
      return '';
    }

    const ceremony = order.order.appointments.find(({ type }) => type === 'ceremony');
    if (!ceremony) {
      return t('Date not set');
    }

    return moment(ceremony.start_date).format('YYYY-MM-DD HH:mm');
  }

  function getFuneralPlace () {
    if (!order.order) {
      return '';
    }

    return getOrderFuneralPlace(order.order);
  }

  const onGetCustomerDetailsClick = (details) => {
    updateFields({
      deceased: {
        first_name: details.first_name,
        last_name: details.last_name,
        social_security_number: details.social_security_number,
        address: details.address2 ? details.address2 : details.address1,
        postcode: details.zip_code,
        city: details.city
      }
    });
  };

  async function onViewingChange (checked) {
    await updateField('deceased.viewing', checked);

    if (getCountryCode() === 'se') {
      await persist();
      await toggleOrderViewing(!checked);
    }
  }

  async function updateCleanUp (checked) {
    await updateField('deceased.clean_up', checked);

    if (getCountryCode() === 'se') {
      await persist();
      await toggleCleanUpProduct(!checked);
    }
  }

  async function updateEstateAgentEvaluation (checked) {
    await updateField('deceased.estate_agent_evaluation', checked);

    if (getCountryCode() === 'se' && isFuneralOrder) {
      await persist();
      await toggleEstateAgentEvaluationProduct(!checked);
    }
  }

  async function updateEstateAgentSale (checked) {
    await updateField('deceased.estate_agent_sale', checked);

    if (getCountryCode() === 'se' && isFuneralOrder) {
      await persist();
      await toggleEstateAgentSaleProduct(!checked);
    }
  }

  function hasFuneral () {
    if (!order.order) {
      return false;
    }

    const { funeral, appointments } = order.order;
    const ceremony = appointments.find(({ type }) => type === 'ceremony');

    return funeral && ceremony;
  }

  const inputProps = {
    className: 'col-4',
    required: !isTombstoneOrder,
    floatingLabel: true
  };

  return (
    <OrderTab
      tab='deceased'
      header={t('Deceased')}
      afterTasks={(
        <div className='row'>
          <Textarea
            className='col-12'
            value={deceased.note}
            onChange={value => updateField('deceased.note', value)}
            label={t('Other')}
            data-cy='deceased.note'
            floatingLabel
            rows={10}
          />
        </div>
      )}
    >
      <If condition={isFuneralOrder}>
        <SocialSecurityNumber
          onUpdate={onGetCustomerDetailsClick}
          onChange={socialSecurityNumber => updateField('deceased.social_security_number', socialSecurityNumber)}
          value={deceased.social_security_number}
          type={getCountryCode() === 'dk' ? 'cpr' : 'spar'}
          title={t('Deceased social security number')}
          isCustomer={false}
        />
        {getCountryCode() === 'dk' ? (
          <div className='row'>
            <Input
              value={deceased.member_of_state_church}
              label={t('Member of state church')}
              onChange={value => updateField('deceased.member_of_state_church', value)}
              {...inputProps}
              floatingLabel
            />

            <Input
              value={deceased.last_will}
              label={t('My last will')}
              onChange={value => updateField('deceased.last_will', value)}
              data-cy='deceased.last_will'
              {...inputProps}
            />
          </div>
        ) : null}
      </If>
      <div className='row'>
        <Input
          value={deceased.first_name}
          label={t('First name')}
          onChange={value => updateField('deceased.first_name', value)}
          data-cy='deceased.first_name'
          {...inputProps}
          showRequiredIcon
        />
        <Input
          value={deceased.last_name}
          label={t('Last name')}
          onChange={value => updateField('deceased.last_name', value)}
          data-cy='deceased.last_name'
          {...inputProps}
          showRequiredIcon
        />
        {isFuneralOrder ? (
          <Input
            value={deceased.nickname}
            label={t('Nickname')}
            onChange={value => updateField('deceased.nickname', value)}
            data-cy='deceased.nickname'
            {...inputProps}
          />
        ) : (
          <Input
            value={deceased.social_security_number}
            label={t('Social security number')}
            onChange={value => updateField('deceased.social_security_number', value)}
            data-cy='deceased.social_security_number'
            {...inputProps}
            showRequiredIcon
          />
        )}
      </div>
      {isTombstoneOrder ? (
        <div className='row'>
          <Input
            value={deceased.birth_year}
            label={t('Birth year')}
            onChange={value => updateField('deceased.birth_year', value)}
            data-cy='deceased.birth_year'
            {...inputProps}
          />
          <Input
            value={deceased.death_year}
            label={t('Death year')}
            onChange={value => updateField('deceased.death_year', value)}
            data-cy='deceased.death_year'
            {...inputProps}
          />
        </div>
      ) : null}
      {isFuneralOrder ? (
        <>
          <div className='row'>
            <Input
              value={deceased.address}
              label={t('Address')}
              onChange={value => updateField('deceased.address', value)}
              data-cy='deceased.address'
              showRequiredIcon
              {...inputProps}
            />
            <Input
              value={deceased.postcode}
              label={t('Post code')}
              onChange={value => updateField('deceased.postcode', value)}
              data-cy='deceased.postcode'
              showRequiredIcon
              {...inputProps}
            />
            <Input
              value={deceased.city}
              label={t('City')}
              onChange={value => updateField('deceased.city', value)}
              data-cy='deceased.city'
              showRequiredIcon
              {...inputProps}
            />
          </div>
          <div className='row'>
            <Input
              value={deceased.parish}
              label={t('Parish')}
              onChange={value => updateField('deceased.parish', value)}
              data-cy='deceased.parish'
              {...inputProps}
              showRequiredIcon
            />
            <OrderDatePicker
              field='deceased.date'
              label={t('Deceased date')}
              value={deceased.date}
              showRequiredIcon
            />
            <div className={classNames('col-4 react-select__floating-label')}>
              <YearPicker
                defaultYear={deceased.birth_year}
                onChange={value => updateField('deceased.birth_year', value)}
              />
              <label htmlFor='date-of-birth'>{t('Birth year')}</label>
            </div>
          </div>
          <div className='row'>
            <Select
              label={t('Clothing')}
              onChange={value => updateField('deceased.own_clothes', value)}
              value={deceased.own_clothes}
              defaultOption={t('-- Select --')}
              options={[
                t('No'),
                t('Yes, collect from Lavendla', {
                  description: 'Deceased clothing selected option'
                }),
                t('Yes, collect from relative', {
                  description: 'Deceased clothing selected option'
                }),
                t('Yes, collect from the morgue', {
                  description: 'Deceased clothing selected option'
                }),
                t('Yes, shirt', {
                  description: 'Deceased clothing selected option'
                })
              ]}
              data-cy='deceased.own_clothes'
              {...inputProps}
              showRequiredIcon
            />
            <OrderSupplierDropdown
              label={t('Morgue')}
              type='barhus'
              noValueLabel={t('-- No morgue selected --')}
              className='col-4'
            />
            <OrderDatePicker
              field='deceased.pick_up_date'
              label={t('Ready for pickup')}
              value={deceased.pick_up_date}
              showRequiredIcon
            />
          </div>
        </>
      ) : (
        <div className='row'>
          <If condition={hasFuneral()}>
            <Input
              value={getFuneralPlace()}
              label={t('Ceremony venue')}
              onChange={() => null}
              data-cy='order.ceremony-place'
              readOnly
              {...inputProps}
            />
            <Input
              value={getFuneralDate()}
              label={t('Ceremony date')}
              onChange={() => null}
              data-cy='order.ceremony-date'
              readOnly
              {...inputProps}
            />
          </If>
        </div>
      )}
      <div className='row'>
        <If condition={(deceased.estate_agent_evaluation || deceased.estate_agent_sale)}>
          <OrderSupplierDropdown
            label={t('Estate agent')}
            type='fastighetsmaklare'
            noValueLabel={t('-- No estate agent selected --')}
            className='col-6'
          />
        </If>

        <If condition={deceased.clean_up}>
          <OrderSupplierDropdown
            label={t('Deceased cleaning service')}
            type='tomning_dodsbo'
            noValueLabel={t('-- Select a cleaning service --')}
            className='col-6'
          />
        </If>
      </div>

      <If condition={!isTombstoneOrder}>
        <div className='checkbox-grouped-container'>
          <If condition={isFuneralOrder}>
            <>
              <Checkbox
                label={t('Viewing')}
                data-cy='deceased.viewing'
                checked={!!deceased.viewing}
                onChange={(checked) => onViewingChange(checked)}
              />
              <Checkbox
                label={t('Deceased cleaning service')}
                data-cy='deceased.clean_up'
                checked={!!deceased.clean_up}
                onChange={(checked) => updateCleanUp(checked)}
              />
            </>
          </If>
          <Checkbox
            label={t('Property evaluation')}
            data-cy='deceased.estate_agent_evaluation'
            defaultChecked={!!deceased.estate_agent_evaluation}
            onChange={(checked) => updateEstateAgentEvaluation(checked)}
          />
          <Checkbox
            label={t('Property sale')}
            data-cy='deceased.estate_agent_sale'
            defaultChecked={!!deceased.estate_agent_sale}
            onChange={(checked) => updateEstateAgentSale(checked)}
          />
        </div>
      </If>

      <If condition={deceased.viewing}>
        <OrderAppointments
          header={t('Viewing')}
          type='visning'
        />
      </If>
    </OrderTab>
  );
}

export default DeceasedForm;
