import React, { useState } from 'react';
import { Table } from 'Layout';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';
import PaymentInfoModal from 'Components/PaymentComponents/PaymentInfoModal';
import LavendlaModal from 'Components/Modal';
import ExpenseForm from './ExpenseForm';
import { formatCurrency, getTranslatedStripeStatus } from 'Library/functions';
import PropTypes from 'prop-types';

function ExpensesTable ({ order, updateField }) {
  const { t } = useTranslation();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [expenseModalOpen, setExpenseModalOpen] = useState(false);
  const expenses = order.expenses;

  const openExpenseModal = (expense) => {
    setEditingExpense(expense);
    setExpenseModalOpen(true);
  };

  const closeExpenseModal = () => {
    setExpenseModalOpen(false);
    setEditingExpense(null);
  };

  const saveExpense = (updatedExpense) => {
    const expenses = order.expenses.map(expense => {
      if (expense.id === updatedExpense.id) {
        return updatedExpense;
      }

      return expense;
    });

    // If the amount has changed we need to update the stripePayment on the order as well

    updateStripePayment(updatedExpense);

    updateField('expenses', expenses);
    closeExpenseModal();
  };

  const updateStripePayment = (updatedExpense, remove = false) => {
    const stripePaymentId = updatedExpense?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);

    if (!stripePayment) {
      return;
    }

    const stripePaymentItem = stripePayment.items.find(item => item.id === updatedExpense.stripePaymentItem.id);

    if (!stripePaymentItem) {
      return;
    }

    if (remove) {
      stripePayment.items = stripePayment.items.filter(item => item.id !== updatedExpense.stripePaymentItem.id);
    } else {
      stripePaymentItem.amount = updatedExpense.stripePaymentItem.amount;
    }

    stripePayment.amount = stripePayment.items.reduce((total, item) => total + parseFloat(item.amount), 0);

    updateField('stripePayments', order.stripePayments);
  };

  const deleteExpense = (expense) => {
    const expenses = order.expenses.filter(exp => exp.id !== expense.id);
    updateStripePayment(expense, true);
    updateField('expenses', expenses);
  };

  const getPaymentInformation = (expenseObject) => {
    const stripePaymentId = expenseObject?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return null;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);
    const offerId = stripePayment?.offer_id || null;

    if (!stripePayment) {
      return null;
    }

    return (
      <LoadingButton
        className='text' onClick={() => {
          setPaymentInfo(stripePayment);
          setModalIsOpen(true);
        }}
      >
        <span className={`payment-info__status ${stripePayment.status}`}><span>#{offerId} - </span>{getTranslatedStripeStatus(stripePayment.status)}</span>
      </LoadingButton>
    );
  };

  const canDeleteExpense = (expense) => {
    const stripePaymentId = expense?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return true;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);

    if (!stripePayment) {
      return true;
    }

    return stripePayment.status === 'pending';
  };

  const getPrice = (expense) => {
    if (!expense.stripePaymentItem) {
      return null;
    }

    return formatCurrency(expense.stripePaymentItem.amount, null, order.countryCode, order?.currency);
  };

  return (
    <>
      <Table
        isLoading={false}
        thead={[
          t('Description'),
          t('Price'),
          t('Payment'),
          t('Actions')
        ]}
      >
        {expenses && expenses.length > 0 ? (
          <>
            {expenses.map((expense) => (
              <tr key={expense.id}>
                <td>{expense.description}</td>
                <td>{getPrice(expense)}</td>
                <td>{getPaymentInformation(expense)}</td>
                <td>
                  <LoadingButton
                    className='btn small white'
                    onClick={() => openExpenseModal(expense)}
                  >
                    {t('Edit')}
                  </LoadingButton>
                  {canDeleteExpense(expense) && (
                    <>
                  &nbsp;
                      <LoadingButton
                        className='btn small red'
                        onClick={() => deleteExpense(expense)}
                      >
                        {t('Delete')}
                      </LoadingButton>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan='4'>
              <span>{t('No expenses')}</span>
            </td>
          </tr>
        )}
      </Table>
      {paymentInfo && (
        <PaymentInfoModal
          stripePayment={paymentInfo}
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
            setPaymentInfo(null);
          }}
        />
      )}
      <LavendlaModal
        className='category-modal'
        close={closeExpenseModal}
        headerTitle={editingExpense && editingExpense.id ? t('Edit expense') : t('Add expense')}
        isOpen={expenseModalOpen}
        parentSelector={() => document.querySelector('#customer-meeting-wrapper')}
      >
        {editingExpense && (
          <ExpenseForm
            expense={editingExpense}
            onChange={(updatedExpense) => setEditingExpense(updatedExpense)}
            onSave={() => saveExpense(editingExpense)}
            onCancel={closeExpenseModal}
          />
        )}
      </LavendlaModal>
    </>
  );
}

ExpensesTable.propTypes = {
  order: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired
};

export default ExpensesTable;
