import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const GoogleCalendarAccessIcon = ({ id }) => {
  const { t } = useTranslation();

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('Has access to Google Calendar')}</h3>
    </div>
  );

  return (
    <div className='registry-icons'>
      <FontAwesomeIcon
        aria-label={t('Google Calendar')}
        icon={['fa-brands', 'google']}
        data-tip='tooltip'
        data-for={`google-calendar-access-${id}`}
        className='registry-icons__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`google-calendar-access-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

GoogleCalendarAccessIcon.propTypes = {
  id: PropTypes.number
};

export default GoogleCalendarAccessIcon;
