import React from 'react';
import moment from 'moment';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Trans, useTranslation } from 'react-i18next';

const RelatedOrders = () => {
  const { t } = useTranslation();
  const { order, isLawyerOrder, isTombstoneOrder, isTherapyOrder } = useSingleOrder();
  const { related_orders: relatedOrders } = order;

  if (!relatedOrders || !relatedOrders.length) {
    return null;
  }

  const getLinkToOrder = (orderId) => {
    if (isLawyerOrder) {
      return `/juridik/${orderId}`;
    }

    if (isTombstoneOrder) {
      return `/gravstenar/${orderId}`;
    }

    if (isTherapyOrder) {
      return `/therapy/${orderId}`;
    }

    return `/ordrar/order/${orderId}`;
  };

  const numOrders = relatedOrders.length;
  const numOrdersString = (numOrders === 1) ? t('1 order') : `${numOrders} ${t('orders')}`;
  const ordersText = (numOrders === 1) ? t('this order') : t('these orders');

  return (
    <div className='related-orders'>
      <p>
        <Trans numOrders={numOrdersString} ordersText={ordersText}>
          An additional {{ numOrdersString }} have been created over the past 2 days. Please check {{ ordersText }} and transfer all relevant information to a single order.
        </Trans>
        <br/><br/>
        <Trans numOrders={ordersText}>
          Remove subsequenty {{ ordersText }} so as to avoid duplicated orders.
        </Trans>
      </p>

      {relatedOrders.map(relatedOrder => (
        <span
          key={relatedOrder.id}
          className='related-orders__order-link'
        >
          <a href={getLinkToOrder(relatedOrder.id)}>{t('Show order')} #{relatedOrder.number}</a>
          &nbsp;&nbsp;({t('created')} {moment(relatedOrder.created_at).fromNow()})<br/>
        </span>
      ))}
    </div>
  );
};

export default RelatedOrders;
