import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { locales } from 'Library/languages';

const Translate = () => {
  const { t } = useTranslation();

  return (
    <div className='row align-top'>
      <div className='col-12'>
        <h2 className='page-title'>{t('Translate')}</h2>
        <ul>
          {locales.map((file, index) => (
            <li key={index}>
              <Link to={`/translate/${file}`}>{file}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Translate;
