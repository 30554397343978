export const getStarsUrl = (rating) => {
  const baseUrl = 'trustpilot/stars';
  if (rating < 0.2) {
    return `${baseUrl}/0.svg`;
  }

  if (rating < 0.7) {
    return `${baseUrl}/0.5.svg`;
  }

  if (rating < 1.2) {
    return `${baseUrl}/1.svg`;
  }

  if (rating < 1.7) {
    return `${baseUrl}/1.5.svg`;
  }

  if (rating < 2.2) {
    return `${baseUrl}/2.svg`;
  }

  if (rating < 2.7) {
    return `${baseUrl}/2.5.svg`;
  }

  if (rating < 3.2) {
    return `${baseUrl}/3.svg`;
  }

  if (rating < 3.7) {
    return `${baseUrl}/3.5.svg`;
  }

  if (rating < 4.2) {
    return `${baseUrl}/4.svg`;
  }

  if (rating < 4.7) {
    return `${baseUrl}/4.5.svg`;
  }

  return `${baseUrl}/5.svg`;
};

export const getStarLabel = (rating) => {
  if (rating < 1.8) {
    return 'Mycket dålig';
  }

  if (rating < 2.8) {
    return 'Dålig';
  }

  if (rating < 3.8) {
    return 'Medel';
  }

  if (rating < 4.3) {
    return 'Bra';
  }

  return 'Utmärkt';
};
