import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Select } from 'FormElements';
import Editor from '../../Editor';
import { useTranslation } from 'react-i18next';

function General ({ updatedTask, updateValue }) {
  const { t } = useTranslation();
  function getTabOptions () {
    let tabs = {
      '': t('-- Select a tab --'),
      customer: t('Customer'),
      deceased: t('Deceased')
    };

    if (updatedTask.type !== 'lawyer_order') {
      tabs.entombment = t('Entombment');
    }

    if (updatedTask.type === 'therapy_order') {
      tabs.sessions = t('Sessions');
    }

    if (updatedTask.type === 'order') {
      tabs = {
        ...tabs,
        ...{
          funeral: t('Funeral'),
          memorial: t('Memorial service'),
          memorial_page: t('Memorial page'),
          transport: t('Transport'),
          flowers: t('Flowers')
        }
      };
    }

    tabs.products = t('Products');

    return tabs;
  }

  return (
    <>
      <Input
        label={t('Title')}
        floatingLabel
        data-cy='task-name'
        value={updatedTask.name}
        onChange={name => updateValue({ name })}
      />

      <div className='editor'>
        <label htmlFor='description'>
          {t('Description')}
        </label>
        <Editor
          id='description'
          data={updatedTask.description}
          onChange={description => updateValue({ description })}
        />
      </div>

      <Select
        floatingLabel
        data-cy='task-type'
        value={updatedTask.type}
        label={t('Type of task')}
        options={{
          order: t('Funeral order'),
          lawyer_order: t('Legal order'),
          tombstone_order: t('Tombstone order'),
          therapy_order: t('Therapy order'),
          ceremony_order: t('Ceremony order')
        }}
        onChange={type => updateValue({ type })}
      />

      <Input
        floatingLabel
        type='number'
        data-cy='task-minutes_to_log'
        label={t('Duration for the task (in minutes)')}
        value={updatedTask ? updatedTask.minutes_to_log : ''}
        onChange={minutesToLog => updateValue({ minutes_to_log: minutesToLog })}
      />

      <Select
        floatingLabel
        label={t('Priority')}
        data-cy='task-priority'
        value={updatedTask.priority}
        defaultOption='-- Välj --'
        options={{
          1: t('High priority'),
          2: t('Medium priority'),
          3: t('Low priority')
        }}
        onChange={priority => updateValue({ priority })}
      />

      <Select
        floatingLabel
        data-cy='task-order_tab'
        value={updatedTask.order_tab}
        label={t('Which tab should the task be visible on')}
        options={getTabOptions()}
        onChange={value => updateValue({ order_tab: value })}
      />

      <div className='checkbox-grouped-container'>
        <Checkbox
          data-cy='task-optional'
          label={t('Optional')}
          defaultChecked={updatedTask.optional}
          onChange={value => updateValue({ optional: value })}
        />

        <Checkbox
          data-cy='task-hide_email_button'
          label={t('Hide the send email button')}
          defaultChecked={updatedTask.hide_email_button}
          onChange={value => updateValue({ hide_email_button: value })}
        />

        <Checkbox
          data-cy='task-hide_confirmation_button'
          label={t('Hide the confirmation link in the email')}
          defaultChecked={updatedTask.hide_confirmation_button}
          onChange={value => updateValue({ hide_confirmation_button: value })}
        />

        <Checkbox
          data-cy='task-hide_completed_button'
          label={t('Remove the checkbox to mark the task as complete')}
          defaultChecked={updatedTask.hide_completed_button}
          onChange={value => updateValue({ hide_completed_button: value })}
        />

        <Checkbox
          data-cy='task-allowlist'
          label={t('The task can be sent even if required data is missing')}
          defaultChecked={updatedTask.allowlist}
          onChange={value => updateValue({ allowlist: value })}
        />
        <Checkbox
          data-cy='task-is_offer_task'
          label={t('Add offer link to email') + ' (' + t('Therapy') + ')'}
          defaultChecked={updatedTask.is_payment_task}
          onChange={value => updateValue({ is_payment_task: value })}
        />
        <Checkbox
          data-cy='task-is_offer_task'
          label={t('Add offer link to email')}
          defaultChecked={updatedTask.is_offer_task}
          onChange={value => updateValue({ is_offer_task: value })}
        />
        <Checkbox
          data-cy='task-is_updated_offer_task'
          label={t('Add updated offer link to email')}
          defaultChecked={updatedTask.is_updated_offer_task}
          onChange={value => updateValue({ is_updated_offer_task: value })}
        />
        <Checkbox
          data-cy='task-is_invoice_task'
          label={t('Add invoice link to email')}
          defaultChecked={updatedTask.is_invoice_task}
          onChange={value => updateValue({ is_invoice_task: value })}
        />
        <Checkbox
          data-cy='task-send_trustpilot_email'
          label={t('Send review invitation from Trustpilot')}
          defaultChecked={updatedTask.send_trustpilot_email}
          onChange={value => updateValue({ send_trustpilot_email: value })}
        />
        <Checkbox
          data-cy='task-send_google_review_email'
          label={t('Send review invitation for Google My Business')}
          defaultChecked={updatedTask.send_google_review_email}
          onChange={value => updateValue({ send_google_review_email: value })}
        />
        <Checkbox
          data-cy='task-inmemory'
          label={t('Send to InMemory')}
          defaultChecked={updatedTask.inmemory}
          onChange={value => updateValue({ inmemory: value })}
        />
      </div>
    </>
  );
}

General.propTypes = {
  updatedTask: PropTypes.object,
  updateValue: PropTypes.func
};

export default General;
