import { ROLES } from '../Redux/actions/users';
import { useTranslation } from 'react-i18next';

export function getRoleLabel (role) {
  switch (role) {
    case ROLES.ADMIN:
      return 'Admin';

    case ROLES.FUNERAL:
      return 'Funeral advisor';

    case ROLES.LEGAL:
      return 'Lawyer';

    case ROLES.TOMBSTONE:
      return 'Gravestone manager';

    case ROLES.SUPPLIER:
      return 'Supplier';

    case ROLES.THERAPIST:
      return 'Therapist';

    case ROLES.OFFICIANT:
      return 'Officiant';
  }
}

export function translateRole (role) {
  const { t } = useTranslation();
  return t(getRoleLabel(role));
}

export const getAllRoles = () => {
  const { t } = useTranslation();
  return {
    [ROLES.ADMIN]: t('Admin'),
    [ROLES.FUNERAL]: t('Funeral advisor'),
    [ROLES.LEGAL]: t('Lawyer'),
    [ROLES.TOMBSTONE]: t('Gravestone manager'),
    [ROLES.SUPPLIER]: t('Supplier'),
    [ROLES.THERAPIST]: t('Therapist'),
    [ROLES.OFFICIANT]: t('Officiant')
  };
};
