import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserDropdown from '../UserDropdown';
import useBonusReports from 'ReduxHooks/useBonusReports';
import UserReports from './UserReports';
import { useHistory } from 'react-router-dom';
import UnpaidTotals from './UnpaidTotals';
import { AdminOnly, If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'FormElements';

function BonusReports ({ match }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { generateCSVReport } = useBonusReports();
  const [isGeneratingCSVReport, setIsGeneratingCSVReport] = useState(false);
  const userId = match.params.userId ? parseInt(match.params.userId) : null;

  const changeUser = id => {
    const uri = id ? `/ersattningsrapporter/user/${id}` : '/ersattningsrapporter';
    history.push(uri);
  };

  const generateReport = async () => {
    setIsGeneratingCSVReport(true);
    await generateCSVReport();
  };

  return (
    <div className='bonus-reports'>
      <AdminOnly>
        <div className='bonus-reports__header'>
          <h2 className='page-title'>{t('Bonus reports')}</h2>
          <UnpaidTotals />
          <div className='row'>
            <div className='col-12'>
              <LoadingButton
                onClick={generateReport}
                disabled={isGeneratingCSVReport}
              >
                {t('Generate CSV file and email')}
              </LoadingButton>

              <If condition={isGeneratingCSVReport}>
                <p><em>{t('The bonus report csv is being generated, and will then be emailed to you.')}</em></p>
              </If>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <UserDropdown
              all
              withArchived
              user={userId}
              onChange={changeUser}
            />
          </div>
        </div>
      </AdminOnly>

      <UserReports userId={userId} />
    </div>
  );
}

BonusReports.propTypes = {
  match: PropTypes.object.isRequired
};

export default BonusReports;
