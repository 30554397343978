import API from '../stores/API';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useState } from 'react';
import store from '../stores/RootStore';

function useLoggedTimes () {
  const { order } = useSingleOrder();
  const [times, setTimes] = useState([]);

  async function all () {
    const { data, status, errors } = await API.GET(`/time-logged/${order.id}?order_type=${order.class}`);

    if (status !== 'ok') {
      store.noticeStore.showWarningNotice(`💔 ${errors.error_message}`);
    }

    setTimes(data);
  }

  async function create (data = {}) {
    const response = await API.POST(`/time-logged/${order.id}`, { ...data, order_type: order.class });

    createUpdateCallback(response);
  }

  async function update (loggedTimeId, data = {}) {
    const response = await API.PUT(`/time-logged/${loggedTimeId}`, { ...data, order_type: order.class });

    createUpdateCallback(response, loggedTimeId);
  }

  async function destroy (loggedTimeId) {
    const { status, errors } = await API.DELETE(`/time-logged/${loggedTimeId}`);

    if (status !== 'ok') {
      store.noticeStore.showWarningNotice(`💔 ${errors.error_message}`);
    }

    const timesCopy = [...times];
    const index = timesCopy.findIndex(time => time.id === loggedTimeId);

    timesCopy.splice(index, 1);

    setTimes(timesCopy);
  }

  async function sendReport (data = {}) {
    return API.POST(`/time-logged/${order.id}/send-report`, {
      ...data,
      order_type: order.class
    });
  }

  function createUpdateCallback ({ status, errors, data }, id = null) {
    if (status === 'error') {
      store.noticeStore.showWarningNotice(errors.error_message);
      return;
    }

    const timesCopy = [...times];

    if (id) {
      const index = timesCopy.findIndex(time => time.id === id);
      timesCopy[index] = data;
    } else {
      timesCopy.unshift(data);
    }

    setTimes(timesCopy);
  }

  return {
    times,
    all,
    create,
    update,
    destroy,
    sendReport
  };
}

export default useLoggedTimes;
