import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notice } from 'Layout';
import { addMinutes, formatDistanceToNowStrict } from 'date-fns';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useInvitations from 'ReduxHooks/useInvitations';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useDateLocale from 'Hooks/useDateLocale';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'FormElements';

function QueueWarning () {
  const history = useHistory();
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { loggedInUser } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const { rejectAcceptInvitation } = useInvitations();
  const { getDateLocale } = useDateLocale();
  const { order_offer_invitations: queueInfo } = order;
  const [isRejected, setIsRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const unvalidQueueInfo = !queueInfo || Array.isArray(queueInfo) || queueInfo.userId !== loggedInUser.id;

  const getTimeLeft = async () => {
    if (unvalidQueueInfo) {
      return null;
    }

    const date = addMinutes(new Date(queueInfo.sent), queueInfo.expires);
    const locale = await getDateLocale();

    return formatDistanceToNowStrict(date, { locale });
  };

  useEffect(() => {
    async function getTime () {
      try {
        const time = await getTimeLeft();
        if (!time) {
          return;
        }

        setTimeLeft(time);
      } catch (err) {
        console.error(err);
      }
    }
    getTime();
  }, []);

  if (unvalidQueueInfo) {
    return null;
  }

  const acceptReject = async type => {
    setIsLoading(true);

    const response = await rejectAcceptInvitation(queueInfo.id, type);
    if (response.status !== 'ok') {
      return;
    }

    if (type === 'accept') {
      setIsAccepted(true);
      return;
    }

    setIsRejected(true);

    setTimeout(() => {
      history.push('/');
    }, 1000);
  };

  return (
    <AnimatePresence initial={false}>
      {!isAccepted && (
        <motion.div
          className='m-mg-vert'
          exit={{ opacity: 0, height: 0, transition: { duration: 0.2 } }}
        >
          <Notice
            type='notice'
            heading={t('Accept invitation')}
          >
            {!isRejected ? (
              <>
                <p>{t('You need to accept the invitation within')} <strong>{timeLeft}</strong>.</p>
                <p className='queue-warning__button-container'>
                  <LoadingButton
                    isLoading={isLoading}
                    className='btn'
                    onClick={() => acceptReject('accept')}>{t('Accept')}
                  </LoadingButton>

                  <em> {t('or')} </em>

                  <LoadingButton
                    isLoading={isLoading}
                    className='btn'
                    onClick={() => acceptReject('reject')}>{t('reject')}
                  </LoadingButton>
                </p>
              </>
            ) : (
              <p>{t('You have rejected the invitation. Redirecting to the start page...')}</p>
            )}
          </Notice>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default QueueWarning;
