import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Card = ({ children, classlist = '' }) => (
  <div className={classNames('lav-card', classlist)}>
    {children}
  </div>
);

Card.propTypes = {
  classlist: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Card;
