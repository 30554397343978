import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useSettings from 'ReduxHooks/useSettings';
import { useTranslation } from 'react-i18next';

function HeaderSearch () {
  const { getSetting } = useSettings();
  const { isAdmin, isLegalAdvisor, isFuneralAdvisor, isTombstoneAdvisor, isTherapist, isOfficiant } = useLoggedInUser();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [pathname, setPathname] = useState('ordrar');
  const { t } = useTranslation();

  useEffect(() => {
    const searchTypes = Object.keys(dropddownOptions());
    setPathname(searchTypes[0]);
  }, []);

  const submit = e => {
    e.preventDefault();

    if (search === '') {
      return;
    }

    history.push(`/${pathname}/sok?search=${search.replace('#', '').trim()}`);
  };

  const resetSearch = e => {
    e.preventDefault();

    setSearch('');

    history.push(`/${pathname}`);
  };

  function dropddownOptions () {
    const orderTypes = {};

    if (isAdmin || isFuneralAdvisor) {
      orderTypes.ordrar = t('Funeral orders');
    }

    if (isAdmin || isLegalAdvisor) {
      orderTypes.juridik = t('Law orders');
    }

    if (isAdmin || isTombstoneAdvisor) {
      orderTypes.gravstenar = t('Tombstone orders');
    }

    if (isAdmin || isTherapist) {
      orderTypes.therapy = t('Therapy orders');
    }

    if (isAdmin || isOfficiant) {
      orderTypes.ceremonies = t('Ceremony orders');
    }

    if (isAdmin) {
      orderTypes.wills = t('Wills');
    }

    return orderTypes;
  }

  if (!isAdmin && !isLegalAdvisor && !isFuneralAdvisor && !isTombstoneAdvisor && !isTherapist && !isOfficiant) {
    return null;
  }

  return (
    <div className={classNames('header-search', 'search-group', { 'search-open': getSetting('showSearchbar') })}>
      <form onSubmit={submit}>
        <select
          className='rounded'
          value={pathname}
          onChange={({ target }) => setPathname(target.value)}
        >
          {Object.entries(dropddownOptions()).map(([key, value]) => (
            <option value={key} key={key}>{value}</option>
          ))}
        </select>
        <div className='search-input-group'>
          <input
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            type='text'
            className='form-control'
            placeholder={t('Search by order, deceased, ceremony...')}
          />
          <button className='search-button' type='submit'>
            <ReactSVG src='/icons/icon-search.svg' />
          </button>
        </div>
        <a href='#' onClick={resetSearch} className={search === '' ? 'reset-link hidden' : 'reset-link'}>
          <ReactSVG src='/icons/icon-close-black.svg' />
          <span>{t('Reset')}</span>
        </a>
      </form>
    </div>
  );
}

export default HeaderSearch;
