import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Textarea } from 'FormElements';
import { If } from 'Components/Conditionals';
import API from '../../stores/API';

const Translation = ({ original, contex, translation, fileName }) => {
  const [translationState, setTranslationState] = useState(translation);
  const [dirty, setDirty] = useState(false);

  const saveTranslation = async () => {
    const result = await API.PUT(`/translations/${fileName}`, {
      key: original,
      context: contex,
      translation: translationState
    });

    if (result.data) {
      setDirty(false);
    }
  };

  const setValue = (value) => {
    setTranslationState(value);
    setDirty(true);
  };

  return (
    <li>
      <Textarea label='Original' value={original} disabled onChange={value => {}} />
      <If condition={contex}>
        <Textarea label='Context' value={contex} disabled onChange={value => {}} />
      </If>
      <Textarea label='Translation' value={translationState} onChange={value => setValue(value)} />
      <If condition={dirty}>
        <br />
        <button
          className='btn small'
          onClick={() => saveTranslation()}
        >
          Spara
        </button>
      </If>
    </li>
  );
};

Translation.propTypes = {
  original: PropTypes.string.isRequired,
  contex: PropTypes.string,
  translation: PropTypes.string,
  fileName: PropTypes.string.isRequired
};

export default Translation;
