import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './SummaryCard';
import { useTranslation } from 'react-i18next';

const DeceasedInfo = ({
  deceasedDetails: {
    birthYear,
    deceasedYear,
    name
  }
}) => {
  const { t } = useTranslation();
  const details = [
    {
      title: t('Name of the deceased'),
      description: name
    },
    {
      title: t('Birth year'),
      description: birthYear || '-'
    },
    {
      title: t('Deceased year'),
      description: (deceasedYear && deceasedYear !== 'Invalid date') ? deceasedYear : '-'
    }
  ];

  return (
    <SummaryCard
      heading={t('Information on the deceased')}
      subHeading={t('These details can be edited on the Deceased tab.')}
      details={details}
    />
  );
};

DeceasedInfo.propTypes = {
  deceasedDetails: PropTypes.object
};

export default DeceasedInfo;
