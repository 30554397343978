import React from 'react';
import PropTypes from 'prop-types';
import File from './File';
import { useTranslation } from 'react-i18next';

function FileList ({ files = [], header = '' }) {
  const { t } = useTranslation();
  return (
    <div className='files-list-container'>
      {header && (
        <h4 className='files-list-container__header'>{header}</h4>
      )}
      {files.length ? (
        <table className='files-list'>
          <thead>
            <tr>
              <th>{t('File name')}</th>
              <th>{t('Created')}</th>
              <th>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => (
              <File
                key={file.id}
                file={file}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p>{t('No documents found')}</p>
      )}
    </div>
  );
}

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  header: PropTypes.string
};

export default FileList;
