import React from 'react';
import PropTypes from 'prop-types';
import { canSendTransportTask } from 'Library/Tasks';
import { Notice } from 'Layout';

function TransportTaskWarning ({ order, task }) {
  if (canSendTransportTask(order, task)) {
    return null;
  }

  const bookingTask = order.tasks.find(({ id }) => id === 2);

  return (
    <Notice>
      <p>Du behöver skicka uppgiften <strong>{bookingTask.name}</strong> innan du beställer transporten, då kyrkogårdsförvaltningen kräver underlag från oss innan vi transporterar kistan till de. Annars riskerar vi att vi inte får lämna av kistan och får åka dubbelt.</p>
    </Notice>
  );
}

TransportTaskWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default TransportTaskWarning;
