import React from 'react';
import PropTypes from 'prop-types';
import OrderSidebar from 'Components/OrderSidebar';
import SidebarMobileToggle from 'Components/Sidebar/SidebarMobileToggle';
import OrderHeader from 'Components/OrderTabs/OrderHeader';
import { useTranslation } from 'react-i18next';

function SingleWrapper ({ children }) {
  const { t } = useTranslation();
  return (
    <div className='order-main case-single-order'>
      <div className='order-content'>
        <OrderHeader/>
        {children}
      </div>

      <OrderSidebar/>
      <div className='mobile-toggle-sidebar__container'>
        <SidebarMobileToggle>
          <span className='mobile-toggle-sidebar__wrapper'>
            <span className='mobile-toggle-sidebar__open mobile-toggle-sidebar__text'>{t('View notes and time log')}</span>
            <span className='mobile-toggle-sidebar__close mobile-toggle-sidebar__text'>{t('Hide notes and time log')}</span>
          </span>
        </SidebarMobileToggle>
      </div>
    </div>
  );
}

SingleWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default SingleWrapper;
