import React from 'react';
import PropTypes from 'prop-types';
import LavendlaDatePicker from '../../LavendlaDatePicker';
import { format, subWeeks, startOfWeek } from 'date-fns';
import UserDropdown from '../../UserDropdown';
import Select from '../../FormElements/Select';
import { AdminOnly } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function GraphFilters ({ params = {}, setParam, orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const dateFormat = 'yyyy-MM-dd';

  function getUserSelectProps () {
    if (orderType === 'App\\LawyerOrder') {
      return {
        lawyers: true
      };
    }
    if (orderType === 'App\\TombstoneOrder') {
      return {
        tombstoneUsers: true
      };
    }

    if (orderType === 'App\\TherapyOrder') {
      return {
        therapists: true
      };
    }

    return {};
  }

  return (
    <form className='datepicker-form'>
      <div className='datepicker-container start-datepicker'>
        <label htmlFor='start_date'>{t('Start date')}</label>
        <LavendlaDatePicker
          date={params.start_date}
          onChange={date => {
            const startDate = date || format(startOfWeek(subWeeks(new Date(), 9)), dateFormat);
            setParam({ start_date: startDate });
          }}
          maxDate={new Date()}
        />
      </div>

      <div className='datepicker-container'>
        <label htmlFor='end_date'>{t('End date')}</label>
        <LavendlaDatePicker
          placeholder={t('Today')}
          date={params.end_date}
          onChange={date => setParam({ end_date: date || null })}
          maxDate={new Date()}
        />
      </div>

      <AdminOnly>
        <div className='datepicker-container'>
          <label htmlFor='order-stats-site-picker'>{t('Select user')}</label>
          <UserDropdown
            {...getUserSelectProps()}
            user={params.user_id}
            placeholder={t('All users')}
            onChange={userId => setParam({ user_id: userId })}
          />
        </div>
      </AdminOnly>

      <AdminOnly>
        <div className='site-picker-container'>
          <label htmlFor='order-stats-display-picker'>{t('Select view')}</label>
          <Select
            id='order-stats-display-picker'
            value={params.sortBy}
            onChange={sortBy => setParam({ sortBy })}
            options={{
              week: t('Week'),
              month: t('Month')
            }}
          />
        </div>
      </AdminOnly>
    </form>
  );
}

GraphFilters.propTypes = {
  params: PropTypes.object.isRequired,
  setParam: PropTypes.func.isRequired,
  orderType: PropTypes.string
};

export default GraphFilters;
