import { SET_RECORDS, SET_IS_LOADING_RECORDS, SET_HAS_LOADED_RECORDS, SET_RECORD, SET_ACCESS_LOG, SET_IS_ADDING_RECORD, SET_IS_LOADING_ACCESS_LOG } from '../actions/customerRecords';

const initialState = {
  records: [],
  isLoadingRecords: false,
  isLoadingAccessLog: false,
  hasLoadedRecords: false,
  isAddingRecord: false,
  accessLog: []
};

export default function customerRecords (state = initialState, { type, payload }) {
  switch (type) {
    case SET_RECORDS: {
      return {
        ...state,
        records: payload
      };
    }

    case SET_RECORD: {
      return {
        ...state,
        records: payload
      };
    }

    case SET_IS_LOADING_RECORDS: {
      return {
        ...state,
        isLoadingRecords: payload
      };
    }

    case SET_HAS_LOADED_RECORDS: {
      return {
        ...state,
        hasLoadedRecords: payload
      };
    }

    case SET_IS_ADDING_RECORD: {
      return {
        ...state,
        isAddingRecord: payload
      };
    }

    case SET_ACCESS_LOG: {
      return {
        ...state,
        accessLog: payload
      };
    }

    case SET_IS_LOADING_ACCESS_LOG: {
      return {
        ...state,
        isLoadingAccessLog: payload
      };
    }

    default:
      return state;
  }
}
