import store from '../stores/RootStore';
import i18n from 'i18next';

export function maybeShowPhoneNumberError (value) {
  if (!value.match(/[a-zA-Z,/]+/g)) {
    return;
  }

  store.noticeStore.showWarningNotice(
    '<strong>' + i18n.t('Only a valid phone number can be entered in this field.') + '</strong> ' +
    i18n.t('if you want to make a note, please use the note field.')
  );
}
