import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Table = ({
  thead = [],
  isLoading = false,
  mobileView = false,
  className = '',
  children
}) => (
  <table className={classNames('table', className, { 'is-loading': isLoading, 'mobile-order-view ': mobileView })}>
    {thead.length ? (
      <thead className='table__head'>
        <tr>
          {thead.map((column, i) => {
            if (typeof column === 'object' && column.type === 'icon') {
              return (
                <th key={i} className='column'>
                  <FontAwesomeIcon icon={column.icon} />
                </th>
              );
            }

            return (
              <th
                key={i}
                className='column'
                dangerouslySetInnerHTML={{ __html: column }}
              />
            );
          })}
        </tr>
      </thead>
    ) : null}
    <tbody className='table__body'>
      {isLoading ? (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      ) : null}
      {children}
    </tbody>
  </table>
);

Table.propTypes = {
  thead: PropTypes.array,
  isLoading: PropTypes.bool,
  mobileView: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Table;
