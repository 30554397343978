import React, { useEffect, useState } from 'react';
import useSupplier from 'ReduxHooks/useSupplier';
import { Notice } from 'Layout';
import { useTranslation } from 'react-i18next';
import Spinner from 'Components/Spinner';
import SupplierForm from 'Components/Suppliers/SupplierForm';
import useCountries from 'ReduxHooks/useCountries';

function CreateSupplier () {
  const { t } = useTranslation();
  const { country } = useCountries();
  const { supplier, setsupplier } = useSupplier();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setsupplier({
      addresses: [],
      files: [],
      locations: [],
      assembly: '',
      contact_person: '',
      description: '',
      email: '',
      latitude: '',
      longitude: '',
      name: '',
      phone: '',
      price: '',
      type: '',
      url: '',
      youcal_id: '',
      country_id: country ? country.id : 1
    });
    setIsLoading(false);
  }, []);

  if (!supplier || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='row align-top'>
        <div className='col-8'>
          <h2 className='page-title'>{t('Create supplier')}</h2>
          <Notice heading={t('NB!')}>
            <p>{t('Before you add a new supplier, make sure they can send invoices. Lavendla does NOT pay salary to suppliers. The invoices should be sent to faktura@lavendla.se')}</p>
            <p>{t('Regards, Lavendla Economy')}</p>
          </Notice>
        </div>
      </div>
      <div className='row'>
        <div className='col-8'>
          <SupplierForm />
        </div>
      </div>
    </>
  );
}

export default CreateSupplier;
