import { addInvitation, setInvitationText, reset } from './invitations';
import API from '../../stores/API';
import { getResouceRootUri } from 'Library/Orders';
import { set } from './order';
import { showNotice, showWarningNotice } from 'Library/notifications';
import i18n from 'i18next';
import { sprintf } from 'sprintf-js';

export const SET_USER_TO_ASSIGN = 'SET_USER_TO_ASSIGN';
export const SET_SHOULD_REMOVE_ASSIGNED_USER = 'SET_SHOULD_REMOVE_ASSIGNED_USER';

function receiveUserToAssign (payload) {
  return {
    type: SET_USER_TO_ASSIGN,
    payload
  };
}

function receiveShouldRemoveAssignedUser (payload) {
  return {
    type: SET_SHOULD_REMOVE_ASSIGNED_USER,
    payload
  };
}

export function setUserToAssign (user) {
  return (dispatch) => {
    dispatch(reset());
    dispatch(addInvitation(user));
    dispatch(receiveUserToAssign(user));
  };
}

export function setShouldRemoveAssignedUser (remove = true) {
  return (dispatch) => {
    dispatch(receiveShouldRemoveAssignedUser(remove));
  };
}

export function assignUser ({ order }) {
  return async (dispatch, getState) => {
    const { orderAssigner, invitations } = getState();
    const data = { message: invitations.text };

    const response = await API.POST(`${getResouceRootUri(order)}/${order.id}/assign`, {
      user_id: orderAssigner.userToAssign.id,
      ...data
    });

    if (response.status === 'ok') {
      showNotice('🎉' + sprintf(i18n.t('You have assigned the order to %s'), orderAssigner.userToAssign.name));

      dispatch(setUserToAssign(null));
      dispatch(setInvitationText());

      return;
    }

    showWarningNotice(`💔 ${response.errors.error_message}`);
  };
}

export function unassignUser (order) {
  return async (dispatch) => {
    const response = await API.POST(`${getResouceRootUri(order)}/${order.id}/assign`, {
      user_id: null
    });

    if (response.status === 'ok') {
      showNotice(i18n.t('You have unassigned the user from the order'));

      dispatch(set(response.data));
      dispatch(setShouldRemoveAssignedUser(false));

      return;
    }

    showWarningNotice(`💔 ${response.errors.error_message}`);
  };
}
