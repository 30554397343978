import React, { useState } from 'react';
import 'rc-time-picker/assets/index.css';
import OrderAppointments from '../Appointments/OrderAppointments';
import LocationDropdown from '../LocationDropdown';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import OrderTab from './OrderTab';
import { Checkbox, Input, Select, Textarea } from 'FormElements';
import OrderSupplierDropdown from '../OrderSupplierDropdown';
import moment from 'moment';
import { Notice } from 'Layout';
import { AnimatePresence, motion } from 'framer-motion';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import FuneralPlaceDropdown from '../FuneralPlaceDropdown';
import useOrderSuppliers from 'Hooks/useOrderSuppliers';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function CeremonyForm () {
  const { t } = useTranslation();
  const [ceremonyDateWarning, setCeremonyDateWarning] = useState(null);
  const { getSupplierOfType } = useOrderSuppliers();
  const { order, updateField, persist, getCountryCode } = useSingleOrder();
  const { toggleCivilOfficial } = useOrderProducts();
  const { funeral, location_id: locationId } = order;

  const inputProps = {
    className: 'col-4',
    floatingLabel: true
  };

  const motionProps = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 }
  };

  function isManualPlaceFieldDisabled () {
    const type = ['Kyrkor', 'Kapell'];
    return !!getSupplierOfType(type);
  }

  const updateOfficiantSupplier = async (type, supplier) => {
    const supplierId = (supplier) ? supplier.id : null;
    await persist();
    await toggleCivilOfficial(supplierId);
  };

  const onCeremonyChange = (type, appointment = null) => {
    setCeremonyDateWarning(null);

    if (getCountryCode() !== 'se' || !appointment) {
      return;
    }

    const fifteenDaysTime = moment().startOf('day').add(15, 'days');
    const date = moment(appointment.start_date);

    if (fifteenDaysTime.diff(date, 'days') > 0) {
      setCeremonyDateWarning(
        t('Before you confirm this booking with relatives please confirm that transportation, representatives and other parties are possible with so short notice.', {
          description: 'Innan du bekräftar denna bokning till anhöriga så säkerställ att transportör, representant och andra involverade kan med så kort varsel'
        })
      );
      return;
    }

    if (date.day() === 0 || date.day() === 6) {
      setCeremonyDateWarning(
        t('The selected date falls on the weekend. Before you confirm this booking with relatives please confirm that transportation, representatives and other parties are available then. Note that the prices for transportation and representatives is doubled on weekends. Prices can be manually adjusted on the Products tab', {
          description: 'Du har angett en helgdag. Innan du bekräftar denna bokning till anhöriga så säkerställ att transportör, representant och andra involverade kan. Observera att priset på transport och representant är det dubbla under helgdagar. Priset justerar du manuellt på fliken Produkter'
        })
      );
    }
  };

  return (
    <OrderTab
      header={t('Ceremony')}
      tab='funeral'
      afterTasks={(
        <>
          <div className='row'>
            <Textarea
              className='col-12'
              value={funeral.note}
              onChange={value => updateField('funeral.note', value)}
              label={t('Other')}
              rows={10}
              data-cy='funeral.note'
              floatingLabel
            />
          </div>
        </>
      )}
    >
      <>
        <div className='gray-box ceremony-place'>
          <div className='row'>
            <FuneralPlaceDropdown />
            <Input
              value={funeral.place}
              label={t('Ceremony venue')}
              onChange={value => updateField('funeral.place', value)}
              data-cy='funeral.place'
              floatingLabel
              className='col-6'
              disabled={isManualPlaceFieldDisabled()}
            />
          </div>
          <p>{t("Select a church or chapel from the list (type the name in order to select) or provide a place manually if you can't find the place you're looking for in the list", {
            description: 'Välj en kyrka eller kapell från listan (skriv namnet för att söka) eller ange en plats manuellt om inte den finns med i listan.'
          })}
          </p>
        </div>
        <div className='row'>
          <div className='col-6 react-select__floating-label'>
            <LocationDropdown
              id='location'
              value={locationId}
              onChange={location => {
                updateField('location_id', location ? location.value : null);
              }}
            />
            <label htmlFor='location'>{t('City')}</label>
          </div>
          <Select
            label={t('Divine economy')}
            onChange={value => updateField('funeral.divine_economy', value)}
            value={funeral.divine_economy}
            defaultOption={t('-- Select --')}
            options={{
              christian: t('Christian'),
              svenska_kyrkan: t('Swedish church'),
              borgerlig: t('Civil'),
              katolsk: t('Catholic'),
              muslimsk: t('Muslim'),
              annan: t('Other')
            }}
            data-cy='funeral.divine_economy'
            className='col-6'
            floatingLabel
          />
        </div>
        <div className='row'>
          {(funeral.divine_economy === 'borgerlig') ? (
            <OrderSupplierDropdown
              label={t('Officials')}
              type='officiant'
              noValueLabel={t('-- No official selected --')}
              onChange={updateOfficiantSupplier}
            />
          ) : (
            <Input
              value={funeral.officiant}
              label={t('Official')}
              onChange={value => updateField('funeral.officiant', value)}
              data-cy='funeral.officiant'
              {...inputProps}
            />
          )}
          <Input
            value={funeral.organist}
            label={t('Organist')}
            onChange={value => updateField('funeral.organist', value)}
            data-cy='funeral.organist'
            {...inputProps}
          />
          <Input
            value={funeral.soloist}
            label={t('Soloist')}
            onChange={value => updateField('funeral.soloist', value)}
            data-cy='funeral.soloist'
            {...inputProps}
          />
        </div>
        <div className='row'>
          <Input
            value={funeral.janitor}
            label={t('Janitor')}
            onChange={value => updateField('funeral.janitor', value)}
            data-cy='funeral.janitor'
            {...inputProps}
          />
          <Input
            value={funeral.clothing}
            label={t('Dress code')}
            onChange={value => updateField('funeral.clothing', value)}
            data-cy='funeral.clothing'
            {...inputProps}
          />
          <Select
            label={t('Expected number of guests')}
            onChange={value => updateField('funeral.expected_number_of_guests', value)}
            value={funeral.expected_number_of_guests}
            defaultOption={t('-- Select --')}
            options={[
              '10', '20', '30', '40', '50', '60', '70', '80', '90', '100', '150', '200', '300', '400'
            ]}
            data-cy='funeral.expected_number_of_guests'
            className='col-4'
            floatingLabel
          />
        </div>
        <div className='row'>
          <OrderSupplierDropdown
            label={t('Memorial fund')}
            type='minnesfond'
            noValueLabel={t('-- No memorial fund selected --')}
          />
          <OrderSupplierDropdown
            label={t('Representative')}
            type='representant'
            noValueLabel={t('-- Select a representative (max 5) --')}
            className='col-8'
            isMulti
            showSelectedInfo={false}
            max={5}
          />
        </div>
        <If condition={funeral.live_stream}>
          <div className='row'>
            <OrderSupplierDropdown
              className='col-6'
              label={t('Photographer')}
              type='fotograf_filmare'
              noValueLabel={t('-- No photographer selected --')}
            />
          </div>
        </If>

        <div className='checkbox-grouped-container'>
          <Checkbox
            label={t('Canope')}
            data-cy='funeral.canope'
            checked={!!funeral.canope}
            onChange={(checked) => updateField('funeral.canope', checked)}
          />
          <Checkbox
            label={t('Memorial')}
            data-cy='funeral.has_memorial'
            checked={!!funeral.has_memorial}
            onChange={(checked) => updateField('funeral.has_memorial', checked)}
          />
          <Checkbox
            label={t('Livestreamed ceremony')}
            data-cy='funeral.live_stream'
            checked={!!funeral.live_stream}
            onChange={(checked) => updateField('funeral.live_stream', checked)}
          />
        </div>

        <If condition={ceremonyDateWarning}>
          <AnimatePresence>
            <motion.div {...motionProps} className='row'>
              <div className='col-12'>
                <Notice heading={t('NB!')}>
                  <p>{ceremonyDateWarning}</p>
                </Notice>
              </div>
            </motion.div>
          </AnimatePresence>
        </If>

        <OrderAppointments
          header={t('Ceremony')}
          type='ceremony'
          onChange={onCeremonyChange}
        />
      </>
    </OrderTab>
  );
}

export default CeremonyForm;
