import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useGraphs from '../../../hooks/useGraphs';
import Spinner from '../../Spinner';
import CustomTooltip from './CustomTooltip';
import GraphFilters from './GraphFilters';
import { useTranslation } from 'react-i18next';

function MeetingTypes ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { data, isLoading, setParam, params } = useGraphs(
    '/dashboard/get-meeting-types-stats',
    {
      start_date: moment().subtract(9, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      end_date: null,
      user_id: null,
      sortBy: null,
      orderType
    }
  );

  return (
    <div className='charts-container'>
      <h4>{t('Meeting types')}</h4>
      <GraphFilters
        params={params}
        setParam={setParam}
        orderType={orderType}
      />

      {isLoading && <Spinner />}

      {(!isLoading && data && data.length) ? (
        <ResponsiveContainer height={500}>
          <AreaChart data={data}>
            <XAxis dataKey='name' />
            <CartesianGrid strokeDasharray='3 3' />
            <YAxis />
            <Tooltip cursor={false} content={CustomTooltip} />
            <Legend iconType='circle' iconSize={10} />
            <Area
              name={t('Orders') + '*'}
              stackId='a'
              dataKey='orders'
              stroke='#480640'
              fill='#480640'
              activeDot={{ r: 5 }}
              fillOpacity={0.2}
              strokeWidth='4'
            />
            <Area
              name={t('Customer meetings, all types')}
              stackId='b'
              dataKey='customer_meetings'
              stroke='#437783'
              fill='#437783'
              activeDot={{ r: 7 }}
              strokeWidth='4'
              fillOpacity={0.6}
            />
            <Area
              name={t('Phone meetings')}
              stackId='c'
              dataKey='phone'
              stroke='#ffb9cb'
              fill='#ffb9cb'
              activeDot={{ r: 7 }}
              strokeWidth='4'
              fillOpacity={0.6}
            />
            <Area
              name={t('Video meetings')}
              stackId='d'
              dataKey='video'
              stroke='#4a90e2'
              fill='#4a90e2'
              activeDot={{ r: 7 }}
              strokeWidth='4'
              fillOpacity={0.6}
            />
            <Area
              name={t('In our offices')}
              stackId='e'
              dataKey='in_store'
              stroke='#ec2b48'
              fill='#ec2b48'
              activeDot={{ r: 7 }}
              strokeWidth='4'
              fillOpacity={0.6}
            />
            <Area
              name={t('At the customer')}
              stackId='f'
              dataKey='at_home'
              stroke='#bc5b00'
              fill='#bc5b00'
              activeDot={{ r: 7 }}
              strokeWidth='4'
              fillOpacity={0.6}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        t('No data to for this period')
      )}
      <p className='legend-misc'>*{t('Order count is based on all orders for that period that are not archived or lost')}.</p>
    </div>
  );
}

MeetingTypes.propTypes = {
  orderType: PropTypes.string
};

export default MeetingTypes;
