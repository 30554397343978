import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import OrderTask from './OrderTask';
import useOrderTasks from '../../hooks/useOrderTasks';
import { useTranslation } from 'react-i18next';

const OrderTasks = ({ tab }) => {
  const { t } = useTranslation();
  const { getTasksForTab, isMissingRequiredFields } = useOrderTasks();
  const tasks = getTasksForTab(tab);

  if (!tasks.length) {
    return null;
  }

  const completed = tasks.filter(({ pivot }) => !!pivot.completed);
  const inProgress = tasks
    .filter(({ pivot }) => !pivot.completed)
    .sort((taskA, taskB) => taskB.priority - taskA.priority);

  const numHighPrio = () => {
    const numTasks = tasks.filter(({ priority, pivot }) => {
      return parseInt(priority) === 1 && !pivot.completed;
    }).length;

    function getNumTasksText () {
      if (numTasks === 0) {
        return t('No urgent tasks');
      }

      return (numTasks === 1) ? t('One urgent task') : numTasks + ' ' + t('urgent tasks');
    }

    return (
      <>
        {numTasks > 0 && (
          <ReactSVG src='/icons/icon-warning.svg' />
        )}
        <span>{getNumTasksText()}</span>
      </>
    );
  };

  const renderTask = task => (
    <OrderTask
      key={task.id}
      task={task}
      canSendTasks={!isMissingRequiredFields()}
    />
  );

  return (
    <div className='order-tasks'>
      <div className='order-tasks__header'>
        <h4 className='order-tasks__header__header'>
          <strong>{t('Administrative tasks')}</strong>
        </h4>
        <a
          className='order-tasks__header__link'
          href='#'
          onClick={e => {
            e.preventDefault();
            document.body.classList.remove('sidebar-closed');
            document.body.classList.add('sidebar-open');
          }}
        >{t('Show all')}
        </a>
      </div>

      <div className='order-tasks__num-urgent'>
        {numHighPrio()}
      </div>

      <div className='order-tasks__tasks-list'>
        {inProgress.map(task => renderTask(task))}
        {completed.map(task => renderTask(task))}
      </div>
    </div>
  );
};

OrderTasks.propTypes = {
  tab: PropTypes.string
};

export default OrderTasks;
