import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UploadDropzone from './UploadDropzone';
import ImageProcessor from '../ImageProcessor';
import MemorialPageModal from '../MemorialPageComponents/MemorialPageModal';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const ImageUpload = ({
  closeModal,
  closeSave,
  description,
  chosenImage = null,
  isBanner = false,
  isCard = false,
  modalHeading,
  isOpen
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [progress, setProgress] = useState(false);

  // Clear state when modal closes, needed since we are hiding modal with animation and not removing it entirely.
  useEffect(() => {
    if (!isOpen) {
      setFile(null);
      setImageSrc('');
    }
  }, [isOpen]);

  return (
    <MemorialPageModal
      closeModal={closeModal}
      isOpen={isOpen}
      header={modalHeading}
    >
      <div className='memorial-modal__box-wrapper'>
        <div className='memorial-modal__box-main'>

          <div className='obituary__description'>
            <p className='inline-notice'>{description || t('For the best result – use a close-up with good quality and sharpness.')}</p>
          </div>
          <AnimatePresence initial={false}>
            {imageSrc ? (
              <ImageProcessor
                isBanner={isBanner}
                isCard={isCard}
                file={file}
                save={closeSave}
                src={imageSrc}
                setFile={setFile}
                setImageSrc={setImageSrc}
              />
            ) : (
              <UploadDropzone
                chosenImage={chosenImage}
                file={file}
                progress={progress}
                setFile={setFile}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                setProgress={setProgress}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </MemorialPageModal>
  );
};

ImageUpload.propTypes = {
  chosenImage: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  closeSave: PropTypes.func.isRequired,
  modalHeading: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  description: PropTypes.string,
  isBanner: PropTypes.bool,
  isCard: PropTypes.bool
};

export default ImageUpload;
