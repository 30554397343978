import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'Library/functions';
import { Input, Select } from 'FormElements';
import Certificates from './Certificates';
import Editor from '../Editor';
import useUser from 'ReduxHooks/useUser';
import If from 'Conditionals/If';

const UserProfileRoleTabPanel = ({
  role,
  userProfile,
  updateUserProfileFields
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [metaFields, setMetaFields] = useState(userProfile.metaFields || []);

  useEffect(() => {
    setMetaFields(userProfile.metaFields || []);
  }, [userProfile]);

  const getMetaFieldValue = (key, role = null) => {
    if (!metaFields.length) {
      return '';
    }

    const field = metaFields.find(item => {
      if (role && item.role !== role) {
        return false;
      }
      return item.key === key;
    });

    return (field) ? field.value : '';
  };

  const onMetaFieldChange = (key, value, role = null) => {
    const fields = [...metaFields];
    const field = { key, value, role: role, userProfileId: userProfile.id };

    const index = fields.findIndex(item => {
      if (userProfile.id !== item.userProfileId) {
        return false;
      }

      if (role && item.role !== role) {
        return false;
      }

      return item.key === key;
    });

    if (index > -1) {
      fields.splice(index, 1, field);
    } else {
      fields.push(field);
    }

    updateUserProfileFields('metaFields', fields);
  };

  const feeList = () => {
    const startPrice = userProfile.countryCode === 'dk' ? 900 : 1200;
    const endPrice = 3000;
    return Array.from({ length: Math.floor((endPrice - startPrice) / 100) + 1 }, (_, i) => startPrice + (i * 100))
      .reduce((fees, fee) => ({ ...fees, [fee]: formatCurrency(fee, null, user.country_code) }), {});
  };

  const discountList = () => {
    return Array.from({ length: 11 }, (_, i) => i * 5)
      .reduce((discounts, discount) => ({ ...discounts, [discount]: `${discount}%` }), {});
  };

  return (
    <div
      className='row new-form'
    >
      <div className='col-8'>

        <Input
          floatingLabel
          onChange={value => onMetaFieldChange('title', value, role)}
          label={t('Title')}
          value={getMetaFieldValue('title', role)}
        />

        <Input
          floatingLabel
          label={t('Quote')}
          value={getMetaFieldValue('quote', role)}
          onChange={value => onMetaFieldChange('quote', value, role)}
        />

        <Input
          floatingLabel
          label={t('Personal quote')}
          value={getMetaFieldValue('personal_quote', role)}
          onChange={value => onMetaFieldChange('personal_quote', value, role)}
        />

        <div className='editor'>
          <label>{t('Background')}</label>
          <Editor
            data={getMetaFieldValue('background', role)}
            onChange={value => onMetaFieldChange('background', value, role)}
          />
        </div>

        <div className='editor'>
          <label>{t('My advice')}</label>
          <Editor
            data={getMetaFieldValue('advice', role)}
            onChange={value => onMetaFieldChange('advice', value, role)}
          />
        </div>

        <div className='editor'>
          <label>{t('My motivation in becoming a funeral advisor/lawyer')}</label>
          <Editor
            data={getMetaFieldValue('motivation', role)}
            onChange={value => onMetaFieldChange('motivation', value, role)}
          />
        </div>
        {role === 'therapy_user' && (
          <>
            <div>
              <label>{t('Fees')}</label>
              <Select
                label={t('45 minutes')}
                defaultOption={t('-- Select a fee --')}
                value={getMetaFieldValue('fee_45', role)}
                options={feeList()}
                onChange={value => onMetaFieldChange('fee_45', value, role)}
                className='lavendla-select location-dropdown'
                classNamePrefix='lavendla-select'
                floatingLabel
              />
              <Select
                label={t('60 minutes')}
                defaultOption={t('-- Select a fee --')}
                value={getMetaFieldValue('fee_60', role)}
                options={feeList()}
                onChange={value => onMetaFieldChange('fee_60', value, role)}
                className='lavendla-select location-dropdown'
                classNamePrefix='lavendla-select'
                floatingLabel
              />
              <Select
                label={t('90 minutes')}
                defaultOption={t('-- Select a fee --')}
                value={getMetaFieldValue('fee_90', role)}
                options={feeList()}
                onChange={value => onMetaFieldChange('fee_90', value, role)}
                className='lavendla-select location-dropdown'
                classNamePrefix='lavendla-select'
                floatingLabel
              />
            </div>

            <If condition={user.fixed_price_from}>
              <div>
                <label>{t('Discounts')}</label>
                <Select
                  label={t('Three (3) meetings')}
                  defaultOption={t('-- Select a discount --')}
                  value={getMetaFieldValue('discount_3', role) || 10}
                  options={discountList()}
                  onChange={value => onMetaFieldChange('discount_3', value, role)}
                  className='lavendla-select location-dropdown'
                  classNamePrefix='lavendla-select'
                  floatingLabel
                />
                <Select
                  label={t('Five (5) meetings')}
                  defaultOption={t('-- Select a discount --')}
                  value={getMetaFieldValue('discount_5', role) || 25}
                  options={discountList()}
                  onChange={value => onMetaFieldChange('discount_5', value, role)}
                  className='lavendla-select location-dropdown'
                  classNamePrefix='lavendla-select'
                  floatingLabel
                />
                <Select
                  label={t('Ten (10) meetings')}
                  defaultOption={t('-- Select a discount --')}
                  value={getMetaFieldValue('discount_10', role) || 30}
                  options={discountList()}
                  onChange={value => onMetaFieldChange('discount_10', value, role)}
                  className='lavendla-select location-dropdown'
                  classNamePrefix='lavendla-select'
                  floatingLabel
                />
              </div>
            </If>
          </>
        )}
        <Certificates role={role} userProfile={userProfile} updateUserProfileFields={updateUserProfileFields}/>
      </div>
    </div>
  );
};

UserProfileRoleTabPanel.propTypes = {
  role: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  updateUserProfileFields: PropTypes.func.isRequired
};

export default UserProfileRoleTabPanel;
