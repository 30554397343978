import React from 'react';
import PropTypes from 'prop-types';
import { getOrderPersonOfReference } from 'Library/functions';
import { useTranslation } from 'react-i18next';
import FavoriteButton from 'Components/FavoriteButton';

const OrderTitle = ({ order, order: { number, is_test: isTest } }) => {
  const { t } = useTranslation();

  return (
    <div className='order-title'>
      <FavoriteButton order={order} />
      <h1>
        {getOrderPersonOfReference(order)}
        <span className='order-title__number'>#{number}</span>
        {isTest ? <span className='test-suffix'>({t('test')})</span> : ''}
      </h1>
    </div>
  );
};

OrderTitle.propTypes = {
  order: PropTypes.object.isRequired
};

export default OrderTitle;
