import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
import store from '../stores/RootStore';
import { formatCurrency } from '../library/functions';
import ToggleInvoicedProduct from './ToggleInvoicedProduct';
import useFlowerOrderProducts from '../Redux/hooks/useFlowerOrderProducts';
import useOrderProducts from '../Redux/hooks/useOrderProducts';
import LoadingButton from './FormElements/LoadingButton';
import { Textarea } from './FormElements';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

function FlowerShopOrderProduct ({ order, product, countryCode }) {
  const { t } = useTranslation();
  const { updateProduct } = useFlowerOrderProducts();
  const { getImgSrc } = useOrderProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedBandText, setUpdatedBandText] = useState(product.pivot.mourning_band_text);
  const [updatedCardText, setUpdatedCardText] = useState(product.pivot.card_text);

  function renderMourningBandText () {
    const { mourning_band_text: bandText, mourning_band_color: bandColor } = product.pivot;

    if (!bandText) {
      return null;
    }

    return (
      <div>
        <span>{t('Type')}:</span> {t('Ribbon')}, <span>{t('Colour')}:</span> {bandColor || '(' + t('not chosen') + ')'}, <span>{t('Text')}: </span> {bandText.replace(/&amp;/g, '&')}
      </div>
    );
  }

  function renderCardText () {
    const { card_text: cardText } = product.pivot;

    if (!cardText) {
      return null;
    }

    return (
      <div>
        <span>{t('Type')}:</span> {t('Card')}, <span>{t('Text')}: </span>{cardText.replace(/&amp;/g, '&')}
      </div>
    );
  }

  async function update () {
    setIsLoading(true);
    await updateProduct(order.id, product.id, {
      card_text: updatedCardText,
      mourning_band_text: updatedBandText
    });
    setIsLoading(false);
    setIsModalOpen(false);
    store.noticeStore.showNotice(sprintf('🌹 ' + t('The product %s has been updated'), `<strong>${product.name}</strong>`));
  }

  const price = product.price * product.pivot.quantity;

  return (
    <>
      <div className='flowershop-flowers__row'>
        <div>
          {typeof product.media[0] !== 'undefined' ? (
            <img
              className='product-thumbnail'
              src={encodeURI(getImgSrc(product.media[0]))}
              alt='Product thumbnail'
            />
          ) : (
            <div className='no-thumbnail' />
          )}
        </div>
        <div>
          <strong>{product.name}</strong> × {product.pivot.quantity}
          &nbsp;-&nbsp;
          <span className='price'>{formatCurrency(price, null, countryCode, order?.currency)}</span>
        </div>

        <div className='flower-toggle-invoiced'>
          <ToggleInvoicedProduct
            {...{
              order,
              product,
              type: 'flowershopOrderStore'
            }}
          />
          <small>{t('Invoiced').toUpperCase()}<br />({t('finance dept.')})</small>
        </div>

        <LoadingButton onClick={() => setIsModalOpen(true)} className='small' isLoading={false}>{t('Edit')}</LoadingButton>
      </div>

      <div className='flowershop-flowers__text'>
        {renderCardText()}
        {renderMourningBandText()}
      </div>

      <Modal
        className='order-product-modal'
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel=''
      >
        <button className='close-modal' onClick={() => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        <h4>{sprintf(t('Change the text for %s'), product.name)}</h4>
        <div className='order-tab__form'>
          <div className='row'>
            <Textarea
              onChange={value => setUpdatedCardText(value)}
              className='col-12'
              label={t('Card text')}
              rows={3}
              value={updatedCardText}
              floatingLabel
            />
          </div>
          <div className='row'>
            <Textarea
              onChange={value => setUpdatedBandText(value)}
              label={t('Ribbon text')}
              className='col-12'
              rows={3}
              value={updatedBandText}
              floatingLabel
            />
          </div>
          <div className='row'>
            <div className='col-6'>
              <LoadingButton
                onClick={() => update()}
                isLoading={isLoading}
              >{t('Save')}
              </LoadingButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

FlowerShopOrderProduct.propTypes = {
  order: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired
};

export default FlowerShopOrderProduct;
