import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Library/functions';
import { If } from 'Conditionals';
import { Trans, useTranslation } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function CalculationsResult ({ distance = null }) {
  const { t } = useTranslation();
  const { order } = useSingleOrder();

  if (!distance) {
    return null;
  }

  const price = formatCurrency(distance.price);
  const calculatedDistance = Math.round(distance.distance);
  const freeKm = 50;
  const extraDistance = calculatedDistance - freeKm;

  return (
    <div className='distance-calculator__calculations-result'>
      <p><strong>{t('Estimated transport cost:')} {formatCurrency(distance.price + distance.additionalMileage, null, order.country.code, order?.currency)}</strong></p>
      <ul>
        <li>
          <Trans calculatedDistance={calculatedDistance}>
            Route: {{ calculatedDistance }} km
          </Trans>
        </li>
        <li>
          <Trans price={price} freeKm={freeKm}>
            Base price: {{ price }} ({{ freeKm }} km included)
          </Trans>
        </li>
        <If condition={extraDistance > 0}>
          <li>
            <em>
              {t('Additional mileage:')} {formatCurrency(distance.additionalMileage, null, order.country.code)}
                &nbsp;({formatCurrency(distance.kmRate, 1)}/km * {extraDistance} km)<br />
            </em>
          </li>
        </If>
      </ul>
    </div>
  );
}

CalculationsResult.propTypes = {
  distance: PropTypes.object
};

export default CalculationsResult;
