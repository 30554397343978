import React, { useEffect, useState } from 'react';
import useBonusReports from 'ReduxHooks/useBonusReports';
import { formatCurrency } from 'Library/functions';
import { useTranslation } from 'react-i18next';
import useCountries from 'ReduxHooks/useCountries';

function UnpaidTotals () {
  const { t } = useTranslation();
  const { totals, getTotals } = useBonusReports();
  const [isLoading, setIsLoading] = useState(false);
  const { country } = useCountries();
  // TODO: This should be refactored to be market specific.
  useEffect(() => {
    fetchTotals();
  }, []);

  async function fetchTotals () {
    setIsLoading(true);
    await getTotals();
    setIsLoading(false);
  }

  const dict = {
    TRUSTPILOT: 'Trustpilot',
    FUNERAL: t('Funeral'),
    LEGAL: t('Legal'),
    TOMBSTONE: t('Tombstone'),
    MANUAL: t('Manual adjustments')
  };

  if (isLoading || !totals) {
    return null;
  }

  return (
    <div className='bonus-reports__unpaid-totals'>
      <h4>{t('To pay')}</h4>
      {Object.entries(dict).map(([key, value]) => (
        <span key={key}>
          <strong>{value}</strong> {formatCurrency(totals[key].total, null, country.code)} ({formatCurrency(totals[key].totalTax, null, country.code)} {t('Tax')})
        </span>
      ))}
    </div>
  );
}

export default UnpaidTotals;
