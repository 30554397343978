import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency, getOrderLink } from 'Library/functions';
import API from '../stores/API';
import { AnimatePresence, motion } from 'framer-motion';
import { If } from 'Conditionals';
import { animateOpacity } from 'Library/motion';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function renderUser (user = null) {
  let userInfo = i18next.t('(No advisor)');
  if (user && !Array.isArray(user)) {
    userInfo = `${i18next.t('Advisor:')} <strong>${user.name}</strong>, <a href='mailto:${user.email}'>${user.email}</a>`;

    if (user.tel) {
      userInfo += `, ${user.tel}`;
    }
  }

  return (
    <div className='user'>
      <span dangerouslySetInnerHTML={{ __html: userInfo }} />
    </div>
  );
}

function getConnectedOrderType (order) {
  if (order.class === 'App\\LawyerOrder') {
    return i18next.t('legal order');
  }

  return (order.class === 'App\\Order') ? i18next.t('funeral order') : i18next.t('tombstone order');
}

function getFuneralCost ({ total, has_invoice: hasInvoice = false, countryCode = 'SE', currency }) {
  const invoiced = (hasInvoice) ? i18next.t('Invoiced') : i18next.t('Not invoiced');

  return `${formatCurrency(total, null, countryCode, currency)} (${invoiced})`;
}

function ConnectedOrder () {
  const { t } = useTranslation();
  const { order, isFuneralOrder } = useSingleOrder();
  const [relatedOrders, setRelatedOrders] = useState([]);

  useEffect(() => {
    getRelatedOrders();
  }, [order.id]);

  async function getRelatedOrders () {
    const { data } = await API.GET(`/related-orders/${order.id}?order_type=${order.class}`);

    setRelatedOrders(data);
  }

  if (!relatedOrders || !relatedOrders.length) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div {...animateOpacity}>
        <div className='connected-order gray-box'>
          <h4>{t('Connected orders')}</h4>
          {relatedOrders.map(relatedOrder => (
            <div key={relatedOrder.id}>
              <span>
                {t('This order is connected to')} {getConnectedOrderType(relatedOrder)}
                &nbsp;
                <Link to={getOrderLink(relatedOrder)}>#{relatedOrder.number}</Link>
                <If condition={!!relatedOrder.funeral_type}>
                  &nbsp;({relatedOrder.funeral_type})
                </If>
              </span>
              <span>{renderUser(relatedOrder.user)}</span>
              <If condition={!isFuneralOrder}>
                <span>{t('Funeral cost:')} <strong>{getFuneralCost(relatedOrder)}</strong></span>
              </If>

              <If condition={!!relatedOrder.ceremony_date}>
                <span>{t('Ceremony date')} {relatedOrder.ceremony_date}</span>
              </If>
            </div>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default ConnectedOrder;
