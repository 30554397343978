import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Library/functions';
import useSettings from 'ReduxHooks/useSettings';
import { useTranslation } from 'react-i18next';

const BonusAmount = ({ product, countryCode, price, orderInfo }) => {
  const { t } = useTranslation();
  const { getSetting } = useSettings();
  const {
    bonus_amount: amount,
    bonus_percentage: percent
  } = product;

  function getBonus () {
    if (percent) {
      return price * (percent / 100);
    }

    return amount || null;
  }

  if (getSetting('hideBonuses') || !getBonus()) {
    return null;
  }

  return (
    <div className='single-product__bonus'>
      <span className='single-product__bonus-icon'>
        <ReactSVG src='/icons/icon-work.svg' />
      </span>
      <strong>{t('Your compensation:')}</strong>&nbsp;
      <span className='single-product__bonus-amount'>{formatCurrency(getBonus(), null, countryCode, orderInfo?.order?.currency)}</span>
    </div>
  );
};

BonusAmount.propTypes = {
  product: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  orderInfo: PropTypes.object
};

export default BonusAmount;
