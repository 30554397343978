import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'FormElements';
import useCustomerRecords from 'ReduxHooks/useCustomerRecords';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import ReactSVG from 'react-svg';
import Editor from 'Components/Editor';
import { formatDate } from 'Library/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SingleRecord = ({ record, order }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [newRecordNote, setNewRecordNote] = useState(record.note);
  const { updateRecord, isAddingRecord } = useCustomerRecords();
  const { id, created_at: createdAt, title, user, signed, signedBy } = record;

  const update = async (isDraft = false) => {
    await updateRecord({
      id: id,
      note: newRecordNote,
      title: title,
      signed: !isDraft,
      customer_id: order.customer.id
    });

    setIsEditing(false);
  };

  const reset = () => {
    setNewRecordNote(record.note);
    setIsEditing(false);
  };

  if (!record || !order) {
    return null;
  }

  return (
    <div className='records-accordion__item'>
      <motion.button
        initial={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className='records-accordion__title'
      >
        <span className='title'>
          {createdAt ? formatDate(createdAt, order.country.code) : null} -{' '}
          <strong>{title}</strong>
        </span>
        <motion.div
          key='index'
          initial='collapsed'
          exit='collapsed'
          animate={isOpen ? 'open' : 'collapsed'}
          variants={{
            open: { rotate: 180 },
            collapsed: { rotate: 0 }
          }}
        >
          <ReactSVG src='/icons/icon-chevron.svg' />
        </motion.div>
      </motion.button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            className='records-accordion__content-wrapper'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{
              duration: 0.8,
              ease: [0.04, 0.62, 0.23, 0.98]
            }}
          >
            <div className='records-accordion__content'>
              {isEditing ? (
                <Editor data={newRecordNote} onChange={setNewRecordNote} />
              ) : (
                <div
                  className='records-accordion__note'
                  dangerouslySetInnerHTML={{ __html: newRecordNote }}
                />
              )}
            </div>
            <div className='records-accordion__administration-bar'>
              <div className='records-accordion__administration-bar__info'>
                <p>
                  <em>
                    {!!signed && t('Signed by') + ' ' + signedBy.name + ', '}{t('Written by')} {user.name}
                  </em>
                </p>
              </div>
              {isEditing ? (
                <>
                  <LoadingButton
                    className='records-accordion__button'
                    onClick={() => reset()}
                  >
                    {t('Abort')}
                  </LoadingButton>
                  <LoadingButton
                    className='records-accordion__button'
                    onClick={() => update(true)}
                    isLoading={isAddingRecord}
                  >
                    {t('Save')}
                  </LoadingButton>
                  <LoadingButton
                    className='records-accordion__button'
                    disabled={!!record.signed}
                    onClick={() => update()}
                    isLoading={isAddingRecord}
                  >
                    {!record.signed ? (
                      <>
                        {t('Sign and save')}
                        <FontAwesomeIcon icon={['fal', 'lock-open']} />
                      </>
                    ) : (
                      <>
                        {t('Signed')}
                        <FontAwesomeIcon icon={['fal', 'lock']} />
                      </>
                    )}
                  </LoadingButton>
                </>
              ) : (
                <>
                  {record.versions && record.versions.length ? (
                    <LoadingButton
                      className='records-accordion__button'
                      onClick={() => setShowHistory(!showHistory)}
                    >
                      {!showHistory ? t('History') : t('Hide history')}
                    </LoadingButton>
                  ) : null}
                  <LoadingButton
                    className='records-accordion__button'
                    onClick={() => setIsEditing(true)}
                  >
                    {!record.signed ? t('Edit') : t('Correct')}
                  </LoadingButton>
                  <LoadingButton
                    className='records-accordion__button'
                    disabled={!!record.signed}
                    onClick={() => update()}
                    isLoading={isAddingRecord}
                  >
                    {!record.signed ? (
                      <>
                        {t('Sign')}
                        <FontAwesomeIcon icon={['fal', 'lock-open']} />
                      </>
                    ) : (
                      <>
                        {t('Signed')}
                        <FontAwesomeIcon icon={['fal', 'lock']} />
                      </>
                    )}
                  </LoadingButton>
                </>
              )}
            </div>
            <AnimatePresence initial={false}>
              {showHistory && record.versions && record.versions.length ? (
                <motion.div
                  key='content'
                  initial='collapsed'
                  animate='open'
                  exit='collapsed'
                  className='records-accordion__history'
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 }
                  }}
                  transition={{
                    duration: 0.8,
                    ease: [0.04, 0.62, 0.23, 0.98]
                  }}
                >
                  <div className='records-accordion__history__wrapper'>
                    <h4>{t('History')}</h4>
                    {record.versions.map((version, index) => (
                      <div
                        key={index}
                        className='records-accordion__history__item'
                      >
                        <div className='records-accordion__history__item__header'>
                          <p>
                            {formatDate(version.created_at, order.country.code)}
                          </p>
                        </div>
                        <div
                          className='records-accordion__history__item__note'
                          dangerouslySetInnerHTML={{
                            __html: version.note
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

SingleRecord.propTypes = {
  record: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
};

export default SingleRecord;
