import React, { useEffect, useState } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListItem = ({ appointment }) => {
  const { t } = useTranslation();
  const formatedStart = moment(appointment.start_date).format('HH:mm');
  const formatedEnd = moment(appointment.end_date).format('HH:mm');
  const meetingType = appointment.title || null;
  const meetingTypeTranslated = appointment.title_translated || null;
  const customerPhone = appointment.resource && appointment.resource.customer ? appointment.resource.customer.phone : null;
  const [deceasedName, setDeceasedName] = useState('-');
  const [hasOrder, setHasOrder] = useState(false);

  useEffect(() => {
    if (!!appointment.resource && !!appointment.resource_type) {
      setHasOrder(true);
    }

    if (!!appointment.resource && !!appointment.resource.deceased && !!appointment.resource.deceased.name) {
      let deceasedName = appointment.resource.deceased.name;
      deceasedName = deceasedName.trim();
      setDeceasedName(deceasedName || '-');
    }
  }, [appointment]);

  const appointmentType = {
    ceremony: {
      name: t('Ceremony'),
      icon: '/icons/church.svg'
    },
    entombment: {
      name: t('Entombment'),
      icon: '/icons/grave.svg'
    },
    memorial: {
      name: t('Memorial service'),
      icon: '/icons/church.svg'
    },
    viewing: {
      name: t('Viewing'),
      icon: '/icons/church.svg'
    }
  };

  const orderType = {
    'App\\Order': {
      url: '/ordrar/order/',
      name: t('Funeral'),
      icon: '/icons/church.svg'
    },
    'App\\LawyerOrder': {
      url: '/juridik/',
      name: t('Law'),
      icon: '/icons/law.svg'
    },
    'App\\TombstoneOrder': {
      url: '/gravstenar/',
      name: t('Tombstone orders'),
      icon: '/icons/grave.svg'
    },
    'App\\TherapyOrder': {
      url: '/therapy/',
      name: t('Therapy'),
      faIcon: 'comments'
    },
    'App\\CeremonyOrder': {
      url: '/ceremony/',
      name: t('Ceremony'),
      faIcon: 'gifts'
    }
  };

  const ListItemMeeting = () => {
    if (!meetingType) {
      return null;
    }

    if (meetingType === 'phone_meeting') {
      return (
        <li>
          <FontAwesomeIcon icon={['fal', 'phone-alt']} />
          <span>{t('Meeting by phone')}{(customerPhone) ? `: ${customerPhone}` : null}</span>
        </li>
      );
    } else if (meetingType === 'meeting_at_clients_home') {
      return (
        <li>
          <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
          <span>{t('Meeting in your home')}</span>
        </li>
      );
    }

    return (
      <li>
        <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
        <span>{t('Meeting type')}: {meetingTypeTranslated}</span>
      </li>
    );
  };

  const LinkToOrder = () => (
    <Link to={`${orderType[appointment.resource_type].url}${appointment.resource_id}`}>{t('Show order')}</Link>
  );

  const ListItemType = _ => {
    let name, icon, faIcon;

    if (appointment.type === 'customer_meeting') {
      name = orderType[appointment.resource_type].name;
      icon = orderType[appointment.resource_type].icon;
      faIcon = orderType[appointment.resource_type].faIcon;
    } else {
      if (!appointmentType[appointment.type]) {
        return null;
      }

      name = appointmentType[appointment.type].name;
      icon = appointmentType[appointment.type].icon;
    }

    return (
      <li>
        {faIcon ? <FontAwesomeIcon icon={['fal', faIcon]} /> : <ReactSVG src={icon} />}
        <span>
          {name}
          <LinkToOrder />
        </span>
      </li>
    );
  };

  const ListItemContent = () => {
    if (hasOrder) {
      return (
        <ul>
          <li className='list-item-order'>
            <ReactSVG src='/icons/lavendla-symbol.svg' />
            <span>{deceasedName}</span>
          </li>
          <ListItemMeeting />
          <ListItemType />
        </ul>
      );
    }

    return (
      <ul>
        <li>
          <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
          <span>{appointment.title_translated}</span>
        </li>
      </ul>
    );
  };

  return (
    <div className='mini-calendar__list-item'>
      <div className='mini-calendar__list-item-time'>
        <span>{formatedStart}</span>
        <span>{formatedEnd}</span>
      </div>
      <div className='mini-calendar__list-item-info'>
        <ListItemContent />
      </div>
    </div>
  );
};

ListItem.propTypes = {
  appointment: PropTypes.object.isRequired
};

export default ListItem;
