import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../../UserImage';
import classNames from 'classnames';
import ActiveInvitationListItemStatus from './ActiveInvitationListItemStatus';
import Rating from '../../Rating';

const ActiveInvitationListItem = ({
  user,
  invitation = null
}) => {
  function isActiveInvitation () {
    if (!invitation) {
      return false;
    }

    return !invitation.accepted && !invitation.rejected;
  }

  return (
    <div className={classNames('user-list-group__table__body__item', { active: isActiveInvitation() })}>
      <div className='user-image-with-details'>
        <UserImage
          user={user}
        />
        <div>
          <strong>{user.name}</strong><br/>
          <Rating
            rating={user.rating}
            numberOfReviews={user.number_of_reviews}
          />
        </div>
      </div>
      <div/>
      <div>
        <ActiveInvitationListItemStatus invitation={invitation}/>
      </div>
    </div>
  );
};

ActiveInvitationListItem.propTypes = {
  user: PropTypes.object.isRequired,
  invitation: PropTypes.object
};

export default ActiveInvitationListItem;
