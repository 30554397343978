import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TaskFiles = ({ task }) => {
  const { t } = useTranslation();

  if (!task.files || !task.files.length) {
    return null;
  }

  return (
    <div>
      <p><small>{t('The file')} <strong>{task.files.map(item => item.src.split('/').pop())}</strong> {t('is always sent with the task')}.</small></p>
    </div>
  );
};

TaskFiles.propTypes = {
  task: PropTypes.object.isRequired
};

export default TaskFiles;
