import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { If } from 'Conditionals';

const TableColumn = ({ render, className = '', label }) => (
  <td className={classNames('column', className)}>
    <If condition={label}>
      <h5 className='column__header'>{label}</h5>
    </If>
    <div className='column__content'>
      {render()}
    </div>
  </td>
);

TableColumn.propTypes = {
  render: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  label: PropTypes.string
};

export default TableColumn;
