import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

function PersistFooter ({ tab = 'customer' }) {
  const { t } = useTranslation();
  const { isDirty, persist, reset, isUpdating } = useSingleOrder();
  const message = (tab === 'memorial_page')
    ? t('The order has been saved.') + '<br/><br/><strong>' + t("NB! Don't forget to update/publish your changes") + '</strong>'
    : null;

  if (!isDirty()) {
    return null;
  }

  return (
    <div className='sticky-save'>
      <LoadingButton
        data-cy='persist-order'
        isLoading={isUpdating}
        onClick={() => persist(true, message)}
      >
        {t('Save changes')}
      </LoadingButton>
      <LoadingButton
        data-cy='reset-order'
        className='white'
        onClick={() => reset()}
      >
        {t('Revert')}
      </LoadingButton>
    </div>
  );
}

PersistFooter.propTypes = {
  tab: PropTypes.string
};

export default PersistFooter;
