import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useCountries from 'ReduxHooks/useCountries';
import { AnimatePresence, motion } from 'framer-motion';
import { animateOpacity } from 'Library/motion';
import classNames from 'classnames';
import { sprintf } from 'sprintf-js';
import { If } from 'Conditionals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserWorkloadDetails ({ user }) {
  const { country } = useCountries();
  const [showWorkloadDetails, setShowWorkloadDetails] = useState(false);

  const maxHours = country.code === 'dk' ? 20 : 40;
  const { t } = useTranslation();
  const {
    current_working_hours: workingHours,
    max_working_hours: maxWorkingHours,
    num_assignment_events: numAssignmentEvents
  } = user.penalties;

  function getNumOrders (orderType = 'App\\Order') {
    return user.orders.filter(({ model }) => model === orderType).length;
  }

  function getPercentage (num) {
    if (!num) {
      return '0%';
    }

    return `${Math.round((num / maxHours) * 100)}%`;
  }

  return (
    <div>
      <div className='workload-scoring-buttons'>
        <button
          className={classNames('btn', 'icon', 'show-workload-deets', { 'is-active': showWorkloadDetails })}
          role='button'
          data-cy='ua_show_scoring_details'
          onClick={(e) => {
            e.preventDefault();
            setShowWorkloadDetails(!showWorkloadDetails);
          }}
        >
          <span>{sprintf(t('Workload: %s, Points: %s'), getPercentage(workingHours), Math.round(user.total_score))}</span>
          <FontAwesomeIcon
            size='sm'
            icon={['fal', 'info-circle']}
          />
        </button>
      </div>
      <AnimatePresence>
        <If condition={showWorkloadDetails}>
          <motion.div {...animateOpacity}>
            <div className='user-workload-details'>
              <div className='user-workload-details__section'>
                <h5>{t('Workload')}</h5>

                <p>
                  {t('Desired workload')}: <strong>{getPercentage(maxWorkingHours)}</strong><br/>
                  {t('Calculated workload')}: <strong>{getPercentage(workingHours)}</strong><br/>
                  {t('Number of assigned orders today')}: <strong>{numAssignmentEvents}</strong>
                </p>
              </div>
              <div className="user-workload-details__section">
                <h5>{t('Active orders')}</h5>
                <p>
                  {t('Funeral orders')}: <strong>{getNumOrders()}</strong><br/>
                  {t('Law orders')}: <strong>{getNumOrders('App\\LawyerOrder')}</strong><br/>
                  {t('Tombstone orders')}: <strong>{getNumOrders('App\\TombstoneOrder')}</strong><br/>
                </p>
              </div>
              <div className="user-workload-details__section">
                <h5>{t('Points')}</h5>
                <p>
                  {t('Review points')}: <strong>{+parseFloat(user.score.reviews).toFixed(2)}</strong><br/>
                  {t('Recent review points')}: <strong>{+parseFloat(user.score.recentReviews).toFixed(2)}</strong><br/>
                  {t('Role points')}: <strong>{+parseFloat(user.score.role).toFixed(2)}</strong><br/>
                  {t('Distance points')}: <strong>{+parseFloat(user.score.distance).toFixed(2)}</strong><br/>
                  {t('In location points')}: <strong>{+parseFloat(user.score.inLocation).toFixed(2)}</strong><br/>
                  {t('Penalty points')}: <strong>{+parseFloat(user.score.penality).toFixed(2)}</strong><br/>
                  {t('Competence points')}: <strong>{+parseFloat(user.score.competence).toFixed(2)}</strong><br/>
                  {t('Conversion points')}: <strong>{+parseFloat(user.score.conversion).toFixed(2)}</strong><br/>
                  {t('Threapy Conversion points')}: <strong>{+parseFloat(user.score.therapyConversion).toFixed(2)}</strong><br/>
                  <span className='user-workload-details__total-score'>
                    {t('Total points')}: <strong>{+parseFloat(user.total_score).toFixed(2)}</strong>
                  </span>
                </p>

              </div>
            </div>
          </motion.div>
        </If>
      </AnimatePresence>
    </div>
  );
}

UserWorkloadDetails.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserWorkloadDetails;
