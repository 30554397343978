import React, { useState } from 'react';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { LoadingButton } from 'FormElements';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function DownloadImageArchive () {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { generateArchive } = useMemorialPage();

  const fetch = async () => {
    setIsLoading(true);
    const { status, errors } = await generateArchive();
    setIsLoading(false);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    showNotice(t('A link to download the images will be sent to your email address shortly.'));
  };

  return (
    <div className='download-donations'>
      <LoadingButton
        onClick={fetch}
        disabled={isLoading}
        className='btn-link'
      >
        {t('Download all images from the memorial page (a link will be sent to your email address)')}
      </LoadingButton>
    </div>
  );
}

export default DownloadImageArchive;
