import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { getSupplierTypes, formatCurrency } from 'Library/functions';
import useSupplier from 'ReduxHooks/useSupplier';
import { Fieldset, Input, LoadingButton, Select, Textarea, Toggle } from 'FormElements';
import { AdminOnly, If } from 'Conditionals';
import LocationDropdown from '../LocationDropdown';
import Address from './Address';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCountries from 'ReduxHooks/useCountries';
import ReactSelect from 'react-select';
import { forEach } from 'lodash';

function SupplierForm () {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPennyBridge, setIsLoadingPennyBridge] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [isMeetingPlace, setIsMeetingPlace] = useState(false);
  const { supplier, persist, create, updateField, updateFields, destroy, toggle, getPennyBridgeList, pennyBridgeList } = useSupplier();
  const { isAdmin } = useLoggedInUser();
  const { country } = useCountries();
  const commission = 0.7;

  useEffect(() => {
    const fetchPB = async () => {
      await getPennyBridgeList(country.id);
      setIsLoadingPennyBridge(false);
    };

    fetchPB();
  }, []);

  useEffect(() => {
    if (!supplier) {
      return;
    }

    setIsMeetingPlace(supplier.type === 'moteslokal');
  }, [supplier]);

  function checkMeetingPlaceInfo () {
    // If supplier is a meeting place do nothing...
    if (isMeetingPlace) {
      return true;
    }

    const hasMeetingPlaceInfo = !!supplier.allow_marketing || !!supplier.google_my_business_id || !!supplier.google_review_url;
    // If supplier is not a meeting place but has meeting place info, ask if user wants to save anyway?
    if (hasMeetingPlaceInfo && window.confirm(
      t('You have information specific for meeting places added to this supplier. That informations will be removed if supplier is not of type meeting place. Are you sure you want to save?')
    )) {
      updateFields({
        allow_marketing: false,
        google_my_business_id: null,
        google_review_url: null
      });

      return true;
    } else if (hasMeetingPlaceInfo) {
      return false;
    }

    return true;
  }

  async function save () {
    let response;
    const newSupplier = !supplier.id;

    setIsLoading(true);

    if (!checkMeetingPlaceInfo()) {
      setIsLoading(false);
      return;
    }

    if (newSupplier) {
      response = await create();
    } else {
      response = await persist();
    }

    setIsLoading(false);

    if (newSupplier) {
      history.push(`/leverantorer/leverantor/${response.data.id}`);
    }
  }

  async function deleteSupplier () {
    if (!window.confirm(t('Are you sure?'))) {
      return;
    }

    setIsDeleting(true);
    await destroy();
    setIsDeleting(false);
    history.push('/leverantorer');
  }

  async function archiveSupplier (deactivate = false) {
    if (!window.confirm(t('Are you sure?'))) {
      return;
    }

    setIsArchiving(true);
    await toggle(deactivate);
    setIsArchiving(false);
  }

  function getOptions () {
    const types = {};

    getSupplierTypes().map(({ value, label }) => (types[value] = label));

    return types;
  }

  function getPennyBridgeOptions () {
    if (!pennyBridgeList) {
      return [];
    }

    return Object.keys(pennyBridgeList).map(key => {
      return { value: pennyBridgeList[key].id, label: pennyBridgeList[key].name };
    });
  }

  function updatePennyBridge (option) {
    if (option) {
      let selected = null;
      // loop through all the items in the array and find the correct one
      forEach(pennyBridgeList, (item) => {
        if (item.id === option.value) {
          selected = item;
        }
      });

      updateFields({
        name: selected.name,
        pennybridge_id: option.value,
        description: selected.description,
        pennybridge_logo_url: selected.logoUrl
      });
    } else {
      updateFields({ pennybridge_id: null });
    }
  }

  function getPennyBridgeValue () {
    if (!supplier.pennybridge_id) {
      return null;
    }

    return {
      value: supplier.pennybridge_id,
      label: supplier.name
    };
  }

  return (
    <form className='order-tab__form new-form' autoComplete='off'>
      <fieldset disabled={!isAdmin}>
        <Select
          floatingLabel
          data-cy='supplier-type'
          label={t('Type of supplier')}
          value={supplier.type}
          options={getOptions()}
          defaultOption={t('-- Select a type --')}
          onChange={value => updateField('type', value)}
        />
        {isAdmin && isMeetingPlace ? (
          <>
            <div className='grey-box supplier-meeting-place'>
              <Fieldset legend={t('Meeting place')}>
                <Toggle
                  defaultChecked={supplier.allow_marketing}
                  label={t('Aprove to use for marketing (If yes this place will be synced to and shown on the marketing site)')}
                  onChange={() => updateField('allow_marketing', !supplier.allow_marketing)}
                />
                <Toggle
                  defaultChecked={supplier.allow_therapy}
                  label={t('Aprove to use for therapy pages')}
                  onChange={() => updateField('allow_therapy', !supplier.allow_therapy)}
                />
                <Input
                  floatingLabel
                  data-cy='googleMyBusinessId'
                  label={t('Google My Business ID')}
                  value={supplier.google_my_business_id}
                  onChange={value => updateField('google_my_business_id', value)}
                />
                <Input
                  floatingLabel
                  data-cy='googleReviewUrl'
                  label={t('Google Review URL')}
                  value={supplier.google_review_url}
                  onChange={value => updateField('google_review_url', value)}
                />
              </Fieldset>
            </div>
          </>
        ) : null}
        {isAdmin && supplier.type === 'officiant' ? (
          <>
            <div className='grey-box supplier-meeting-place'>
              <Fieldset legend={t('Official')}>
                <Input
                  floatingLabel
                  data-cy='price'
                  type='number'
                  label={t('Price (only officiants)')}
                  value={supplier.price}
                  onChange={value => updateField('price', value)}
                  description={t('The officiants fee to invoice Lavendla:') + ' ' + formatCurrency(supplier.price * commission, null, country.code) + ' ' + t('incl. VAT')}
                />
              </Fieldset>
            </div>
          </>
        ) : null}
        <Input
          floatingLabel
          data-cy='name'
          label={t('Name')}
          disabled={supplier.pennybridge_id}
          value={supplier.name}
          onChange={value => updateField('name', value)}
        />
        <Input
          floatingLabel
          data-cy='assembly'
          label={t('Assembly', {
            description: 'Församling (LKF)'
          })}
          value={supplier.assembly}
          onChange={value => updateField('assembly', value)}
        />
        {isAdmin && supplier.type === 'minnesfond' ? (
          <>
            <div className='react-select__floating-label input' data-cy='location-dropdown'>
              <ReactSelect
                floatingLabel
                className='lavendla-select'
                classNamePrefix='lavendla-select'
                isDisabled={isLoadingPennyBridge}
                isClearable
                value={getPennyBridgeValue()}
                options={getPennyBridgeOptions()}
                placeholder={'-- ' + t('Select an organisation') + ' --'}
                onChange={value => updatePennyBridge(value)}
              />
              <label>{t('PennyBridge organisation')}</label>
            </div>
            {supplier.pennybridge_id && supplier.pennybridge_logo_url ? (
              <div className='pennybridge-logo'>
                <label>{t('Logo from PennyBridge')}</label>
                <img src={supplier.pennybridge_logo_url} alt={supplier.name} />
              </div>
            ) : null}
            {country.code === 'se' ? (
              <Toggle
                defaultChecked={supplier.sync_as_org}
                label={t('Sync organization to marketing site')}
                onChange={() => updateField('sync_as_org', !supplier.sync_as_org)}
              />
            ) : null}
          </>

        ) : null}
        <div className='react-select__floating-label input' data-cy='location-dropdown'>
          <LocationDropdown
            isMulti
            returnObject
            showParents={isMeetingPlace}
            value={supplier.locations}
            onChange={value => {
              const locations = (value) ? value.map(({ value }) => value) : null;
              updateField('locations', locations);
            }}
          />
          <label>{t('Places')}</label>
        </div>
        <Input
          floatingLabel
          data-cy='latitude'
          label={t('Latitude')}
          value={supplier.latitude}
          onChange={value => updateField('latitude', value)}
        />
        <Input
          floatingLabel
          data-cy='longitude'
          label={t('Longitude')}
          value={supplier.longitude}
          onChange={value => updateField('longitude', value)}
        />
        <Input
          floatingLabel
          data-cy='contact_person'
          label={t('Contact person')}
          value={supplier.contact_person}
          onChange={value => updateField('contact_person', value)}
        />
        <Input
          floatingLabel
          label={t('Org number')}
          value={supplier.org_number}
          onChange={value => updateField('org_number', value)}
        />
        <Input
          floatingLabel
          data-cy='email'
          label={t('Email (separated by comma)')}
          value={supplier.email}
          onChange={value => updateField('email', value)}
        />
        <Input
          floatingLabel
          data-cy='url'
          label={t('URL')}
          value={supplier.url}
          onChange={value => updateField('url', value)}
        />
        <Input
          floatingLabel
          data-cy='phone'
          label={t('Phone')}
          value={supplier.phone}
          onChange={value => updateField('phone', value)}
        />
        <Textarea
          floatingLabel
          data-cy='description'
          disabled={supplier.pennybridge_id}
          rows={6}
          label={t('Description')}
          value={supplier.description}
          onChange={value => updateField('description', value)}
        />

        <Address />
        <Address type='visit' />

        <AdminOnly>
          <LoadingButton
            data-cy='submit'
            disabled={!supplier.name}
            isLoading={isLoading}
            onClick={() => save()}
          >
            {t('Save')}
          </LoadingButton>
          <If condition={supplier.id}>
            <>
              &nbsp;&nbsp;
              <LoadingButton
                isLoading={isDeleting}
                className='delete'
                onClick={() => deleteSupplier()}
              >
                {t('Delete')}
              </LoadingButton>
              &nbsp;&nbsp;
              <LoadingButton
                isLoading={isArchiving}
                className='white'
                onClick={() => archiveSupplier(!supplier.archived_at)}
              >
                {supplier.archived_at ? t('Activate') : t('Deactivate')}
              </LoadingButton>
            </>
          </If>
        </AdminOnly>
      </fieldset>
    </form>
  );
}

SupplierForm.propTypes = {
  supplier: PropTypes.object
};

export default SupplierForm;
