import { getFuneralPlace } from './functions';
import useAppointments from 'Hooks/useAppointments';
import { format } from 'date-fns';
import { sv, da } from 'date-fns/locale';

export const ceremonyTransformer = (order, appointments) => {
  const { getAppointmentOfType } = useAppointments();
  const ceremony = getAppointmentOfType('ceremony');

  if (order.funeral.type === 'direktkremation' || !ceremony) {
    return {
      ceremony: false
    };
  }
  const ceremonyDate = new Date(ceremony.start_date);
  const date = format(ceremonyDate, 'd MMMM yyyy', { locale: order.country.code === 'se' ? sv : da });
  const time = format(ceremonyDate, 'HH.mm', { locale: order.country.code === 'se' ? sv : da });

  return {
    ceremony: true,
    place: getFuneralPlace(order),
    date,
    time
  };
};

export const deceasedTransformer = (deceased) => {
  const {
    birth_year: birthYear,
    date,
    name,
    first_name: firstName,
    last_name: lastName,
    nickname
  } = deceased;

  const deceasedYear = format(new Date(date), 'yyyy');

  return {
    birthYear,
    deceasedYear,
    name: (nickname) ? `${firstName} "${nickname}" ${lastName}` : name
  };
};

export const memorialFundTransformer = (supplier) => {
  if (!supplier) {
    return null;
  }

  return {
    name: supplier.name,
    donationSupport: !!supplier.pennybridge_id
  };
};
