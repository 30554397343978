import React from 'react';
import Attendee from './Attendee';
import NewAttendee from '../NewAttendee';
import NumAttendeesHeader from '../NumAttendeesHeader';
import useOrderAttendees from 'ReduxHooks/useOrderAttendees';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function Attendees () {
  const { t } = useTranslation();
  const { hasAttendees, getAttendees, sortQueuedAttendees } = useOrderAttendees();

  const renderAttendees = (queued = false) => {
    let attendeesToRender = getAttendees(queued);

    if (queued) {
      attendeesToRender = attendeesToRender.sort(sortQueuedAttendees);
    }

    return attendeesToRender.map(attendee => (
      <Attendee
        attendee={attendee}
        key={attendee.id}
      />
    ));
  };

  return (
    <div className='attendees-container'>
      <NumAttendeesHeader />
      <NewAttendee />

      <div className='attendees-form'>
        <h5>{t('Registered')}</h5>
        {hasAttendees() ? renderAttendees() : t('No registrations yet')}

        <If condition={hasAttendees(true)}>
          <h5>{t('Registered in the queue')}</h5>
          {renderAttendees(true)}
        </If>
      </div>
    </div>
  );
}

export default Attendees;
