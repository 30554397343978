import React from 'react';
import PropTypes from 'prop-types';
import EventSummeryWithOrder from './EventSummeryWithOrder';
import { formatDateForCalendar } from 'Library/DateHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventSummery = ({ event, meetingTypes }) => {
  const appointment = event.appointment || null;

  if (!appointment) {
    return null;
  }

  const order = appointment && appointment.resource ? appointment.resource : null;
  const dateTime = appointment && appointment.start_date && appointment.end_date
    ? formatDateForCalendar(appointment.start_date, appointment.end_date)
    : null;

  if (order) {
    return <EventSummeryWithOrder appointment={appointment} order={order} dateTime={dateTime} meetingTypes={meetingTypes} />;
  }

  return (
    <div className='event-summery'>
      <ul>
        <li className='event-summery__title'>
          <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
          <span>{appointment.title_translated}</span>
        </li>
        <li className='event-summery__date-time'>
          <span className='datetime'>{dateTime}</span>
        </li>
      </ul>
    </div>
  );
};

EventSummery.propTypes = {
  event: PropTypes.object.isRequired,
  meetingTypes: PropTypes.object.isRequired
};

export default EventSummery;
