import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const InfoModal = ({ title, children }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className='task__tip'>
        <button
          role='button'
          onClick={e => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
        >
          {t('More info')}
          <ReactSVG src='/icons/icon-tip-light-bulb.svg' />
        </button>
      </div>

      <Modal
        className='mail-modal'
        isOpen={isModalOpen}
        contentLabel=''
        onRequestClose={() => setIsModalOpen(false)}
      >
        <button
          role='button'
          className='close-modal'
          onClick={() => setIsModalOpen(false)}
        >
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        <div className='modal-container task-description'>
          <div>
            <h2>{title}</h2>
            {children}
          </div>
        </div>
      </Modal>
    </>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default InfoModal;
