import React from 'react';
import { toast } from 'react-toastify';

class NoticeStore {
  showNotice (notice, notificaionType = 'info', autoClose = 5000) {
    let type;

    switch (notificaionType) {
      case 'warning':
      case 'notice':
        type = toast.TYPE.WARNING;
        break;

      case 'error':
        type = toast.TYPE.ERROR;
        break;

      default:
        type = toast.TYPE.INFO;
        break;
    }

    if (typeof notice === 'string' || notice instanceof String) {
      notice = (
        <div dangerouslySetInnerHTML={{ __html: notice }} />
      );
    }

    toast(notice, {
      autoClose,
      type
    });
  }

  showWarningNotice (notice, autoClose = 8000) {
    this.showNotice(notice, 'warning', autoClose);
  }
}

export default NoticeStore;
