import React from 'react';
import PropTypes from 'prop-types';
import { Input, LoadingButton } from 'FormElements';
import useContacts from 'Hooks/useContacts';
import { useTranslation } from 'react-i18next';

function Contact ({ contact, index }) {
  const { t } = useTranslation();
  const { updateContact, deleteContact } = useContacts();

  function update (value) {
    updateContact({ ...contact, ...value }, index);
  }

  return (
    <div className='contacts__contact row' data-cy='contact-row'>
      <Input
        floatingLabel
        label={t('Name')}
        className='col-4'
        value={contact.name}
        data-cy={`contact-row-name_${index}`}
        onChange={name => update({ name })}
      />
      <Input
        floatingLabel
        label={t('Email address')}
        className='col-4'
        value={contact.email}
        data-cy={`contact-row-email_${index}`}
        onChange={email => update({ email })}
      />
      <Input
        floatingLabel
        className='col-3'
        label={t('Phone number')}
        value={contact.phone}
        data-cy={`contact-row-phone_${index}`}
        onChange={phone => update({ phone })}
      />
      <div className='col-1'>
        <LoadingButton
          className='text danger'
          data-cy={`contact-row-delete_${index}`}
          onClick={() => deleteContact(index)}
        >
          {t('Delete')}
        </LoadingButton>
      </div>
    </div>
  );
}

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default Contact;
