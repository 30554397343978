import React from 'react';
import PropTypes from 'prop-types';
import Notice from './Notice';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

const NoticeList = ({ notices, markRead, toggleList, deleteNotice, deleteAllNotices }) => {
  const { t } = useTranslation();
  function getNotices (unread = true) {
    return notices.filter(({ acknowledged_at: acknowledgedAt }) => (unread) ? !acknowledgedAt : acknowledgedAt);
  }

  function numNewNoticesHeader () {
    const unread = getNotices().length;

    if (unread === 0) {
      return t('No new notices');
    }

    if (unread === 1) {
      return t('1 new notice');
    }

    return sprintf(t('%s new notices'), unread);
  }

  return (
    <>
      <div className='notice-list__header'>
        <h4 className='notice-list__header__title'>{numNewNoticesHeader()}</h4>
        <button
          className='notice-list__header__delete-all'
          onClick={() => deleteAllNotices()}
        >
          {t('Delete all')}
        </button>
      </div>
      <div className='notice-list__notices'>
        {notices.map(notice => (
          <Notice
            key={notice.id}
            notice={notice}
            markRead={markRead}
            toggleList={toggleList}
            deleteNotice={deleteNotice}
          />
        ))}
      </div>
    </>
  );
};

NoticeList.propTypes = {
  notices: PropTypes.array.isRequired,
  markRead: PropTypes.func.isRequired,
  toggleList: PropTypes.func.isRequired,
  deleteNotice: PropTypes.func.isRequired,
  deleteAllNotices: PropTypes.func.isRequired
};

export default NoticeList;
