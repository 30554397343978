import React from 'react';
import PropTypes from 'prop-types';
import SingleSupplier from './SingleSupplier';

function SuppliersList ({
  suppliers = [],
  attachSupplierToOrder,
  selectedSupplier = null,
  showHeader = true,
  headerText = 'Bäst lämpade alternativ'
}) {
  return (
    <div className='supplier-list'>
      {showHeader ? (
        <div className='single-supplier header'>
          <div className='single-supplier__field name'>
            {headerText}
          </div>
          <div className='single-supplier__field'>
            Kontaktperson
          </div>
          <div className='single-supplier__field'>
            Telefon
          </div>
          <div className='single-supplier__field action'>
            {' '}
          </div>
        </div>
      ) : null}
      {suppliers.map(supplier => (
        <SingleSupplier
          key={supplier.id}
          supplier={supplier}
          isSelected={selectedSupplier && selectedSupplier.id === supplier.id}
          attachSupplierToOrder={attachSupplierToOrder}
        />
      ))}
    </div>
  );
}

SuppliersList.propTypes = {
  suppliers: PropTypes.array,
  attachSupplierToOrder: PropTypes.func.isRequired,
  selectedSupplier: PropTypes.object,
  showHeader: PropTypes.bool,
  headerText: PropTypes.string
};

export default SuppliersList;
