import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../../Spinner';
import { useSelector } from 'react-redux';
import useInvitations from 'ReduxHooks/useInvitations';
import { useTranslation } from 'react-i18next';

/**
 * @needst10ns
 */
function InvitationQueueMessage ({
  isLoading = false,
  currentOrder = null
}) {
  const { invitations } = useInvitations();
  const { userToAssign, shouldRemoveAssignedUser } = useSelector(state => state.orderAssigner);
  const { t } = useTranslation();

  function getContextuelMessages () {
    const assignText = t('When an order is assigned directly, an SMS is sent to the adviser. The advisor then has 2 hours to accept the order.') + ' ' +
      t('If the time runs out or if the advisor declines, the case becomes unassigned again. If this happens, an email is sent to info@lavendla.se.');
    const emptyResponse = {
      header: '',
      body: ''
    };

    if (invitations.length && !userToAssign) {
      return {
        header: t('Create a new SMS queue'),
        body: t('When you create a queue, an SMS is sent to each advisor in the list in sequential order.') + ' ' +
          t('The advisors then have 5 minutes to accept the order. If time runs out or the advisor declines, an invitation is sent to the next advisor in the queue.') +
          '<br/><br/>' + t('If no advisor accepts the order, the queue expires. If this happens, an email will be sent to info@lavendla.se.')
      };
    }

    if (!currentOrder) {
      return emptyResponse;
    }

    if (shouldRemoveAssignedUser) {
      return {
        header: t('Remove delegated advisors'),
        body: t('Are you sure you want to remove the current advisor?')
      };
    }

    if (!userToAssign) {
      return emptyResponse;
    }

    if (currentOrder.user && !Array.isArray(currentOrder.user) && currentOrder.user.id !== userToAssign.id) {
      return {
        header: t('Assign directly'),
        body: `${t('The order will be assigned to')} <strong>${userToAssign.name}</strong>. (${t('The current advisor is')} <strong>${currentOrder.user.name}</strong>).<br/><br/>${assignText}`
      };
    }

    return {
      header: t('Assign directly'),
      body: `${t('The order will be assigned to')} <strong>${userToAssign.name}</strong>.<br/><br/>${assignText}`
    };
  }

  const { header, body } = getContextuelMessages();

  return (
    <div
      className={classNames('invitation-queue-message', {
        'is-loading': isLoading,
        'has-message': header
      })}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h3 className='invitation-queue-message__header'>{header}</h3>
          <p className='invitation-queue-message__body' dangerouslySetInnerHTML={{ __html: body }} />
        </>
      )}
    </div>
  );
}

InvitationQueueMessage.propTypes = {
  isLoading: PropTypes.bool,
  currentOrder: PropTypes.object
};

export default InvitationQueueMessage;
