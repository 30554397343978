import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SidebarMobileToggle = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add('sidebar-mobile-open');
    // dismount
    return () => {
      document.body.classList.remove('sidebar-mobile-open');
      document.body.classList.remove('sidebar-mobile-closed');
    };
  }, []);

  useEffect(() => {
    // set body classes
    if (isOpen) {
      document.body.classList.add('sidebar-mobile-open');
      document.body.classList.remove('sidebar-mobile-closed');
    } else {
      document.body.classList.add('sidebar-mobile-closed');
      document.body.classList.remove('sidebar-mobile-open');
    }
  }, [isOpen]);

  return (
    <button className='sidebar__mobile-toggle_wrapper' onClick={() => setIsOpen(!isOpen)}>
      {children}
    </button>
  );
};

SidebarMobileToggle.propTypes = {
  children: PropTypes.node.isRequired
};

export default SidebarMobileToggle;
