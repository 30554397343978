import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { LoadingButton } from 'FormElements';
import { getResourceUrl } from 'Library/Orders';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { showNotice } from 'Library/notifications';

function RestoreButton ({ order }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { restore } = useSingleOrder();
  const [isLoading, setIsLoading] = useState(false);

  async function restoreOrder () {
    setIsLoading(true);
    const response = await restore(order);
    setIsLoading(false);

    if (response) {
      const {
        number: orderNumber
      } = order;
      showNotice(
        <Trans orderNumber={orderNumber}>
          The order #{{ orderNumber }} has been restored
        </Trans>
      );

      history.push(getResourceUrl(order));
    }
  }

  return (
    <LoadingButton
      data-cy='restore-button'
      className='archive-btn'
      isLoading={isLoading}
      onClick={() => restoreOrder()}
    >
      {order.archived_at !== null ? t('Reactivate') : t('Restore')}
    </LoadingButton>
  );
}

RestoreButton.propTypes = {
  order: PropTypes.object.isRequired
};

export default RestoreButton;
