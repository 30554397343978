import React from 'react';
import PropTypes from 'prop-types';
import OrderTab from './OrderTab';
import { Input, LoadingButton, Select, Textarea } from 'FormElements';
import SupplierPicker from '../SupplierPicker';
import DistanceCalculator from '../DistanceCalculator/DistanceCalculator';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import OrderSupplierDropdown from 'Components/OrderSupplierDropdown';

function TransportForm ({ updateTabs }) {
  const { t } = useTranslation();
  const { order, updateField, getCountryCode } = useSingleOrder();
  const { transport } = order;

  const inputProps = {
    className: 'col-4',
    floatingLabel: true
  };

  function hasTransportProduct () {
    const articleNos = ['1601004', '1601001', '1601001-2'];

    return !!order.products.find(({ sku }) => articleNos.includes(sku));
  }

  return (
    <OrderTab
      tab='transport'
      header={t('Transportation')}
      afterTasks={(
        <div className='row'>
          <Textarea
            className='col-12'
            value={transport.note}
            onChange={value => updateField('transport.note', value)}
            label={t('Other')}
            data-cy='transport.note'
            floatingLabel
            rows={10}
          />
        </div>
      )}
    >
      <div className='row'>
        {getCountryCode() === 'se' ? (
          <div className='col-12'>
            {hasTransportProduct() ? (
              <SupplierPicker updateTabs={updateTabs} />
            ) : (
              <LoadingButton onClick={() => updateTabs(8)} className='btn-link'>
                {t('Please select a transportation product in order to select a transportation provider')}
              </LoadingButton>
            )}
          </div>
        ) : (
          <OrderSupplierDropdown
            className='col-8'
            type='transportoer'
            label={t('Transportation')}
            noValueLabel={t('-- No transportation provider selected --')}
          />
        )}
      </div>

      {getCountryCode() === 'se' ? (
        <>
          <div className='row'>
            <Input
              value={transport.coffin_storage}
              label={t('Coffin storage', {
                description: 'Bisättningslokal'
              })}
              onChange={value => updateField('transport.coffin_storage', value)}
              data-cy='transport.coffin_storage'
              {...inputProps}
            />
            <Input
              value={transport.invoice_to_swedish_church}
              label={t('Invoice to Swedish church', {
                description: 'Taxa eftertransport'
              })}
              onChange={value => updateField('transport.invoice_to_swedish_church', value)}
              data-cy='transport.invoice_to_swedish_church'
              {...inputProps}
            />
            <Select
              label={t('Coffin bearers to invoice Swedish church', {
                description: 'Bärare att fakturera Sv. Ky.'
              })}
              onChange={value => updateField('transport.num_coffin_carriers', value)}
              value={transport.num_coffin_carriers}
              defaultOption={t('-- Select --')}
              options={[1, 2, 3, 4, 5, 6]}
              data-cy='transport.num_coffin_carriers'
              {...inputProps}
            />
          </div>
          <div className='row'>
            <div className='col-12'>
              <DistanceCalculator updateTabs={updateTabs} />
            </div>
          </div>
        </>
      ) : null}
    </OrderTab>
  );
}

TransportForm.propTypes = {
  updateTabs: PropTypes.func.isRequired
};

export default TransportForm;
