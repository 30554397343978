import React from 'react';
import PropTypes from 'prop-types';
import EditUser from '../components/EditUser';

const SingleUser = ({ match }) => (
  <EditUser id={match.params.id} />
);

SingleUser.propTypes = {
  match: PropTypes.object.isRequired
};

export default SingleUser;
