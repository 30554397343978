import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import { Select, LoadingButton } from './FormElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

const PercentageIndicator = ({
  archived,
  status,
  statusLabel,
  percentage,
  availableStatuses,
  orderHeader
}) => {
  const { isAdmin } = useLoggedInUser();
  const { updateOrderStatus } = useSingleOrder();
  const [showSelect, setShowSelect] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  function changeStatus (newStatus) {
    setLoadingStatus(true);
    updateOrderStatus(newStatus, isAdmin);
  }

  useEffect(() => {
    setShowSelect(false);
    setLoadingStatus(false);
  }, [statusLabel]);

  return (
    <div className='percentage-indicator small-spinner-container'>
      <div className='percentage-indicator__text'>
        <div className='percentage-indicator__text__status'>
          {!showSelect ? (
            <span className='percentage-indicator__text__status-text'>{statusLabel}</span>
          ) : (
            <Select
              className='percentage-indicator__text__status-select'
              options={availableStatuses}
              value={status}
              onChange={changeStatus}
            />
          )}

          {(isAdmin && orderHeader && !archived && status !== 'invoiced') && (
            <LoadingButton
              className='btn icon percentage-indicator__text__status-edit-close'
              onClick={() => setShowSelect(!showSelect)}
              isLoading={loadingStatus}
            >
              {!showSelect ? (
                <FontAwesomeIcon icon={['fal', 'pen']} />
              ) : (
                <FontAwesomeIcon icon={['fal', 'times']} />
              )}
            </LoadingButton>
          )}

        </div>
        {percentage === 'loading' ? (
          <Spinner />
        ) : (
          <span className='percentage-indicator__text__percentage'>{percentage}%</span>
        )}
      </div>
      {percentage !== 'loading' && (
        <div className='percentage-indicator--wrapper'>
          <div className='percentage-indicator__single'>
            <span className='percentage-indicator__single__percentage'>{percentage}%</span>
          </div>
          <span className='percentage-indicator__line'>
            <span className='percentage-indicator__line__complete' style={{ right: `${100 - percentage}%` }} />
          </span>
        </div>
      )}
    </div>
  );
};

PercentageIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  statusLabel: PropTypes.string,
  archived: PropTypes.PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  availableStatuses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  orderHeader: PropTypes.bool
};

export default PercentageIndicator;
