import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AICard } from 'Layout';
import Markdown from 'react-markdown';
import useCountries from 'ReduxHooks/useCountries';

const WeeklyReport = ({ user, orderType }) => {
  const { t } = useTranslation();
  const { country } = useCountries();

  // Check if feature is activated for the account
  if (!user.user_features.find(f => f.feature === 'ai_weekly_report')) return null;

  const reportTypes = {
    'App\\Order': 'funeral_advisor',
    'App\\TherapyOrder': 'therapy_user',
    'App\\LawyerOrder': 'legal_advisor',
    'App\\CeremonyOrder': 'officiant_user',
    'App\\TombstoneOrder': 'tombstone_advisor'
  };

  // Get the latest report based on country.id and role. Based on this array:
  const report = user.reports[reportTypes[orderType]] ? user.reports[reportTypes[orderType]][country.id] : null;

  if (!report) return null;

  return (
    <AICard>
      <Markdown>{report.content}</Markdown>

      <p><i>{t('The report is generated by AI and may contain inaccuracies.')}</i></p>
    </AICard>
  );
};

WeeklyReport.propTypes = {
  user: PropTypes.object.isRequired,
  orderType: PropTypes.string
};

export default WeeklyReport;
