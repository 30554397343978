import { useDispatch, useSelector } from 'react-redux';
import { showNotice } from 'Library/notifications';
import {
  getWills,
  getWill,
  downloadWillFile,
  persistWill,
  deleteWill,
  archiveWill,
  markWillPrinted,
  markWillStored,
  updateWill,
  setIsLoading
} from '../actions/wills';
import { useTranslation } from 'react-i18next';

function useWills () {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { wills, will, pagination, isLoading } = useSelector((state) => state.wills);

  // Fetch list of wills
  async function fetchWills (filters) {
    await dispatch(setIsLoading(true));
    await dispatch(getWills(filters));
    await dispatch(setIsLoading(false));
  }

  async function downloadFile (id, fileId) {
    await dispatch(downloadWillFile(id, fileId));
  }

  // Fetch a single will by ID
  async function fetchWill (id) {
    await dispatch(setIsLoading(true));
    await dispatch(getWill(id));
    await dispatch(setIsLoading(false));
  }

  // Persist a will (create or update)
  async function persist () {
    await dispatch(setIsLoading(true));
    const response = await dispatch(persistWill());
    showNotice(t(will?.id ? 'The will has been updated' : 'The will has been created'));
    await dispatch(setIsLoading(false));
    return response;
  }

  // Delete a will
  async function destroy (id) {
    await dispatch(deleteWill(id));
    showNotice(t('The will has been deleted'));
  }

  async function archive (id, restore = false) {
    await dispatch(archiveWill(id));

    if (restore) {
      showNotice(t('The will has been restored'));
      return;
    }

    showNotice(t('The will has been archived'));
  }

  // Mark a will as printed
  async function markPrinted (id) {
    await dispatch(markWillPrinted(id));
    showNotice(t('The will has been marked as printed'));
  }

  // Mark a will as stored
  async function markStored (id) {
    await dispatch(markWillStored(id));
    showNotice(t('The will has been marked as stored'));
  }

  return {
    fetchWills,
    fetchWill,
    downloadFile,
    persist,
    destroy,
    archive,
    markPrinted,
    markStored,
    setWill: (data) => dispatch(updateWill(data)),
    isLoading,
    wills,
    will,
    pagination
  };
}

export default useWills;
