import React from 'react';
import { Toggle } from 'FormElements';
import { ROLES } from 'ReduxActions/users';
import { If } from 'Conditionals';
import useUser from 'ReduxHooks/useUser';
import { useTranslation } from 'react-i18next';

function UserNotificationSettingsTab () {
  const { t } = useTranslation();
  const { user, updateField } = useUser();
  const notificationTypes = {};

  if (user.roles.includes(ROLES.LEGAL)) {
    notificationTypes.email = t('Notify me daily via email about new cases in my area (legal cases only)');
  }

  if (user.roles.includes(ROLES.FUNERAL)) {
    notificationTypes.sms = t('Send me SMS notifications when new orders are available (funeral orders only)');
  }

  if (user.roles.includes(ROLES.THERAPIST)) {
    notificationTypes.sms = t('Send me SMS notifications when new orders are available');
  }

  function userHasNotificationType (type) {
    const userNotification = user.user_notifications.find(notification => notification.type === type);

    if (!userNotification) {
      return false;
    }

    return !!userNotification.has_notifications;
  }

  function toggleNotificationType (type, shouldNotify) {
    const notifications = [...user.user_notifications];
    const index = notifications.findIndex(notification => notification.type === type);
    const notification = {
      type,
      has_notifications: shouldNotify
    };

    if (index > -1) {
      notifications.splice(index, 1, notification);
    } else {
      notifications.push(notification);
    }

    updateField('user_notifications', notifications);
  }

  return (
    <div className='user-notification-settings'>
      <h4>{t('Your notification settings')}</h4>

      <div className='user-notification-settings__toggles'>
        <div className='user-notification-settings__toggles__toggles'>
          {Object.entries(notificationTypes).map(([type, label], index) => (
            <div key={index}>
              <Toggle
                data-cy={`user-toggle-${type}`}
                label={label}
                defaultChecked={userHasNotificationType(type)}
                onChange={shouldNotify => toggleNotificationType(type, shouldNotify)}
              />
              <If condition={type === 'sms'}>
                <p>
                  <small>{t('SMS is sent out between 08.00 and 22.00 approx.')}</small>
                </p>
              </If>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserNotificationSettingsTab;
