import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCompetenceAreas from '../../Redux/hooks/useCompetenceAreas';
import CompetenceAreaForm from './CompetenceAreaForm';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
function CompetenceArea ({ match }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCompetenceArea, competenceArea } = useCompetenceAreas();

  useEffect(() => {
    fetch(match.params.id);
  }, [match.params.id]);

  async function fetch (id) {
    setIsLoading(true);
    await fetchCompetenceArea(id);
    setIsLoading(false);
  }

  return (
    <div className='competence-area'>
      <h2 className='page-title'>{t('Edit competence area')}</h2>

      {isLoading ? (
        <Spinner />
      ) : (
        <CompetenceAreaForm
          competenceArea={competenceArea}
        />
      )}
    </div>
  );
}

CompetenceArea.propTypes = {
  match: PropTypes.object.isRequired
};

export default CompetenceArea;
