import React from 'react';
import PropTypes from 'prop-types';

const ImageProcessorButtons = ({
  cropper,
  file,
  save,
  setFile,
  setImageSrc
}) => {
  /**
   * Get the cropped version of the canvas
   */
  const getCanvas = () => cropper.getCroppedCanvas();

  /**
   * Reset values for file and imageSrc
   *
   * @param {Event} e
   */
  const reset = (e) => {
    e.preventDefault();

    setFile(null);
    setImageSrc('');
  };

  return (
    <div className='image-processor__buttons'>
      {!file || !cropper ||
        <button
          className='btn btn-cta'
          onClick={e => save(e, getCanvas(), cropper)}
        >
         Spara bild
        </button>}

      {!file ||
        <button
          className='btn btn-link'
          onClick={reset}
        >
          Avbryt
        </button>}
    </div>
  );
};

ImageProcessorButtons.propTypes = {
  cropper: PropTypes.object,
  file: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(File)
  ]).isRequired,
  save: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  setImageSrc: PropTypes.func.isRequired
};

export default ImageProcessorButtons;
