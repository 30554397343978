import React, { useState, useEffect } from 'react';
import OrderTab from './OrderTab';
import { LoadingButton, Input } from 'FormElements';
import useCustomerRecords from 'ReduxHooks/useCustomerRecords';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { showWarningNotice } from 'Library/notifications';
import Editor from 'Components/Editor';
import SingleRecord from 'Components/SingleRecord';
import { formatDate } from 'Library/functions';
import { Notice } from 'Layout';
import { If } from 'Conditionals';
import moment from 'moment';

function CustomerRecordsForm () {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const dateFormat = 'YYYY-MM-DD';
  const date = moment();
  const formatedDate = date.format(dateFormat);
  const exampleTitle = order.country.code === 'dk' ? `Samtale ` + formatedDate : `Samtal ` + formatedDate;
  const exampleText = order.country.code === 'dk' ? `<ul>
    <li><strong>Henvendelsesårsag:</strong></li>
    <li><strong>Baggrund/historie:</strong></li>
    <li><strong>Aktuelt:</strong></li>
    <li><strong>Psykisk status:</strong></li>
    <li><strong>Bedømmelse:</strong></li>
    <li><strong>Mål:</strong></li>
    <li><strong>Diagnose:</strong></li>
    </ul>` : `<ul>
    <li><strong>Kontaktorsak:</strong></li>
    <li><strong>Anamnes:</strong></li>
    <li><strong>Aktuellt:</strong></li>
    <li><strong>Psykisk status:</strong></li>
    <li><strong>Bedömning:</strong></li>
    <li><strong>Åtgärd:</strong></li>
    <li><strong>Diagnos:</strong></li>
    </ul>`;

  const [newRecordNote, setNewRecordNote] = useState(exampleText);
  const [newRecordTitle, setNewRecordTitle] = useState(exampleTitle);
  const [showAddNewRecords, setShowAddNewRecords] = useState(false);

  const { records, accessLog, resetRecords, addRecord, getRecords, isLoadingRecords, hasLoadedRecords, isAddingRecord, isLoadingAccessLog, getAcccessLog } = useCustomerRecords();

  // this beautiful code runs when the component unmounts :) :)
  useEffect(() => () => resetRecords(), []);

  const handleAddRecord = async (isDraft = false) => {
    if (!newRecordTitle) {
      showWarningNotice(t('You need to set a title'));
      return;
    }

    await addRecord(order.customer.id, {
      note: newRecordNote,
      title: newRecordTitle,
      signed: !isDraft

    });

    setNewRecordNote(exampleText);
    setNewRecordTitle(exampleTitle);
    setShowAddNewRecords(false);
  };

  return (
    <OrderTab tab='records' header={t('Customer Records')}>
      <div className='row'>
        <div className='col-12'>
          <div>
            <If condition={order.customer.social_security_number}>
              <Notice type='warning' heading={t('Please note')}>
                <p>
                  {t(
                    'Only view these records if necessary. We log every access for security purposes.'
                  )}
                </p>
                <div className='records-buttons'>
                  <LoadingButton
                    className='btn btn--primary'
                    onClick={() => setShowAddNewRecords(!showAddNewRecords)}
                    disabled={showAddNewRecords}
                  >
                    {t('Add new record')}
                  </LoadingButton>
                  <LoadingButton
                    onClick={() => getRecords(order.customer.id)}
                    isLoading={isLoadingRecords}
                    disabled={hasLoadedRecords}
                  >
                    {t('Show records')}
                  </LoadingButton>
                </div>
              </Notice>
            </If>
            <If condition={!order.customer.social_security_number}>
              <Notice type='warning' heading={t('Please note')}>
                <p>
                  {t(
                    'The customer social security number has not been set. Please add one before accessing customer records'
                  )}
                </p>
              </Notice>
            </If>
          </div>
          <AnimatePresence initial={false}>
            {showAddNewRecords && (
              <motion.div
                key='content'
                initial='collapsed'
                animate='open'
                exit='collapsed'
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 }
                }}
                className='add-new-record'
                style={{ overflow: 'hidden' }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <Input
                  label={t('Title')}
                  value={newRecordTitle}
                  onChange={setNewRecordTitle}
                  floatingLabel
                  showRequiredIcon
                />
                <Editor data={newRecordNote} onChange={setNewRecordNote} />
                <LoadingButton
                  onClick={() => handleAddRecord()}
                  isLoading={isAddingRecord}
                >
                  {t('Sign and save')}
                </LoadingButton>
                <LoadingButton
                  className='white'
                  onClick={() => handleAddRecord(true)}
                  isLoading={isAddingRecord}
                >
                  {t('save')}
                </LoadingButton>
              </motion.div>
            )}
          </AnimatePresence>
          {hasLoadedRecords && records && !isLoadingRecords && (
            <>
              <p>
                <strong>{t('Showing records for:')}</strong>{' '}
                {order.customer.social_security_number}
              </p>
              <div className='records-accordion'>
                {records.map((record, index) => (
                  <SingleRecord key={record.id} record={record} order={order} />
                ))}
              </div>
            </>
          )}
          {hasLoadedRecords &&
            records &&
            records.length < 1 &&
            !isLoadingRecords && <p>{t('We couldn\'t find any records. Please note that you can only access records authored by you. If there are records for this customer, they might have been created by another user.')}</p>}

          <If condition={order.customer.social_security_number}>
            <div className='records-footer'>
              <LoadingButton
                className='btn btn-link'
                onClick={() => getAcccessLog(order.customer.id)}
                disabled={isLoadingAccessLog || accessLog.length > 0}
              >
                {t('Show access log')}
              </LoadingButton>
              {accessLog && accessLog.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>{t('Date')}</th>
                      <th>{t('User')}</th>
                      <th>{t('Type')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessLog.map((log) => (
                      <tr key={log.id}>
                        <td>
                          {formatDate(log.created_at, order.country.code)}
                        </td>
                        <td>{log.user.name}</td>
                        <td>
                          {log.method === 'GET' ? t('Viewed') : t('Updated')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </If>
        </div>
      </div>
    </OrderTab>
  );
}

export default CustomerRecordsForm;
