import React from 'react';
import useBonusReports, { MANUAL_ADJUSTMENT_NO_VAT_TYPE, MANUAL_ADJUSTMENT_TYPE } from 'ReduxHooks/useBonusReports';
import { Checkbox, Input, LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function ManualAdjustmentsTable () {
  const { t } = useTranslation();
  const { bonusReport, updateFields, persist } = useBonusReports();
  const isConfirmed = bonusReport.confirmed_at || bonusReport.paid_at;

  function addRow () {
    const rows = [
      ...bonusReport.rows,
      ...[
        {
          id: null,
          user_id: null,
          type: MANUAL_ADJUSTMENT_TYPE,
          amount: '',
          note: ''
        }
      ]
    ];

    updateFields({ rows });
  }

  function updateRow (index, row) {
    const rows = [...bonusReport.rows];

    rows.splice(index, 1, row);

    updateFields({ rows });
  }

  async function removeRow (index) {
    const rows = [...bonusReport.rows];

    rows.splice(index, 1);

    await updateFields({ rows });
    await persist();
  }

  return (
    <div className='manual-adjustments-table overflow-y-scroll'>
      <table className='bonus-report__report manual'>
        <thead>
          <tr>
            <th>{t('Manual adjustments')}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {bonusReport.rows.map((row, index) => (
            <tr key={index} className='bonus-report__report__rows__row'>
              <td>
                <Input
                  floatingLabel
                  label='Anteckning'
                  value={row.note}
                  readOnly={isConfirmed}
                  onChange={note => updateRow(index, { ...row, note })}
                />
              </td>
              <td>
                <Input
                  floatingLabel
                  label='Summa'
                  value={row.amount || ''}
                  readOnly={isConfirmed}
                  onChange={amount => updateRow(index, { ...row, amount })}
                />
              </td>
              <td>
                <Checkbox

                  label={t('tax free')}
                  checked={row.type === MANUAL_ADJUSTMENT_NO_VAT_TYPE}
                  onChange={checked => updateRow(index, {
                    ...row,
                    type: (checked) ? MANUAL_ADJUSTMENT_NO_VAT_TYPE : MANUAL_ADJUSTMENT_TYPE
                  })}
                />
              </td>
              <td>
                {row.user ? row.user.name : ''}
              </td>
              <td>
                <If condition={!isConfirmed}>
                  <LoadingButton
                    className='btn-link'
                    onClick={() => removeRow(index)}
                  >
                    {t('Remove row')}
                  </LoadingButton>
                </If>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <If condition={!isConfirmed}>
        <LoadingButton
          className='white small'
          onClick={() => addRow()}
        >
          {t('Add row')}
        </LoadingButton>
        &nbsp;&nbsp;
        <LoadingButton
          className='small'
          onClick={() => persist()}
        >
          {t('Save')}
        </LoadingButton>
      </If>
    </div>
  );
}

export default ManualAdjustmentsTable;
