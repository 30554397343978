/**
 * Radio buttons component
 *
 * All props are required to follow best practices for Accessibilty. (https://a11y-style-guide.com/style-guide/section-forms.html)
 * Props: legend, name, choice, choices, updateChoice (see PropTypes for more info)
 *
 * Example for array of choices:
 *
  const choices = [
    {
      id: 'value_1',
      label: 'Label 1'
    },
    {
      id: 'value_2',
      label: 'Label 2'
    }
  ];
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { Fieldset } from './';
import { If } from 'Conditionals';

const RadioButtons = ({
  className,
  legend,
  name = uniqueId('radio-name'),
  choice,
  choices,
  updateChoice,
  subField = false,
  ...rest
}) => {
  return (
    <Fieldset
      className={classNames('radio-buttons', { className })}
      legend={legend}
      subField={subField}
      data-cy='radio-buttons'
    >
      {choices.map(({ id, label, description = null }) => {
        const inputId = uniqueId('radio_');
        return (
          <div className='radio-wrapper' key={id}>
            <div className='radio-wrapper__inner'>
              <input
                type='radio'
                name={name}
                id={inputId}
                value={id}
                onChange={({ target: { value } }) => updateChoice(value)}
                checked={id === choice}
                {...rest}
              />
              <label htmlFor={inputId}>{label}</label>
            </div>
            <If condition={description}>
              <span className='radio-wrapper__description'>{description}</span>
            </If>
          </div>
        );
      })}
    </Fieldset>
  );
};

RadioButtons.propTypes = {
  className: PropTypes.string,
  legend: PropTypes.string, // The <legend> provides a description for the grouping
  name: PropTypes.string, // Name of the radio group (enables normal radio button behaviour in HTML)
  choice: PropTypes.string.isRequired, // State of chosen choice handled by parent
  choices: PropTypes.array.isRequired, // Array of choice objects - needs an id (string) and a value (string)
  updateChoice: PropTypes.func.isRequired, // Function to update the state 'choice' in parent
  subField: PropTypes.bool
};

export default RadioButtons;
