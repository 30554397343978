import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import ModalHeader from './ModalHeader';
import UserList from './UserList';
import ActiveIntivationList from './ActiveIntivationList';
import InvitationQueueCreator from './IntivationQueueCreator';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../Spinner';
import {
  addCustomerMeeting,
  cancelInvitations,
  getQueue,
  getUsers,
  resetInvitations,
  sendInvitations,
  setInvitationText
} from 'ReduxActions/invitations';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useAppointments from 'Hooks/useAppointments';
import { useTranslation } from 'react-i18next';

/**
 * @needst10ns
 */
function UserAssignerModal ({ close, isOpen = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { order, get, isLawyerOrder, isFuneralOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder } = useSingleOrder();
  const { getAppointmentOfType, parseMeetingDetailsForSchedule } = useAppointments();
  const { queue, text, invitations } = useSelector(state => state.invitations);
  const { userToAssign } = useSelector(state => state.orderAssigner);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingQueue, setIsLoadingQueue] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    init();
  }, [isOpen]);

  function getCustomerMeeting () {
    const meeting = getAppointmentOfType('customer_meeting');

    if (!meeting) {
      return null;
    }

    return parseMeetingDetailsForSchedule(meeting);
  }

  async function init () {
    dispatch(resetInvitations());
    dispatch(setInvitationText());
    dispatch(addCustomerMeeting(getCustomerMeeting()));

    setIsLoadingUsers(true);
    await dispatch(getUsers(order));
    setIsLoadingUsers(false);

    if (!isTombstoneOrder) {
      setIsLoadingQueue(true);
      await dispatch(getQueue(order, { isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder }));
      setIsLoadingQueue(false);
    }
  }

  const createQueue = async () => {
    const data = {
      lawyer_order: isLawyerOrder,
      tombstone_order: isTombstoneOrder,
      therapy_order: isTherapyOrder,
      ceremony_order: isCeremonyOrder,
      user_ids: invitations.map(({ id }) => id),
      text
    };

    if (userToAssign) {
      data.expires_in_minutes = 120;
      data.direct_assign = 1;
    }

    await dispatch(sendInvitations(order, data));

    return refreshQueueOrder();
  };

  const cancelQueue = async () => {
    await dispatch(cancelInvitations(order, { lawyer_order: isLawyerOrder, tombstone_order: isTombstoneOrder, therapy_order: isTherapyOrder, ceremony_order: isCeremonyOrder }));

    return refreshQueueOrder();
  };

  async function refreshQueueOrder () {
    setIsLoadingQueue(true);
    await dispatch(getQueue(order, { isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder }));
    setIsLoadingQueue(false);

    await get(order.id, order.class);

    return true;
  }

  function hasActiveQueue () {
    if (!queue) {
      return false;
    }

    if (queue.queued.length) {
      return true;
    }

    return (queue.sent.some(({ accepted, rejected }) => !accepted && !rejected));
  }

  const queueIsActive = hasActiveQueue();

  return (
    <>
      <button
        role='button'
        className='close-modal'
        onClick={close}
      >
        <ReactSVG src='/icons/icon-close-black.svg' />
      </button>

      <div className='user-assigner-modal__content'>
        <h4>{t('Assign order')} #{order.number}</h4>

        <ModalHeader
          order={order}
          hasActiveQueue={queueIsActive}
        />

        {isLoadingUsers ? (
          <Spinner />
        ) : (
          <div>
            <ActiveIntivationList
              hasActiveQueue={queueIsActive}
              isLoading={isLoadingQueue}
              cancelQueue={cancelQueue}
            />
            <InvitationQueueCreator
              order={order}
              createQueue={createQueue}
              isFuneralOrder={isFuneralOrder}
              isTombstoneOrder={isTombstoneOrder}
              isLawyerOrder={isLawyerOrder}
              isTherapyOrder={isTherapyOrder}
              isCeremonyOrder={isCeremonyOrder}
            />
            <UserList
              order={order}
              hasActiveQueue={queueIsActive}
            />
          </div>
        )}
      </div>
    </>
  );
}

UserAssignerModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

export default UserAssignerModal;
