import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function SelectedSupplier ({ supplier = null }) {
  const { t } = useTranslation();

  if (!supplier) {
    return null;
  }

  const address = supplier.addresses.find(({ type, address_1: addressOne }) => type === 'visit' && addressOne);

  if (!supplier.phone && !address && supplier.type !== 'minnesfond') {
    return null;
  }

  const isFund = supplier.type === 'minnesfond';
  const isFundWithDonationsSupport = isFund && supplier.pennybridge_id;
  const isFundWithoutDonationsSupport = isFund && !supplier.pennybridge_id;

  const renderAddress = () => {
    if (!address) {
      return null;
    }

    const { address_1, postcode } = address; // eslint-disable-line camelcase

    return [address_1.trim(), postcode.trim()].filter(item => item).join(', ');
  };

  const motionProps = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 }
  };

  return (
    <AnimatePresence>
      <motion.div {...motionProps} className='selected-supplier'>
        {isFundWithDonationsSupport && (
          <div className='selected-supplier__donation-support'>
            <span className='selected-supplier__label'>{t('Donations supported on the memorial page')}</span>
            <FontAwesomeIcon
              icon={['fal', 'check']}
              color='#437783'
              size='xl'
            />
          </div>
        )}
        {isFundWithoutDonationsSupport && (
          <div className='selected-supplier__donation-support'>
            <span className='selected-supplier__label'>{t('NO donations supported on the memorial page')}</span>
            <FontAwesomeIcon
              icon={['fal', 'exclamation-triangle']}
              color='#ea8b00'
              size='lg'
            />
          </div>
        )}
        {supplier.phone && (
          <div className='selected-supplier__phone'>
            <span className='selected-supplier__label'>Tel: </span>{supplier.phone.trim()}
          </div>
        )}
        {address && (
          <div className='selected-supplier__address'>
            <span className='selected-supplier__label'>Besöksadress: </span>{renderAddress()}
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

SelectedSupplier.propTypes = {
  supplier: PropTypes.object
};

export default SelectedSupplier;
