import API from '../../stores/API';

export const SET_NOTES = 'SET_NOTES';

function recieveNotes (payload) {
  return {
    type: SET_NOTES,
    payload
  };
}

export function getNotes (orderId, orderType = 'App\\Order') {
  return async (dispatch) => {
    dispatch(recieveNotes([]));

    const response = await API.GET(`/notes/${orderId}?order_type=${orderType}`);

    dispatch(recieveNotes(response.data));
  };
}

export function createNote (orderId, { noteText = '', orderType = 'App\\Order' }) {
  return async (dispatch) => {
    const response = await API.POST(`/notes/${orderId}`, { note: noteText, order_type: orderType });

    dispatch(recieveNotes(response.data));
  };
}

export function updateNote (orderId, id, { noteText = '', orderType = 'App\\Order' }) {
  return async (dispatch) => {
    const response = await API.PUT(`/notes/${orderId}/${id}`, { note: noteText, order_type: orderType });

    dispatch(recieveNotes(response.data));
  };
}

export function deleteNote (orderId, id, orderType = 'App\\Order') {
  return async (dispatch) => {
    const response = await API.DELETE(`/notes/${orderId}/${id}?order_type=${orderType}`);

    dispatch(recieveNotes(response.data));
  };
}
