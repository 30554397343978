import API from '../../stores/API';

export const SET_COUPONS = 'SET_COUPONS';

function recieveCoupons (payload) {
  return {
    type: SET_COUPONS,
    payload
  };
}

export function getCoupons (countryId) {
  return async (dispatch) => {
    const { data } = await API.GET(`/coupons/${countryId}?limit=0`);
    return dispatch(recieveCoupons(data));
  };
}

export function saveCoupons (countryId, coupons) {
  return async (dispatch) => {
    const { data } = await API.POST(`/coupons/${countryId}`, coupons);

    return dispatch(recieveCoupons(data));
  };
}
