import React, { Component } from 'react';
import store from '../stores/RootStore';
import API from '../stores/API';
import PropTypes from 'prop-types';
import LavendlaDatePicker from '../components/LavendlaDatePicker';
import { format, startOfWeek, subWeeks, startOfMonth } from 'date-fns';
import { withTranslation } from 'react-i18next';
class Export extends Component {
  state = {
    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endDate: null,
    loading: false,
    type: 'orders'
  };

  startDateChange = date => {
    const startDate = date || format(startOfWeek(subWeeks(new Date(), 9)), 'yyyy-MM-dd');
    this.setState({ startDate });
  };

  handleEndDateChange = date => {
    const endDate = date || format(new Date(), 'yyyy-MM-dd');
    this.setState({ endDate });
  };

  /**
   */
  generateExport = e => {
    e.preventDefault();

    const { startDate, endDate, type } = this.state;
    const { t } = this.props;

    API.POST('/export/orders', {
      start_date: startDate,
      end_date: endDate,
      type
    }).then(res => store.noticeStore.showNotice(t('The CSV file is being generated. It will be sent to your email when it is ready.')));
  };

  changeType = ({ target }) => {
    this.setState({ type: target.value });
  };

  render () {
    const { startDate, endDate, loading, type } = this.state;
    const buttonClass = loading ? 'btn loading' : 'btn';
    const { t } = this.props;
    return (
      <div className='export'>
        <h2 className='page-title'>{t('Export')}</h2>

        <form className='datepicker-form' onSubmit={this.generateExport}>
          <div className='datepicker-container start-datepicker'>
            <label htmlFor='start_date'>{t('Start date')}</label>
            <LavendlaDatePicker
              date={startDate}
              onChange={this.startDateChange}
              isClearable={false}
              maxDate={new Date()}
            />
          </div>

          <div className='datepicker-container'>
            <label htmlFor='end_date'>{t('End date')}</label>
            <LavendlaDatePicker
              placeholder={t('Today')}
              date={endDate}
              onChange={this.endDateChange}
              maxDate={new Date()}
            />
          </div>

          <div className='dropdown-container'>
            <label htmlFor='type-of-order'>{t('Order type')}</label>
            <select name='type-of-order' id='type-of-order' onChange={this.changeType} value={type}>
              <option value='orders'>{t('Funeral orders')}</option>
              <option value='lawyer_orders'>{t('Law orders')}</option>
              <option value='tombstone_orders'>{t('Gravestone orders')}</option>
              <option value='therapy_orders'>{t('Therapy orders')}</option>
              <option value='ceremony_orders'>{t('Ceremony orders')}</option>
            </select>
          </div>

          <button className={buttonClass}>
            <span className='btn-text'>{t('Generate CSV')}</span>
            <span className='btn-loading'> </span>
          </button>
        </form>

      </div>
    );
  }
}

Export.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Export);
