import React, { useState } from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useCountries from 'ReduxHooks/useCountries';
import useTags from 'ReduxHooks/useTags';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleTag from './SingleTag';
import Modal from 'Components/Modal';
import Spinner from 'Components/Spinner';

const OrderTags = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { country } = useCountries();

  const { order, isFuneralOrder, isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder } = useSingleOrder();

  const { tags, getTags } = useTags();
  const getTagType = () => {
    if (isFuneralOrder) {
      return 'funeral';
    }
    if (isLawyerOrder) {
      return 'lawyer';
    }
    if (isTombstoneOrder) {
      return 'tombstone';
    }
    if (isTherapyOrder) {
      return 'therapy';
    }
    if (isCeremonyOrder) {
      return 'ceremony';
    }
    return '';
  };

  const openModal = async () => {
    setIsLoading(true);
    setModalOpen(true);
    await getTags(country.id, getTagType());
    setIsLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <div className='order-tags' id='order-tags-wrapper'>
        <ol className='order-tags__list'>
          <span className='icon'>
            <FontAwesomeIcon
              icon={['fal', 'tag']}
            />
          </span>
          {order.tags && order.tags.map((tag, index) => (
            <SingleTag key={index} tag={tag} />
          ))}
          <li className='order-tags__list-item order-tags__list__add-item'>
            <button
              onClick={() => openModal()}
            >{t('Edit tags')}
            </button>
          </li>
        </ol>
      </div>
      <Modal
        close={closeModal}
        className='tags-modal'
        headerTitle={t('Manage tags')}
        isOpen={modalOpen}
        parentSelector={() => document.querySelector('#order-tags-wrapper')}
      >
        <div>
          {isLoading && <Spinner />}
          <h3>{t('Current tags')}:</h3>
          {!isLoading && order.tags && order.tags.length ? (
            <ul className='modal-tags-list order-tags__list'>
              {order.tags.map((tag, index) => (
                <SingleTag key={index} tag={tag} removeItem />
              ))}
            </ul>
          ) : (<p>{t('No tags')}</p>)}
          <h3>{t('Available tags')}:</h3>
          {!isLoading && tags && tags.length ? (
            <ul className='modal-tags-list order-tags__list'>
              {tags.map((tag, index) => (
                <SingleTag key={index} tag={tag} addItem disabled={order.tags.map(t => t.id).includes(tag.id)} />
              ))}
            </ul>
          ) : (<p>{t('No available tags')}</p>)}
        </div>
      </Modal>
    </>
  );
};

export default OrderTags;
