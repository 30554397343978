import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LavendlaDatePicker from './LavendlaDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSingleOrder from '../Redux/hooks/useSingleOrder';
import uniqueId from 'lodash/uniqueId';

function OrderDatePicker ({
  field,
  label,
  value = '',
  id = uniqueId('input_'),
  showRequiredIcon = false,
  ...rest
}) {
  const { updateDateField } = useSingleOrder();

  return (
    <div
      className={classNames(
        'col-4 floating-label datepicker__floating-label', {
          'has-content': !!value,
          'has-required-icon': showRequiredIcon && !!value
        }
      )}
      data-cy={field}
      {...rest}
    >
      <LavendlaDatePicker
        id={id}
        date={value || ''}
        onChange={date => updateDateField(date, field)}
      />
      <label htmlFor={id}>{label}</label>

      {showRequiredIcon && !value ? (
        <span className='input__icon-required'>
          <FontAwesomeIcon
            icon={['fal', 'exclamation-triangle']}
            color='#ea8b00'
            size='lg'
          />
        </span>
      ) : null}
    </div>
  );
}

OrderDatePicker.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  showRequiredIcon: PropTypes.bool
};

export default OrderDatePicker;
