import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoggedTimeList from './LoggedTime/LoggedTimeList';
import Sidebar from './Sidebar';
import NoteList from './NoteList';
import EventList from './Sidebar/EventList';
import TaskList from './Tasks/TaskList';
import { useTranslation } from 'react-i18next';

function OrderSidebar () {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <Tabs>
        <TabList>
          <Tab data-cy='sidebar-tabs.tasks'>{t('Tasks')}</Tab>
          <Tab data-cy='sidebar-tabs.notes'>{t('Notes')}</Tab>
          <Tab data-cy='sidebar-tabs.history'>{t('History')}</Tab>
          <Tab data-cy='sidebar-tabs.log'>{t('Log time')}</Tab>
        </TabList>
        <TabPanel>
          <TaskList/>
        </TabPanel>
        <TabPanel>
          <NoteList/>
        </TabPanel>
        <TabPanel>
          <EventList/>
        </TabPanel>
        <TabPanel>
          <LoggedTimeList/>
        </TabPanel>
      </Tabs>
    </Sidebar>
  );
}

export default OrderSidebar;
