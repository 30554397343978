import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const OrderCommentPopout = ({ text, id, header = null, link = null }) => {
  if (text === '' || text === undefined) {
    return null;
  }

  const tooltip = () => (
    <div className='tip-content'>
      {header ? <h3>{header}</h3> : ''}

      <div
        className='tip-content__text'
        dangerouslySetInnerHTML={{
          __html: text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }}
      />

      {link ? <Link className='btn' to={link}>Visa ärende </Link> : ''}
    </div>
  );

  return (
    <div className='order-comment-popout'>
      <div
        data-tip='tooltip'
        data-for={`order-tooltip-${id}`}
        className='order-comment-popout__excerpt'
      >
        <span>{text}</span>
      </div>
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`order-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

OrderCommentPopout.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.number,
  header: PropTypes.string,
  link: PropTypes.string
};

export default OrderCommentPopout;
