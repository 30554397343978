import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../Redux/actions/users';
import { Redirect } from 'react-router';

function LogOut () {
  const dispatch = useDispatch();
  dispatch(logout());

  return (
    <Redirect to='/'/>
  );
}

export default LogOut;
