import { useState } from 'react';
import { isCreateLawyerOrderTask, isCreateTombstoneOrderTask, isOfferTask, isReviewEmailTask } from 'Library/Tasks';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { isEqual } from 'lodash';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useTextParser from 'Hooks/useTextParser';
import { useTranslation } from 'react-i18next';

function useTaskEmail (task) {
  const { t } = useTranslation();
  const { getParsedString } = useTextParser();
  const { order, isFuneralOrder, isTherapyOrder } = useSingleOrder();
  const { isLegalAdvisor, isTombstoneAdvisor } = useLoggedInUser();
  const [values, setValues] = useState(getInitialValues());

  const restoreDefaultValues = () => {
    setValues({ ...getDefaultValues() });
  };

  const setValue = (args = {}) => {
    // CKEditor triggers the onChange event when we reset the body
    // of the email. This is a workaround to avoid a race-condition
    // that otherwise results in not all the values resetting to their
    // defaults.
    if (args.body && args.body === getDefaultBody()) {
      return;
    }

    setValues({
      ...values,
      ...args
    });
  };

  function getInitialValues () {
    const { email_content: content = {} } = task.pivot;

    return { ...getDefaultValues(), ...content };
  }

  function parseString (string) {
    return getParsedString(string);
  }

  function getSuppliersOfType (type) {
    const suppliers = (isFuneralOrder) ? order.funeral.suppliers : order.suppliers;

    return suppliers.filter(supplier => supplier.type === type);
  }

  function getDefaultPaymentDeadline () {
    if (isTherapyOrder && isOfferTask(task)) {
      return '48';
    }

    return null;
  }

  function shouldAssignToSelf () {
    if (isCreateLawyerOrderTask(task) && isLegalAdvisor) {
      return true;
    }

    if (isCreateTombstoneOrderTask(task) && isTombstoneAdvisor) {
      return true;
    }

    return false;
  }

  function getDefaultValues () {
    return {
      recipient: getDefaultRecipient(),
      replyto: getDefaultReplyTo(),
      subject: getDefaultSubject(),
      body: getDefaultBody(),
      assign_to_self: shouldAssignToSelf(),
      payment_deadline: getDefaultPaymentDeadline(),
      user_id: null,
      note: '',
      customerContactRecipients: getOrderCustomerContactEmailRecipients()
    };
  }

  function getDefaultRecipient () {
    const { email_to: emailTo } = task;

    if (!emailTo || reviewEmailTaskHasRecipientOptions()) {
      return '';
    }

    if (emailTo === '[customer.email]' || emailTo === '[customer]') {
      return order.customer.email;
    }

    if (emailTo === '[customer.contacts]' || emailTo === '[customer_contacts]') {
      return getAllCustomerContacts();
    }

    const match = /\[suppliers.([a-z_]+)\]/.exec(emailTo.replace('[supplier.', '[suppliers.'));

    if (Array.isArray(match) && match.length) {
      return getSuppliersOfType(match[1])
        .map(({ email }) => email)
        .join(',');
    }

    return emailTo;
  }

  function getAllCustomerContacts () {
    const contactEmails = order.contacts.map(({ email }) => email);

    return [order.customer.email, ...contactEmails].filter(email => email).join(', ');
  }

  function reviewEmailTaskHasRecipientOptions () {
    return isReviewEmailTask(task) && getOrderCustomerContactEmailRecipients().length;
  }

  function getOrderCustomerContactEmailRecipients () {
    if (!isReviewEmailTask(task) || !order.customer?.email) {
      return [];
    }

    return [order.customer, ...(order.contacts || [])]
      .map(({ name, email }) => {
        return {
          value: email,
          label: name
        };
      }).filter(({ email }) => !email);
  }

  function getDefaultReplyTo () {
    return (order.user) ? order.user.email : '';
  }

  function getDefaultSubject () {
    if (task.email_subject !== '') {
      return parseString(task.email_subject);
    }

    return task.name;
  }

  function getDefaultBody () {
    return parseString(task.emailbody);
  }

  function showUserSelectCheckbox () {
    if (isCreateTombstoneOrderTask(task) && !isTombstoneAdvisor) {
      return false;
    }

    if (isCreateLawyerOrderTask(task) && !isLegalAdvisor) {
      return false;
    }

    return true;
  }

  function getFileUploadHeader () {
    if (isCreateTombstoneOrderTask(task)) {
      return t('Attach an image of the tombstone where applicable', {
        description: 'Bifoga ev. bilder på stenen'
      });
    }

    return (isCreateLawyerOrderTask(task)) ? t('Attach the death certificate') : t('Select one or more files to attach');
  }

  return {
    values,
    setValue,
    restoreDefaultValues,
    hasChangedValues: !isEqual(getDefaultValues(), values),
    showUserSelectCheckbox,
    getFileUploadHeader,
    getDefaultRecipient,
    getOrderCustomerContactEmailRecipients,
    reviewEmailTaskHasRecipientOptions
  };
}

export default useTaskEmail;
