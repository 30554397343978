import React, { useEffect, useState } from 'react';
import ReportsTable from './ReportsTable';
import useBonusReports, { MANUAL_ADJUSTMENT_NO_VAT_TYPE, MANUAL_ADJUSTMENT_TYPE } from 'ReduxHooks/useBonusReports';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useTranslation } from 'react-i18next';
import useUser from 'ReduxHooks/useUser';

function ReportContainer () {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const { resolvedReport } = useBonusReports();
  const { loadUser, user } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  if (!resolvedReport) {
    return null;
  }

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser () {
    await loadUser(resolvedReport.user.id);
    setIsLoading(false);
  }

  function flattenReports () {
    let flat = [];

    Object.entries(resolvedReport.rows)
      .map(([key, report]) => {
        if (isAdmin && [MANUAL_ADJUSTMENT_TYPE, MANUAL_ADJUSTMENT_NO_VAT_TYPE].includes(key)) {
          return;
        }

        if (report.rows.find(row => Array.isArray(row.rows))) {
          for (const subRow of report.rows) {
            if (Array.isArray(subRow.rows)) {
              flat = [...flat, ...subRow.rows];
            }
          }
        }

        flat = [...flat, ...report.rows];
      });

    return flat;
  }

  function getFlatReport (untaxable = true) {
    return flattenReports()
      .filter(({ taxRate }) => {
        if (untaxable) {
          return taxRate === 0;
        }

        return taxRate > 0;
      });
  }

  function getTotals (rows = [], addManualAdjustments = false, noVat = false) {
    const manualAdjustments = resolvedReport.rows[MANUAL_ADJUSTMENT_TYPE];
    const manualAdjustmentsNoVat = resolvedReport.rows[MANUAL_ADJUSTMENT_NO_VAT_TYPE];

    let total = rows.reduce((carry, { total }) => carry + total, 0);
    let totalTax = rows.reduce((carry, { totalTax }) => carry + totalTax, 0);

    if (isAdmin && addManualAdjustments && !noVat) {
      total += manualAdjustments.total;
      totalTax += manualAdjustments.totalTax;
    }

    if (isAdmin && addManualAdjustments && noVat) {
      total += manualAdjustmentsNoVat.total;
    }

    return {
      total,
      totalTax
    };
  }

  const rowsWithTax = getFlatReport(false);
  const nonTaxedRows = getFlatReport();
  if (isLoading) {
    return null;
  }
  return (
    <div className='bonus-report__row overflow-y-scroll'>
      <ReportsTable
        rows={rowsWithTax}
        totals={getTotals(rowsWithTax, true)}
        reportUser={user}
      />
      <h4>{t('Tax free')}</h4>
      <ReportsTable
        noVat
        rows={nonTaxedRows}
        totals={getTotals(nonTaxedRows, true, true)}
        reportUser={user}
      />
    </div>
  );
}

export default ReportContainer;
