import React from 'react';
import PropTypes from 'prop-types';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useOrderFiles, { EXPENSE_FILE_TYPE } from 'Hooks/useOrderFiles';
import { Notice } from 'Layout';
import { useTranslation } from 'react-i18next';

function ExpenseProductWarning ({ product, addedToCart = false }) {
  /**
   * HERE BE DRAGONS!!!!!
   *
   * Because the funnel comes from WC and not Jeff,
   * the term ID here is the term ID in WP|WC.
   *
   * Thus when we check for expense products attached
   * to the order we need to check against this ID.
   *
   * This will probably break when we migrate away from
   * WC to our own PIM, so that's great.
   *
   * @type {number}
   */
  const { t } = useTranslation();
  const expenseWcTermId = 621;
  const { getFilesOfType } = useOrderFiles();
  const {
    isLawyerOrder,
    order: {
      products = []
    }
  } = useSingleOrder();

  if (!isLawyerOrder || addedToCart) {
    return null;
  }

  if (!product.categories.find(term => term.id === expenseWcTermId)) {
    return null;
  }

  const numExpenseFiles = getFilesOfType(EXPENSE_FILE_TYPE).length;
  const numExpenseProducts = products
    .filter(({ categories }) => !!categories.find(term => term.wc_term_id === expenseWcTermId))
    .reduce((carry, product) => carry + product.pivot.quantity, 0);

  if (numExpenseFiles === 0) {
    return (
      <Notice type='error' heading={t('This product is an expense')}>
        {t('Please note that it is mandatory to upload all documentation regarding the expense via the files tab. This notice disappears when the relevant documentation has been uploaded. If no documentation is provided, it will not be possible to compensate for the expense.', {
          description: 'Notera att det är obligatoriskt att ladda upp samtliga underlag för utlägget, på fliken filer. Denna notis försvinner när underlaget laddats upp. Finns inget underlag, utgår ingen ersättning för utlägget.'
        })}
      </Notice>
    );
  }

  if (numExpenseFiles <= numExpenseProducts) {
    return (
      <Notice type='warning' heading={t('Please note the number of expenses does not match the number of documents uploaded')}>
        {t('Please ensure you have uploaded documentation for all expenses, in order to avoid issues with compensation.', {
          description: 'Säkerställ att du laddat upp underlag för samtliga utlägg, för att undvika att ersättning uteblir.'
        })}
      </Notice>
    );
  }

  return null;
}

ExpenseProductWarning.propTypes = {
  product: PropTypes.object.isRequired,
  addedToCart: PropTypes.bool
};

export default ExpenseProductWarning;
