import useSingleOrder from 'ReduxHooks/useSingleOrder';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function useAppointments () {
  const { t } = useTranslation();
  const { order, updateField } = useSingleOrder();
  const appointments = order ? [...order.appointments] : [];

  function getAppointmentOfType (type) {
    const nextMeetingArray = appointments.filter((appointment) => appointment.type === type && new Date(appointment.start_date) > new Date()).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    if (nextMeetingArray.length > 1) {
      return nextMeetingArray[0];
    }

    return appointments.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).find((appointment) => appointment.type === type);
  }

  function getIndexOfAppointmentOfType (type) {
    return appointments.findIndex(appointment => appointment.type === type);
  }

  function toggleApointment (type, appointment = null) {
    const index = getIndexOfAppointmentOfType(type);

    if (index > -1) {
      if (appointment) {
        appointments.splice(index, 1, appointment);
      } else {
        appointments.splice(index, 1);
      }

      updateField('appointments', appointments);
      return;
    }

    if (appointment) {
      appointments.push(appointment);
    }

    updateField('appointments', appointments);
  }

  function maybeGetTranslatedTitle (title) {
    switch (title) {
      case 'phone_meeting' :
      case 'Telefonmöte':
        return i18next.t('Meeting by phone');

      case 'meeting_at_clients_home':
      case 'Möte hemma hos dig':
        return i18next.t('Meeting in your home');

      case 'meeting_at_our_offices':
      case 'Möte i våra lokaler':
        return i18next.t('Meeting at our offices');

      case 'video_meeting':
      case 'Videomöte':
        return i18next.t('Video meeting');
    }

    return i18next.t('Unknown');
  }

  function getMeetingTypesForSelect () {
    return {
      phone_meeting: t('Meeting by phone'),
      video_meeting: t('Video meeting'),
      meeting_at_clients_home: t('Meeting in your home'),
      meeting_at_our_offices: t('Meeting at our offices')
    };
  }

  function parseMeetingDetailsForSchedule (appointment) {
    if (!appointment || !appointment.start_date) {
      return appointment;
    }

    function getMeetingType () {
      switch (appointment.title) {
        case 'Möte hemma hos dig':
        case 'Möte i våra lokaler':
        case 'meeting_at_our_offices':
        case 'meeting_at_clients_home':
          return 'in_person';

        default:
          return 'all';
      }
    }

    const startDate = moment(appointment.start_date);

    return {
      meetingType: getMeetingType(),
      weekday: startDate.day(),
      startTime: startDate.format('HH:mm')
    };
  }

  return {
    appointments,
    getAppointmentOfType,
    toggleApointment,
    maybeGetTranslatedTitle,
    getMeetingTypesForSelect,
    parseMeetingDetailsForSchedule
  };
}

export default useAppointments;
