import React, { useRef, useState } from 'react';
import { LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import Spinner from '../Spinner';
import useUser from 'ReduxHooks/useUser';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useTranslation } from 'react-i18next';

function UserProfileImage () {
  const { t } = useTranslation();
  const inputRef = useRef();
  const { user, addUserImage, deleteUserImage } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(user.profile_image);
  const { isAdmin } = useLoggedInUser();
  const noImageLabel = !isAdmin ? t('No image') : t('Upload image');

  async function add () {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('profile_image', inputRef.current.files[0]);
    const response = await addUserImage(user.id, formData);

    setIsLoading(false);

    if (response) {
      setProfileImage(response.profile_image);
    }
  }

  async function remove () {
    const response = await deleteUserImage(user.id);

    if (response) {
      setProfileImage(null);
    }
  }

  return (
    <div className='notifications-settings'>
      <div
        data-cy='profile-picture'
        className='profile-picture'
        style={{ backgroundImage: `url(${encodeURI(profileImage)})` }}
      >
        {(isAdmin && !!profileImage) && (
          <div className='delete-container'>
            <LoadingButton
              className='dark'
              onClick={() => remove()}
            >{t('Delete')}
            </LoadingButton>
          </div>
        )}

        {(!profileImage) && (
          <div className='upload-container' data-cy='upload-container'>
            <If condition={isLoading}>
              <Spinner />
            </If>
            <div>
              <input
                data-cy='profile-image'
                id='profile-image'
                accept='image/png, image/jpeg, image/gif'
                type='file'
                onChange={() => add()}
                ref={inputRef}
                name='profile_image'
                disabled={!isAdmin || isLoading}
              />
              <label htmlFor='profile-image' className={'file-upload' + (!isAdmin ? ' no-click' : '')}>{noImageLabel}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfileImage;
