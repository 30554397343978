import React from 'react';

const NotFound = () => (
  <div className='404'>
    <h2>404 - sidan hittades inte</h2>

    <p>Denna sidan gick tyvärr inte att hitta.</p>
  </div>
);

export default NotFound;
