import API from '../../stores/API';
import { setUserToAssign } from './orderAssigner';
import { showNotice, showWarningNotice } from 'Library/notifications';
import i18n from 'i18next';

export const ADD_INVITATION = 'ADD_INVITATION';
export const REMOVE_INVITATION = 'REMOVE_INVITATION';
export const RESET_INVITATIONS = 'RESET_INVITATIONS';
export const REORDER_INVITATIONS = 'REORDER_INVITATIONS';
export const ADD_TEXT = 'ADD_TEXT';
export const ADD_USERS = 'ADD_USERS';
export const ADD_QUEUE = 'ADD_QUEUE';
export const ADD_CUSTOMER_MEETING = 'ADD_CUSTOMER_MEETING';

function add (payload) {
  return {
    type: ADD_INVITATION,
    payload
  };
}

function remove (payload) {
  return {
    type: REMOVE_INVITATION,
    payload
  };
}

export function reset () {
  return {
    type: RESET_INVITATIONS,
    payload: null
  };
}

function reorder (payload) {
  return {
    type: REORDER_INVITATIONS,
    payload
  };
}

function receiveText (payload) {
  return {
    type: ADD_TEXT,
    payload
  };
}

function receiveUsers (payload) {
  return {
    type: ADD_USERS,
    payload
  };
}

function receiveQueue (payload) {
  return {
    type: ADD_QUEUE,
    payload
  };
}

function receiveCustomerMeeting (payload) {
  return {
    type: ADD_CUSTOMER_MEETING,
    payload
  };
}

export function addInvitation (invitation) {
  return (dispatch) => {
    dispatch(add(invitation));
  };
}

export function removeInvitation (invitation) {
  return (dispatch) => {
    dispatch(remove(invitation));
  };
}

export function resetInvitations () {
  return (dispatch) => {
    dispatch(reset());
  };
}

export function reorderInvitations (list) {
  return (dispatch) => {
    dispatch(reorder(list));
  };
}

export function sendInvitations (order, data) {
  return async (dispatch) => {
    const response = await API.POST(`/orders/${order.id}/send-order-offer-invitation`, data);

    if (response.status === 'ok') {
      showNotice('🎉' + i18n.t('The SMS queue was created!'));
      reset();
      dispatch(setInvitationText(''));
      dispatch(setUserToAssign(null));

      return;
    }

    showWarningNotice(`💔 ${response.errors.error_message}`);
  };
}

export function cancelInvitations (order, data) {
  return async (dispatch) => {
    const response = await API.POST(`/orders/${order.id}/cancel-order-offer-invitation`, data);

    if (response.status === 'ok') {
      showNotice('🎉' + i18n.t('The SMS queue was removed!'));
      dispatch(reset());

      return true;
    }

    showWarningNotice(`💔 ${response.errors.title}: ${response.errors.details}`);

    return false;
  };
}

export function setInvitationText (text = '') {
  return async (dispatch) => {
    dispatch(receiveText(text));
  };
}

export function resetUsers () {
  return async (dispatch) => {
    dispatch(receiveUsers([]));
  };
}

export function getUsers (order, search = null, offset = 0) {
  return async (dispatch, getState) => {
    if (!search && !offset) {
      dispatch(receiveUsers([]));
      dispatch(receiveQueue({
        queued: [],
        sent: []
      }));
    }

    let uri = `/users/get-for-assignment/${order.id}?order_type=${order.class}&offset=${offset}`;

    if (search) {
      uri += `&search=${search}`;
    }

    const { status, errors, data } = await API.GET(uri);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);

      return;
    }

    if (!offset) {
      dispatch(receiveUsers(data));
      return;
    }

    const { invitations: { users } } = getState();
    dispatch(receiveUsers([...users, ...data]));
  };
}

export function getPreCreationUsers (params) {
  return async (dispatch) => {
    dispatch(receiveUsers([]));

    const { status, errors, data } = await API.POST('/users/get-pre-creation', params);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(receiveUsers(data));
  };
}

function getOrderType ({ isLawyerOrder = false, isTombstoneOrder = false, isTherapyOrder = false, isCeremonyOrder = false }) {
  switch (true) {
    case isLawyerOrder:
      return 'lawyer_order';
    case isTombstoneOrder:
      return 'tombstone_order';
    case isTherapyOrder:
      return 'therapy_order';
    case isCeremonyOrder:
      return 'ceremony_order';
    default:
      return 'order';
  }
}

export function getQueue (order, { isLawyerOrder = false, isTombstoneOrder = false, isTherapyOrder = false, isCeremonyOrder = false }) {
  return async (dispatch) => {
    const response = await API.GET('/order-offer-invitations-queue/get-queue-for-order' +
      `?order_id=${order.id}&order_type=${getOrderType({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder })}`
    );

    if (response.status !== 'ok') {
      showWarningNotice(`💔 ${response.errors.title}: ${response.errors.details}`);

      return;
    }

    dispatch(receiveQueue(response.data));
  };
}

export function addCustomerMeeting (meeting = null) {
  return (dispatch) => {
    dispatch(receiveCustomerMeeting(meeting));
  };
}

export function rejectAcceptOrderInvitation (invitationId, type) {
  return async () => {
    const response = await API.POST(`/order-offer-invitations-queue/${type}/${invitationId}`);

    if (response.status === 'ok') {
      showNotice((type === 'accept') ? i18n.t('You accepted the order') : i18n.t('You declined the order'));
    }

    return response;
  };
}
