import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import LocationDropdown from '../components/LocationDropdown';
import TypeDropdown from '../components/TypeDropdown';
import SuppliersExport from '../components/SuppliersExport';
import { HeaderWithFilters, Table } from 'Layout';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { getSupplierTypes } from 'Library/functions';
import ListingPagination from '../components/ListingPagination';
import { Input } from 'FormElements';
import useSuppliers from 'ReduxHooks/useSuppliers';
import { useTranslation } from 'react-i18next';
import { AdminOnly } from 'Components/Conditionals';

function Suppliers () {
  const { isAdmin } = useLoggedInUser();
  const { suppliers, pagination, isLoading, fetchSuppliers } = useSuppliers();
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    type: null,
    location: null,
    search: '',
    page: 1
  });

  useEffect(() => {
    fetchSuppliers(filters);
  }, [filters]);

  const changeLocation = (location) => {
    setFilters({
      ...filters,
      page: 1,
      location
    });
  };

  const changeType = type => {
    setFilters({
      ...filters,
      page: 1,
      type
    });
  };

  const changeSearch = search => {
    setFilters({
      ...filters,
      page: 1,
      search
    });
  };

  function translateSupplierType (type = null) {
    if (!type) {
      return '-- ';
    }

    const supplierType = getSupplierTypes().find(({ value }) => value === type);

    return supplierType ? supplierType.label : '-- ';
  }

  function paginate (direction = 'next') {
    if (pagination.current_page === 1 && direction === 'previous') {
      return;
    }

    if (pagination.current_page === pagination.total_pages && direction === 'next') {
      return;
    }

    if (direction === 'next') {
      setFilters({
        ...filters,
        page: filters.page + 1
      });
      return;
    }

    setFilters({
      ...filters,
      page: filters.page - 1
    });
  }

  return (
    <div className='suppliers'>
      <HeaderWithFilters header={t('Suppliers')}>
        {isAdmin ? (
          <div className='header-with-filters__filters__filter'>
            <Link className='btn' to='/leverantorer/skapa'>{t('Create new')}</Link>
          </div>
        ) : null}

        <div className='header-with-filters__filters__filter'>
          <Input
            className='filter-box-suppliers__search-field'
            placeholder='Sök efter leverantör'
            value={filters.search}
            onChange={changeSearch}
          />
        </div>

        <div className='header-with-filters__filters__filter'>
          <LocationDropdown
            value={filters.location}
            onChange={changeLocation}
          />
        </div>

        <div className='header-with-filters__filters__filter'>
          <TypeDropdown
            value={filters.type}
            onChange={changeType}
          />
        </div>

        <AdminOnly>
          <div className='header-with-filters__filters__filter'>
            <SuppliersExport {...filters} />
          </div>
        </AdminOnly>
      </HeaderWithFilters>

      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          thead={[
            t('Name'), t('Contact person'), t('Type')
          ]}
          isLoading={isLoading}
        >
          {suppliers.map(({ id, name, type, contact_person: contact }) => (
            <tr key={id}>
              <td>
                <Link to={`/leverantorer/leverantor/${id}`}>{name}</Link>
              </td>
              <td>{contact || '-- '}</td>
              <td>{translateSupplierType(type)}</td>
            </tr>
          ))}
        </Table>
      )}
      <ListingPagination
        switchPage={direction => paginate(direction)}
        pagination={pagination}
      />
    </div>
  );
}

Suppliers.propTypes = {
  match: PropTypes.object.isRequired
};

export default Suppliers;
