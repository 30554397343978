import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from '../stores/RootStore';
import API from '../stores/API';
import LoadingButton from './FormElements/LoadingButton';
import { useTranslation } from 'react-i18next';

const SparGetCustomerDetails = ({ socialSecurityNumber, onUpdate, disabled = false, type = 'spar' }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const onGetDetailsClick = async () => {
    setIsLoading(true);
    const endpoint = type === 'spar' ? '/ssn/spar' : '/ssn/cpr';
    const res = await API.POST(endpoint, { social_security_number: socialSecurityNumber });
    setIsLoading(false);

    if (res.status === 'error') {
      store.noticeStore.showWarningNotice(res.errors.error_message);
      return;
    }

    if (res.data.details) {
      const message = type === 'spar' ? 'Hämtningen från SPAR lyckades' : 'Hentningen fra CPR lykkedes';
      store.noticeStore.showNotice(message, 'success', 5000);
      onUpdate(res.data.details);
      return;
    }

    store.noticeStore.showWarningNotice(t('No personal data found for this social security number'));
  };

  return (
    <div className='get-customer-details'>
      <LoadingButton
        isLoading={isLoading}
        onClick={() => onGetDetailsClick()}
        text={t('Retrieve personal information')}
        disabled={disabled}
      />
    </div>
  );
};

SparGetCustomerDetails.propTypes = {
  socialSecurityNumber: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string
};

export default SparGetCustomerDetails;
