import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSVG from 'react-svg';

const IconButton = ({
  onClick,
  text = 'Spara',
  disabled = false,
  className = '',
  icon = '',
  ...rest
}) => (
  <button
    role="button"
    aria-disabled={disabled}
    disabled={disabled}
    className={classNames('btn', 'icon', className)}
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    {...rest}
  >
    <ReactSVG src={`/icons/${icon}.svg`} />
    <span className="btn-text">{text}</span>
  </button>
);

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string
};

export default IconButton;
