/* global FormData */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Spinner from './Spinner';
import { Select } from 'FormElements';
import useOrderFiles from 'ReduxHooks/useOrderFiles';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import { showNotice, showWarningNotice } from 'Library/notifications';

function FileUpload () {
  const { t } = useTranslation();
  const { order, isLawyerOrder } = useSingleOrder();
  const { attachFile } = useOrderFiles();
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState('');
  const selectOptions = {
    client_documentation: t('Document for delivery to client'),
    expense: t('Expense')
  };

  function showRejectedFiles (files) {
    return t('The following files failed to upload:') + ' ' + files.map(({ name }) => name).join(', ');
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const data = new FormData();

    data.append('order_type', order.class);
    data.append('type', fileType);

    if (rejectedFiles.length > 0) {
      showWarningNotice(showRejectedFiles(rejectedFiles));

      return;
    }

    for (const file of acceptedFiles) {
      data.append('attached_files[]', file);
    }

    attachFiles(data);
  };

  const attachFiles = async (data) => {
    setIsLoading(true);
    const response = await attachFile(order.id, data);
    setIsLoading(false);

    if (response.status === 'error') {
      showWarningNotice(response.errors.details);

      return;
    }

    showNotice(t('The file/files have been uploaded'));
  };

  function getAcceptedMimeTypes () {
    const types = [
      // Image formats.
      'image/png',
      'image/jpg',
      'image/gif',
      'image/jpeg',
      // Text formats
      'text/plain',
      'text/csv',
      'text/tab-separated-values',
      'text/richtext',
      // Misc application formats.
      'application/pdf',
      'application/x-tar',
      'application/x-gzip',
      'application/zip',
      // MS Office formats.
      'application/msword',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-write',
      'application/vnd.ms-excel',
      'application/vnd.ms-access',
      'application/vnd.ms-project',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.slide',
      'application/vnd.ms-powerpoint.slide.macroEnabled.12',
      'application/onenote',
      'application/oxps',
      'application/vnd.ms-xpsdocument',
      // OpenOffice formats.
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.graphics',
      'application/vnd.oasis.opendocument.chart',
      'application/vnd.oasis.opendocument.database',
      'application/vnd.oasis.opendocument.formula',
      // WordPerfect formats.
      'application/wordperfect',
      // iWork formats.
      'application/vnd.apple.keynote',
      'application/vnd.apple.numbers',
      'application/vnd.apple.pages'
    ];

    return types.join(',');
  }

  return (
    <div className='file-upload'>
      <If condition={isLawyerOrder}>
        <div className='row'>
          <Select
            className='col-6'
            label={t('Select a file to upload')}
            defaultOption={t('Internal documents')}
            onChange={setFileType}
            options={selectOptions}
            floatingLabel
          />
        </div>
      </If>

      <div className='file-upload__inner'>
        <If condition={isLoading}>
          <div className='loading__container'>
            <Spinner />
          </div>
        </If>

        <Dropzone
          accept={getAcceptedMimeTypes()}
          onDrop={onDrop}
          disabled={isLoading}
          activeClassName='dropzone-box__active'
          acceptClassName='dropzone-box__accepted'
          disabledClassName='dropzone-box__disabled'
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: 'dropzone-box' })}>
                <input {...getInputProps()} />
                <p>{t('Drag files here or click to select from your computer.')}</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

export default FileUpload;
