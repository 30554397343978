import React, { useEffect, useState } from 'react';
import useUser from 'ReduxHooks/useUser';
import { LoadingButton } from 'FormElements';
import { useHistory } from 'react-router-dom';
import UserForm from '../components/User/UserForm';
import Spinner from '../components/Spinner';
import { useTranslation } from 'react-i18next';

function CreateUser () {
  const history = useHistory();
  const { dipatchUser, create, user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dipatchUser({
      id: null,
      name: '',
      competence_areas: [],
      email: '',
      employed_since: '',
      hide_from_stats: 0,
      locations: [],
      locationsPriority: [],
      user_notifications: [],
      meta_fields: [],
      mobile_phone: '',
      no_schedule: 0,
      number_of_reviews: '',
      profile_image: '',
      rating: '',
      remember_token: null,
      roles: [],
      schedule: [],
      country: null,
      countries: [],
      suppliers: [],
      tel: '',
      title: '',
      user_initials: '',
      username: ''
    });
  }, []);

  async function createUser () {
    setIsLoading(true);
    const response = await create();
    setIsLoading(false);

    if (response) {
      history.push(`/anvandare/id/${response.id}`);
    }
  }

  if (!user) {
    return (
      <Spinner />
    );
  }

  return (
    <div className='row order-tab__form'>
      <form className='col-12 user-profile'>
        <h2 className='page-title'>
          {t('Create user')}
        </h2>
        <div className='row new-form'>
          <div className='col-8'>
            <UserForm />
          </div>
        </div>

        <LoadingButton
          data-cy='create-user'
          isLoading={isLoading}
          onClick={() => createUser()}
        >
          {t('Create')}
        </LoadingButton>
      </form>
    </div>
  );
}

export default CreateUser;
