import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import Spinner from '../../Spinner';
import useGraphs from '../../../hooks/useGraphs';
import GraphFilters from './GraphFilters';
import { useTranslation } from 'react-i18next';
import { generateId } from 'Library/functions';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) {
    return null;
  }

  return (
    <div className='custom-tooltip'>
      <p className='label'>{label}</p>
      {payload.map(item => {
        return <p key={generateId()} className='row' style={{ color: item.color }}>{`${item.name} : ${item.value}`}%</p>;
      })}
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

function ConversionRates ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { data, isLoading, setParam, params } = useGraphs(
    '/dashboard/get-conversion-rates-stats-orders',
    {
      start_date: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
      end_date: null,
      user_id: null,
      sortBy: 'month',
      orderType
    }
  );

  return (
    <div className='charts-container'>
      <h4>{t('Conversion Rate - Lead to Case Won')}</h4>
      <GraphFilters
        params={params}
        setParam={setParam}
      />
      {isLoading && <Spinner />}

      {(!isLoading && data && data.length)
        ? (
          <ResponsiveContainer height={500}>
            <AreaChart data={data}>
              <XAxis dataKey='name' />
              <CartesianGrid strokeDasharray='3 3' />
              <YAxis />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Legend iconType='circle' iconSize={10} />
              <Area
                name={t('All')}
                stackId='a'
                dataKey='all'
                stroke='#480640'
                fill='#480640'
                activeDot={{ r: 5 }}
                fillOpacity={0.2}
                strokeWidth='4'
              />
              <Area
                name={t('User')}
                stackId='b'
                dataKey='user'
                stroke='#437783'
                fill='#437783'
                activeDot={{ r: 7 }}
                strokeWidth='4'
                fillOpacity={0.6}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          t('No data to for this period')
        )}
    </div>
  );
}

ConversionRates.propTypes = {
  orderType: PropTypes.string
};

export default ConversionRates;
