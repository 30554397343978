import React from 'react';
import useContacts from 'Hooks/useContacts';
import Contact from './Contact';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';

function Contacts () {
  const { t } = useTranslation();
  const { contacts, addNewContact } = useContacts();

  return (
    <div className='contacts'>
      <h4>{t('Other contact persons')}</h4>

      <LoadingButton
        className='text add-more'
        data-cy='contacts-add-contact'
        onClick={() => addNewContact()}
      >
        {t('Add a contact person')}
      </LoadingButton>

      {contacts.map((contact, index) => (
        <Contact
          key={index}
          index={index}
          contact={contact}
        />
      ))}
    </div>
  );
}

export default Contacts;
