import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PaidInvoiceStatus = ({ order }) => {
  const { t } = useTranslation();

  if (!order.invoices) {
    return null;
  }

  // Loop over order.invoices and check if any of them have paid_at null and late_notification_sent_at not null
  const hasLateInvoice = order.invoices.some(invoice => !invoice.paid_at && invoice.late_notification_sent_at);

  if (hasLateInvoice) {
    return <span className='order-status unpaid'>{t('Has unpaid invoices')}</span>;
  }

  return null;
};

PaidInvoiceStatus.propTypes = {
  order: PropTypes.object.isRequired
};

export default PaidInvoiceStatus;
