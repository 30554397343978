import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditLoggedTime from './EditLoggedTime';
import { useTranslation } from 'react-i18next';

const CreateLoggedTime = ({ persist }) => {
  const { t } = useTranslation();
  const [editing, updateEditing] = useState(false);

  return (
    <div className='create-logged-time'>
      {editing
        ? (
          <div className='create-logged-time__edit-section'>
            <h4>{t('Add a completed task')}</h4>
            <EditLoggedTime
              reset={() => updateEditing(false)}
              persist={data => {
                updateEditing(false);
                persist(data);
              }}
            />
          </div>
        ) : (
          <button
            className='create-logged-time__edit-trigger'
            onClick={e => {
              e.preventDefault();
              updateEditing(true);
            }}
          >
            {t('Add task manually')}
          </button>
        )}
    </div>
  );
};

CreateLoggedTime.propTypes = {
  persist: PropTypes.func.isRequired
};

export default CreateLoggedTime;
