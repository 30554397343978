import React from 'react';
import PropTypes from 'prop-types';
import { Fieldset, Input } from 'FormElements';
import useSupplier from 'ReduxHooks/useSupplier';
import { useTranslation } from 'react-i18next';

function Address ({ type = 'postal' }) {
  const { t } = useTranslation();
  const { supplier, updateField } = useSupplier();
  const address = supplier.addresses.find(address => address.type === type) || {
    address_1: '',
    postcode: '',
    city: '',
    type
  };

  function updateAddressField (key, value) {
    const addresses = [...supplier.addresses];
    const index = supplier.addresses.findIndex(address => address.type === type);
    const updatedAddress = { ...address, ...{ [key]: value } };

    if (index === -1) {
      addresses.push(updatedAddress);
    } else {
      addresses.splice(index, 1, updatedAddress);
    }

    updateField('addresses', addresses);
  }

  return (
    <div className='grey-box supplier-address'>
      <Fieldset legend={type === 'postal' ? t('Postal address') : t('Visiting address')}>
        <Input
          floatingLabel
          data-cy={`address_1-${type}`}
          label={t('Street adress')}
          value={address.address_1}
          onChange={value => updateAddressField('address_1', value)}
        />
        <Input
          floatingLabel
          data-cy={`postcode-${type}`}
          label={t('Postcode')}
          value={address.postcode}
          onChange={value => updateAddressField('postcode', value)}
        />
        <Input
          floatingLabel
          data-cy={`city-${type}`}
          label={t('City')}
          value={address.city}
          onChange={value => updateAddressField('city', value)}
        />
      </Fieldset>
    </div>
  );
}

Address.propTypes = {
  type: PropTypes.string
};

export default Address;
