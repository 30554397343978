import React from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import useInvitations from 'ReduxHooks/useInvitations';
import InvitationQueueListItem from './IntivationQueueListItem';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

/**
 * @needst10ns
 */
function InvitationQueueList () {
  const { users } = useSelector(state => state.invitations);
  const { userToAssign } = useSelector(state => state.orderAssigner);
  const { invitations, reorderQueue, addToQueue } = useInvitations();
  const hasNoQueue = !invitations.length;
  const { t } = useTranslation();

  function getNumQueuedAdvisors () {
    return invitations.length === 1 ? t('1 added advisor') : sprintf(t('%s added advisors'), invitations.length);
  }

  const localUsers = users.filter(({ in_location: local }) => local);

  function addLocalUsersToQueue () {
    localUsers.slice(0, 5).forEach(user => addToQueue(user));
  }

  const SortableContainer = sortableContainer(({ children }) => <div className='user-list-group__table__body'>{children}</div>);
  const SortableItem = sortableElement(({ user }) => <InvitationQueueListItem user={user} />);

  return (
    <div className={classNames('user-list-group__table', 'invitation-queue-list', { empty: hasNoQueue })}>
      <div className='user-list-group__table__header'>
        {hasNoQueue ? (
          <div className='full'>{t('No selected advisers for SMS invitation')}</div>
        ) : (
          <>
            <div>{getNumQueuedAdvisors()}</div>
            <div>{t('Distance to customer')}</div>
            <div>{t('Available for meeting')}</div>
            {!userToAssign ? (
              <div>{t('Status')}</div>
            ) : null}
          </>
        )}
      </div>
      {hasNoQueue ? (
        <div className='user-list-group__table__body'>
          <div className='user-list-group__table__body__item'>
            <div className='half text'>
              {t('Add the advisors to whom you want to send the SMS invitation here, or click "Assign directly" to assign the case directly.')}
            </div>
            <div className='half text'>
              <button
                className='btn icon'
                data-cy='ua_add_all_local'
                onClick={() => addLocalUsersToQueue()}
              >
                <ReactSVG src='/icons/icon-plus-circle.svg' />
                <span>{t('Add local advisors')}</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div
            {...animateTop}
          >
            <SortableContainer
              helperClass='sortable-invitation-queue'
              onSortEnd={reorderQueue}
              useDragHandle
            >
              {invitations.map((user, index) => (
                <SortableItem
                  key={`item-${user.id}`}
                  user={user}
                  index={index}
                />
              ))}
            </SortableContainer>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default InvitationQueueList;
