import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListItem from './ListItem';
// import { compareAsc } from 'date-fns';

const List = ({ appointments }) => {
  const { t } = useTranslation();
  const [sortedAppointments, setSortedAppointments] = useState([]);

  useEffect(() => {
    // Sort appointments Asc according to start date/time
    const sortedAppointments = appointments.sort((a, b) => {
      const aDate = new Date(a.start_date);
      const bDate = new Date(b.start_date);

      return aDate - bDate;
    });

    setSortedAppointments(sortedAppointments);
  }, [appointments]);

  return (
    <div className='mini-calendar__list'>
      {!sortedAppointments.length
        ? <div className='mini-calender__no-appointments'><p>{t('No calendar events today')}.</p></div>
        : sortedAppointments.map(appointment => (
          <ListItem
            key={appointment.id}
            appointment={appointment}
          />
        ))}
    </div>
  );
};

List.propTypes = {
  appointments: PropTypes.array.isRequired
};

export default List;
