import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';
import store from '../../stores/RootStore';
import { getResouceRootUri } from '../../library/Orders';

function useOrderDeletion () {
  const { order } = useSingleOrder();

  async function sendDeletionRequest () {
    const { status, errors } = await API.POST(`${getResouceRootUri(order)}/${order.id}/request-order-deletion`);

    if (status !== 'ok') {
      store.noticeStore.showWarningNotice(errors.error_message);
      return false;
    }

    store.noticeStore.showNotice('Begäran skickades');
    return true;
  }

  async function createOrderReponse (data = {}) {
    const { status, errors } = await API.POST('/deleted-order-responses', data);

    if (status !== 'ok') {
      store.noticeStore.showWarningNotice(errors.error_message);
      return false;
    }

    return true;
  }

  return {
    sendDeletionRequest,
    createOrderReponse
  };
}

export default useOrderDeletion;
