import React from 'react';
import PropTypes from 'prop-types';
import { renderAddress } from '../../library/SupplierFunctions';
import LoadingButton from '../FormElements/LoadingButton';
import classNames from 'classnames';

function SingleSupplier ({
  supplier,
  attachSupplierToOrder,
  isSelected = false
}) {
  const { contact_person: contactPerson, phone } = supplier;
  const address = renderAddress(supplier);

  return (
    <div className={classNames('single-supplier', { 'is-selected': isSelected })}>
      <div className='single-supplier__field name'>
        <p><strong>{supplier.name}</strong></p>
        <p>{address}</p>
      </div>
      <div className='single-supplier__field'>
        <p>{contactPerson || '—'}</p>
      </div>
      <div className='single-supplier__field'>
        <p>{phone || '—'}</p>
      </div>
      <div className='single-supplier__field action'>
        {!isSelected ? (
          <LoadingButton
            disabled={isSelected}
            onClick={() => attachSupplierToOrder(supplier)}
            text='Välj leverantör'
          />
        ) : null}
      </div>
    </div>
  );
}

SingleSupplier.propTypes = {
  supplier: PropTypes.object.isRequired,
  attachSupplierToOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

export default SingleSupplier;
