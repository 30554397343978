import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './SummaryCard';
import { useTranslation } from 'react-i18next';

const CeremonyInfo = ({
  ceremonyDetails,
  hideCeremonyInfo = false
}) => {
  const { t } = useTranslation();

  if (!ceremonyDetails.ceremony || hideCeremonyInfo) {
    const message = hideCeremonyInfo ? t('The details are hidden') : t('Direct cremation or no ceremony');
    const className = hideCeremonyInfo ? 'disabled' : '';

    return (
      <SummaryCard
        heading={t('Ceremony')}
        subHeading={t('These details can be edited on the Ceremony tab.')}
        details={[{ message }]}
        className={className}
      />
    );
  }

  const { place, date, time } = ceremonyDetails;

  const details = [
    {
      title: t('Ceremony venue'),
      description: place
    },
    {
      title: t('Ceremony date'),
      description: date
    },
    {
      title: t('Ceremony time'),
      description: time
    }
  ];

  return (
    <SummaryCard
      heading={t('Ceremony')}
      subHeading={t('These details can be edited on the Ceremony tab.')}
      details={details}
    />
  );
};

CeremonyInfo.propTypes = {
  ceremonyDetails: PropTypes.object,
  hideCeremonyInfo: PropTypes.bool
};

export default CeremonyInfo;
