import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useBonusReports from 'ReduxHooks/useBonusReports';
import Spinner from '../Spinner';
import UserReportsList from './UserReportsList';
import { useHistory } from 'react-router-dom';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Redirect } from 'react-router';
import { LoadingButton, Toggle } from 'FormElements';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function UserReports ({ userId = null }) {
  const { t } = useTranslation();
  const { isAdmin, loggedInUser } = useLoggedInUser();
  const { getForUser, bonusReports, generateReportForUser } = useBonusReports();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [includeCurrentMonth, setIncludeCurrentMonth] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  if (!isAdmin && loggedInUser.id !== userId) {
    return (
      <Redirect to='/403' />
    );
  }

  useEffect(() => {
    fetchUserReports();
  }, [userId]);

  async function fetchUserReports () {
    if (!userId) {
      return;
    }

    setIsLoading(true);
    await getForUser(userId);
    setIsLoading(false);
  }

  if (!userId) {
    return (
      <p>{t('Select a user')}</p>
    );
  }

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  const generateReport = async () => {
    setIsGeneratingReport(true);
    const report = await generateReportForUser(userId, includeCurrentMonth);
    setIsGeneratingReport(false);

    if (!report) {
      return;
    }

    history.push(`/ersattningsrapporter/${report.id}`);
  };

  const hasPendingReport = !!bonusReports.find(({ paid_at: paidAt }) => !paidAt);

  return (
    <div className='user-reports order-tab__form'>
      {isAdmin && (
        <div className='row'>
          <div className='col-12'>
            <Toggle
              label={t('Include current month when generating report')}
              defaultChecked={!!includeCurrentMonth}
              onChange={setIncludeCurrentMonth}
            />
            <br />
            <LoadingButton
              className='btn-link'
              onClick={generateReport}
              disabled={isGeneratingReport}
            >
              {hasPendingReport ? t('Rebuild the most recent unpaid report') : t('Create a new report')}
            </LoadingButton>

            <If condition={isGeneratingReport}>
              <p><em>{t('The report is being generated, please wait')}</em></p>
            </If>
          </div>
        </div>
      )}

      <div className='row overflow-y-scroll'>
        <div className='col-12'>
          <UserReportsList
            bonusReports={bonusReports}
          />
        </div>
      </div>
      {/* Todo implement pagination! */}
    </div>
  );
}

UserReports.propTypes = {
  userId: PropTypes.number
};

export default UserReports;
