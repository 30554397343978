import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';

const ImageProcessorActions = ({
  cropper
}) => {
  if (!cropper) {
    return null;
  }

  const actions = [
    {
      label: 'Zooma in',
      action: () => cropper.zoom(0.1),
      icon: '/icons/icon-zoom-in.svg'
    },
    {
      label: 'Zooma ut',
      action: () => cropper.zoom(-0.1),
      icon: '/icons/icon-zoom-out.svg'
    },
    {
      label: 'Rotera',
      action: () => cropper.rotate(90),
      icon: '/icons/icon-rotate-right.svg'
    }
  ];

  return (
    <div className='image-processor__actions'>
      {actions.map(button =>
        <button
          type='button'
          className='btn icon'
          onClick={button.action}
          key={button.label}
        >
          <ReactSVG
            aria-hidden='true'
            src={button.icon}
          />
          <span>{button.label}</span>
        </button>
      )}
    </div>
  );
};

ImageProcessorActions.propTypes = {
  cropper: PropTypes.object
};

export default ImageProcessorActions;
