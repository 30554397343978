import { useDispatch, useSelector } from 'react-redux';
import { showNotice } from 'Library/notifications';
import {
  attachFileToSupplier,
  deleteSupplier,
  detachFileFromSupplier,
  getPennyBridgeList,
  getSupplier,
  persistSupplier,
  recieveSupplier,
  toggleSupplier,
  updateSupplier
} from '../actions/suppliers';
import { useTranslation } from 'react-i18next';

function useSupplier () {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { supplier, pennyBridgeList } = useSelector(state => state.suppliers);

  async function persist () {
    const response = await dispatch(persistSupplier());
    showNotice(t('The supplier has been updated'));
    return response;
  }

  async function create () {
    const response = await dispatch(persistSupplier());
    showNotice(t('The supplier has been created'));
    return response;
  }

  async function destroy () {
    await dispatch(deleteSupplier(supplier.id));
    showNotice(t('The supplier has been deleted'));
  }

  async function toggle (deactivate = false) {
    await dispatch(toggleSupplier(supplier.id, deactivate));
    const msg = (deactivate) ? t('The supplier has been deactivated') : t('The supplier has been activated');
    showNotice(msg);
  }

  return {
    supplier,
    destroy,
    persist,
    create,
    pennyBridgeList,
    toggle: (deactivate = false) => toggle(deactivate),
    setsupplier: (data) => dispatch(recieveSupplier(data)),
    fetchSupplier: (id) => dispatch(getSupplier(id)),
    attachFile: (data) => dispatch(attachFileToSupplier(supplier.id, data)),
    detachFile: (data) => dispatch(detachFileFromSupplier(supplier.id, data)),
    updateField: (key, value) => dispatch(updateSupplier({ ...supplier, ...{ [key]: value } })),
    updateFields: (data) => dispatch(updateSupplier({ ...supplier, ...data })),
    dispatchSupplier: (data) => dispatch(updateSupplier(data)),
    getPennyBridgeList: (countryId) => dispatch(getPennyBridgeList(countryId))
  };
}

export default useSupplier;
