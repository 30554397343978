import React from 'react';
import FileList from '../../components/FileList';
import FileUpload from '../../components/FileUpload';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import OrderTab from './OrderTab';
import { If } from 'Conditionals';
import useOrderFiles, { CLIENT_DOCUMENTATION_FILE_TYPE, EXPENSE_FILE_TYPE } from 'Hooks/useOrderFiles';
import { useTranslation } from 'react-i18next';

function FilesForm () {
  const { t } = useTranslation();
  const { isLawyerOrder } = useSingleOrder();
  const { files, getFilesOfType } = useOrderFiles();

  return (
    <OrderTab
      tab='files'
      header={t('Documents')}
    >
      <FileUpload />

      <If condition={isLawyerOrder}>
        <FileList
          header={t('Documents for delivery to client')}
          files={getFilesOfType(CLIENT_DOCUMENTATION_FILE_TYPE)}
        />
        <FileList
          header={t('Internal documents')}
          files={getFilesOfType(null)}
        />
        <FileList
          header={t('Expenses')}
          files={getFilesOfType(EXPENSE_FILE_TYPE)}
        />
      </If>

      <If condition={!isLawyerOrder}>
        <FileList
          files={files}
        />
      </If>
    </OrderTab>
  );
}

export default FilesForm;
