import React, { useEffect, useState } from 'react';
import { LoadingButton, Select } from 'FormElements';
import API from '../stores/API';
import LocationDropdown from 'Components/LocationDropdown';
import CompetenceAreaDropdown from 'Components/CompetenceAreaDropdown';
import { If } from 'Conditionals';
import { TYPE_THERAPY, TYPE_LEGAL } from 'ReduxActions/competenceAreas';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';
import useCallRoutes from 'ReduxHooks/useCallRoutes';
import useCountries from 'ReduxHooks/useCountries';
import useLocations from 'ReduxHooks/useLocations';

function Tests () {
  const { t } = useTranslation();
  const { country } = useCountries();
  const { locations, getLocations } = useLocations();
  const { callRoutes, getCallRoutes } = useCallRoutes();
  const [orderType, setOrderType] = useState('App\\Order');
  const [routing, setRouting] = useState(null);
  const [orderTestData, setOrderTestData] = useState(null);
  const [routingTestData, setRoutingTestData] = useState(null);
  const [location, setLocation] = useState(null);
  const [caseType, setCaseType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (!country || !country.id) {
      return;
    }

    async function fetchData () {
      setIsFetching(true);
      await getLocations({ countryId: country.id });
      await getCallRoutes(country.id);
      setIsFetching(false);
    }
    fetchData();
  }, [country]);

  const testOrderQueue = async () => {
    setIsLoading(true);
    setOrderTestData(null);
    const { data } = await API.POST('/admin-tests/order-queue', {
      order_type: orderType,
      location_id: location
    });
    setOrderTestData(data);
    setIsLoading(false);
  };

  const changeOrderType = value => {
    setOrderType(value);
    setCaseType([]);
  };

  const testRouting = async () => {
    setIsLoading(true);
    setRoutingTestData(null);
    const { data } = await API.POST('/admin-tests/call-routing', {
      order_type: orderType,
      routing,
      country_id: country.id,
      case_type: caseType ? caseType[0] : null
    });
    setRoutingTestData(data);
    setIsLoading(false);
  };

  if (isFetching || !country || !locations || !callRoutes) {
    return (
      <div className='row order-tab__form'>
        <div className='col-12'>
          <h2 className='page-title'>{t('Tests')}</h2>
          <div className='row new-form'>
            <div className='col-12'>
              <h3>{t('Loading')}...</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const routesList = () => {
    let list = {};

    callRoutes.forEach(route => {
      const location = locations.find(location => location.id === parseInt(route.location_id));
      if (location) {
        list = {
          ...list,
          [route.number]: `${route.number} - ${location.name}`
        };
      }
    });

    return list;
  };

  return (
    <div className='row order-tab__form'>
      <div className='col-12'>

        <h2 className='page-title'>{t('Tests')}</h2>
        <Tabs>
          <TabList>
            <Tab className='react-tabs__tab' key='orders'>
              {t('Order queue')}
            </Tab>
            <Tab className='react-tabs__tab' key='call-routing'>
              {t('Call routing')}
            </Tab>
          </TabList>
          <TabPanel key='orders'>
            <div className='row new-form'>
              <div className='col-8'>
                <Select
                  label={t('Order type')}
                  onChange={value => changeOrderType(value)}
                  value={orderType}
                  options={{
                    'App\\Order': 'Funeral',
                    'App\\LawyerOrder': 'Lawyer',
                    'App\\TherapyOrder': 'Therapy',
                    'App\\CeremonyOrder': 'Ceremony'
                  }}
                />
                <label htmlFor='location'>{t('Location')}</label>
                <LocationDropdown
                  id='location'
                  value={location}
                  onChange={location => setLocation(location)}
                  placeholder={t('Select location')}
                />
                <br />
                <LoadingButton
                  className='col-4'
                  color='primary'
                  onClick={() => testOrderQueue()}
                  isLoading={isLoading}
                  disabled={!location}
                >
                  {t('Test')}
                </LoadingButton>
              </div>
              {orderTestData && (
                <div className='col-12'>
                  <h3>Result</h3>
                  <pre>
                    {JSON.stringify(orderTestData, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel key='call-routing'>
            <div className='row new-form'>
              <div className='col-8'>
                <Select
                  label={t('Order type')}
                  onChange={value => changeOrderType(value)}
                  value={orderType}
                  options={{
                    'App\\Order': 'Funeral',
                    'App\\LawyerOrder': 'Lawyer',
                    'App\\TombstoneOrder': 'Tombstone',
                    'App\\TherapyOrder': 'Therapy',
                    'App\\CeremonyOrder': 'Ceremony'
                  }}
                />
                <Select
                  id='routing'
                  label={t('Routing')}
                  onChange={value => setRouting(value)}
                  value={routing || null}
                  options={routesList()}
                  defaultOption={t('Select routing')}

                />
                <If condition={['App\\LawyerOrder', 'App\\TherapyOrder'].includes(orderType)}>
                  <label htmlFor='competence-area'>{t('Case type')}</label>
                  <CompetenceAreaDropdown
                    countryId={country.id}
                    selected={caseType}
                    disabled={!orderType}
                    type={orderType === 'App\\LawyerOrder' ? TYPE_LEGAL : TYPE_THERAPY}
                    onChange={value => setCaseType(value)}
                    placeholder={t('-- Select --')}
                  />
                  <br />
                </If>
                <LoadingButton
                  className='col-4'
                  color='primary'
                  onClick={() => testRouting()}
                  isLoading={isLoading}
                  disabled={(orderType === 'App\\Order' && !routing)}
                >
                  {t('Test')}
                </LoadingButton>
              </div>
              {routingTestData && (
                <div className='col-12'>
                  <h3>Result</h3>
                  <pre>
                    {JSON.stringify(routingTestData, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>

  );
}

Tests.propTypes = {
  match: PropTypes.object.isRequired
};

export default Tests;
