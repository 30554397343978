import API from '../../stores/API';
import useSingleOrder from './useSingleOrder';

function useOrderFiles () {
  const { orderActionCallback } = useSingleOrder();

  async function attachFile (orderId, data) {
    const response = await API.POST(`/files/${orderId}`, data, false);

    return orderActionCallback(response);
  }

  async function deleteFile (id) {
    const response = await API.DELETE(`/files/${id}`);

    return orderActionCallback(response);
  }

  return {
    attachFile,
    deleteFile
  };
}

export default useOrderFiles;
