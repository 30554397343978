import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LavendlaTimePicker from '../LavendlaTimePicker';
import LavendlaDatePicker from '../LavendlaDatePicker';
import { objectHasEmptyValues } from 'Library/functions';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const EditLoggedTime = ({ persist, loggedTime = null, reset = null }) => {
  const { t } = useTranslation();
  const [title, updateTitle] = useState(loggedTime ? loggedTime.title : '');
  const [timeSpent, updateTimeSpent] = useState(loggedTime ? loggedTime.time_spent : '');
  const [time, updateTime] = useState(loggedTime ? loggedTime.time : format(new Date(), 'HH:mm:ss'));
  const [date, updateDate] = useState(loggedTime ? loggedTime.date : format(new Date(), 'yyyy-MM-dd'));

  /**
   * Get an ID attr for a form field.
   * @param {string} attr
   * @return {string}
   */
  const getAttr = attr => {
    return loggedTime ? `logged-time-${attr}-${loggedTime.id}` : `logged-time-${attr}`;
  };

  /**
   * Reset the form to the original state.
   */
  const resetState = () => {
    updateTitle(loggedTime ? loggedTime.title : '');
    updateTimeSpent(loggedTime ? loggedTime.time_spent : '');
    updateTime(loggedTime ? loggedTime.time : '');
    updateDate(loggedTime ? loggedTime.date : '');

    if (reset) {
      reset();
    }
  };

  /**
   * Get the current values of the form.
   * @return {Object}
   */
  const getValues = () => {
    return {
      title,
      time,
      date,
      time_spent: timeSpent
    };
  };

  /**
   * Persist the form to the database.
   */
  const save = () => {
    const data = getValues();
    const id = loggedTime ? loggedTime.id : null;

    if (!loggedTime) {
      resetState();
    }

    persist(data, id);
  };

  /**
   * Check for empty values.
   *
   * @return {boolean}
   */
  const hasEmptyValues = () => {
    return objectHasEmptyValues(getValues());
  };

  return (
    <div className='edit-logged-time'>
      <form
        className='edit-logged-time__form'
        onSubmit={e => {
          e.preventDefault();
          save();
        }}
      >
        <div className='edit-logged-time__form__field title'>
          <label htmlFor={getAttr('id')}>
            {t('Title (what did you do?) *')}
          </label>
          <input
            placeholder={t('Title (what did you do?) *')}
            id={getAttr('id')}
            type='text'
            value={title}
            onChange={({ target }) => updateTitle(target.value)}
          />
        </div>

        <div className='edit-logged-time__form__group'>
          <div className='edit-logged-time__form__field time-spent'>
            <label htmlFor={getAttr('time-spent')}>
              {t('Time (minutes) *')}
            </label>
            <input
              placeholder={t('Time (minutes) *')}
              id={getAttr('time-spent')}
              type='number'
              min={1}
              step={1}
              value={timeSpent}
              onChange={({ target }) => updateTimeSpent(target.value)}
            />
          </div>

          <div className='edit-logged-time__form__field time'>
            <label htmlFor={getAttr('time')}>
              {t('Time *')}
            </label>
            <LavendlaTimePicker
              name={getAttr('time')}
              id={getAttr('time')}
              placeholder={t('Time *')}
              time={time}
              onChange={time => {
                time = (time) ? time.format('HH:mm:ss') : null;
                updateTime(time);
              }}
            />
          </div>
        </div>

        <div className='edit-logged-time__form__field date'>
          <label htmlFor={getAttr('date')}>
            {t('Date *')}
          </label>
          <LavendlaDatePicker
            name={getAttr('date')}
            id={getAttr('date')}
            placeholder={t('Date *')}
            date={date}
            onChange={date => {
              date = date || null;
              updateDate(date);
            }}
          />
        </div>

        <div className='logged-time__actions'>
          <button
            className='logged-time__actions__button reset bbl'
            onClick={e => {
              e.preventDefault();
              resetState();
            }}
          >{t('Cancel')}
          </button>
          <button
            disabled={hasEmptyValues()}
            className='logged-time__actions__button submit bbr'
            type='submit'
          >{t('Save *')}
          </button>
        </div>
      </form>
    </div>
  );
};

EditLoggedTime.propTypes = {
  persist: PropTypes.func.isRequired,
  loggedTime: PropTypes.object,
  reset: PropTypes.func
};

export default EditLoggedTime;
