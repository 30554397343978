import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';

function useSessions () {
  const { order } = useSingleOrder();

  async function createPayment (data) {
    const response = await API.POST(`/therapy_orders/${order.id}/create-payment?include=stripePayments.items,expenses.stripePaymentItem,appointments.stripePaymentItem`, data);

    order.appointments = response.data.appointments;
    order.expenses = response.data.expenses;
    order.stripePayments = response.data.stripePayments;
    order.status = response.data.status;
    order.status_translated = response.data.status_translated;

    return response;
  }

  return {
    createPayment
  };
}

export default useSessions;
