import {
  SET_IS_AUTHENTICATED,
  SET_LOGGED_IN_USER,
  SET_USER,
  SET_USERS,
  SET_PAGINATION,
  SET_PAYMENT_METHODS, SET_FEATURES
} from '../actions/users';

const initialState = {
  loggedInUser: null,
  isAuthenticated: false,
  user: null,
  users: [],
  pagination: {},
  paymentMethods: [],
  features: {}
};

export default function users (state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOGGED_IN_USER: {
      return {
        ...state,
        loggedInUser: payload
      };
    }

    case SET_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: payload
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: payload
      };
    }

    case SET_USERS: {
      return {
        ...state,
        users: payload
      };
    }

    case SET_PAGINATION: {
      return {
        ...state,
        pagination: payload
      };
    }

    case SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: payload
      };
    }

    case SET_FEATURES: {
      return {
        ...state,
        features: payload
      };
    }

    default:
      return state;
  }
}
