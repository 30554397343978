import React from 'react';
import PropTypes from 'prop-types';
import { ROLES } from '../../Redux/actions/users';
import { translateRole } from '../../library/UserRoles';
import { useTranslation } from 'react-i18next';

const UserRoleDropdown = ({ onChange }) => {
  const { t } = useTranslation();

  return (
    <select
      className='user-role-dropdown'
      onChange={({ target }) => onChange(target.value)}
    >
      <option value=''> {t('-- Choose role --')} </option>
      {Object.values(ROLES).map((role, index) => (
        <option key={`${index}-${role}`} value={role}>{translateRole(role)}</option>
      ))}
      <option value='inactive'>{t('Disabled users')}</option>
    </select>
  );
};

UserRoleDropdown.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default UserRoleDropdown;
