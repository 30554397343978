import { format, parse } from 'date-fns';
import { enGB, sv, da } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const formatDateForCalendar = (startDate, endDate) => {
  const { i18n } = useTranslation();
  const locale = (() => {
    switch (i18n.language) {
      case 'sv_se':
        return sv;
      case 'da_dk':
        return da;
      default:
        return enGB;
    }
  })();

  const datetime = parse(startDate, 'yyyy-MM-dd HH:mm:ss', new Date());
  const endDatetime = parse(endDate, 'yyyy-MM-dd HH:mm:ss', new Date());

  return (
    <>
      {capitalize(format(datetime, 'EEEE d MMMM', { locale }))}
      <br />
      {`${format(datetime, 'HH:mm')} - ${format(endDatetime, 'HH:mm')}`}
    </>
  );
};
