import React from 'react';
import { Toggle } from 'FormElements';
import { Link } from 'react-router-dom';
import WelcomeSplash from './WelcomeSplash';
import { Notice } from 'Layout';
import API from '../../../stores/API';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UserScheduleDashboard from './UserScheduleDashboard';
import { showWarningNotice } from 'Library/notifications';

function UserScheduleInfo () {
  const { t } = useTranslation();
  const {
    loggedInUser: {
      id,
      name,
      schedule,
      no_schedule: noSchedule,
      user_profiles: userProfiles
    },
    updateLoggedInUser
  } = useLoggedInUser();
  const visibleOnWeb = userProfiles.some(profile => profile.active === '1');

  async function toggleSchedule () {
    const { data, status, errors } = await API.PUT(`/users/${id}`, {
      no_schedule: !noSchedule
    });

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return;
    }

    updateLoggedInUser(data);
  }

  function getMeetingsCount () {
    let numActive = 0;

    for (const key of Object.keys(schedule)) {
      numActive += schedule[key].filter(slot => !slot.inactive).length;
    }

    return numActive;
  }

  const meetingsCount = getMeetingsCount();

  return (
    <div className='grey-box dashboard__visibility-toggle'>
      <WelcomeSplash
        userName={name}
        noSchedule={!!noSchedule}
        visibleOnWeb={visibleOnWeb}
      />

      <If condition={meetingsCount === 0 && visibleOnWeb}>
        <Notice
          type='notice'
          heading={t('No working hours')}
        >
          <p>{t('You must add your availability in your schedule.')} <Link to='profil'>{t('Add working hours')}</Link>.</p>
        </Notice>
        <br />
      </If>

      <If condition={visibleOnWeb}>
        <Toggle
          label={t('Available for meetings')}
          desc={`(${t('your schedule will be visible on the web')})`}
          defaultChecked={!noSchedule}
          onChange={() => toggleSchedule()}
        />
        <UserScheduleDashboard
          schedule={schedule}
          noSchedule={noSchedule}
        />
      </If>
    </div>
  );
}

export default UserScheduleInfo;
