import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans } from 'react-i18next';
import { Notice } from 'Layout';

function EmailBodySavedAsDraftWarning ({ motionProps, canResetEmail = false }) {
  if (!canResetEmail) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div {...motionProps}>
        <Notice type='warning'>
          <p>
            <Trans
              i18nKey='updateOrderInformationText'
              description='If you wish to fetch updated order information (for example the date) you need to reset the content of the email. In this case use the button below, <strong>Reset information.</strong>'
              defaults='If you wish to fetch updated order information (for example the date) you need to reset the content of the email. In this case use the button below, <strong>Reset information</strong>.'
            >
              If you wish to fetch updated order information (for example the date) you need to reset the content of the email. In this case use the button below <strong>Reset information</strong>.
            </Trans>
          </p>
        </Notice>
      </motion.div>
    </AnimatePresence>
  );
}

EmailBodySavedAsDraftWarning.propTypes = {
  motionProps: PropTypes.object.isRequired,
  canResetEmail: PropTypes.bool
};

export default EmailBodySavedAsDraftWarning;
