import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { useTranslation } from 'react-i18next';

const ListingPagination = ({ switchPage, pagination = null }) => {
  const { t } = useTranslation();
  if (!pagination || pagination.total === 0 || pagination.total <= pagination.per_page) {
    return null;
  }

  return (
    <div className='pagination listing-pagination'>
      {pagination.current_page > 1 && (
        <div className='pagination__item pagination__item--prev'>
          <a className='pagination__link' onClick={() => switchPage('previous')}>
            <ReactSVG src='/icons/icon-arrow-left.svg' />
            {t('Previous page')}
          </a>
        </div>
      )}
      {pagination.current_page < pagination.total_pages && (
        <div className='pagination__item pagination__item--next'>
          <a className='pagination__link' onClick={() => switchPage('next')}>
            {t('Next page')}
            <ReactSVG src='/icons/icon-arrow-right.svg' />
          </a>
        </div>
      )}
    </div>
  );
};

ListingPagination.propTypes = {
  switchPage: PropTypes.func.isRequired,
  pagination: PropTypes.object
};

export default ListingPagination;
