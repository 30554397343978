import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

function WelcomeSplash ({ userName, noSchedule, visibleOnWeb, meetingsCount }) {
  if (visibleOnWeb && !noSchedule && meetingsCount > 0) {
    return (
      <p className='dashboard__section-small-heading dashboard__welcome-message'>
        <Trans userName={userName}>
          Welcome back <strong>{{ userName }}!</strong>
        </Trans>
      </p>
    );
  }

  return (
    <p className='dashboard__section-small-heading dashboard__welcome-message no-schedule'>
      <Trans userName={userName}>
        Welcome back <strong>{{ userName }}!</strong>
      </Trans>
    </p>
  );
}

WelcomeSplash.propTypes = {
  userName: PropTypes.string.isRequired,
  noSchedule: PropTypes.bool.isRequired,
  visibleOnWeb: PropTypes.bool.isRequired,
  meetingsCount: PropTypes.number
};

export default WelcomeSplash;
