import uniqueId from 'lodash/uniqueId';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function UserGroupedTimeSlots ({
  schedule = [],
  type = 'all',
  header = 'Alla mötestider'
}) {
  const { t } = useTranslation();
  const { customerMeeting } = useSelector(state => state.invitations);
  const filteredSlots = schedule
    .map(slot => {
      if (!slot.schedule) {
        return null;
      }
      let slots = [];

      if (type === 'all') {
        slots = slot.schedule['all'] ? slot.schedule['all'] : [];
      } else {
        slots = slot.schedule['phone'] ? slot.schedule['phone'] : [];
      }

      return {
        weekday: getReadableDayName(slot.date),
        slots: slots,
        startTime: slot.startTime,
        endTime: slot.endTime
      };
    }).filter(slot => slot.slots.length);

  if (!filteredSlots.length) {
    return null;
  }

  function getReadableDayName (date) {
    const selectedDate = moment(date).startOf('day');
    const diff = selectedDate.diff(moment().startOf('day'), 'day');

    if (!diff) {
      return t('today');
    }

    return (diff === 1)
      ? t('tomorrow')
      : selectedDate
        .locale('se')
        .format('dddd');
  }

  function isMeetingTime ({ meetingType, startTime, weekday }) {
    if (!customerMeeting) {
      return false;
    }

    if (customerMeeting.startTime !== startTime || customerMeeting.weekday !== weekday) {
      return false;
    }

    if (customerMeeting.meetingType === 'in_person' && meetingType === 'phone') {
      return false;
    }

    return true;
  }

  if (!filteredSlots.length) {
    return null;
  }

  return (
    <div className='single-time-slot'>
      <h5 className='single-time-slot__header'>{header}</h5>
      {filteredSlots.map(slot => {
        return (
          <span
            key={uniqueId()}
            className='single-time-slot__container'
          >
            <span className='single-time-slot__label'>
              {slot.weekday}
            </span>
            <span className='single-time-slot__slots'>
              {slot.slots.map(day => (
                <span
                  key={uniqueId()}
                  className={classNames('single-time-slot__slots__slot', { 'is-meeting-time': isMeetingTime(day) })}
                >{day.startTime} - {day.endTime}
                </span>
              ))}
            </span>
          </span>
        );
      })}
    </div>
  );
}

UserGroupedTimeSlots.propTypes = {
  schedule: PropTypes.array.isRequired,
  type: PropTypes.string,
  header: PropTypes.string
};

export default UserGroupedTimeSlots;
