
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

function UserDistance ({ user }) {
  const { t } = useTranslation();

  if (!user.distance) {
    return t('unknown');
  }

  if (user.distance <= 3) {
    return t('within 3 km');
  }

  return sprintf(t('approx. %s km'), Math.round(user.distance));
}

export default UserDistance;
