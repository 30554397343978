import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { LoadingButton } from 'FormElements';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function DownloadDonations ({ archives = false }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { getDonations } = useMemorialPage();

  const fetch = async () => {
    setIsLoading(true);
    const { status, errors, data } = await getDonations(archives);
    setIsLoading(false);

    if (status === 'error') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    if (archives) {
      showNotice(t("The archive is being created. You'll receive an email with a link when the archive is ready"));
      return;
    }

    showNotice(t('The PDF has been created an can be downloaded from the following link') + ` <a target='_blank' rel='noopener noreferrer' href='${data.link}'>${data.link}</a>`);
  };

  return (
    <div className='download-donations'>
      <LoadingButton
        onClick={fetch}
        disabled={isLoading}
        className='btn-link'
      >
        {archives
          ? t('Download all donation certificates (a link will be sent to your email address)')
          : t('Download a list of all those who have donated')
        }
      </LoadingButton>
    </div>
  );
}

DownloadDonations.propTypes = {
  archives: PropTypes.bool
};

export default DownloadDonations;
