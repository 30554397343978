import useSingleOrder from '../Redux/hooks/useSingleOrder';
import API from '../stores/API';
import store from '../stores/RootStore';

function useOrderSuppliers () {
  const { order, updateField, updateFields } = useSingleOrder();
  let suppliers = [];

  if (order && order.class === 'App\\Order') {
    suppliers = order.funeral.suppliers;
  } else {
    suppliers = order.suppliers;
  }

  function getSupplierOfType (type) {
    if (Array.isArray(type)) {
      return suppliers.find(supplier => type.includes(supplier.type));
    }

    return suppliers.find(supplier => supplier.type === type);
  }

  function getSuppliersOfType (type) {
    if (Array.isArray(type)) {
      return suppliers.filter(supplier => type.includes(supplier.type));
    }

    return suppliers ? suppliers.filter(supplier => supplier.type === type) : [];
  }

  async function setSuppliers (suppliers) {
    if (order.class === 'App\\Order') {
      const { funeral } = order;
      funeral.suppliers = suppliers;

      return updateFields({ funeral });
    }

    return updateField('suppliers', suppliers);
  }

  const toggleSupplier = async (type, supplier = null) => {
    const suppliersCopy = (Array.isArray(type))
      ? [...suppliers].filter(supplier => !type.includes(supplier.type))
      : [...suppliers].filter(supplier => supplier.type !== type);

    if (supplier) {
      suppliersCopy.push(supplier);
    }

    return setSuppliers(suppliersCopy);
  };

  const toggleSuppliers = async (type, supplierArray = []) => {
    const suppliersCopy = (Array.isArray(type))
      ? [...suppliers].filter(supplier => !type.includes(supplier.type))
      : [...suppliers].filter(supplier => supplier.type !== type);

    return setSuppliers([...suppliersCopy, ...supplierArray]);
  };

  async function getSortedSuppliers (type) {
    const { data, status, errors } = await API.GET(`/suppliers/sorted/${order.id}?type=${type}`);

    if (status !== 'ok') {
      store.noticeStore.showWarningNotice(`💔 ${errors.error_message}`);
      return false;
    }

    return data;
  }

  return {
    getSupplierOfType,
    getSuppliersOfType,
    toggleSupplier,
    toggleSuppliers,
    getSortedSuppliers,
    suppliers
  };
}

export default useOrderSuppliers;
