import { SET_COUNTRIES, SET_COUNTRY, SET_COUNTRY_SETTINGS } from '../actions/countries';

const initialState = {
  countries: [],
  countrySettings: {},
  country: {
    id: null,
    code: '',
    name: ''
  }
};

export default function countries (state = initialState, { type, payload }) {
  switch (type) {
    case SET_COUNTRIES: {
      return {
        ...state,
        countries: payload
      };
    }

    case SET_COUNTRY: {
      return {
        ...state,
        country: payload
      };
    }

    case SET_COUNTRY_SETTINGS: {
      return {
        ...state,
        countrySettings: payload
      };
    }

    default:
      return state;
  }
}
