import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../library/functions';
import FlowerShopOrderProduct from './FlowerShopOrderProduct';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function FlowerShopOrder ({ flowerShopOrder }) {
  const { t } = useTranslation();
  const { order, getCountryCode } = useSingleOrder();

  return (
    <div className='flowershop-flowers__order'>
      <div className='flowershop-flowers__order-header'>
        <div className='flowershop-flowers__order-header__group'>
          <h4>{t('Order')} #{flowerShopOrder.wc_order_id}</h4>
          <p>{formatDate(flowerShopOrder.created_at, getCountryCode())}</p>
        </div>
        <p className='flowershop-flowers__order-header__customer'>
          {flowerShopOrder.first_name} {flowerShopOrder.last_name},
          &nbsp;<a href={`mailto:${flowerShopOrder.email}`}>{flowerShopOrder.email}</a>,
          &nbsp;{flowerShopOrder.telephone}
        </p>
      </div>

      <div className='flowershop-flowers__products'>
        {flowerShopOrder.products.map(product =>
          <FlowerShopOrderProduct
            key={flowerShopOrder.id + product.id}
            order={flowerShopOrder}
            product={product}
            countryCode={order.country.code}
          />
        )}
      </div>
    </div>
  );
}

FlowerShopOrder.propTypes = {
  flowerShopOrder: PropTypes.object.isRequired
};

export default FlowerShopOrder;
