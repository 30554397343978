import React from 'react';
import PropTypes from 'prop-types';

function UserHomeAddress ({ user }) {
  if (!user.address) {
    return null;
  }

  return (
    <div className='user-home-address'>
      {user.address.city}
    </div>
  );
}

UserHomeAddress.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserHomeAddress;
