import store from './RootStore';
import { getToken } from 'Components/localstorage';

export const WEB_URL_SE = 'https://lavendla.se';
export const WEB_URL_DK = 'https://lavendla.dk';
export const WEB_URL_NO = 'https://lavendla.no';
export const WEB_URL_FI = 'https://lavendla.fi';
export const WEB_URL_GB = 'https://lavendla.co.uk';

export default class API {
  static fetch (
    url,
    method = 'GET',
    data = {},
    json = true,
    externalApi
  ) {
    const fetchData = {
      headers: this.getHeaders(method, json),
      method
    };

    if (json === true && Object.keys(data).length > 0) {
      fetchData.body = JSON.stringify(data);
    } else {
      fetchData.body = data;
    }
    // A GET call must not have a body attribute. ( latest Chrome, FF, Edge and Safari... )
    if (method === 'GET') {
      fetchData.body = undefined;
    }

    // fix for staging api-address
    const host = window && window.location && window.location.host;
    let apiUrlParam = API_URL;

    if (host.includes('netlify.app') || host.includes('localhost')) {
      apiUrlParam = localStorage.getItem('backend') || 'https://stage.api.lavendla.com/v1';
    }

    if (externalApi) {
      externalApi = host.includes('netlify.app') ? externalApi.replace('https://lavendla', 'https://stage.lavendla') : externalApi;
      apiUrlParam = `${externalApi}/wp-json/lavenla_funnel/v2`;
    }

    if (store.is_test) {
      if (url.includes('?')) {
        url += '&is_test_mode=true';
      } else {
        url += '?is_test_mode=true';
      }
    }

    return fetch(apiUrlParam + url, fetchData)
      .then(response => response.json());
  }

  static getHeaders (method = 'GET', json = true) {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getToken()
    };

    if (json === true) {
      headers['Content-Type'] = this.getContentType(method);
    }

    return headers;
  }

  static getContentType (method = 'GET') {
    return (method === 'PUT') ? 'application/merge-patch+json' : 'application/json';
  }

  static GET (url, json = true, externalApi = false) {
    return this.fetch(url, 'GET', {}, json, externalApi);
  }

  static POST (url, data = {}, json = true) {
    return this.fetch(url, 'POST', data, json);
  }

  static PUT (url, data = {}, json = true) {
    return this.fetch(url, 'PUT', data, json);
  }

  static DELETE (url, json = true) {
    return this.fetch(url, 'DELETE', {}, json);
  }
}
