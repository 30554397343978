import { useDispatch, useSelector } from 'react-redux';
import { getCoupons, saveCoupons } from '../actions/coupons';

function useCoupons () {
  const dispatch = useDispatch();
  const { coupons } = useSelector(state => state.coupons);

  return {
    coupons,
    getCoupons: (countryId) => dispatch(getCoupons(countryId)),
    saveCoupons: (countryId, coupons) => dispatch(saveCoupons(countryId, coupons))
  };
}

export default useCoupons;
