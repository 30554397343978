import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const VisibleOnWebIcon = ({ id, activeProfiles }) => {
  const { t } = useTranslation();
  const activeProfilesSiteNames = activeProfiles && activeProfiles.map(profile => profile.site_name).join(', ');

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('This user is visible on the marketing sites')}</h3>
      <p className='tip-content__text'>{t('This user has choosen to be visible on the following marketing sites:')} {activeProfilesSiteNames}</p>
      <p className='tip-content__text'>{t('However, without an active schedule they will not be visible.')}</p>
    </div>
  );

  return (
    <div className='registry-icons'>
      <FontAwesomeIcon
        aria-label={t('Active schedule')}
        icon={['fal', 'globe']}
        data-tip='tooltip'
        data-for={`visible-on-web-tooltip-${id}`}
        className='registry-icons__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`visible-on-web-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

VisibleOnWebIcon.propTypes = {
  id: PropTypes.number,
  activeProfiles: PropTypes.array
};

export default VisibleOnWebIcon;
