import React from 'react';
import PropTypes from 'prop-types';
import useSingleOrder from '../Redux/hooks/useSingleOrder';
import { getRequiredFields } from 'Library/Orders';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function RemainingMandatoryFields ({ tab = 'customer' }) {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const requiredFields = getRequiredFields(order);

  if (!requiredFields[tab] || typeof requiredFields[tab] === 'undefined') {
    return null;
  }

  const requiredRemaining = requiredFields[tab].filter(property => !order[tab][property]);

  if (!requiredRemaining.length) {
    return null;
  }

  return (
    <div className={classNames('remaining-mandatory-fields')}>
      <FontAwesomeIcon
        icon={['fal', 'exclamation-triangle']}
        color='#ea8b00'
        size='lg'
      />
      {requiredRemaining.length} {(requiredRemaining.length === 1) ? t('task') : t('tasks')}
      &nbsp;
      {t('remain', {
        description: '3 tasks remain'
      })}
    </div>
  );
}

RemainingMandatoryFields.propTypes = {
  tab: PropTypes.string
};

export default RemainingMandatoryFields;
