import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import useOrderSuppliers from '../hooks/useOrderSuppliers';
import useSuppliers from '../hooks/useSuppliers';
import { AnimatePresence, motion } from 'framer-motion';
import { animateOpacity } from 'Library/motion';
import SelectedSupplier from './SelectedSupplier';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function FuneralPlaceDropdown () {
  const { t } = useTranslation();
  const type = ['Kyrkor', 'Kapell'];
  const { getSupplierOfType, toggleSupplier, suppliers: orderSuppliers } = useOrderSuppliers();
  const [supplier, setSupplier] = useState(getInitialSupplier());
  const [suppliers, setSuppliers] = useState([]);
  const { fetchSuppliers } = useSuppliers();

  useEffect(() => {
    setSupplier(getInitialSupplier());
  }, [orderSuppliers]);

  function getInitialSupplier () {
    const currentSupplier = getSupplierOfType(type);

    return currentSupplier ? formatSupplierWithLocation(currentSupplier) : null;
  }

  const onSupplierChange = async (value) => {
    const selected = (value) ? suppliers.find(({ id }) => value.value === id) : null;

    setSupplier(value);
    await toggleSupplier(type, selected);
  };

  function formatSupplierWithLocation ({ name: label, id: value, locations = [] }) {
    const locationName = locations.map(({ name }) => name).join(', ');

    return {
      label: locationName ? `${label} (${locationName})` : label,
      value
    };
  }

  const loadOptions = async (search, resolve) => {
    const data = await fetchSuppliers(type, {
      search,
      limit: 30
    });

    setSuppliers(data);

    const options = data.map(supplier => formatSupplierWithLocation(supplier));

    return new Promise(() => resolve(options));
  };

  return (
    <div
      data-cy={`supplier-${type}`}
      className={classNames('react-select__floating-label col-6')}
    >
      <AnimatePresence>
        <If condition={!!supplier}>
          <motion.div {...animateOpacity} className='selected-supplier__container'>
            <SelectedSupplier supplier={getSupplierOfType(type)} />
          </motion.div>
        </If>
      </AnimatePresence>
      <AsyncSelect
        isClearable
        cacheOptions
        defaultOptions
        value={supplier}
        onChange={onSupplierChange}
        loadOptions={loadOptions}
        id='funeral-place-selector'
        className='lavendla-select'
        classNamePrefix='lavendla-select'
        placeholder={t('-- No church/chapel selected --')}
      />
      <label htmlFor='funeral-place-selector'>{t('Church/chapel')}</label>
    </div>
  );
}

export default FuneralPlaceDropdown;
