import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SingleUser = ({ user, addItem, removeItem, disabled }) => {
  const { order, updateFields } = useSingleOrder();

  if (!user) {
    return null;
  }

  return (
    <li
      className={classNames('order-users__list-item', { 'can-add': addItem, 'can-remove': removeItem, disabled })}
      onClick={() => {
        if (removeItem) {
          updateFields({
            users: order.users.filter((t) => t.id !== user.id)
          });
        }
        if (addItem) {
          updateFields({
            users: order.users.concat(user)
          });
        }
      }}
    >
      {user.name}
      {removeItem && (
        <button
          className='button order-users__list__toggle'
          onClick={() => {
            updateFields({
              users: order.users.filter((t) => t.id !== user.id)
            });
          }}
        >
          <FontAwesomeIcon
            icon={['fal', 'times']}
          />
        </button>
      )}
      {addItem && (
        <button
          className='button order-users__list__toggle'
          onClick={() => {
            updateFields({
              users: order.users.concat(user)
            });
          }}
        >
          <FontAwesomeIcon
            icon={['fal', 'plus']}
          />
        </button>
      )}
    </li>
  );
};

SingleUser.propTypes = {
  user: PropTypes.object.isRequired,
  addItem: PropTypes.bool,
  removeItem: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SingleUser;
