import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import GraphFilters from './GraphFilters';
import Spinner from '../../Spinner';
import useGraphs from '../../../hooks/useGraphs';
import moment from 'moment';
import { generateId } from 'Library/functions';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) {
    return null;
  }

  return (
    <div className='custom-tooltip'>
      <p className='label'>{label}</p>
      {payload.map(item => {
        const ordersSum = item.payload.orders.length;
        const x = ((item.value / ordersSum) * 100).toFixed(1);
        return <p key={generateId()} className='row' style={{ color: item.color }}>{`${item.name} : ${item.value} (${x}%)`}</p>;
      })}
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

function OrderStatus ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { isAdmin, loggedInUser } = useLoggedInUser();

  const { data, isLoading, setParam, params } = useGraphs('/dashboard/get-order-stats', {
    start_date: moment().subtract(9, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
    end_date: null,
    user_id: (isAdmin) ? null : loggedInUser.id,
    sortBy: (isAdmin) ? 'week' : 'month',
    orderType
  });

  return (
    <div className='charts-container'>
      <h4>{t('Order status')}</h4>

      <GraphFilters
        params={params}
        setParam={setParam}
        orderType={orderType}
      />

      {isLoading && <Spinner />}
      {!isLoading && data && data.length ? (
        <ResponsiveContainer height={500}>
          {orderType === 'App\\TherapyOrder' ? (
            <BarChart data={data}>
              <XAxis dataKey='name' />
              <CartesianGrid strokeDasharray='3 3' />
              <YAxis />

              <Bar
                name={t('Invoiced')}
                stackId='a'
                dataKey='invoiced'
                fill='#437783'
              />
              <Bar
                name={t('In progress')}
                stackId='a'
                dataKey='paid_inquiry'
                fill='#bc5b00'
              />
              <Bar
                name={t('Awaiting payment')}
                stackId='a'
                dataKey='awaiting_payment'
                fill='#ffb9cb'
              />
              <Bar
                name={t('Inquiries')}
                stackId='a'
                dataKey='inquiries'
                fill='#4a90e2'
              />
              <Bar
                name={t('Lost')}
                stackId='a'
                dataKey='deleted'
                fill='#ec2b48'
              />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Legend iconType='circle' iconSize={10} />
            </BarChart>
          ) : (
            <BarChart data={data}>
              <XAxis dataKey='name' />
              <CartesianGrid strokeDasharray='3 3' />
              <YAxis />

              <Bar
                name={t('Invoiced')}
                stackId='a'
                dataKey='invoiced'
                fill='#437783'
              />
              <Bar
                name={t('In progress')}
                stackId='a'
                dataKey='in_progress'
                fill='#3ad397'
              />
              <Bar
                name={t('Offers')}
                stackId='a'
                dataKey='offers'
                fill='#e00078'
              />
              <Bar
                name={t('Inquiries')}
                stackId='a'
                dataKey='inquiries'
                fill='#4a90e2'
              />
              <Bar
                name={t('Lost')}
                stackId='a'
                dataKey='deleted'
                fill='#ec2b48'
              />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Legend iconType='circle' iconSize={10} />
            </BarChart>
          )}
        </ResponsiveContainer>
      ) : (
        t('No data to for this period')
      )}
    </div>
  );
}

OrderStatus.propTypes = {
  orderType: PropTypes.string,
  url: PropTypes.string
};

export default OrderStatus;
