import React from 'react';
import { formatCurrency } from 'Library/functions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function OrderFees () {
  const { order } = useSingleOrder();

  if (!order.fees || !order.fees.length) {
    return null;
  }

  return (
    <div className='order-fees'>
      {order.fees.map(fee => (
        <div key={fee.id}>
          {fee.name}: {formatCurrency(fee.total + fee.total_tax, null, order.country.code, order?.currency)}
        </div>
      ))}
    </div>
  );
}

export default OrderFees;
