import React, { useState } from 'react';
import { Table } from 'Layout';
import { LoadingButton } from 'FormElements';
import useAppointments from 'Hooks/useAppointments';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import PaymentInfoModal from 'Components/PaymentComponents/PaymentInfoModal';
import { formatCurrency, getTranslatedStripeStatus } from 'Library/functions';
import LavendlaModal from 'Components/Modal';
import SessionForm from './SessionForm';
import PropTypes from 'prop-types';

function SessionsTable ({ appointments, order, updateField, isPassed = false }) {
  const { t } = useTranslation();
  const { maybeGetTranslatedTitle } = useAppointments();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editingSession, setEditingSession] = useState(null);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const openSessionModal = (session) => {
    setEditingSession(session);
    setSessionModalOpen(true);
  };

  const closeSessionModal = () => {
    setSessionModalOpen(false);
    setEditingSession(null);
  };

  const saveSession = (updatedSession) => {
    const sessions = order.appointments.map(session => {
      if (session.id === updatedSession.id) {
        return updatedSession;
      }

      return session;
    });

    updateStripePayment(updatedSession);

    updateField('appointments', sessions);
    closeSessionModal();
  };

  const updateStripePayment = (updatedSession, remove = false) => {
    const stripePaymentId = updatedSession?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);

    if (!stripePayment) {
      return;
    }

    const stripePaymentItem = stripePayment.items.find(item => item.id === updatedSession.stripePaymentItem.id);

    if (!stripePaymentItem) {
      return;
    }

    if (remove) {
      stripePayment.items = stripePayment.items.filter(item => item.id !== updatedSession.stripePaymentItem.id);
    } else {
      stripePaymentItem.amount = updatedSession.stripePaymentItem.amount;
    }

    stripePayment.amount = stripePayment.items.reduce((total, item) => total + parseFloat(item.amount), 0);

    updateField('stripePayments', order.stripePayments);
  };

  const deleteSession = (session) => {
    const sessions = order.appointments.filter(s => s.id !== session.id);

    updateStripePayment(session, true);
    updateField('appointments', sessions);
  };

  const formatAppointmentDate = (startDate, meetingLength, customerTimezone) => {
    const dateFormat = 'yyyy-MM-dd HH:mm';
    const formattedStart = startDate ? format(utcToZonedTime(new Date(startDate), Intl.DateTimeFormat().resolvedOptions().timeZone), dateFormat) : '';
    const endDate = startDate && meetingLength ? new Date(startDate).setMinutes(new Date(startDate).getMinutes() + meetingLength) : '';
    const formattedEnd = endDate ? format(utcToZonedTime(new Date(endDate), Intl.DateTimeFormat().resolvedOptions().timeZone), dateFormat) : '';

    if (!formattedStart) {
      return t('Date not set');
    }

    let appointmentTimes = formattedStart;
    if (formattedEnd) {
      appointmentTimes = `${appointmentTimes} - ${formattedEnd}`;
    }

    if (customerTimezone) {
      try {
        const zonedStart = utcToZonedTime(new Date(startDate), customerTimezone);
        const zonedEnd = utcToZonedTime(new Date(endDate), customerTimezone);
        const formattedZonedStart = format(zonedStart, dateFormat);
        const formattedZonedEnd = format(zonedEnd, dateFormat);

        return (
          <>
            {appointmentTimes}
            <span className='customer-meeting-time'>
              <br />
              {t('Customer Time')}: ({formattedZonedStart} - {formattedZonedEnd})
            </span>
          </>
        );
      } catch (error) {
        console.error("Error formatting date in customer's timezone:", error);
      }
    }

    return <>{appointmentTimes}</>;
  };

  const getPaymentInformation = (appointment) => {
    const stripePaymentId = appointment?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return null;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);
    const offerId = stripePayment?.offer_id || null;

    if (!stripePayment) {
      return null;
    }

    return {
      stripePayment,
      offerId
    };
  };

  const renderPaymentInformation = (appointment) => {
    const paymentInformation = getPaymentInformation(appointment);

    if (!paymentInformation) {
      return null;
    }

    const { stripePayment, offerId } = paymentInformation;

    return (
      <>
        <LoadingButton
          className='text' onClick={() => {
            setPaymentInfo(stripePayment);
            setModalIsOpen(true);
          }}
        >
          <span className={`payment-info__status ${stripePayment.status}`}><span>#{offerId} - </span>{getTranslatedStripeStatus(stripePayment.status)}</span>
        </LoadingButton>
      </>
    );
  };

  const getJitsiLink = () => {
    if (!order.meet_url) {
      return null;
    }

    const name = order.user ? order.user.name : '';
    return `https://meet.lavendla.com/${order.meet_url}#config.prejoinConfig.enabled=true&config.subject="Lavendla"&userInfo.displayName="${name}"`;
  };

  const canEditSession = (appointment) => {
    const paymentInformation = getPaymentInformation(appointment);

    if (!paymentInformation) {
      const product = order.products[0];
      let invoice = null;
      let stripePaymentId = null;
      let stripePayment = null;

      if (product) {
        invoice = order.invoices.find(invoice => invoice.id === product.invoice_id);
        stripePaymentId = invoice?.stripe_id || null;
        stripePayment = stripePaymentId && order.stripePayments.find(payment => payment.stripe_id === stripePaymentId);
      }

      return stripePayment && (stripePayment.status === 'paid' || stripePayment.status === 'pending');
    }

    const { stripePayment } = paymentInformation;

    return stripePayment.status === 'paid' || stripePayment.status === 'pending';
  };

  const canDeleteSession = (appointment) => {
    if (isPassed) {
      return false;
    }

    const stripePaymentId = appointment?.stripePaymentItem?.stripe_payment_id || null;

    if (!stripePaymentId) {
      return true;
    }

    const stripePayment = order.stripePayments.find(payment => payment.id === stripePaymentId);

    if (!stripePayment) {
      return true;
    }

    return stripePayment.status === 'pending';
  };

  const getPrice = (appointment) => {
    if (!appointment.stripePaymentItem) {
      return null;
    }

    return formatCurrency(appointment.stripePaymentItem.amount, null, order.countryCode, order?.currency);
  };

  const headers = [
    t('Date and time'),
    t('Meeting type'),
    t('Place'),
    t('Price'),
    t('Payment'),
    t('Actions')
  ];

  return (
    <div className='table-wrapper'>
      <Table
        isLoading={false}
        thead={headers}
        className='sessions-table'
      >
        {appointments && appointments.length > 0 ? (
          <>
            {appointments.map((appointment) => (
              <tr key={appointment.id ?? appointment.internalId} className={appointment.missed_payment ? 'missed-payment' : ''}>
                <td>{formatAppointmentDate(appointment.start_date, appointment.meeting_length, order.customer?.timezone)}</td>
                <td>{maybeGetTranslatedTitle(appointment.title)}</td>
                <td>
                  {order.meet_url && appointment.title === 'video_meeting' && (
                    <a
                      href={getJitsiLink()}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {`https://meet.lavendla.com/${order.meet_url}`}
                    </a>
                  )}
                  {appointment.supplier && appointment.supplier.name && appointment.title !== 'video_meeting' && (
                    <span>{appointment.supplier.name}</span>
                  )}
                </td>
                <td className='smaller-cell'>{getPrice(appointment)}</td>
                <td>
                  {renderPaymentInformation(appointment)}
                </td>
                <td>
                  <div className='sessions-table__actions'>
                    {canEditSession(appointment) && (
                      <LoadingButton
                        className='btn small white'
                        onClick={() => openSessionModal(appointment)}
                      >
                        {t('Edit')}
                      </LoadingButton>
                    )}
                    {canDeleteSession(appointment) && (
                      <LoadingButton
                        className='btn small red'
                        onClick={() => deleteSession(appointment)}
                      >
                        {t('Delete')}
                      </LoadingButton>
                    )}
                    {!canDeleteSession(appointment) && !canEditSession(appointment) && (
                      <span>{t('No actions available')}</span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan='5'>
              <span>{t('No passed sessions')}</span>
            </td>
          </tr>
        )}
      </Table>
      {paymentInfo && (
        <PaymentInfoModal
          stripePayment={paymentInfo}
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
            setPaymentInfo(null);
          }}
        />
      )}
      <LavendlaModal
        className='category-modal'
        close={closeSessionModal}
        headerTitle={editingSession && editingSession.id ? t('Edit session') : t('Add session')}
        isOpen={sessionModalOpen}
        parentSelector={() => document.querySelector('#customer-meeting-wrapper')}
      >
        {editingSession && (
          <SessionForm
            session={editingSession}
            onChange={(updatedSession) => setEditingSession(updatedSession)}
            onSave={() => saveSession(editingSession)}
            onCancel={closeSessionModal}
          />
        )}
      </LavendlaModal>
    </div>
  );
}

SessionsTable.propTypes = {
  appointments: PropTypes.array,
  order: PropTypes.object,
  updateField: PropTypes.func,
  isPassed: PropTypes.bool
};

export default SessionsTable;
