import React from 'react';
import PropTypes from 'prop-types';

function Rating ({
  rating,
  numberOfReviews = null
}) {
  function getWidth () {
    const ratingWidth = rating * 20;
    return `${ratingWidth < 0 ? 0 : (ratingWidth > 100 ? 100 : ratingWidth)}%`;
  }

  return (
    <div className='lavendla-rating'>
      <span className='lavendla-rating__base'>
        <span className='lavendla-rating__rating' style={{ width: getWidth() }}/>
      </span>
      {numberOfReviews ? (
        <span className='lavendla-rating__number-of-reviews'>({numberOfReviews})</span>
      ) : null}
    </div>
  );
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  numberOfReviews: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default Rating;
