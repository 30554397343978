const initialState = {
  defaultImages: []
};

export default function memorialPageImages (state = initialState, { type, payload }) {
  switch (type) {
    case 'defaultImages': {
      return {
        ...state,
        defaultImages: payload
      };
    }

    default:
      return state;
  }
}
