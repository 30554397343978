import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import LoadingButton from '../FormElements/LoadingButton';
import store from '../../stores/RootStore';
import useOrderTasks from '../../Redux/hooks/useOrderTasks';

/**
 * @needsT10ns
 */
function TaskVerbalOfferModal ({ task, closeModal, sentVerbalOffer }) {
  const { updateMetadataForTask } = useOrderTasks();
  const [isLoading, setIsLoading] = useState(false);

  const sendVerbalOffer = async () => {
    setIsLoading(true);
    const response = await updateMetadataForTask(task.id, {
      metadata: 'verbal_offer'
    });
    setIsLoading(false);

    if (response.status === 'error') {
      store.noticeStore.showWarningNotice(`😬 Det uppstod ett fel: <strong>${response.errors.error_message}</strong>`);

      return;
    }

    sentVerbalOffer();
  };

  return (
    <>
      <button className='close-modal' onClick={closeModal}>
        <ReactSVG src='/icons/icon-close-black.svg' />
      </button>
      <div className='modal-container'>
        <h3>Kom ihåg</h3>
        <ul>
          <li>
            Om du lämnar muntlig offert registreras din ersättning på ersättningsrapporten, det datum då kunden betalar fakturan, dvs inte vid fakturadatumet som den gör vid skriftlig offert.
            <br />Detta då vi inte kan driva ärendet till Inkasso och Kronofogden utan skriftligt underlag.
            <br />Vi rekommenderar därför alltid skriftlig offert, speciellt vid större och löpande uppdrag.
          </li>
          <br />
          <li>
            Lägg till rätt produkt (timdebitering eller fast pris) som du lämnat muntlig offert på, så du kommer ihåg vad du gett kunden för prisuppgift.
            <br />Detta är också viktigt för Lavendla ska ha rätt information i ärendet.
          </li>
        </ul>
        <LoadingButton
          isLoading={isLoading}
          text='Skicka muntlig offert'
          onClick={sendVerbalOffer}
        />
      </div>
    </>
  );
}

TaskVerbalOfferModal.propTypes = {
  task: PropTypes.object.isRequired,
  sentVerbalOffer: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default TaskVerbalOfferModal;
