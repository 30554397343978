import React from 'react';
import { Link } from 'react-router-dom';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

function MemorialPageHeaderLink () {
  const { t } = useTranslation();
  const { order, isFuneralOrder, getCountryCode } = useSingleOrder();
  if (getCountryCode() !== 'se' || !isFuneralOrder) {
    return null;
  }

  const {
    id,
    memorial_page: {
      lavendla_post_url: memorialPageLink
    }
  } = order;

  return (
    <div className='free-shipping-link'>
      <div className='free-shipping-link__inner'>
        {memorialPageLink ? (
          <a
            href={memorialPageLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>{t('Memorial page with free delivery for flowers')}</strong>
          </a>
        ) : (
          <span>
            {t('Memorial page with free delivery for flowers')} ({t('Memorial page not created')},
            &nbsp;
            <Link to={`/ordrar/order/${id}/minnessida`}>
              <strong>{t('Create here')}</strong>
            </Link>)
          </span>
        )}
      </div>
    </div>
  );
}

export default MemorialPageHeaderLink;
