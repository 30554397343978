import UserImage from 'Components/UserImage';
import UserTitle from 'Components/UserAssigner/Components/UserTitle';
import UserHomeAddress from 'Components/UserAssigner/Components/UserHomeAddress';
import Rating from 'Components/Rating';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import UserDistance from 'Components/UserAssigner/Components/UserDistance';
import UserTimeSlots from 'Components/UserTimeSlots';
import { AnimatePresence, motion } from 'framer-motion';
import { If } from 'Conditionals/index';
import { animateOpacity } from 'Library/motion';
import UserWorkloadDetails from 'Components/UserAssigner/Components/UserWorkloadDetails';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function UserToAssign ({ user, setUser }) {
  const { t } = useTranslation();
  const [showWorkloadDetails, setShowWorkloadDetails] = useState(false);

  return (
    <div className='user-list-group-item'>
      <div className='user-list-group__table__body__item'>
        <div className='user-image-with-details'>
          <UserImage
            user={user}
          />
          <div>
            <UserTitle
              user={user}
            />
            <UserHomeAddress
              user={user}
            />
            <Rating
              rating={user.rating}
              numberOfReviews={user.number_of_reviews}
            />
            <button
              role='button'
              className={classNames('btn', 'icon', 'show-workload-deets', { 'is-active': showWorkloadDetails })}
              onClick={e => {
                e.preventDefault();
                setShowWorkloadDetails(!showWorkloadDetails);
              }}
            >
              <span>{showWorkloadDetails ? t('Hide') : t('Show')} {t('workload')}</span>
              <ReactSVG src='/icons/icon-chevron.svg' />
            </button>
          </div>
        </div>
        <div>
          <UserDistance user={user} />
        </div>
        <div>
          <UserTimeSlots user={user} />
        </div>
        <div className='radio-buttons'>
          <div className='radio-wrapper'>
            <div className='radio-wrapper__inner'>
              <input
                type='radio'
                name='assign_user'
                id={`assign-user-${user.id}`}
                onClick={() => setUser(user)}
              />
              <label htmlFor={`assign-user-${user.id}`}>
                {t('Assign')}
              </label>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        <If condition={showWorkloadDetails}>
          <motion.div {...animateOpacity}>
            <UserWorkloadDetails user={user} />
          </motion.div>
        </If>
      </AnimatePresence>
    </div>
  );
}

UserToAssign.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired
};

export default UserToAssign;
