import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, formatMinutes } from 'Library/functions';
import SendReport from './SendReport';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import useCountries from 'ReduxHooks/useCountries';

function LoggedTimeSummary ({ times = [] }) {
  const { t } = useTranslation();
  const { country } = useCountries();
  const totalLoggedTime = () => {
    return times.reduce((carry, { time_spent: timeSpent }) => carry + timeSpent, 0);
  };

  const firstLoggedTime = () => {
    if (times.length === 0) {
      return '';
    }

    return formatDate(`${times[0].date} ${times[0].time}`, country.code);
  };

  const numLogged = () => {
    return (times.length === 1)
      ? t('One time log')
      : sprintf(t('%s time logs'), times.length);
  };

  const numPeople = () => {
    const userNames = times.map(({ user: { username } }) => username);
    const numUsers = new Set(userNames).size;

    return (numUsers === 1)
      ? t('from one person')
      : sprintf(t('from %s persons'), numUsers);
  };

  return (
    <div className='logged-time-summary'>
      <h4 className='logged-time-summary__header'>{t('Total logged time')}</h4>
      {times.length === 0
        ? <span>{t('No time logs yet')}</span>
        : <div>
          <span>{formatMinutes(totalLoggedTime(), country.code)}, {t('from')} {firstLoggedTime()}</span>
          <span>{numLogged()} {numPeople()}</span>

          <SendReport />
        </div>}
    </div>
  );
}

LoggedTimeSummary.propTypes = {
  times: PropTypes.array.isRequired
};

export default LoggedTimeSummary;
