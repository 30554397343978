import React from 'react';
import PropTypes from 'prop-types';
import { ROLES } from 'ReduxActions/users';

function UserTitle ({ user, order = null }) {
  function isEstateManager () {
    if (!order) {
      return false;
    }

    if (order.class !== 'App\\LawyerOrder') {
      return false;
    }

    return order.case_type === 'Bouppteckning' && user.roles.includes(ROLES.FUNERAL);
  }

  return (
    <div className='user-title'>
      {user.name}
      {isEstateManager() ? (
        <span className='user-title__estate-manager'>
          (boutredare)
        </span>
      ) : null}
    </div>
  );
}

UserTitle.propTypes = {
  user: PropTypes.object.isRequired,
  order: PropTypes.object
};

export default UserTitle;
