import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InvitationQueueList from './IntivationQueueList';
import useInvitations from 'ReduxHooks/useInvitations';
import ReactSVG from 'react-svg';
import { LoadingButton } from 'FormElements';
import { useDispatch, useSelector } from 'react-redux';
import { setInvitationText } from 'ReduxActions/invitations';
import { AnimatePresence, motion } from 'framer-motion';
import { setShouldRemoveAssignedUser, unassignUser } from 'ReduxActions/orderAssigner';
import InvitationQueueMessage from './IntivationQueueMessage';
import API from '../../../stores/API';
import { getResouceRootUri } from 'Library/Orders';
import { animateTop, animateOpacity } from 'Library/motion';
import { useTranslation } from 'react-i18next';

/**
 * @needst10ns
 */
function InvitationQueueCreator ({
  order,
  createQueue,
  isFuneralOrder = false,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false
}) {
  const dispatch = useDispatch();
  const { resetQueue, invitations } = useInvitations();
  const orderAssigner = useSelector(state => state.orderAssigner);
  const { queue, text } = useSelector(state => state.invitations);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const componentRef = createRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (!orderAssigner.shouldRemoveAssignedUser && !orderAssigner.userToAssign) {
      return;
    }

    maybeLoadCurrentOrder();
  }, [orderAssigner]);

  async function maybeLoadCurrentOrder () {
    setIsLoading(true);
    setCurrentOrder(null);

    document.getElementsByClassName('ReactModal__Overlay')[0].scrollTo({
      top: componentRef.current.offsetTop - 50,
      behavior: 'smooth'
    });

    const currentOrderViaAPI = await getCurrentOrder();
    if (isFuneralOrder) {
      setCurrentOrder(currentOrderViaAPI.data);
    } else {
      setCurrentOrder(currentOrderViaAPI);
    }

    setIsLoading(false);
  }

  async function unassign () {
    setIsLoading(true);
    await dispatch(unassignUser(order));
    setIsLoading(false);
  }

  async function getCurrentOrder () {
    return API.GET(`${getResouceRootUri(order)}/${order.id}?include=user`);
  }

  const submit = async () => {
    setIsLoading(true);
    await createQueue();
    setIsLoading(false);
  };

  function hasActiveQueue () {
    if (!queue) {
      return false;
    }

    if (queue.queued.length) {
      return true;
    }

    if (!queue.sent.length) {
      return false;
    }

    const lastItem = [...queue.sent].pop();

    return !lastItem.accepted && !lastItem.rejected;
  }

  if (hasActiveQueue()) {
    return null;
  }

  const updateText = ({ target }) => {
    if (target.value.length > 100) {
      return;
    }

    dispatch(setInvitationText(target.value));
  };

  const isDisabled = (!invitations.length && !orderAssigner.userToAssign) || isLoading;

  return (
    <div ref={componentRef}>
      <InvitationQueueMessage
        isLoading={isLoading}
        currentOrder={currentOrder}
      />

      {orderAssigner.shouldRemoveAssignedUser ? (
        <AnimatePresence>
          <motion.div {...animateOpacity}>
            <div className='invitation-queue-creator__actions'>
              <LoadingButton
                onClick={() => unassign()}
                disabled={isLoading}
                text={t('Remove advisor')}
              />
              <LoadingButton
                onClick={() => dispatch(setShouldRemoveAssignedUser(false))}
                text={t('Cancel')}
                className='white'
              />
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}

      <AnimatePresence>
        <motion.div {...animateTop}>
          <InvitationQueueList />
        </motion.div>
      </AnimatePresence>

      {orderAssigner.userToAssign || invitations.length ? (
        <AnimatePresence>
          <motion.div {...animateOpacity}>
            <div className='invitation-queue-creator__text'>
              <label htmlFor='invitation-queue-creator-text'>
                {t('Optional: Add info to the SMS, max 100 characters. Write e.g. "Urgent" or "Meeting tomorrow"')}<br />
                <strong>{t('Information about the location and any customer meeting is always included in the SMS')}</strong>
              </label>
              <textarea
                id='invitation-queue-creator-text'
                value={text}
                disabled={isDisabled}
                onChange={updateText}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}

      <div className='invitation-queue-creator__actions'>
        {!orderAssigner.shouldRemoveAssignedUser ? (
          <>
            <LoadingButton
              onClick={submit}
              disabled={isDisabled}
              text={orderAssigner.userToAssign ? t('Assign the case and send SMS') : t('Send the SMS invitation')}
            />
            <button
              type='button'
              className='btn icon'
              disabled={isDisabled}
              onClick={() => resetQueue()}
            >
              <ReactSVG src='/icons/icon-trash.svg' />
              <span>{t('Remove all')}</span>
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

InvitationQueueCreator.propTypes = {
  order: PropTypes.object.isRequired,
  createQueue: PropTypes.func.isRequired,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isFuneralOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default InvitationQueueCreator;
