import { SET_BONUS_AMOUNTS, SET_REPRESENTATIVE_PRODUCTS } from '../actions/bonusAmounts';

const initialState = {
  bonusAmounts: [],
  representativeProducts: []
};

export default function bonusAmounts (state = initialState, { type, payload }) {
  switch (type) {
    case SET_BONUS_AMOUNTS: {
      return {
        ...state,
        bonusAmounts: payload
      };
    }

    case SET_REPRESENTATIVE_PRODUCTS: {
      return {
        ...state,
        representativeProducts: payload
      };
    }

    default:
      return state;
  }
}
