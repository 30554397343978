import React from 'react';
import PropTypes from 'prop-types';

const ProductProperties = ({ properties }) => {
  if (!properties || properties.length === 0) {
    return null;
  }

  const Property = ({ property }) => {
    if (property.header === 0 || !property.property) {
      return null;
    }

    return (
      <div className='single-product__properties__property'>
        {!property.icon || <img src={property.icon.url} alt={property.icon.alt} />}
        <strong>{property.header}:&nbsp;</strong>
        <span>{property.property}</span>
      </div>
    );
  };

  Property.propTypes = {
    property: PropTypes.object
  };

  return (
    <div className='single-product__properties'>
      {properties.map((property, index) => (
        <Property property={property} key={index} />
      ))}
    </div>
  );
};

ProductProperties.propTypes = {
  properties: PropTypes.array
};

export default ProductProperties;
