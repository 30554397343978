import { useDispatch, useSelector } from 'react-redux';
import { getFlowerFee, setFlowerFee, saveFlowerFee } from '../actions/flowerFee';

function useFlowerFee () {
  const dispatch = useDispatch();
  const { flowerFee } = useSelector(({ flowerFee }) => flowerFee);

  return {
    flowerFee,
    getFlowerFee: (countryId) => dispatch(getFlowerFee(countryId)),
    updateFlowerFeeField: (key, value) => dispatch(setFlowerFee({ ...flowerFee, ...{ [key]: value } })),
    setFlowerFee: (flowerFee) => dispatch(setFlowerFee(flowerFee)),
    saveFlowerFee: (countryId, flowerFee) => dispatch(saveFlowerFee(countryId, flowerFee))
  };
}

export default useFlowerFee;
