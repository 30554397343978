import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const options = [
  {
    value: 'awaiting_payment',
    label: i18next.t('Awaiting payment')
  },
  {
    value: 'inquiry',
    label: i18next.t('Inquiry')
  },
  {
    value: 'offer',
    label: i18next.t('Offer')
  },
  {
    value: 'in_progress',
    label: i18next.t('In progress')
  },
  {
    value: 'passed',
    label: i18next.t('Passed')
  },
  {
    value: 'partially_invoiced',
    label: i18next.t('Partially invoiced')
  },
  {
    value: 'new_inquiry',
    label: i18next.t('New inquiry')
  },
  {
    value: 'invoiced',
    label: i18next.t('Invoiced')
  }
];

function StatusSelect ({
  onChange,
  defaultValue = []
}) {
  const { t } = useTranslation();

  function getDefaultValue () {
    if (!defaultValue || !defaultValue.length) {
      return [];
    }

    return defaultValue.map(value => {
      return options.find(option => option.value === value);
    });
  }

  const onUpdate = (values) => {
    if (!values) {
      onChange([]);
      return;
    }

    onChange(values.map(({ value }) => value));
  };

  return (
    <Select
      placeholder={t('Show all orders')}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      onChange={onUpdate}
      value={getDefaultValue()}
      options={options}
      isMulti
      isClearable
    />
  );
}

StatusSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.array
};

export default StatusSelect;
