import React from 'react';
import { useTranslation } from 'react-i18next';

const NotAuthorized = props => {
  const { t } = useTranslation();

  return (
    <div className='not-authorized'>
      <h2>{t('Access denied')}</h2>

      <p>{t('You do not have permission to acess this!')}</p>
    </div>
  );
};

export default NotAuthorized;
