import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import TaskModal from './TaskModal';
import TaskVerbalOfferModal from './TaskVerbalOfferModal';

const OrderTaskModals = ({
  task,
  isOpen = false,
  closeModal,
  verbalOfferModalIsOpen = false,
  closeVerbalOfferModal,
  sentVerbalOffer
}) => (
  <>
    <Modal
      className='mail-modal'
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel=''
      bodyOpenClassName={null}
    >
      <TaskModal
        task={task}
        closeModal={() => closeModal()}
      />
    </Modal>

    <Modal
      className='mail-modal'
      isOpen={verbalOfferModalIsOpen}
      onRequestClose={closeVerbalOfferModal}
      contentLabel=''
    >
      <TaskVerbalOfferModal
        task={task}
        closeModal={closeVerbalOfferModal}
        sentVerbalOffer={sentVerbalOffer}
      />
    </Modal>
  </>
);

OrderTaskModals.propTypes = {
  task: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  verbalOfferModalIsOpen: PropTypes.bool,
  closeVerbalOfferModal: PropTypes.func.isRequired,
  sentVerbalOffer: PropTypes.func.isRequired
};

export default OrderTaskModals;
