import {
  ADD_INVITATION,
  REMOVE_INVITATION,
  RESET_INVITATIONS,
  REORDER_INVITATIONS,
  ADD_USERS,
  ADD_QUEUE,
  ADD_TEXT,
  ADD_CUSTOMER_MEETING
} from '../actions/invitations';

const initialState = {
  customerMeeting: null,
  invitations: [],
  users: [],
  text: '',
  queue: {
    queued: [],
    sent: []
  }
};

export default function invitations (state = initialState, { type, payload }) {
  switch (type) {
    case ADD_INVITATION: {
      const invitation = state.invitations.find(({ id }) => id === payload.id);
      if (invitation) {
        return state;
      }

      const invitations = [...state.invitations];
      invitations.push(payload);

      return {
        ...state,
        invitations
      };
    }

    case REMOVE_INVITATION: {
      const index = state.invitations.findIndex(({ id }) => id === payload.id);
      if (index === -1) {
        return state;
      }

      const invitations = [...state.invitations];
      invitations.splice(index, 1);

      return {
        ...state,
        invitations
      };
    }

    case RESET_INVITATIONS: {
      return {
        ...state,
        invitations: []
      };
    }

    case REORDER_INVITATIONS: {
      return {
        ...state,
        invitations: payload
      };
    }

    case ADD_USERS: {
      return {
        ...state,
        users: payload
      };
    }

    case ADD_TEXT: {
      return {
        ...state,
        text: payload
      };
    }

    case ADD_QUEUE: {
      return {
        ...state,
        queue: payload
      };
    }

    case ADD_CUSTOMER_MEETING: {
      return {
        ...state,
        customerMeeting: payload
      };
    }

    default: {
      return state;
    }
  }
}
