import API from '../../stores/API';

export const ADD_PARTIAL_INVOICE_PRODUCT = 'ADD_PARTIAL_INVOICE_PRODUCT';
export const REMOVE_PARTIAL_INVOICE_PRODUCT = 'REMOVE_PARTIAL_INVOICE_PRODUCT';
export const REMOVE_PARTIAL_INVOICE_PRODUCTS = 'REMOVE_PARTIAL_INVOICE_PRODUCTS';
export const ADD_PRODUCT_CATEGORIES = 'ADD_PRODUCT_CATEGORIES';
export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';

export function addPartialInvoiceProduct (payload) {
  return {
    type: ADD_PARTIAL_INVOICE_PRODUCT,
    payload
  };
}

export function removePartialInvoiceProduct (payload) {
  return {
    type: REMOVE_PARTIAL_INVOICE_PRODUCT,
    payload
  };
}

export function removePartialInvoiceProducts (payload) {
  return {
    type: REMOVE_PARTIAL_INVOICE_PRODUCTS,
    payload
  };
}

function receiveProductCategories (payload) {
  return {
    type: ADD_PRODUCT_CATEGORIES,
    payload
  };
}

function receiveProductCategory (payload) {
  return {
    type: ADD_PRODUCT_CATEGORY,
    payload
  };
}

function receiveProducts (payload) {
  return {
    type: ADD_PRODUCTS,
    payload
  };
}

export function getProductCategories (countryId = null, withChildren = true) {
  return async dispatch => {
    let params = `&country_id=${countryId}`;
    params += (withChildren) ? '&include_children=1' : '';

    const { data } = await API.GET(`/categories?limit=-1&include=media${params}`);

    dispatch(receiveProductCategories(data));
  };
}

export function getSingleCategory (countryId = null, termId = null) {
  return async dispatch => {
    const { data } = await API.GET(`/categories/${termId}?country_id=${countryId}&include_children=1`);

    dispatch(receiveProductCategory(data));
  };
}

export function getProducts (term, withChildren = true) {
  return async dispatch => {
    let params = `&category_id=${term}`;
    params += (withChildren) ? '&include_variations=1' : '';

    const { data } = await API.GET(`/products?limit=-1${params}`);

    dispatch(receiveProducts(data));
  };
}
