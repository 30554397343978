import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../../UserImage';
import ReactSVG from 'react-svg';
import useInvitations from '../../../Redux/hooks/useInvitations';
import Rating from '../../Rating';
import UserTimeSlots from '../../UserTimeSlots';
import { sortableHandle } from 'react-sortable-hoc';
import { useSelector } from 'react-redux';
import UserDistance from './UserDistance';
import NumActiveOrders from './NumActiveOrders';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function InvitationQueueListItem ({ user, isSortable = true }) {
  const { userToAssign } = useSelector(state => state.orderAssigner);
  const { removeFromQueue } = useInvitations();
  const { t } = useTranslation();

  const DragHandle = sortableHandle(() => (
    <span className='user-list-group__drag-handle'>
      <ReactSVG src='/icons/icon-drag-handle.svg' />
    </span>
  ));

  return (
    <div className='user-list-group__table__body__item'>
      <div className='user-image-with-details'>
        <If condition={isSortable}>
          <DragHandle />
        </If>
        <UserImage user={user} />
        <div>
          <strong>{user.name}</strong>
          <br />
          <NumActiveOrders user={user} />
          <Rating
            rating={user.rating}
            numberOfReviews={user.number_of_reviews}
          />
        </div>
      </div>
      <div>
        <UserDistance user={user} />
      </div>
      <div>
        <UserTimeSlots user={user} />
      </div>
      {!userToAssign ? (
        <div className='user-list-group__table__body__item__action'>
          <button
            role='button'
            className='btn icon'
            disabled={!!userToAssign}
            onClick={() => removeFromQueue(user)}
          >
            <ReactSVG src='/icons/icon-trash.svg' />
            <span>{t('Remove')}</span>
          </button>
        </div>
      ) : null}
    </div>
  );
}

InvitationQueueListItem.propTypes = {
  user: PropTypes.object.isRequired,
  isSortable: PropTypes.bool
};

export default InvitationQueueListItem;
