import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pd } from './functions/helpers';
import Canvas from './Canvas';
import drawBackground from './functions/drawBackground';
import drawText from './functions/drawText';
import drawLogo from './functions/drawLogo';
import drawFundSupplierLogo from './functions/drawFundSupplierLogo';
import Spinner from '../Spinner';
import { Fieldset } from 'FormElements';
import { If } from 'Conditionals';
import useMemorialPage from 'ReduxHooks/useMemorialPage';

const ObituaryPreview = ({
  heading,
  ceremonyDetails,
  deceasedDetails,
  memorialFund,
  previewIsLoading,
  setPreviewIsLoading,
  isLoading = false,
  textColor,
  text,
  title,
  hideCeremonyInfo,
  userCardBgImage,
  wpCardBgImage,
  passCanvasToParent
}) => {
  const { defaultImages } = useMemorialPage();
  const wrapper = useRef(null);
  const container = useRef(null);

  const [provider, setProvider] = useState({ context: null });
  const [render, setRender] = useState(false);

  const findBackground = () => {
    if (!defaultImages.length) {
      return {};
    }

    return defaultImages.find(image => image.id === wpCardBgImage);
  };

  const width = pd(664);
  const height = pd(534);

  const data = {
    ctx: provider.context,
    background: {
      width,
      height,
      wpCardBgImageObj: findBackground(),
      userCardBgImage
    },
    text: {
      x: pd(40),
      ceremonyDetails,
      deceasedDetails,
      title,
      text,
      textColor,
      hideCeremonyInfo
    },
    logo: {
      x: pd(40),
      y: pd(548),
      bottomMargin: pd(40),
      width: pd(140),
      textColor
    },
    fundSupplierLogo: {
      x: pd(490),
      y: pd(548),
      bottomMargin: pd(40),
      width: pd(140),
      textColor,
      memorialFund
    }
  };

  useEffect(() => {
    if (!provider.context) {
      return;
    }

    // Check if we have the default images from WP loaded before
    // executing any logic dependent on these.
    if (!defaultImages.length) {
      return;
    }

    clearCanvas();
    drawPreview();
    passCanvasToParent(provider.context.canvas);
  }, [
    defaultImages,
    provider,
    text,
    textColor,
    title,
    hideCeremonyInfo,
    userCardBgImage,
    wpCardBgImage
  ]);

  const clearCanvas = () => {
    const ctx = provider.context;
    ctx.clearRect(0, 0, width, height);
  };

  const drawPreview = async () => {
    setPreviewIsLoading(true);

    const bgRendered = await drawBackground(data.ctx, data.background);

    if (bgRendered) {
      drawText(data.ctx, data.text);
      drawLogo(data.ctx, data.logo);
      drawFundSupplierLogo(data.ctx, data.fundSupplierLogo);
    }

    setPreviewIsLoading(false);
  };

  const previewClasses = classNames('obituary-preview', { loading: previewIsLoading });

  return (
    <div className='obituary-preview__wrapper' ref={wrapper}>
      <Fieldset legend={heading}>
        <div className={previewClasses} ref={container}>
          <Canvas
            width={pd(664)}
            height={pd(534)}
            provider={provider}
            setProvider={value => setProvider(value)}
            render={render}
            setRender={bool => setRender(bool)}
          />
          <If condition={previewIsLoading || isLoading}>
            <Spinner />
          </If>
        </div>
      </Fieldset>
    </div>
  );
};

ObituaryPreview.propTypes = {
  heading: PropTypes.string.isRequired,
  ceremonyDetails: PropTypes.object,
  deceasedDetails: PropTypes.object,
  memorialFund: PropTypes.object,
  previewIsLoading: PropTypes.bool.isRequired,
  setPreviewIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  logo: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  hideCeremonyInfo: PropTypes.bool,
  userCardBgImage: PropTypes.object,
  wpCardBgImage: PropTypes.number,
  passCanvasToParent: PropTypes.func.isRequired
};

export default ObituaryPreview;
