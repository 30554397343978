import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useDistanceCalculations from 'Hooks/useDistanceCalculations';
import { LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import CalculationsResult from './CalculationsResult';
import { useTranslation } from 'react-i18next';

function DistanceCalculator ({ updateTabs }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { steps, funeralType, hasAllSteps, calculateDistance, distance } = useDistanceCalculations();

  async function calculate () {
    setIsLoading(true);
    await calculateDistance();
    setIsLoading(false);
  }

  function renderStep (step) {
    return (
      <>
        <strong>{step.label}</strong>&nbsp;-&nbsp;
        {step.value ? (
          <span>{step.value.name}</span>
        ) : (
          <a
            href='#'
            onClick={e => {
              e.preventDefault();
              updateTabs(step.tab);
            }}>{t('Provide here')}</a>
        )}
      </>
    );
  }

  return (
    <div className='distance-calculator gray-box'>
      <h4>{t('Calculate transportation costs')}</h4>
      {funeralType === t('Not selected') ? (
        <p>
          {t('Select first')}&nbsp;
          <a
            href='#'
            onClick={e => {
              e.preventDefault();
              updateTabs(4);
            }}
          >{t('burial type')}</a>
        </p>
      ) : (
        <div className='distance-calculator__distances'>
          <div className='distance-calculator__distances__list'>
            <div className='distance-calculator__distances__list__funeral-type'>
              <strong>{t('Burial type:')}</strong> <span>{funeralType}</span>
            </div>

            <If condition={steps.length > 0}>
              <ol className='distance-calculator__distances__list__list'>
                {steps.map((step, index) => (
                  <li key={index} className={classNames({ 'no-value': !step.value })}>
                    {renderStep(step)}
                  </li>
                ))}
                <li>{renderStep(steps[0])}</li>
              </ol>
            </If>
          </div>

          <LoadingButton
            isLoading={isLoading}
            disabled={!hasAllSteps}
            onClick={() => calculate()}
          >{t('Calculate route and transportation cost')}</LoadingButton>

          <If condition={!hasAllSteps}>
            <p><em>{t('In order to calculate the route all relevant places must be provided')}</em></p>
          </If>

          <AnimatePresence>
            <If condition={!!distance}>
              <motion.div {...animateTop}>
                <CalculationsResult distance={distance}/>
              </motion.div>
            </If>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}

DistanceCalculator.propTypes = {
  updateTabs: PropTypes.func.isRequired
};

export default DistanceCalculator;
