import React, { useEffect, useState } from 'react';
import Note from 'Components/Note';
import { LoadingButton } from 'FormElements';
import { useDispatch, useSelector } from 'react-redux';
import { createNote, getNotes } from 'ReduxActions/notes';
import Spinner from './Spinner';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

function NoteList () {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { notes } = useSelector(state => state.notes);
  const dispatch = useDispatch();
  const [noteText, setNoteText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const orderType = order.class;

  useEffect(() => {
    init();
  }, []);

  async function init () {
    setIsLoading(true);

    await dispatch(getNotes(order.id, orderType));

    setIsLoading(false);
  }

  const create = async () => {
    setIsLoading(true);

    await dispatch(createNote(order.id, { noteText, orderType }));

    setIsLoading(false);
    setNoteText('');
  };

  return (
    <div className='note-list'>
      <div className='new-note-contaner'>
        <textarea
          rows={4}
          name='new_note'
          value={noteText}
          placeholder={t('Write a new note...')}
          onChange={({ target }) => setNoteText(target.value)}
        />
        <LoadingButton
          className='new-note-submit'
          onClick={create}
          disabled={isLoading || noteText === ''}
        >{t('Save')}
        </LoadingButton>
        <div className='notes'>
          {isLoading ? (
            <Spinner />
          ) : (
            notes && notes.map(note => (
              <Note
                key={note.id}
                note={note}
                order={order}
              />
            )
            ))}
        </div>
      </div>
    </div>
  );
}

export default NoteList;
