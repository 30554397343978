import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Textarea, LoadingButton } from 'FormElements';
import useOrderAttendees from 'ReduxHooks/useOrderAttendees';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function AttendeeForm ({ attendee = null, onUpdate = null }) {
  const isUpdate = !!attendee;
  const { t } = useTranslation();
  const { create, update, remove } = useOrderAttendees();
  const defaultAttendee = {
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
    num_attendees: 1,
    note: ''
  };
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAttendee, setUpdatedAttendee] = useState(attendee ? { ...attendee } : { ...defaultAttendee });

  const createAttendee = async () => {
    if (!isValid()) {
      showWarningNotice(t('Certain fields are missing. First and last name are mandatory. You must provide either an email address or a mobile phone number'));
      return;
    }

    const regEx = /^(?:\+467|07)(?:\d{8})$/;
    updatedAttendee.telephone = updatedAttendee.telephone.replace(/\s|-/g, '');

    if (updatedAttendee.telephone !== '' && !regEx.test(updatedAttendee.telephone)) {
      showWarningNotice(t('Please provide the phone number in the following format:') + ' <strong>070*******</strong>');
      return;
    }

    setIsLoading(true);
    const response = await create(updatedAttendee);
    setIsLoading(false);

    if (response.status === 'ok') {
      showNotice(t('The attendee has been added'));
      setUpdatedAttendee({ ...defaultAttendee });
    }
  };

  function isValid () {
    if (updatedAttendee.first_name === '' || updatedAttendee.last_name === '') {
      return false;
    }

    return !(updatedAttendee.email === '' && updatedAttendee.telephone === '');
  }

  const removeAttendee = async () => {
    if (!window.confirm(t('Are you sure you want to remove this?'))) {
      return;
    }

    setIsLoading(true);
    await remove(attendee.id);
    setIsLoading(false);
  };

  const updateAttendee = async () => {
    setIsLoading(true);
    const response = await update(attendee.id, updatedAttendee);
    setIsLoading(false);

    if (response.status === 'ok') {
      showNotice(t('The attendee has been updated'));
    }

    if (onUpdate) {
      onUpdate();
    }
  };

  const updateField = (key, value) => {
    setUpdatedAttendee({
      ...updatedAttendee,
      ...{
        [key]: value
      }
    });
  };

  return (
    <div className='order-tab__form attendee-form'>
      <div className='row'>
        <Input
          label={t('First name')}
          name='first_name'
          className='col-4'
          data-cy='attendee.first_name'
          value={updatedAttendee.first_name}
          onChange={(value) => updateField('first_name', value)}
          floatingLabel
          showRequiredIcon
        />
        <Input
          label={t('Last name')}
          name='last_name'
          className='col-4'
          data-cy='attendee.last_name'
          value={updatedAttendee.last_name}
          onChange={(value) => updateField('last_name', value)}
          floatingLabel
          showRequiredIcon
        />
        <Input
          label={t('Email')}
          name='email'
          className='col-4'
          data-cy='attendee.email'
          value={updatedAttendee.email}
          onChange={(value) => updateField('email', value)}
          floatingLabel
        />
      </div>

      <div className='row'>
        <Input
          label={t('Phone')}
          name='telephone'
          className='col-4'
          data-cy='attendee.telephone'
          value={updatedAttendee.telephone}
          onChange={(value) => updateField('telephone', value)}
          floatingLabel
        />
        <Input
          label={t('Total number of attendees')}
          type='number'
          name='num_attendees'
          className='col-4'
          data-cy='attendee.num_attendees'
          value={updatedAttendee.num_attendees}
          onChange={(value) => updateField('num_attendees', value)}
          floatingLabel
        />
      </div>

      <div className='row'>
        <Textarea
          label={t('Note')}
          rows={3}
          name='note'
          className='col-12'
          data-cy='attendee.note'
          value={updatedAttendee.note}
          onChange={(value) => updateField('note', value)}
          floatingLabel
        />
      </div>

      {isUpdate ? (
        <>
          <LoadingButton
            disabled={isLoading}
            onClick={updateAttendee}
          >{t('Update')}</LoadingButton>
          <LoadingButton
            disabled={isLoading}
            className='delete'
            onClick={removeAttendee}
          >{t('Delete')}</LoadingButton>
        </>
      ) : (
        <LoadingButton
          disabled={isLoading}
          onClick={createAttendee}
        >{t('Create')}</LoadingButton>
      )}
    </div>
  );
}

AttendeeForm.propTypes = {
  attendee: PropTypes.object,
  onUpdate: PropTypes.func
};

export default AttendeeForm;
