import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import useFlowerOrderProducts from 'ReduxHooks/useFlowerOrderProducts';

const ToggleInvoicedProduct = ({ product, order, type = '', isDisabled = false }) => {
  const { isAdmin } = useLoggedInUser();
  const { toggleCertified } = useOrderProducts();
  const { toggleFlowerProductCertified } = useFlowerOrderProducts();
  if (!isAdmin) {
    return null;
  }

  const [invoiced, toggleInvoiced] = (type === 'flowershopOrderStore')
    ? useState(!!product.pivot.invoiced)
    : useState(!!product.invoiced);

  const id = `toggle-invoiced-${product.id}`;

  /**
   * Persist the state to the database.
   *
   * @param invoiced
   */
  const persist = invoiced => {
    const productId = (type === 'flowershopOrderStore') ? product.id : product.pivot.product_resource_id;

    if (type === 'flowershopOrderStore') {
      toggleFlowerProductCertified(order.id, productId, invoiced);
    } else {
      toggleCertified(productId, invoiced);
    }
  };

  /**
   * Toggle the product as invoiced.
   */
  const toggleInvoiceCallback = () => {
    const invoicedNewState = !invoiced;

    toggleInvoiced(invoicedNewState);

    persist(invoicedNewState);
  };

  return (
    <label aria-disabled={isDisabled} className='checkbox' htmlFor={id}>
      <input
        disabled={isDisabled}
        type='checkbox'
        id={id}
        checked={invoiced}
        onChange={toggleInvoiceCallback}
      />
      <span/>
    </label>
  );
};

ToggleInvoicedProduct.propTypes = {
  product: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  type: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default ToggleInvoicedProduct;
