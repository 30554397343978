export function saveToken (token) {
  window.localStorage.setItem('lavendla-token', token);
}

export function saveID (id) {
  window.localStorage.setItem('lavendla-id', id);
}

export function getToken () {
  return window.localStorage.getItem('lavendla-token');
}

export function getID () {
  return window.localStorage.getItem('lavendla-id');
}

export function removeToken () {
  window.localStorage.removeItem('lavendla-token');
}
export function removeID () {
  window.localStorage.removeItem('lavendla-id');
}
