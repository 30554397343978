import { SET_SETTINGS } from '../actions/settings';

const initialState = {
  settings: {
    hideBonuses: false,
    showSearchbar: false
  }
};

export default function settings (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SETTINGS: {
      return {
        ...state,
        settings: { ...state.settings, ...payload }
      };
    }

    default:
      return state;
  }
}
