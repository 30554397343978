import React from 'react';
import useSettings from '../Redux/hooks/useSettings';
import { Checkbox } from './FormElements';
import { useTranslation } from 'react-i18next';

function ToggleShowBonuses () {
  const { t } = useTranslation();
  const { setSetting, getSetting } = useSettings();
  const hideBonuses = getSetting('hideBonuses');

  return (
    <Checkbox
      label={t('Hide compensation')}
      defaultChecked={!!hideBonuses}
      onChange={() => setSetting({ hideBonuses: !hideBonuses })}
    />
  );
}

export default ToggleShowBonuses;
