import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const AddToCartButton = ({
  addedToCart,
  addingToCart,
  addProduct,
  product,
  variationId
}) => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState(t('Add to cart'));
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setButtonText(getButtonText);
    setDisabled(getDisabledState);
  }, [variationId, addedToCart]);

  /**
   * Checks if there are any variations and if so
   * also checks if one is selected.
   *
   * @returns {boolean}
   */
  const noVariationSelected = () => {
    return product.variations && product.variations.length > 0 && !variationId;
  };

  /**
   * Get the disbaled state
   */
  const getDisabledState = () => {
    return !product || addingToCart || noVariationSelected();
  };

  /**
   * Get the text for the button depending
   * on the context and state of the current product.
   *
   * @returns {String}
   */
  const getButtonText = () => {
    if (addedToCart) {
      return t('Added to cart');
    }

    if (noVariationSelected()) {
      return t('Make a selection');
    }

    return t('Add to cart');
  };

  return (
    <button
      className={classNames('single-product__add-to-cart', 'btn', 'btn-cta', { loading: addingToCart })}
      onClick={() => addProduct()}
      disabled={disabled}
    >
      <span className='btn-text'>{buttonText}</span>
      <span className='btn-loading' />
    </button>
  );
};

AddToCartButton.propTypes = {
  addedToCart: PropTypes.bool,
  addingToCart: PropTypes.bool,
  addProduct: PropTypes.func,
  addedToOrder: PropTypes.func,
  product: PropTypes.object,
  variationId: PropTypes.number
};

export default AddToCartButton;
