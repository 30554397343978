import { LoadingButton } from 'FormElements';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import API from '../stores/API';
import { showNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

const APITokenGenerator = ({ user }) => {
  const { t } = useTranslation();
  const [apiToken, setAPIToken] = useState(user.api_token);
  const [isLoading, setIsLoading] = useState(false);

  const generate = async () => {
    setIsLoading(true);
    const { token } = await API.POST(`/users/${user.id}/generate-token?includes=locations`);
    setIsLoading(false);

    showNotice(t('The token has been generated'));

    setAPIToken(token);
  };

  return (
    <div className='profile-action-box api-token-generator'>
      <p><strong>{t('API TOKEN:')}</strong> {apiToken}</p>

      <LoadingButton
        text={t('Generate an API Token')}
        isLoading={isLoading}
        className='white'
        onClick={generate}
      />
    </div>
  );
};

APITokenGenerator.propTypes = {
  user: PropTypes.object.isRequired
};

export default APITokenGenerator;
