import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function AvailableOrderWarning ({ id }) {
  const { t } = useTranslation();

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('This order has been made available for advisors in all locations.')}</h3>
      <p className='tip-content__text'>{t('Please note the location of this order, it may be outside your specified areas.')}</p>
    </div>
  );

  return (
    <div className='memorial-warning-popout'>
      <FontAwesomeIcon
        aria-label={t('Available for all advisors')}
        icon={['fal', 'globe']}
        data-tip='tooltip'
        data-for={`available-warning-tooltip-${id}`}
        className='memorial-warning-popout__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`available-warning-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
}

AvailableOrderWarning.propTypes = {
  id: PropTypes.number
};

export default AvailableOrderWarning;
