export const SET_SETTINGS = 'SET_SETTINGS';

function recieveSettings (payload) {
  return {
    type: SET_SETTINGS,
    payload
  };
}

export function setSettings (settings) {
  return (dispatch) => {
    dispatch(recieveSettings(settings));
  };
}
