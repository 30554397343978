import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageProcessorPreviews = ({
  isBanner = false,
  isCard = false
}) => {
  const previewsClasses = classNames('image-processor__previews', { 'is-banner': isBanner }, { 'is-card': isCard });

  return (
    <div className={previewsClasses}>
      <h4>Förhandsvisning</h4>
      {!isBanner &&
        <div data-preview-label={isCard ? 'Kort' : ''}>
          <div className='image-processor__single-preview' />
        </div>}
      {isBanner &&
        <>
          <div className='image-processor__desktop-hero-preview' data-preview-label='Datorskärm'>
            <div className='image-processor__single-preview' />
          </div>
          <div className='image-processor__mobile-hero-preview' data-preview-label='Mobil'>
            <div className='image-processor__single-preview' />
          </div>
        </>}
    </div>
  );
};

ImageProcessorPreviews.propTypes = {
  isBanner: PropTypes.bool,
  isCard: PropTypes.bool
};

export default ImageProcessorPreviews;
