import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, CartesianGrid, BarChart, Bar, YAxis, XAxis, Tooltip, Legend } from 'recharts';
import useGraphs from '../../../hooks/useGraphs';
import Spinner from '../../Spinner';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) {
    return null;
  }

  const { t } = useTranslation();

  const order = (payload[0].value === 1) ? t('Order') : t('Orders');
  return (
    <div className='custom-tooltip'>
      <p className='label'>{`${label}: ${payload[0].value} ${order.toLowerCase()}`}</p>
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

function ActiveOrdersForUsers ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { data, isLoading } = useGraphs(
    '/dashboard/get-active-orders-for-users',
    {
      orderType
    }
  );
  const height = data && data.length ? data.length * 40 : 1000;

  if (!isLoading && Array.isArray(data) && !data.length) {
    return (
      <div className='charts-container'>
        <h4>{t('Division of labor')}</h4>
        {t('No data to for this period')}
      </div>
    );
  }

  return (
    <div className='charts-container'>
      <h4>{t('Division of labor')}</h4>

      {isLoading ? (
        <Spinner />
      ) : (
        <ResponsiveContainer height={height}>
          <BarChart data={data} layout='vertical' margin={{ left: 60 }}>
            <XAxis type='number' />
            <YAxis type='category' dataKey='name' />
            <CartesianGrid strokeDasharray='3 3' />
            <Bar dataKey='amt' fill='#437783' name='Antal ordrar' />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Legend iconType='circle' iconSize={10} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

ActiveOrdersForUsers.propTypes = {
  orderType: PropTypes.string
};

export default ActiveOrdersForUsers;
