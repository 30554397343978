import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LoadingButton } from 'FormElements';
import AttendeeForm from './AttendeeForm';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { formatDate } from 'Library/functions';
import { useTranslation } from 'react-i18next';

function Attendee ({ attendee }) {
  const { t } = useTranslation();
  const { getCountryCode } = useSingleOrder();
  const [isVisible, setIsVisible] = useState(false);

  const buttonText = isVisible ? t('Cancel') : t('Edit');
  const numRegistered = (attendee.num_attendees > 1)
    ? `${attendee.num_attendees} ${t('registered_plural', {
      description: 'plural'
    })}`
    : `${attendee.num_attendees} ${t('registered_single', {
      description: 'singluar'
    })}`;
  const attendeeEmail = attendee.email ? (attendee.email === '') ? '' : ` - ${attendee.email}` : '';

  return (
    <div className='attendee'>
      <div className='attendee-details'>
        <span className='attendee-details__details'>
          {attendee.first_name} {attendee.last_name}{attendeeEmail} ({numRegistered})
          {attendee.queued ? ` - ${t('Queued')} ${formatDate(attendee.queued, getCountryCode())}` : ''}
        </span>
        <LoadingButton
          onClick={() => setIsVisible(!isVisible)}
          className='text'
        >{buttonText}
        </LoadingButton>
      </div>

      <div className={classNames('attendee-form-container', { hidden: !isVisible })}>
        <AttendeeForm
          attendee={attendee}
          onUpdate={() => setIsVisible(false)}
        />
      </div>
    </div>
  );
}

Attendee.propTypes = {
  attendee: PropTypes.object.isRequired
};

export default Attendee;
