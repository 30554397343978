import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addPartialInvoiceProduct, removePartialInvoiceProduct } from 'ReduxActions/products';

const MarkProductForPartialInvoicing = ({ order, product, isDisabled }) => {
  const dispatch = useDispatch();

  const toggleProduct = checked => {
    if (checked) {
      dispatch(addPartialInvoiceProduct(product.product_resource_id));

      return;
    }

    dispatch(removePartialInvoiceProduct(product.product_resource_id));
  };

  function isCheckboxDisabled () {
    if (!order.is_partially_invoiced) {
      return true;
    }

    return isDisabled;
  }

  return (
    <label aria-disabled={isCheckboxDisabled()} className='checkbox' htmlFor={`partial-invoice-${product.id}`}>
      <input
        disabled={isCheckboxDisabled()}
        type='checkbox'
        id={`partial-invoice-${product.id}`}
        onChange={({ target }) => toggleProduct(target.checked)}
        defaultChecked={order.is_partially_invoiced && product.invoice_id}
      />
      <span/>
    </label>
  );
};

MarkProductForPartialInvoicing.propTypes = {
  order: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool
};

export default MarkProductForPartialInvoicing;
