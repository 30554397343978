import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Spinner from '../../Spinner';
import useGraphs from '../../../hooks/useGraphs';
import moment from 'moment';
import GraphFilters from './GraphFilters';
import { generateId } from '../../../library/functions';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) {
    return null;
  }

  return (
    <div className='custom-tooltip'>
      <p className='label'>{label}</p>
      {payload.map(item => {
        return (
          [
            <p className='row' key={generateId()} style={{ color: item.color }}>{`${item.name} : ${item.value.toFixed(2)} `}</p>,
            <p className='row' key={generateId()} style={{ color: item.color }}>{`Number of orders : ${item.payload.orderCount} `}</p>
          ]
        );
      })}
    </div>
  );
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

function AverageOrder ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { data, isLoading, setParam, params } = useGraphs(
    '/dashboard/get-average-order',
    {
      start_date: moment().subtract(9, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      end_date: null,
      user_id: null,
      sortBy: null,
      order_type: orderType
    }
  );

  return (
    <div className='charts-container'>
      <h4>{t('Average order')}</h4>

      <GraphFilters
        params={params}
        setParam={setParam}
        orderType={orderType}
      />

      {isLoading ? <Spinner /> : data && data.length ? (
        <ResponsiveContainer height={500}>
          <BarChart
            data={data}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip cursor={false} content={CustomTooltip} />
            <Legend />
            <Bar dataKey='Snittvärde' fill='#437783' />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        t('No data to for this period')
      )}
    </div>
  );
}

AverageOrder.propTypes = {
  orderType: PropTypes.string
};

export default AverageOrder;
