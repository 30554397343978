import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../../UserImage';
import Rating from '../../Rating';
import classNames from 'classnames';
import moment from 'moment';
import { setShouldRemoveAssignedUser } from 'ReduxActions/orderAssigner';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAppointments from 'Hooks/useAppointments';

function ModalHeader ({ order, hasActiveQueue = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { maybeGetTranslatedTitle } = useAppointments();
  const customerMeeting = order.appointments.find(({ type }) => type === 'customer_meeting');

  const unassignOrder = () => {
    dispatch(setShouldRemoveAssignedUser());
  };

  function formatedDate () {
    if (!customerMeeting) {
      return null;
    }

    // Hmm, how do we solve this for DK, etc?
    return moment(customerMeeting.start_date)
      .locale('se')
      .format('DD MMMM [kl] HH:mm');
  }

  return (
    <div className='user-assigner__modal-header'>
      <div className={classNames('user-assigner__modal-header__rows', { 'has-meeting': customerMeeting })}>
        <div>
          <h5>{t('Customer')}</h5>
          <strong>{order.customer.name}</strong>
        </div>
        <div>
          <h5>{t('Location')}</h5>
          <strong>{order.location ? order.location.name : t('Unknown')}</strong>
        </div>
        {customerMeeting && (
          <>
            <div>
              <h5>{t('Requested meeting type')}</h5>
              <strong>{maybeGetTranslatedTitle(customerMeeting.title)}</strong>
            </div>
            <div>
              <h5>{t('Reqested meeting time')}</h5>
              <strong>{formatedDate()}</strong>
            </div>
          </>
        )}
      </div>
      <div className='user-assigner__modal-header__content'>
        <div>
          <h5>{t('Notes')}</h5>
          {order.custom_info}
        </div>
        <div>
          <h5>
            {t('Current advisor')}
            {order.user ? (
              <span>
                &nbsp;
                (
                <button
                  type='button'
                  className='btn btn-link'
                  disabled={hasActiveQueue}
                  onClick={unassignOrder}
                >
                  {t('Remove')}
                </button>
                 )
              </span>
            ) : null}
          </h5>
          {order.user ? (
            <div className='user-assigner__modal-header__content__user'>
              <UserImage user={order.user} />
              <span>
                {order.user.name}<br />
                <Rating
                  rating={order.user.rating}
                  numberOfReviews={order.user.number_of_reviews}
                />
              </span>
            </div>
          ) : (
            <span>{t('No assigned advisor')}</span>
          )}
        </div>
      </div>
    </div>
  );
}

ModalHeader.propTypes = {
  order: PropTypes.object.isRequired,
  hasActiveQueue: PropTypes.bool
};

export default ModalHeader;
