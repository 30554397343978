import { canvasSettings, ceremonyOutput, deceasedOutput, alignYReducer } from './helpers';

const drawText = (
  ctx,
  {
    x,
    ceremonyDetails,
    deceasedDetails,
    title,
    text,
    textColor,
    hideCeremonyInfo = false
  }
) => {
  // Since the parent canvas has to mount first, it's *possible* that the context may not be
  // injected by the time this render function runs the first time.
  if (!ctx) return;

  let alignState = {
    altTextY: canvasSettings.ceremonyY,
    ceremonyY: canvasSettings.ceremonyY,
    deceasedY: canvasSettings.deceasedY,
    textY: canvasSettings.textY
  };

  // Format text for deceased and ceremony info
  const deceased = deceasedOutput(deceasedDetails);
  const ceremony = ceremonyOutput(ceremonyDetails, hideCeremonyInfo);

  /**
   * Dispatch for text alignment object reducer
   *
   * @param {Object} action
   */
  const dispatch = (action) => {
    alignState = alignYReducer(alignState, action);
  };

  /**
   * This method checks the calculated length of the string (word for word) against the set maxWidth
   * and renders overflowing word on a new line if to long. It also checks if we have any intentianal
   * line breaks in our text areas (\n).
   */
  const wrapText = (ctx, txt, y, maxWidth, fontSize, lineHeight, adjecentTextY) => {
    // Check if we have any intentianal line breaks.
    const lines = txt.split(/\n/g);

    // Calculate lineheight and initial Y value.
    const computedLineheight = fontSize * lineHeight;
    let computedY = y + computedLineheight;

    for (let l = 0; l < lines.length; l++) {
      const words = lines[l].split(' ');
      let line = '';

      // Unless we are on the first line update Y for the new line to be drawn.
      if (l > 0) {
        computedY += computedLineheight;
      }

      // Test the length of the line against the set max-width
      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        // Print current line and move current word to new line.
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, computedY);
          line = words[n] + ' ';
          computedY += computedLineheight;
        } else { // Add current word to current line.
          line = testLine;
        }
      }

      // Print line.
      ctx.fillText(line, x, computedY);
    }

    // Add offset to next group of text.
    if (adjecentTextY) {
      dispatch({ type: `${adjecentTextY}`, value: computedY });
    }
  };

  const fontSize = 36;

  ctx.fillStyle = (textColor === 'white') ? '#fff' : 'rgba(0, 0, 0, 0.9)';

  ctx.font = '400 64px calluna-sans';
  ctx.textAlign = 'left';
  wrapText(ctx, title, 72, 640, 64, 1.25, 'deceasedY');

  ctx.font = '600 36px proxima-nova';
  wrapText(ctx, deceased, alignState.deceasedY, 640, fontSize, 1.67, 'ceremonyY');

  if (ceremony !== '') {
    wrapText(ctx, ceremony, alignState.ceremonyY, 640, fontSize, 1.67, 'textY');

    ctx.font = '400 36px proxima-nova';
    wrapText(ctx, text, alignState.textY, 760, fontSize, 1.47);
  } else {
    ctx.font = '400 36px proxima-nova';
    wrapText(ctx, text, alignState.altTextY, 760, fontSize, 1.47);
  }
};

export default drawText;
