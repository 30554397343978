import i18next from 'i18next';

function useMeetingTimes () {
  function getDayOfWeek (index, plural = true) {
    const dict = (plural)
      ? [
          i18next.t('Sundays'),
          i18next.t('Mondays'),
          i18next.t('Tuesdays'),
          i18next.t('Wednesdays'),
          i18next.t('Thursdays'),
          i18next.t('Fridays'),
          i18next.t('Saturdays')
        ]
      : [
          i18next.t('Sunday'),
          i18next.t('Monday'),
          i18next.t('Tuesday'),
          i18next.t('Wednesday'),
          i18next.t('Thursday'),
          i18next.t('Friday'),
          i18next.t('Saturday')
        ];

    return dict[index];
  }

  function hasScheduledTimes (scheduledTimes) {
    if (!Array.isArray(scheduledTimes)) {
      return false;
    }

    if (!scheduledTimes.length) {
      return false;
    }

    return true;
  }

  function isDayActive (scheduledTimes) {
    if (!hasScheduledTimes(scheduledTimes)) {
      return true;
    }

    return scheduledTimes.every(({ inactive }) => !inactive);
  }

  return {
    getDayOfWeek,
    isDayActive,
    hasScheduledTimes
  };
}

export default useMeetingTimes;
