import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useCountries from 'ReduxHooks/useCountries';
import Select, { components } from 'react-select';

const { Option } = components;
const IconOption = props => (
  <Option {...props}>
    <LargeFlagIcon {...props.data} />
  </Option>
);

IconOption.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string
  }).isRequired
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <FlagIcon {...props.data} />
  </components.SingleValue>
);

SingleValue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string
  }).isRequired
};

const FlagIcon = ({ icon, label }) => (
  <img
    src={icon}
    style={{ width: 32, height: 24, marginTop: 4, paddingRight: 4 }}
    alt={label}
  />
);

FlagIcon.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string
};

const LargeFlagIcon = ({ icon, label }) => (
  <img
    src={icon}
    style={{ width: 34, height: 28, marginTop: 4 }}
    alt={label}
  />
);

LargeFlagIcon.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string
};

function UserCountryDropdown () {
  const { loggedInUser } = useLoggedInUser();
  const { country, setCountry } = useCountries();

  useEffect(() => {
    setCountry(loggedInUser.country);
  }, [loggedInUser]);

  if (loggedInUser.countries.length < 2) {
    return null;
  }

  const mapCountry = ({ id, name, code }) => ({
    value: id,
    label: name,
    icon: `/icons/${code}.svg`
  });

  const options = loggedInUser.countries.map(mapCountry);
  return (
    <Select
      components={{
        Option: IconOption,
        SingleValue
      }}
      isDisabled={loggedInUser.countries.length < 2}
      options={options}
      isSearchable={false}
      classNamePrefix='lavendla-select'
      onChange={({ value }) => setCountry(
        loggedInUser.countries.find(({ id }) => parseInt(value) === id),
        true
      )}
      value={(country) ? mapCountry(country) : null}
      className='lavendla-select user-country-dropdown'
    />
  );
}

UserCountryDropdown.propTypes = {
  country: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string
  })
};

export default UserCountryDropdown;
