import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalHeader = ({
  title,
  titleNode,
  close
}) => {
  const { t } = useTranslation();
  return (
    <div className='modal-header'>
      {titleNode || (
        <h2 className='modal-title' dangerouslySetInnerHTML={{ __html: title }} />
      )}

      <button className='close-button' onClick={close}>
        {t('Close')} <ReactSVG src='/icons/icon-close-black.svg' />
      </button>
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  titleNode: PropTypes.node,
  close: PropTypes.func
};

export default ModalHeader;
