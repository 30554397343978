import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../library/functions';

const ReportTableRow = ({ row: {
  source, id, type, title, reference, total
}, reportUser }) => (
  <tr className='bonus-report__report__rows__row'>
    <td>{source}</td>
    <td>{id}</td>
    <td>{type}</td>
    <td>{title}</td>
    <td>{reference}</td>
    <td>{total ? formatCurrency(total, 2, reportUser?.country?.code) : '-'}</td>
  </tr>
);

ReportTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  reportUser: PropTypes.object
};

export default ReportTableRow;
