import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ loading = true }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="spinner"/>
  );
};

Spinner.propTypes = {
  loading: PropTypes.bool
};

export default Spinner;
