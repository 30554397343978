import React from 'react';
import PropTypes from 'prop-types';
import PercentageIndicator from './PercentageIndicator';

const OrderStatus = ({ order, percentageComplete = null, orderHeader = false }) => (
  <div
    className={`order-status status-${order.status}`}
    data-tip='tooltip'
    data-for={`order-status-tooltip-${order.id}`}
  >
    {percentageComplete !== null ? (
      <PercentageIndicator
        archived={order.archived}
        statusLabel={order.status_translated}
        status={order.status}
        availableStatuses={order.available_statuses}
        percentage={percentageComplete.percentage}
        orderHeader={orderHeader}
      />
    ) : order.status_translated}
  </div>
);

OrderStatus.propTypes = {
  order: PropTypes.object.isRequired,
  percentageComplete: PropTypes.object,
  orderHeader: PropTypes.bool
};

export default OrderStatus;
