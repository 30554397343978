export function blobToFile (blob, name = 'fileFromBlob', ext = 'jpg') {
  const args = {
    fileName: `${name}_${Date.now()}.${ext}`,
    type: blob.type,
    lastModified: Date.now(),
    lastModifiedDate: new Date()
  };

  let file;

  try {
    file = new File(
      [blob],
      args.fileName,
      {
        type: args.type,
        lastModified: args.lastModifiedDate
      }
    );
  } catch (e) {
    // Polyfill for Object assign since the browsers (read IE11) not supporting File constructor neither supports Object assign.
    if (typeof Object.assign !== 'function') {
      Object.assign = function (target) {
        'use strict';
        if (target == null) {
          throw new TypeError('Cannot convert undefined or null to object');
        }
        target = Object(target);
        for (let index = 1; index < arguments.length; index++) {
          const source = arguments[index];
          if (source != null) {
            for (const key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
              }
            }
          }
        }
        return target;
      };
    }

    blob.name = args.fileName;
    blob.lastModifiedDate = args.lastModifiedDate;

    file = Object.assign(blob, { lastModified: args.lastModified, type: args.type });
  }

  return file;
}

/**
 * Does this image have a name matching the names given to the new cropped images?
 */
export const lookForCroppedImageName = imgSrc => imgSrc.includes('memorial_page_deceased') || imgSrc.includes('memorial_page_user_bg_image_cropped');
