import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const getOptions = () => {
  const thisYear = moment().format('YYYY');
  const options = [];

  for (let year = 1900; year <= thisYear; year++) {
    options.push({
      value: year,
      label: year
    });
  }

  return options;
};

const YearPicker = ({ onChange, defaultYear = null }) => {
  const { t } = useTranslation();
  const getDefaultYear = () => {
    if (!defaultYear) {
      return null;
    }

    return {
      value: defaultYear,
      label: defaultYear
    };
  };

  const [year, setYear] = useState(getDefaultYear());

  useEffect(() => {
    onChange(year ? year.value : null);
  }, [year]);

  return (
    <Select
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      options={getOptions()}
      defaultValue={year}
      placeholder={t('-- Select --')}
      onChange={value => setYear(value)}
    />
  );
};

YearPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultYear: PropTypes.number
};

export default YearPicker;
