import React from 'react';
import PropTypes from 'prop-types';
import Appointment from './Appointment';
import { getAssignedUserForOrder } from 'Library/functions';
import { AnimatePresence, motion } from 'framer-motion';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { animateTop } from 'Library/motion';
import useAppointments from '../../hooks/useAppointments';
import { useTranslation } from 'react-i18next';

const OrderAppointments = ({
  type,
  header = '',
  showSuppliers = false,
  onChange = null,
  ...rest
}) => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { toggleApointment, getAppointmentOfType } = useAppointments();
  const user = getAssignedUserForOrder(order);
  const defaultAppointment = {
    id: null,
    user_id: user ? user.id : null,
    supplier_id: null,
    type,
    start_date: null,
    end_date: null,
    title: '',
    description: '',
    supplier: null
  };

  const onUpdateAppointment = (appointment = null) => {
    toggleApointment(type, appointment);

    if (onChange) {
      onChange(type, appointment);
    }
  };

  /**
   * If no appointments are set, create a default one that
   * we can use to create to an appointment via the API.
   * @return {Object}
   */
  function getAppointment () {
    return getAppointmentOfType(type) || defaultAppointment;
  }

  const appointment = getAppointment();

  return (
    <AnimatePresence>
      <motion.div
        {...animateTop}
        className='order-appointments'
      >
        <h3>{t('Calendar events')}</h3>
        <h5>{header}</h5>

        <Appointment
          key={appointment.id || `appointment-${order.id}`}
          appointment={appointment}
          showSuppliers={showSuppliers}
          locationId={order.location_id}
          onChange={onUpdateAppointment}
        />
      </motion.div>
    </AnimatePresence>
  );
};

OrderAppointments.propTypes = {
  type: PropTypes.string.isRequired,
  header: PropTypes.string,
  showSuppliers: PropTypes.bool,
  onChange: PropTypes.func
};

export default OrderAppointments;
