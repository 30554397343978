import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../actions/settings';

function useSettings () {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.settings);

  function getSetting (key = null, fallback = null) {
    if (!key) {
      return settings;
    }

    return settings[key] ? settings[key] : fallback;
  }

  function setSetting (setting) {
    dispatch(setSettings(setting));
  }

  return { getSetting, setSetting };
}

export default useSettings;
