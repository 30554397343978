import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function InvoiceStatus ({ invoice }) {
  const { t } = useTranslation();
  const { getCountryCode } = useSingleOrder();
  const isStripePayment = invoice.stripe_id !== null;
  const dateFormat = 'P p';
  let invoiceId = invoice.vismanet_invoice_id ?? invoice.fortnox_invoice_id;

  if (isStripePayment) {
    invoiceId = invoice.stripe_id;
  }

  return (
    <div className='invoice-status'>
      <span className='task-sent-date invoice-status__field created'>
        <strong>
          {isStripePayment ? t('Stripe Payment') : t('Invoice')} #{invoiceId}
        </strong>
        <If condition={invoice.invoicelink}>
            &nbsp;-&nbsp;
          <a href={invoice.invoicelink} target='_blank' rel='noopener noreferrer'>{t('Download invoice')}</a>
        </If>
        <strong>
          <br />{t('Created')}
        </strong>{' '}
        {formatDate(invoice.created_at, getCountryCode(), dateFormat)}
      </span>
      <If condition={invoice.due_at && invoice.late_notification_sent_at}>
        <span className='task-sent-date invoice-status__field late'>
          <strong>
            {isStripePayment ? t('Stripe Payment') : t('Invoice')} #{invoiceId} {t('has not been paid on time')}
          </strong>{' '}
          {invoice.due_at}
        </span>
      </If>
      <If condition={!invoice.paid_at && invoice.is_credited}>
        <span className='task-sent-date invoice-status__field credited'>
          <strong>
            {isStripePayment ? t('Stripe Payment') : t('Invoice')} #{invoiceId} {t('credited')}
          </strong>{' '}
          {invoice.paid_at}
        </span>
      </If>
      <If condition={invoice.paid_at && !invoice.is_credited}>
        <span className='task-sent-date invoice-status__field paid'>
          <strong>
            {isStripePayment ? t('Stripe Payment') : t('Invoice')} #{invoiceId} {t('paid')}
          </strong>{' '}
          {invoice.paid_at}
        </span>
      </If>
      <If condition={invoice.paid_at && invoice.is_credited}>
        <span className='task-sent-date invoice-status__field credited'>
          <strong>
            {isStripePayment ? t('Stripe Payment') : t('Invoice')} #{invoiceId} {t('credited')} & {t('paid')}
          </strong>{' '}
          {invoice.paid_at}
        </span>
      </If>
    </div>
  );
}

InvoiceStatus.propTypes = {
  invoice: PropTypes.object.isRequired
};

export default InvoiceStatus;
