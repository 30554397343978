import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TaskPrioHeader = ({ priority = 1 }) => {
  const { t } = useTranslation();

  const prioText = () => {
    return (priority === 1) ? t('Urgent') : t('Normal');
  };

  return (
    <h4 className={`task-prio-header prio-${priority}`}>
      {t('Priority:')} <span className='task-prio-header__prio'>{prioText()}</span>
    </h4>
  );
};

TaskPrioHeader.propTypes = {
  priority: PropTypes.number
};

export default TaskPrioHeader;
