import { useDispatch, useSelector } from 'react-redux';
import arrayMove from 'array-move';
import {
  addInvitation,
  removeInvitation,
  resetInvitations,
  reorderInvitations,
  rejectAcceptOrderInvitation,
  addCustomerMeeting,
  getPreCreationUsers,
  resetUsers
} from '../actions/invitations';
import { setUserToAssign } from '../actions/orderAssigner';
import useSingleOrder from './useSingleOrder';

function useInvitations () {
  const dispatch = useDispatch();
  const { orderActionCallback } = useSingleOrder();
  const { invitations, users } = useSelector(state => state.invitations);

  const reorderQueue = ({ newIndex, oldIndex }) => {
    const reorderedInvitations = arrayMove([...invitations], oldIndex, newIndex);

    dispatch(reorderInvitations(reorderedInvitations));
  };

  const addToQueue = user => {
    dispatch(addInvitation(user));
  };

  const removeFromQueue = user => {
    dispatch(removeInvitation(user));
  };

  const resetQueue = () => {
    dispatch(setUserToAssign(null));
    dispatch(resetInvitations());
  };

  const rejectAcceptInvitation = async (invitationId, type) => {
    const response = await dispatch(rejectAcceptOrderInvitation(invitationId, type));

    return orderActionCallback(response);
  };

  return {
    // sanity check, this is occisionally [null]
    users,
    invitations: invitations.filter(i => i),
    removeFromQueue,
    addToQueue,
    resetQueue,
    resetUsers: () => dispatch(resetUsers()),
    addCustomerMeeting: (appointment) => dispatch(addCustomerMeeting(appointment)),
    getPreCreationUsers: (params) => dispatch(getPreCreationUsers(params)),
    reorderQueue,
    rejectAcceptInvitation
  };
}

export default useInvitations;
