import { useDispatch, useSelector } from 'react-redux';
import { getRecords, addRecord, updateRecord, getAcccessLog, resetRecords } from '../actions/customerRecords';

function useCustomerRecords () {
  const dispatch = useDispatch();
  const { records, accessLog, isLoadingAccessLog, isLoadingRecords, hasLoadedRecords, isAddingRecord } = useSelector(state => state.customerRecords);

  return {
    records,
    isLoadingRecords,
    hasLoadedRecords,
    isAddingRecord,
    isLoadingAccessLog,
    accessLog,
    resetRecords: () => dispatch(resetRecords()),
    getAcccessLog: (customerId) => dispatch(getAcccessLog(customerId)),
    updateRecord: (record) => dispatch(updateRecord(record)),
    getRecords: (countryId) => dispatch(getRecords(countryId)),
    addRecord: (countryId, records) => dispatch(addRecord(countryId, records))
  };
}

export default useCustomerRecords;
