import PropTypes from 'prop-types';
import React from 'react';
import { LoadingButton, IconButton } from 'FormElements';
import InformationBox from '../Generic/InformationBox';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function SuggestedSelectedSupplier ({
  openModal,
  supplier = null,
  isSelectedSupplier = false,
  task = null,
  attachSupplierToOrder,
  taskInfo = null,
  address = null
}) {
  const { t } = useTranslation();

  if (!supplier) {
    return null;
  }

  const motionProps = {
    initial: { opacity: 0, y: 25 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -25 }
  };
  const { contact_person: contactPerson, phone } = supplier;

  return (
    <AnimatePresence>
      <motion.div {...motionProps}>
        <InformationBox className={
          classNames('suggested-supplier', { 'is-suggested': !isSelectedSupplier })
        }
        >
          <div className='suggested-supplier__supplier-info'>
            <div>
              <h4>{isSelectedSupplier ? t('Selected') : t('Suggested')} {t('Transportation')} {taskInfo}</h4>
              <p><strong>{supplier.name}</strong></p>
              <p>{address}</p>
            </div>
            <div className='action-buttons'>
              <If condition={!isSelectedSupplier}>
                <LoadingButton
                  onClick={() => attachSupplierToOrder(supplier)}
                  text={t('Select this transportation provider')}
                />
              </If>
              <If condition={openModal}>
                <IconButton
                  onClick={openModal}
                  icon='reports-v-2'
                  className={isSelectedSupplier ? 'is-selected' : 'is-suggested'}
                  text={isSelectedSupplier ? t('Change transportation provider') : t('Show alternatives')}
                />
              </If>
            </div>
          </div>
          <div className='suggested-supplier__supplier-info contact'>
            <div className='suggested-supplier__supplier-info__contact'>
              <span>{t('Contact person')}</span>
              <span><strong>{contactPerson || '—'}</strong></span>
            </div>
            <div className='suggested-supplier__supplier-info__contact'>
              <span>{t('Phone')}</span>
              <span><strong>{phone || '—'}</strong></span>
            </div>
          </div>
        </InformationBox>
      </motion.div>
    </AnimatePresence>
  );
}

SuggestedSelectedSupplier.propTypes = {
  openModal: PropTypes.func,
  supplier: PropTypes.object,
  isSelectedSupplier: PropTypes.bool,
  task: PropTypes.object,
  attachSupplierToOrder: PropTypes.func,
  taskInfo: PropTypes.string,
  address: PropTypes.string
};

export default SuggestedSelectedSupplier;
