import React, { Fragment, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';

// Redux
import { ROLES } from 'ReduxActions/users';
import { TYPE_FUNERAL, TYPE_LEGAL, TYPE_THERAPY, TYPE_CEREMONY } from 'ReduxActions/competenceAreas';
import useUser from 'ReduxHooks/useUser';

// Components
import { Fieldset, Input, Toggle } from 'FormElements';
import { If } from 'Conditionals';
import { Notice } from 'Layout';
import APITokenGenerator from 'Components/APITokenGenerator';
import CompetenceAreaDropdown from 'Components/CompetenceAreaDropdown';
import CountryDropdown from 'Components/CountryDropdown';
import DeleteOrDisableUser from 'Components/DeleteOrDisableUser';
import LavendlaDatePicker from 'Components/LavendlaDatePicker';
import LocationDropdown from 'Components/LocationDropdown';
import PauseUser from 'Components/PauseUser';
import RolesDropdown from 'Components/RolesDropdown';
import SupervisorDropdown from 'Components/SupervisorDropdown';
import SupplierDropdown from 'Components/SupplierDropdown';

function AdminForm () {
  const { t } = useTranslation();
  const { user, features, updateField, getFeatures } = useUser();
  const { suppliers = [] } = user;
  const [locationsForOrder, setLocationsForOrder] = useState(user.locations.filter(({ type }) => type === 'App\\Order'));
  const [locationsForLawyerOrder, setLocationsForLawyerOrder] = useState(user.locations.filter(({ type }) => type === 'App\\LawyerOrder'));
  const [locationsForTherapyOrder, setLocationsForTherapyOrder] = useState(user.locations.filter(({ type }) => type === 'App\\TherapyOrder'));
  const [locationsForCeremonyOrder, setLocationsForCeremonyOrder] = useState(user.locations.filter(({ type }) => type === 'App\\CeremonyOrder'));
  const hasLegalRole = user.roles.includes(ROLES.LEGAL);
  const hasFuneralRole = user.roles.includes(ROLES.FUNERAL);
  const hasTherapyRole = user.roles.includes(ROLES.THERAPIST);
  const hasOfficiantRole = user.roles.includes(ROLES.OFFICIANT);
  const [showUsernameWarning, setShowUsernameWarning] = useState(!validateUsername(user.username));

  useEffect(() => {
    loadDependencies();
  }, []);

  const loadDependencies = async () => {
    await getFeatures();
  };

  const updateLocations = (values, type) => {
    const locations = values.map(({ value: id }) => ({ id, type }));

    switch (type) {
      case 'App\\Order':
        setLocationsForOrder(locations);
        updateField('locations', [...locations, ...locationsForLawyerOrder, ...locationsForTherapyOrder, ...locationsForCeremonyOrder]);
        break;
      case 'App\\LawyerOrder':
        setLocationsForLawyerOrder(locations);
        updateField('locations', [...locations, ...locationsForOrder, ...locationsForTherapyOrder, ...locationsForCeremonyOrder]);
        break;
      case 'App\\TherapyOrder':
        setLocationsForTherapyOrder(locations);
        updateField('locations', [...locations, ...locationsForOrder, ...locationsForLawyerOrder, ...locationsForCeremonyOrder]);
        break;
      case 'App\\CeremonyOrder':
        setLocationsForCeremonyOrder(locations);
        updateField('locations', [...locations, ...locationsForOrder, ...locationsForLawyerOrder, ...locationsForTherapyOrder]);
        break;
    }
  };

  function updateCompetenceAreas (values, type = TYPE_LEGAL, countryId = null) {
    const otherAreas = [...user.competence_areas].filter(ca => ca.type !== type || (countryId && ca.country_id !== countryId));

    updateField('competence_areas', [...otherAreas, ...values]);
  }

  function setRepSupplier (supplier) {
    const updatedSuppliers = [...suppliers];
    const index = suppliers.findIndex(({ type }) => type === 'representant');

    if (index > -1) {
      updatedSuppliers.splice(index, 1, supplier);
    } else {
      updatedSuppliers.push(supplier);
    }

    updateField('suppliers', updatedSuppliers);
  }

  const updateUsername = (value) => {
    setShowUsernameWarning(false);

    if (!validateUsername(value)) {
      setShowUsernameWarning(true);
    }

    updateField('username', value);
  };

  function validateUsername (value) {
    const regEx = /^[a-z]+$/;

    if (!value) {
      return true;
    }

    return regEx.test(value);
  }

  return (
    <div>
      <div className='react-select__floating-label floating-label input has-content'>
        <RolesDropdown
          user={user}
          onChange={values => updateField('roles', values)}
        />
        <label htmlFor='role-select'>{t('Roles')}</label>
      </div>
      <div className='react-select__floating-label floating-label input has-content'>
        <CountryDropdown
          isMulti
          isClearable
          id='countries-dropdown'
          selected={user.countries}
          onChange={countries => updateField('countries', countries)}
        />
        <label htmlFor='countries-dropdown'>{t('Select active markets for the user')}</label>
      </div>

      <div className='react-select__floating-label floating-label input has-content'>
        <SupervisorDropdown
          selected={user.users}
          isClearable
          isMulti
          id='supervisor-dropdown'
          onChange={users => updateField('users', users)}
        />
        <label>{t('Supervisors')}</label>
      </div>

      {user.countries.map((country) => {
        return (
          <Fragment key={country.id}>
            <AnimatePresence>
              <If condition={hasFuneralRole}>
                <motion.div {...animateTop} key={country.id}>
                  <Fieldset legend={'Funeral: ' + country.name} className='user-admin__fieldset'>
                    <If condition={country.code !== 'global'}>
                      <div
                        data-cy='user-place-list'
                        className='react-select__floating-label floating-label input has-content'
                      >
                        <LocationDropdown
                          isMulti
                          showParents
                          value={locationsForOrder.map(({ id }) => id)}
                          onChange={value => updateLocations(value, 'App\\Order')}
                        />
                        <label>{t('Locations')}</label>
                      </div>
                    </If>
                    <div
                      data-cy='user-competences-funeral'
                      className='react-select__floating-label floating-label input has-content'
                    >
                      <CompetenceAreaDropdown
                        countryId={country.id}
                        isMulti
                        type={TYPE_FUNERAL}
                        id='competences-funeral'
                        onChange={values => updateCompetenceAreas(values, TYPE_FUNERAL, country.id)}
                        selected={user.competence_areas.filter(ca => ca.type === TYPE_FUNERAL && ca.country_id === country.id)}
                      />
                      <label htmlFor='competences-funeral'>{t('Competences')}</label>
                    </div>
                    <div className='react-select__floating-label floating-label input has-content'>
                      <SupplierDropdown
                        isLocal={false}
                        type='representant'
                        showSelectedInfo={false}
                        id='supplier-rep-dropdown'
                        noValueLabel={t('-- Select a supplier --')}
                        onChange={(type, supplier) => setRepSupplier(supplier)}
                        supplier={suppliers.find(({ type }) => type === 'representant')}
                      />
                      <label htmlFor='supplier-rep-dropdown'>{t('Connect this user to a supplier')}</label>
                    </div>
                  </Fieldset>
                </motion.div>
              </If>
            </AnimatePresence>

            <AnimatePresence>
              <If condition={hasLegalRole}>
                <motion.div {...animateTop}>
                  <Fieldset legend={'Law: ' + country.name} className='user-admin__fieldset'>
                    <If condition={country.code !== 'global'}>
                      <div
                        data-cy='lawyer-place-list'
                        className='react-select__floating-label floating-label input has-content'
                      >
                        <LocationDropdown
                          isMulti
                          showParents
                          value={locationsForLawyerOrder.map(({ id }) => id)}
                          onChange={value => updateLocations(value, 'App\\LawyerOrder')}
                        />
                        <label>{t('Locations')}</label>
                      </div>
                    </If>
                    <div
                      data-cy='user-competences'
                      className='react-select__floating-label floating-label input has-content'
                    >
                      <CompetenceAreaDropdown
                        countryId={country.id}
                        isMulti
                        id='competences-legal'
                        onChange={values => updateCompetenceAreas(values, TYPE_LEGAL, country.id)}
                        selected={user.competence_areas.filter(ca => ca.type === TYPE_LEGAL && ca.country_id === country.id)}
                      />
                      <label htmlFor='competences-legal'>{t('Competences')}</label>
                    </div>
                  </Fieldset>
                </motion.div>
              </If>
            </AnimatePresence>

            <AnimatePresence>
              <If condition={hasTherapyRole}>
                <motion.div {...animateTop}>
                  <Fieldset legend={'Therapy: ' + country.name} className='user-admin__fieldset'>
                    <If condition={country.code !== 'global'}>
                      <div
                        data-cy='therapy-place-list'
                        className='react-select__floating-label floating-label input has-content'
                      >
                        <LocationDropdown
                          isMulti
                          showParents
                          value={locationsForTherapyOrder.map(({ id }) => id)}
                          onChange={value => updateLocations(value, 'App\\TherapyOrder')}
                        />
                        <label>{t('Locations')}</label>
                      </div>
                    </If>
                    <div
                      data-cy='user-competences'
                      className='react-select__floating-label floating-label input has-content'
                    >
                      <CompetenceAreaDropdown
                        countryId={country.id}
                        isMulti
                        type={TYPE_THERAPY}
                        id='competences-therapy'
                        onChange={values => updateCompetenceAreas(values, TYPE_THERAPY, country.id)}
                        selected={user.competence_areas.filter(ca => ca.type === TYPE_THERAPY && ca.country_id === country.id)}
                      />
                      <label htmlFor='competences-legal'>{t('Competences')}</label>
                    </div>
                  </Fieldset>
                </motion.div>
              </If>
            </AnimatePresence>
            <AnimatePresence>
              <If condition={hasOfficiantRole}>
                <motion.div {...animateTop}>
                  <Fieldset legend={'Officiant: ' + country.name} className='user-admin__fieldset'>
                    <If condition={country.code !== 'global'}>
                      <div
                        data-cy='ceremony-place-list'
                        className='react-select__floating-label floating-label input has-content'
                      >
                        <LocationDropdown
                          isMulti
                          showParents
                          value={locationsForCeremonyOrder.map(({ id }) => id)}
                          onChange={value => updateLocations(value, 'App\\CeremonyOrder')}
                        />
                        <label>{t('Locations')}</label>
                      </div>
                    </If>
                    <div
                      data-cy='user-competences'
                      className='react-select__floating-label floating-label input has-content'
                    >
                      <CompetenceAreaDropdown
                        countryId={country.id}
                        isMulti
                        type={TYPE_CEREMONY}
                        id='competences-officiant'
                        onChange={values => updateCompetenceAreas(values, TYPE_CEREMONY, country.id)}
                        selected={user.competence_areas.filter(ca => ca.type === TYPE_CEREMONY && ca.country_id === country.id)}
                      />
                      <label htmlFor='competences-legal'>{t('Competences')}</label>
                    </div>
                  </Fieldset>
                </motion.div>
              </If>
            </AnimatePresence>
          </Fragment>
        );
      })}

      <Fieldset legend={t('User permissions')} className='user-admin__fieldset'>
        <Toggle
          label={t('Allow phone hours in schedule')}
          defaultChecked={!!user.allow_phone_hours}
          onChange={() => updateField('allow_phone_hours', !user.allow_phone_hours)}
        />

        <Toggle
          label={t('Self invoice')}
          defaultChecked={!!user.self_invoice}
          onChange={() => updateField('self_invoice', !user.self_invoice)}
        />

        <Input
          floatingLabel
          id='supplier_number'
          onChange={value => updateField('supplier_number', value)}
          label={t('VismaNet supplier number')}
          value={user.supplier_number}
          type='text'
        />
      </Fieldset>

      <Fieldset legend={t('Feature flags')} className='user-admin__fieldset'>
        {Object.keys(features).map((featureKey) => {
          let userFeature = user.user_features.find(f => f.feature === featureKey);
          if (!userFeature) {
            userFeature = { feature: featureKey, active: false };
            user.user_features.push(userFeature);
          }
          const isActive = userFeature.active;
          return (
            <Toggle
              key={featureKey}
              label={t(features[featureKey])}
              defaultChecked={isActive}
              onChange={() => {
                const newUserFeatures = user.user_features.map(f => {
                  if (f.feature === featureKey) {
                    return { ...f, active: !isActive };
                  }
                  return f;
                });
                updateField('user_features', newUserFeatures);
              }}
            />
          );
        })}
      </Fieldset>

      <Fieldset legend={t('Therapy')} className="user-admin__fieldset">
        <Toggle
          desc={t('If checked, the user will use Stripe Connect to receive payments. You need to connect the user to Stripe in the Stripe Connect section in the Economy tab.')}
          label={t('Use Stripe Connect')}
          defaultChecked={!!user.has_stripe_connect}
          onChange={() => updateField('has_stripe_connect', !user.has_stripe_connect)}
        />

        <label>{t('Fixed pricing')}</label>
        <LavendlaDatePicker
          placeholder={t('No fixed pricing')}
          date={user.fixed_price_from ? format(new Date(user.fixed_price_from), 'yyyy-MM-dd') : null}
          onChange={date => updateField('fixed_price_from', date || null)}
          label={t('Use Fixed pricing')}
        />
        <p><small>{t('If a date is added the user will be charged a fee that is the cost of a 45 min session for each new customer. For old customers the fee is procenttal.')}</small></p>
      </Fieldset>

      <Fieldset legend={t('User settings')} className="user-admin__fieldset">
        <Input
          id="username"
          floatingLabel
          label={t('Username')}
          value={user.username}
          onChange={updateUsername}
        />
        <If condition={showUsernameWarning}>
          <Notice type='error'>
            <strong>{t('Invalid username!')}</strong><br /><br />{t('Use ONLY English letters e.g. jakobnordstrom.')}
          </Notice>
          <br /><br />
        </If>

        <div
          data-cy='user-employed-since'
          className={classNames('floating-label datepicker__floating-label input', {
            'has-content': user.employed_since
          })}
        >
          <LavendlaDatePicker
            date={user.employed_since}
            onChange={date => {
              date = date || null;
              updateField('employed_since', date);
            }}
          />
          <label htmlFor='employed_since'>{t('Employed since')}</label>
        </div>
        <Toggle
          label={t('Exclude from statistics')}
          defaultChecked={!!user.hide_from_stats}
          onChange={() => updateField('hide_from_stats', !user.hide_from_stats)}
        />

        <Toggle
          label={t('Top user')}
          defaultChecked={!!user.top_user}
          onChange={() => updateField('top_user', !user.top_user)}
        />
      </Fieldset>

      <Input
        readOnly
        floatingLabel
        id='rating'
        onChange={value => updateField('rating', value)}
        label={t('Rating (calculated automatically by Trustpilot)')}
        value={user.rating}
        type='number'
        step='0.1'
        min='0'
        max='5'
      />

      <Input
        readOnly
        floatingLabel
        id='number_of_reviews'
        onChange={value => updateField('number_of_reviews', value)}
        label={t('Number of reviews (calculated automatically by Trustpilot)')}
        value={user.number_of_reviews}
        type='number'
      />

      <APITokenGenerator user={user} />

      <PauseUser user={user} />

      <DeleteOrDisableUser user={user} />
    </div>
  );
}

export default AdminForm;
