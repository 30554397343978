import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';

// This function is used to get the supported notices, remember to add the new notices here
// and in the getNotificationText function!
export function getSupportedNotices () {
  return [
    'lateinvoice',
    'fileattached',
    'noteadded',
    'customerreview',
    'new_booking',
    'payment_received',
    'payment_expired',
    'task_reminder',
    'offer_confirmed',
    'memorial_page_published_by_customer'
  ];
}

export function getNotificationText (notice, country) {
  const { t } = useTranslation();
  switch (true) {
    case notice.status === 'lateinvoice':
      return sprintf(
        t('%s with order #%s %s did not pay invoice #%s on time.'),
        notice.order.customer_name,
        notice.order.number,
        notice.order.deceased_name,
        notice.order.vismanet_invoice_id
      );
    case notice.status === 'fileattached':
      return sprintf(
        t('%s has uploaded a new file (%s) in order #%s %s.'),
        strongText(notice.generatedBy.name),
        strongText(notice.description),
        notice.order.number,
        notice.order.deceased_name
      );
    case notice.status === 'noteadded':
      return sprintf(
        t('%s left a note (%s) in order #%s %s.'),
        strongText(notice.generatedBy.name),
        notice.description,
        notice.order.number,
        notice.order.deceased_name
      );
    case notice.status === 'customerreview':
      if (notice.order.customer_name) {
        return sprintf(
          t('%s has left a review with %s stars.'),
          strongText(notice.order.customer_name),
          notice.description
        );
      } else {
        return sprintf(
          t('You have got a review with %s stars.'),
          notice.description
        );
      }
    case notice.status === 'new_booking':
      return sprintf(
        t('You have received a new booking #%s.'),
        notice.order.number
      );
    case notice.status === 'payment_received':
      return sprintf(
        t('Payment has been received for case #%s %s.'),
        notice.order.number,
        notice.order.customer_name
      );
    case notice.status === 'payment_expired':
      return sprintf(
        t('Payment request is overdue for case #%s %s. Follow up with the customer.'),
        notice.order.number,
        notice.order.customer_name
      );
    case notice.status === 'task_reminder':
      return sprintf(
        t('The task %s has not been accomplished on time on order %s %s.'),
        strongText(notice.description),
        notice.order.number,
        notice.order.deceased_name
      );
    case notice.status === 'offer_confirmed':
      return sprintf(
        t('Offer has been confirmed for order #%s, %s.'),
        notice.order.number,
        strongText(notice.order.customer_name)
      );
    case notice.status === 'memorial_page_published_by_customer':
      return sprintf(
        t('Memorial page has been published by customer, %s, for order #%s.'),
        strongText(notice.order.customer_name),
        notice.order.number
      );
    default:
      return sprintf(
        t('This notification is not yet supported. Please contact support. <br/><br/> %s <br/> %s'),
        'Notice Id: ' + strongText(notice.id),
        'Notice Status: ' + strongText(notice.status)
      );
  }
}

export const getUrl = (notice) => {
  let url = '';
  const resourceType = notice?.resource_type || '';

  switch (true) {
    case resourceType.includes('TherapyOrder'):
      url = `/therapy/${notice.order.id}`;
      break;
    case resourceType.includes('LawyerOrder'):
      url = `/juridik/${notice.order.id}`;
      break;
    case resourceType.includes('TombstoneOrder'):
      url = `/gravstenar/${notice.order.id}`;
      break;
    case resourceType.includes('Order'):
      url = `/ordrar/order/${notice.order.id}`;
      break;
  }

  if (notice.status === 'task_reminder' && notice?.task && notice.task.orderTab) {
    switch (true) {
      case notice.task.orderTab === 'customer':
        return (url += '/kund');
      case notice.task.orderTab === 'entombment':
        return (url += '/gravsattning');
      case notice.task.orderTab === 'funeral':
        return (url += '/ceremoni');
      case notice.task.orderTab === 'transport':
        return (url += '/transport');
      case notice.task.orderTab === 'flowers':
        return (url += '/blommor');
      case notice.task.orderTab === 'deceased':
        return (url += '/avliden');
      case notice.task.orderTab === 'memorial':
        return (url += '/minnesstund');
      case notice.task.orderTab === 'products':
        return (url += '/produkter');
      case notice.task.orderTab === 'memorial_page':
        return (url += '/minnessida');
    }
  }

  switch (true) {
    case notice.status === 'lateinvoice':
      url += '/produkter';
      break;
    case notice.status === 'fileattached':
      url += '/filer';
      break;
    case notice.status === 'memorial_page_published_by_customer':
      url += '/minnessida';
      break;
    case notice.status === 'customerreview':
      url = '/profil';
      break;
    default:
      return url;
  }

  return url;
};

export const strongText = (text) => `<strong>${text}</strong>`;
