import i18next from 'i18next';

export function getBasePath ({ isLawyerOrder = false, isTombstoneOrder = false, isTherapyOrder = false, isCeremonyOrder = false }) {
  switch (true) {
    case isLawyerOrder:
      return '/juridik';

    case isTombstoneOrder:
      return '/gravstenar';

    case isTherapyOrder:
      return '/therapy';

    case isCeremonyOrder:
      return '/ceremonies';

    default:
      return '/ordrar';
  }
}

export function getEndpoint ({ isLawyerOrder = false, isTombstoneOrder = false, isTherapyOrder = false, isCeremonyOrder = false }) {
  switch (true) {
    case isLawyerOrder:
      return '/lawyer_orders';
    case isTombstoneOrder:
      return '/tombstone_orders';
    case isTherapyOrder:
      return '/therapy_orders';
    case isCeremonyOrder:
      return '/ceremony_orders';
    default:
      return '/orders';
  }
}

export function getOrderArchiveColumns ({
  isAdmin = false,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false
}) {
  switch (true) {
    case isLawyerOrder:
      return [
        { type: 'icon', icon: ['fas', 'star'] },
        i18next.t('Order number'),
        i18next.t('Location'),
        i18next.t('Customer/Deceased'),
        i18next.t('Type'),
        i18next.t('Status'),
        i18next.t('Advisors')
      ];
    case isTombstoneOrder:
      return [
        { type: 'icon', icon: ['fas', 'star'] },
        i18next.t('Order number'),
        i18next.t('Deceased'),
        i18next.t('Customer'),
        i18next.t('Status'),
        i18next.t('Advisors')
      ];
    case isTherapyOrder:
      return [
        { type: 'icon', icon: ['fas', 'star'] },
        i18next.t('Order number'),
        i18next.t('Location'),
        i18next.t('Customer'),
        i18next.t('Type'),
        i18next.t('Next meeting'),
        i18next.t('Status'),
        i18next.t('Advisors')
      ];
    case isCeremonyOrder:
      return [
        { type: 'icon', icon: ['fas', 'star'] },
        i18next.t('Order number'),
        i18next.t('Location'),
        i18next.t('Customer'),
        i18next.t('Type'),
        i18next.t('Status'),
        i18next.t('Advisors')
      ];
    default:
      return [
        { type: 'icon', icon: ['fas', 'star'] },
        i18next.t('Order number'),
        i18next.t('Location'),
        i18next.t('Deceased'),
        i18next.t('Customer meeting'),
        i18next.t('Status'),
        i18next.t('Advisors')
      ];
  }
}

export function getOrderTabIndexes (orderType = 'App\\Order') {
  switch (orderType) {
    case 'App\\TombstoneOrder':
      return [
        'kund',
        'avliden',
        'gravsatting',
        'produkter',
        'filer'
      ];

    case 'App\\TherapyOrder':
      return [
        'kund',
        'sessions',
        'records',
        'filer'
      ];

    case 'App\\CeremonyOrder':
      return [
        'kund',
        'produkter',
        'filer'
      ];
    case 'App\\LawyerOrder':
      return [
        'kund',
        'avliden',
        'produkter',
        'filer'
      ];

    default:
      return [
        'kund',
        'avliden',
        'ceremoni',
        'minnessida',
        'gravsattning',
        'minnesstund',
        'transport',
        'blommor',
        'produkter',
        'filer'
      ];
  }
}

export function getOrderFuneralPlace ({ funeral = null }) {
  if (!funeral) {
    return '';
  }

  const supplier = funeral.suppliers ? funeral.suppliers.find(supplier => ['Kyrkor', 'Kapell'].includes(supplier.type)) : null;

  return (supplier) ? supplier.name : funeral.place;
}

export function getLawOrderTermID ({ country = null }) {
  if (!country) {
    return 33; // Default to Sweden
  }

  switch (true) {
    case country.code === 'se':
      return 33;
    case country.code === 'dk':
      return 303;
    default:
      return 33;
  }
}

export function getTherapyOrderStripeTermID ({ country = null }) {
  if (!country) {
    return 857; // default category in Sweden
  }

  switch (true) {
    case country.code === 'se':
      return 857;
    case country.code === 'dk':
      return 551;
    default:
      return 857;
  }
}

export function getTherapyOrderTermID ({ country = null }) {
  if (!country) {
    return 844; // default category in Sweden
  }

  switch (true) {
    case country.code === 'se':
      return 844;
    case country.code === 'dk':
      return 550;
    default:
      return 844;
  }
}

export function getCeremonyOrderTermID ({ country = null }) {
  if (!country) {
    return 845; // default category in Sweden
  }

  switch (true) {
    case country.code === 'se':
      return 845;
    case country.code === 'dk':
      return 552;
    default:
      return 845;
  }
}

export function getTombstoneOrderTermID ({ country = null }) {
  if (!country) {
    return 246; // default category in Sweden
  }

  switch (true) {
    case country.code === 'se':
      return 246;
    case country.code === 'dk':
      return 305;
    default:
      return 246;
  }
}
