import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SupplierCustomColorsPreview = ({ primaryColor, secondaryColor, tertiaryColor }) => {
  const { t } = useTranslation();

  return (
    <div className='supplier-custom-colors-preview'>
      <h4>{t('Preview')}</h4>
      <div
        className='supplier-custom-colors-preview__content'
        style={{
          backgroundColor: tertiaryColor,
          color: primaryColor
        }}
      >
        <a
          className='preview-link'
          href='#'
          style={{ color: primaryColor }}
        >
          {t('Preview link')}
        </a>
        <button
          className='btn preview-button'
          style={{
            backgroundColor: secondaryColor,
            borderColor: secondaryColor
          }}
        >
          {t('Preview button')}
        </button>
      </div>
    </div>
  );
};

export default SupplierCustomColorsPreview;

SupplierCustomColorsPreview.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  tertiaryColor: PropTypes.string
};
