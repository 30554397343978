export const SET_MENU_STATE = 'SET_MENU_STATE';

function receiveMenuState (payload) {
  return {
    type: SET_MENU_STATE,
    payload
  };
}

export function setMenuState (settings) {
  return (dispatch) => {
    dispatch(receiveMenuState(settings));
  };
}
