import { SET_COMPETENCE_AREA } from '../actions/competenceAreas';

const initialState = {
  competenceArea: null
};

export default function competenceAreas (state = initialState, { type, payload }) {
  switch (type) {
    case SET_COMPETENCE_AREA: {
      return {
        ...state,
        competenceArea: payload
      };
    }

    default:
      return state;
  }
}
