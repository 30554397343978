import React, { useState } from 'react';
import { Table } from 'Layout';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';
import PaymentInfoModal from 'Components/PaymentComponents/PaymentInfoModal';
import { formatCurrency, getTranslatedStripeStatus } from 'Library/functions';
import PropTypes from 'prop-types';

function ProductsTable ({ order }) {
  const { t } = useTranslation();
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const products = order.products;

  const getPaymentInformation = (product) => {
    // get the stirpePayment from order.StripePayments with containing an item with the same resource_id as the product.pivot.product_resource_id

    const stripePayment = order.stripePayments.find(payment => payment.items.find(item => item.resource_id === product.pivot.product_resource_id));
    const offerId = stripePayment?.offer_id || null;

    if (!stripePayment || !offerId) {
      return null;
    }

    return (
      <LoadingButton
        className='text' onClick={() => {
          setPaymentInfo(stripePayment);
          setModalIsOpen(true);
        }}
      >
        <span className={`payment-info__status ${stripePayment.status}`}><span>#{offerId} - </span>{getTranslatedStripeStatus(stripePayment.status)}</span>
      </LoadingButton>
    );
  };

  const getPrice = (product) => {
    if (!product.price) {
      return null;
    }

    let price = product.price;

    if (product.quantity > 1) {
      price = price * product.quantity;
    }

    return formatCurrency(price, null, order.countryCode, order?.currency);
  };

  return (
    <>
      <Table
        isLoading={false}
        thead={[
          t('Product'),
          t('Comment'),
          t('Price'),
          t('Payment')
        ]}
      >
        {products && products.length > 0 ? (
          <>
            {products.map((product) => (
              <tr key={product.id}>
                <td>{product.name} {product.quantity > 1 ? 'x ' + product.quantity : null}</td>
                <td>{product.pivot.comment}</td>
                <td>{getPrice(product)}</td>
                <td>{getPaymentInformation(product)}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan='4'>
              <span>{t('No products')}</span>
            </td>
          </tr>
        )}
      </Table>
      {paymentInfo && (
        <PaymentInfoModal
          stripePayment={paymentInfo}
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
            setPaymentInfo(null);
          }}
        />
      )}
    </>
  );
}

ProductsTable.propTypes = {
  order: PropTypes.object.isRequired
};

export default ProductsTable;
