import React from 'react';
import ReactSVG from 'react-svg';
import { formatDate } from 'Library/functions';
import useAppointments from 'Hooks/useAppointments';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const OrderCustomerMeeting = () => {
  const { t } = useTranslation();
  const { getAppointmentOfType } = useAppointments();
  const { getCountryCode } = useSingleOrder();
  const appointment = getAppointmentOfType('customer_meeting');

  if (!appointment || !appointment.start_date) {
    return null;
  }

  return (
    <div className='order-customer-meeting'>
      <span className='icon'><ReactSVG src='/icons/icon-calendar-black.svg' /></span>
      <span>{t('Customer meeting:')} <strong>{formatDate(appointment.start_date, getCountryCode())}</strong></span>
    </div>
  );
};

export default OrderCustomerMeeting;
