import PropTypes from 'prop-types';

function If ({ condition = false, children }) {
  if (!condition) {
    return null;
  }

  return children;
}

If.propTypes = {
  condition: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default If;
