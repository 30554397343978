import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SummaryCard = ({
  heading,
  subHeading,
  details,
  className
}) => {
  /**
   * Determine how to render summary body information.
   *
   * Details can either include just one item containing a message
   * or several items containing title and description.
   */
  const Details = () => {
    if (!details.length) {
      return null;
    }

    if (details.length === 1) {
      return <p><em>{details[0].message}</em></p>;
    }

    return (
      <dl>
        {details.map(({ title, description }) => (
          <div className='summary-card__list-item' key={title}>
            <dt>{title}</dt>
            <dd>{description}</dd>
          </div>
        ))}
      </dl>
    );
  };

  return (
    <div className={classnames('summary-card', className)}>
      <div className='summary-card__header'>
        <h4>{heading}</h4>&nbsp;&nbsp;•&nbsp;&nbsp;<h5>{subHeading}</h5>
      </div>
      <div className='summary-card__body'>
        <Details />
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  details: PropTypes.array,
  className: PropTypes.string
};

export default SummaryCard;
