import React from 'react';
import TaskCreator from 'Components/Tasks/TaskCreator';
import ListShortcodes from 'Components/Tasks/ListShortcodes';
import { useTranslation } from 'react-i18next';

export default function NewAdministration () {
  const { t } = useTranslation();

  return (
    <div className='row align-top'>
      <div className='col-6'>
        <h2 className='page-title'>{t('Add admin task')}</h2>
        <TaskCreator />
      </div>
      <div className='col-6'>
        <h3>{t('Codes')}</h3>
        <p>{t('Copy and paste your codes to automatically retrieve the correct order information in this email.')}</p>
        <ListShortcodes />
      </div>
    </div>
  );
}
