import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Notice } from 'Layout';
import useSingleOrder from '../../../Redux/hooks/useSingleOrder';

const taksList = [
  'Beställning blommor',
  'Boka bärarlag',
  'Boka gravsättning',
  'Boka kremation/jordbegravning',
  'Bokningsbekräftelse begravning',
  'Catering',
  'Information till representant',
  'Transportbeställning',
  'Underlag borgerlig officiant',
  'Underlag Imam',
  'Urntransport till ceremoni',
  'Urntransport till grav'
];

function UpdatedCeremonyWarning ({ task }) {
  const { events } = useSingleOrder();

  function showWarning () {
    if (!taksList.includes(task.name) || !task.pivot.email_sent_at) {
      return false;
    }

    if (!events || !events.length) {
      return false;
    }

    const event = events.find(({ action_id: actionId }) => actionId === 100);
    if (!event) {
      return false;
    }

    return moment(event.created_at).isSameOrAfter(moment(task.pivot.completed_at));
  }

  if (!showWarning()) {
    return null;
  }

  return (
    <Notice>
      <p><strong>OBS!</strong> Du har ändrat informationen (Ceremoniplats, datum eller tid) sedan du skickade
          mailet senast. Vänligen gå igenom informationen i mailet och säkerställ att den är korrekt.
      </p>
      <p>Vill du återställa informationen och hämta in den i mailet på nytt, klicka på &quot;Återställ information&quot; nedan.</p>
    </Notice>
  );
}

UpdatedCeremonyWarning.propTypes = {
  task: PropTypes.object.isRequired
};

export default UpdatedCeremonyWarning;
