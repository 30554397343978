import API from '../../stores/API';

export const SET_FLOWER_FEE = 'SET_FLOWER_FEE';
export const RESET_FLOWER_FEE = 'RESET_FLOWER_FEE';

function resetFlowerFee () {
  return {
    type: RESET_FLOWER_FEE
  };
}

function recieveFlowerFee (payload) {
  return {
    type: SET_FLOWER_FEE,
    payload
  };
}

export function getFlowerFee (countryId) {
  return async (dispatch) => {
    const { data } = await API.GET(`/flower-fee/${countryId}`);

    if (!data) {
      return dispatch(resetFlowerFee());
    }

    return dispatch(recieveFlowerFee(transformedData(data)));
  };
}

export function setFlowerFee (flowerFee) {
  return async (dispatch) => {
    await dispatch(recieveFlowerFee(flowerFee));
  };
}

export function saveFlowerFee (countryId) {
  return async (dispatch, getState) => {
    const { flowerFee } = getState();
    const { data } = await API.POST(`/flower-fee/${countryId}`, flowerFee);

    return dispatch(recieveFlowerFee(transformedData(data)));
  };
}

function transformedData (data) {
  return {
    ...data,
    ...{ totalTax: data.total_tax }
  };
}
