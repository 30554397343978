import { useDispatch, useSelector } from 'react-redux';
import {
  getResolvedReport,
  setBonusReport,
  updateBonusReport,
  getReportsForUser,
  generateReport,
  generateCSVReport,
  updateReportStatus,
  getUnpaidReportTotals,
  generateBonusInvoice,
  downloadInvoice
} from '../actions/bonusReports';

export const MANUAL_ADJUSTMENT_TYPE = 'MANUAL_ADJUSTMENT';
export const MANUAL_ADJUSTMENT_NO_VAT_TYPE = 'MANUAL_ADJUSTMENT_NO_VAT';

function useBonusReports () {
  const dispatch = useDispatch();
  const { bonusReport, resolvedReport, bonusReports, totals } = useSelector(state => state.bonusReports);

  async function updateFields (updatedFields = {}) {
    return dispatch(setBonusReport({
      ...bonusReport,
      ...updatedFields
    }));
  }

  return {
    get: async (id) => dispatch(getResolvedReport(id)),
    downloadInvoice: async (id) => dispatch(downloadInvoice(id)),
    getForUser: async (userId) => dispatch(getReportsForUser(userId)),
    generateCSVReport: async (userId) => dispatch(generateCSVReport()),
    generateReportForUser: async (userId, includeCurrentMonth) => dispatch(generateReport(userId, includeCurrentMonth)),
    getTotals: async () => dispatch(getUnpaidReportTotals()),
    updateStatus: async (status) => dispatch(updateReportStatus(status)),
    persist: async () => dispatch(updateBonusReport()),
    generateInvoice: async () => dispatch(generateBonusInvoice()),
    updateFields,
    bonusReport,
    bonusReports,
    resolvedReport,
    totals
  };
}

export default useBonusReports;
