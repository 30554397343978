import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const MemorialPageWarning = ({ id, link = null }) => {
  const { t } = useTranslation();

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('No memorial page created')}</h3>

      <p className='tip-content__text'>{t('There is no memorial page for this order')}</p>

      {link ? <Link className='btn' to={link}>{t('Create one here')}</Link> : ''}
    </div>
  );

  return (
    <div className='memorial-warning-popout'>
      <FontAwesomeIcon
        aria-label={t('No memorial page created')}
        icon={['fal', 'exclamation-triangle']}
        data-tip='tooltip'
        data-for={`memorial-warning-tooltip-${id}`}
        className='memorial-warning-popout__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`memorial-warning-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

MemorialPageWarning.propTypes = {
  id: PropTypes.number,
  link: PropTypes.string
};

export default MemorialPageWarning;
