import React from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import If from 'Conditionals/If';

const EventSummeryWithOrder = ({ appointment, order, dateTime, meetingTypes }) => {
  const { t } = useTranslation();

  if (!appointment || !order) {
    return null;
  }

  const customer = order && order.customer ? order.customer : null;

  const meetingType = appointment.title || null;
  const orderIcon = {
    'App\\Order': 'church.svg',
    'App\\LawyerOrder': 'law.svg',
    'App\\TombstoneOrder': 'grave.svg'
  };

  const orderFaIcon = {
    'App\\TherapyOrder': 'comments'
  };

  const linkToOrder = {
    'App\\Order': '/ordrar/order/',
    'App\\LawyerOrder': '/juridik/',
    'App\\TombstoneOrder': '/gravstenar/',
    'App\\TherapyOrder': '/therapy/'
  };

  const deceasedIsTitle = () => appointment.resource_type === 'App\\Order' || appointment.resource_type === 'App\\TombstoneOrder';

  const Title = () => {
    let name = order.customer?.name || '';

    if (deceasedIsTitle()) {
      name = order.deceased?.name || '';
    }

    name = name.trim();

    return (
      <span>{name || '-'}</span>
    );
  };

  const CustomerName = () => {
    if (!customer && !customer.name) {
      return null;
    }

    if (!deceasedIsTitle()) {
      return null;
    }

    return (
      <li>
        <FontAwesomeIcon icon={['fal', 'user']} />
        <span>{customer.name}</span>
      </li>
    );
  };

  const CustomerAddress = () => {
    if (!customer || !customer.address_1) {
      return null;
    }

    return (
      <li className='event-summery__location'>
        <span>
          {customer.address_1}
          {customer.city ? `, ${customer.city}` : null}
        </span>
      </li>
    );
  };

  const MapLink = address => {
    return (
      <li className='event-summery__location'>
        <span>
          <a
            className='show-in-map-link'
            href={`https://www.google.se/maps/place/${address}`}
            target='_blank' rel='noopener noreferrer'
          >
            {t('Show on map')}
          </a>
        </span>
      </li>
    );
  };

  const MeetingType = () => {
    if (!meetingType) {
      return null;
    }

    if (meetingType === 'phone_meeting') {
      return (
        <>
          <CustomerName />
          <li>
            <FontAwesomeIcon icon={['fal', 'phone-alt']} />
            <span>{t('Meeting by phone')}{(customer.phone) ? `: ${customer.phone}` : null}</span>
          </li>
        </>
      );
    } else if (meetingType === 'meeting_at_clients_home') {
      return (
        <>
          <CustomerName />
          <li>
            <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
            <span>{t('Meeting in your home')}</span>
          </li>
          <CustomerAddress />
          <MapLink />
        </>
      );
    }

    return <CustomerName />;
  };

  const OrderInfo = () => {
    return (
      <>
        <If condition={appointment.user_name}>
          <li className='event-summery__order-advisor'>
            <ReactSVG src='/icons/icon-users.svg' />
            {appointment.user_name}
          </li>
        </If>
        <li className='event-summery__order-info'>
          {orderIcon[appointment.resource_type] && <ReactSVG src={`/icons/${orderIcon[appointment.resource_type]}`} />}
          {orderFaIcon[appointment.resource_type] && <FontAwesomeIcon icon={['fal', orderFaIcon[appointment.resource_type]]} />}

          {
            meetingTypes[appointment.type]
          }
          <span
            className={`status status-${order.status}-pill`}
          >
            {order.status_translated}
          </span>
        </li>
        <li className='event-summery__order-link'>
          <Link to={`${linkToOrder[appointment.resource_type]}${appointment.resource_id}`}>{t('Show order')}</Link>
        </li>
      </>
    );
  };

  return (
    <div className='event-summery event-summery-with-order'>
      <ul>
        <li className='event-summery__title'>
          <ReactSVG src='/icons/lavendla-symbol.svg' />
          <Title />
        </li>
        <li className='event-summery__date-time'>
          <span className='datetime'>{dateTime}</span>
        </li>
        <OrderInfo />
        <MeetingType />
      </ul>
    </div>
  );
};

EventSummeryWithOrder.propTypes = {
  appointment: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  dateTime: PropTypes.object.isRequired,
  meetingTypes: PropTypes.object.isRequired
};

export default EventSummeryWithOrder;
