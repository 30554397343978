import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import WebFont from 'webfontloader';

import OrderTab from './OrderTab';
import { If } from 'Conditionals';
import { Fieldset, MemorialSaveActions, Input, LoadingButton } from 'FormElements';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { Notice } from 'Layout';

// Memorial page components
import Card from '../MemorialPageComponents/Card';
import CeremonyInfo from '../MemorialPageComponents/CeremonyInfo';
import DeceasedInfo from '../MemorialPageComponents/DeceasedInfo';
import DownloadActions from '../MemorialPageComponents/DownloadActions';
import MemorialFundInfo from '../MemorialPageComponents/MemorialFundInfo';
import MemorialOSAInfo from '../MemorialPageComponents/MemorialOSAInfo';
import MemorialPagePreview from '../MemorialPageComponents/MemorialPagePreview';
import MemorialPageFilesForm from '../MemorialPageComponents/MemorialPageFilesForm';

// Hooks
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import useOrderSuppliers from 'Hooks/useOrderSuppliers';

import { CARD_IMG, USER_CARD_BG_IMG } from 'Library/MemorialPageHelpers';
import { ceremonyTransformer, deceasedTransformer, memorialFundTransformer } from 'Library/MemorialPageTransformers';
import { useTranslation } from 'react-i18next';

WebFont.load({
  typekit: {
    id: 'xyz1tpr'
  }
});

function MemorialPageForm () {
  const { t } = useTranslation();
  const {
    order,
    updateField,
    updateFields,
    persist,
    isDirty
  } = useSingleOrder();
  const {
    updateVisibility,
    updatePaid,
    isUnlocking,
    loadDefaultImages,
    defaultImages,
    publishMemorialPage,
    attachImage,
    detachImage,
    findImageOfType,
    memorialPage
  } = useMemorialPage();
  const { getSupplierOfType } = useOrderSuppliers();

  const [isPublishing, setIsPublishing] = useState(false);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);

  const deceasedDetails = deceasedTransformer(order.deceased);
  const ceremonyDetails = ceremonyTransformer(order, order.appointments);
  const isPublished = !!memorialPage.lavendla_post_id && memorialPage.creator_status !== 'draft';
  const memorialPageIsLocked = !!order.memorial_page.lavendla_post_id && !!order.memorial_page.lavendla_locked;
  const memorialFund = getSupplierOfType('minnesfond');
  const memorialFundDetails = memorialFundTransformer(memorialFund);
  const userCardBgImage = findImageOfType(USER_CARD_BG_IMG);
  const cardImage = findImageOfType(CARD_IMG);

  useEffect(() => {
    loadDefaultImages();
  }, [defaultImages, previewIsLoading]);

  async function maybePersistCard () {
    if (!canvas) {
      return true;
    }

    return attachImage({
      base64image: canvas.toDataURL('image/jpeg'),
      type: CARD_IMG
    }, true);
  }

  async function deleteCard () {
    if (userCardBgImage) {
      await detachImage(userCardBgImage.id);
      showNotice(t('The background image has been deleted'));
    }

    if (cardImage) {
      await detachImage(cardImage.id);
      showNotice(t('The obituary card has been deleted'));
    }

    await updateFields({
      memorial_page: {
        ...memorialPage,
        ...{
          card_title: '',
          card_text: '',
          card_text_color: '',
          wordpress_card_bg_image_id: null
        }
      }
    });

    await persist(false);
    setCanvas(null);

    return true;
  }

  const publishOrUpdate = async () => {
    setIsPublishing(true);

    showWarningNotice(`${t("Please wait, we're publishing the memorial page")} ☕`);
    await maybePersistCard();
    const { status } = await publishMemorialPage();

    setIsPublishing(false);

    if (status === 'ok') {
      showNotice('🎉 ' + t('The memorial page has been published/updated'));
    }
  };

  const noticeHeading = () => {
    if (memorialPageIsLocked) {
      return t('This memorial page has been unpublished.');
    }

    return isPublished ? t('Once you have saved your changes') : t('Here you create a memorial page for the deceased.');
  };

  return (
    <OrderTab tab='memorial_page'>
      <div className='memorial-page'>
        <Tabs className='sub-tabs' data-cy='sub-tabs'>
          <TabList>
            <li className='react-tabs__tab sub-tabs__context'>{t('Alternatives for the memorial page:')}</li>
            <Tab>{t('Basic settings')}</Tab>
            <Tab>{t('Lavendla live')}</Tab>
            <Tab>{t('Obituary & Funeral Program')}</Tab>
            {isPublished ? (
              <Tab>{t('Images & Donation certificates')}</Tab>
            ) : null}
            <Tab>{t('Memorial invitation')}</Tab>
            <Tab>{t('Information from the order')}</Tab>
          </TabList>

          <Notice
            classlist='memorial-page-notice'
            heading={noticeHeading()}
            type={memorialPageIsLocked ? 'error' : 'info'}
          >
            <If condition={memorialPageIsLocked}>
              <p>{t("The grace period has passed and the customer hasn't paid for the extended time.")}</p>
              <p>{t('If the page has been paid for click the button below to publish the page again.')}</p>
            </If>
            <If condition={!memorialPageIsLocked && isPublished}>
              <p>{t('Then click on "Update Memorial Page" to display these on the website.')}</p>
              <p>{t('You update the page and send it to the customer as you wish.')}</p>
            </If>
            <If condition={!memorialPageIsLocked && !isPublished}>
              <p>{t('Choosing a background image is mandatory. Choose from ready-made background images, or upload your own image.')}</p>
              <p>{t('Once you have saved your changes, click on "Publish Memory Page" to display it on the website.')}</p>
              <p>{t('Then you can refresh the page and send it to the customer as you want. It is always possible to make changes afterwards.')}</p>
            </If>
            <If condition={memorialPageIsLocked}>
              <LoadingButton
                disabled={false}
                isLoading={isUnlocking}
                onClick={updatePaid}
              >
                {t('Customer has paid, republish page')}
              </LoadingButton>
            </If>
            <If condition={!memorialPageIsLocked}>
              <MemorialSaveActions
                isPublished={isPublished}
                memorialPage={memorialPage}
                isPublishing={isPublishing}
                publishOrUpdate={publishOrUpdate}
                updateVisibility={(visibility) => updateVisibility(visibility)}
                isDirty={isDirty()}
              />
            </If>
          </Notice>

          <TabPanel>
            <MemorialPagePreview />
          </TabPanel>
          <TabPanel>
            <Fieldset legend={t('Settings for Lavendla Live')}>
              <Input
                type='url'
                value={memorialPage.live_url}
                data-cy='memorial_page.live_url'
                placeholder={t('Eg. https://livestream.rackfish.com/player/053e...')}
                label={t('URL to the live stream <span>&nbsp;•&nbsp;&nbsp; from Timecut</span>')}
                onChange={liveUrl => updateField('memorial_page.live_url', liveUrl)}
              />
            </Fieldset>
          </TabPanel>
          <TabPanel>
            <MemorialPageFilesForm />
          </TabPanel>
          {isPublished ? (
            <TabPanel>
              <Fieldset legend={t('Download images and proof of donations from the memorial page')}>
                <DownloadActions />
              </Fieldset>
            </TabPanel>
          ) : null}
          <TabPanel>
            <Card
              setCanvas={setCanvas}
              previewIsLoading={previewIsLoading}
              setPreviewIsLoading={setPreviewIsLoading}
              memorialFund={memorialFund}
              deleteCard={() => deleteCard()}
            />
          </TabPanel>
          <TabPanel>
            <Fieldset legend={t('Content from order')}>
              <DeceasedInfo
                deceasedDetails={deceasedDetails}
              />
              <CeremonyInfo
                ceremonyDetails={ceremonyDetails}
                hideCeremonyInfo={!!memorialPage.hide_ceremony_info}
              />
              <MemorialOSAInfo
                memorialInfo={order.memorial}
              />
              <MemorialFundInfo
                memorialFundDetails={memorialFundDetails}
              />
            </Fieldset>
          </TabPanel>
        </Tabs>
      </div>
    </OrderTab>
  );
}

export default MemorialPageForm;
