import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Library/functions';
import Attributes from './ProductParts/Attributes';
import PriceEditor from './ProductParts/PriceEditor';
import ProductGallery from './ProductParts/ProductGallery';
import ProductProperties from './ProductParts/ProductProperties';
import QtyPicker from './ProductParts/QtyPicker';
import AddToCartButton from './ProductParts/AddToCartButton';
import BonusAmount from './ProductParts/BonusAmount';
import { canEditPriceProductNames } from 'Library/productHelpers';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import ExpenseProductWarning from './ProductParts/ExpenseProductWarning';
import { If } from 'Conditionals';
import ProductUserDropdown from './ProductParts/ProductUserDropdown';
import { useTranslation } from 'react-i18next';

const Product = ({ product, countryCode, orderInfo }) => {
  if (!product) {
    return (
      <div>
        <p>Produkten hittades ej.</p>
      </div>
    );
  }

  const canEditPrice = !!canEditPriceProductNames.includes(product.name);

  const { t } = useTranslation();
  const { attachWcProduct, showProductAddedNotice } = useOrderProducts();
  const [addedToCart, setAddedToCart] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [attributes, setAttributes] = useState(product.defaultAttributes);
  const [priceDecimals, setPriceDecimals] = useState(0);
  const [mainCategory, setMainCategory] = useState(null);
  const [price, setPrice] = useState(formatCurrency(product.price, null, countryCode, orderInfo?.order?.currency));
  const [quantity, setQuantity] = useState(1);
  const [variationId, setVariationId] = useState(0);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setVariationId(getVariationId);
    setMainCategory(getMainCategory);
    setPriceDecimals(getPriceDecimals);
  }, []);

  useEffect(() => {
    setPrice(getPrice);
  }, [variationId]);

  /*
   *--------------------------------------------------------------------------
   * Variation & attributes
   *--------------------------------------------------------------------------
   */
  const attrUpdate = (data) => {
    const attr = attributes;
    const index = attr.findIndex(({ name }) => name === data.name);
    if (index > -1) {
      if (data.option === '') {
        attr.splice(index, 1);
      } else {
        attr[index] = data;
      }
    } else {
      if (data.option !== '') {
        attr.push(data);
      }
    }

    setAttributes(attr);
    setVariationId(getVariationId());
  };

  const getVariationId = () => {
    if (!product.attributes || product.attributes.length === 0 || attributes.length === 0 || attributes.length !== product.attributes.length) {
      return 0;
    }

    const variation = product.variations.find(variation => {
      const found = [];
      for (const selectedAttr of attributes) {
        const hasAttr = variation.attributes.find(({ name, option }) => {
          return name === selectedAttr.name && option === selectedAttr.option;
        });

        found.push(!!hasAttr);
      }

      return found.every(item => item === true);
    });

    return (variation) ? variation.id : 0;
  };

  const getCurrentVariation = () => {
    if (variationId === 0) {
      return null;
    }

    return product.variations.find(({ id }) => id === variationId);
  };

  /*
   *--------------------------------------------------------------------------
   * Price - getters for updateing price related state
   *--------------------------------------------------------------------------
   */
  const getPrice = () => {
    const variation = getCurrentVariation();
    const currentProduct = (variation) || product;

    if (parseInt(currentProduct.price) === 0) {
      return t('Separate price per agreement');
    }

    return currentProduct.price;
  };

  const getPriceDecimals = () => {
    if (canEditPrice) {
      return 2;
    }

    return 0;
  };

  /*
   *--------------------------------------------------------------------------
   * Add to cart related functions
   *--------------------------------------------------------------------------
   */

  /**
   * Add to cart
   */
  const addProduct = () => {
    let id = product.id;

    // If variation IS selected use variationId as id.
    if (variationId) {
      id = variationId;
    }

    const productPrice = (price === t('Separate price per agreement')) ? 0 : price;

    attachProduct(id, {
      quantity,
      user_id: userId,
      price: productPrice
    });
  };

  const attachProduct = async (productId, data) => {
    setAddingToCart(true);

    await attachWcProduct(productId, data);

    setAddingToCart(false);
    setAddedToCart(true);

    showProductAddedNotice(product.name);
  };

  /*
   *--------------------------------------------------------------------------
   * Misc functions
   *--------------------------------------------------------------------------
   */

  const getMainCategory = () => {
    if (!product.categories || product.categories.length === 0) {
      return null;
    }

    const term = product.categories.find(term => term.parent !== null);

    return (term) || product.categories.find(term => term.parent === null);
  };

  return (
    <div className='single-product'>
      <div className='single-product__wrapper'>

        <ProductGallery
          product={product}
          variationId={variationId}
        />

        <div className='single-product__content'>

          <h4 className='single-product__category sub-heading'>{mainCategory ? mainCategory.name : ''}</h4>
          <h3 className='single-product__title'>{product.name}</h3>
          <span className='single-product__price'>&nbsp;&nbsp;&nbsp;{isNaN(price) ? price : formatCurrency(price, priceDecimals, countryCode, orderInfo?.order?.currency)}</span>
          <If condition={!!product.usp}>
            <span className='single-product__usp'>
              &nbsp;&nbsp;&nbsp;<span>{product.usp}</span>
            </span>
          </If>

          <div className='single-product__actions'>
            <Attributes
              type={product.type}
              attributes={product.attributes}
              defaultAttributes={product.defaultAttributes}
              update={attrUpdate}
            />

            <PriceEditor
              canEditPrice={canEditPrice}
              price={price}
              setPrice={setPrice}
            />

            <QtyPicker
              mainCategory={mainCategory}
              quantity={quantity}
              setQuantity={setQuantity}
            />

            <ProductUserDropdown
              product={product}
              userId={userId}
              onChange={value => setUserId(value)}
            />

            <AddToCartButton
              product={product}
              addedToCart={addedToCart}
              addingToCart={addingToCart}
              variationId={variationId}
              addProduct={addProduct}
            />
          </div>

          <ExpenseProductWarning
            addedToCart={addedToCart}
            product={product}
          />
          <br />

          <BonusAmount
            price={price}
            product={product}
            countryCode={countryCode}
            orderInfo={orderInfo}
          />

          <ProductProperties properties={product.properties} />
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object,
  countryCode: PropTypes.string,
  orderInfo: PropTypes.object
};

export default Product;
