import React, { useState } from 'react';
import store from '../../stores/RootStore';
import ReactSVG from 'react-svg';
import useLoggedTimes from '../../hooks/useLoggedTimes';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

const SendReport = () => {
  const { t } = useTranslation();
  const { sendReport } = useLoggedTimes();
  const [email, updateEmail] = useState('');
  const [loading, updateLoading] = useState(false);

  const submit = async () => {
    updateLoading(true);
    const { status } = await sendReport({ email });
    updateLoading(false);

    if (status === 'ok') {
      // Rapporten skickades till %s
      store.noticeStore.showNotice(sprintf(t('The report was sent to %s'), `<strong>${email}</strong>`));

      updateEmail('');
    }
  };

  return (
    <form
      className='send-report'
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
    >
      <input
        className='send-report__email'
        placeholder={t('Send by e-mail')}
        type='email'
        value={email}
        disabled={loading}
        onChange={({ target }) => updateEmail(target.value)}
      />
      <button
        className='send-report__button'
        disabled={email === '' || loading}
        type='submit'
      >
        <ReactSVG src='/icons/icon-send.svg' />
        {t('Send')}
      </button>
    </form>
  );
};

export default SendReport;
