import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import UserListGroup from './UserListGroup';
import Spinner from '../../Spinner';
import useInvitations from 'ReduxHooks/useInvitations';
import ReactSVG from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'ReduxActions/invitations';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { LoadingButton, Input } from 'FormElements';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

/**
 * @needst10ns
 */
function UserList ({
  order,
  isLoading = false,
  hasActiveQueue = false
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { users } = useSelector(state => state.invitations);
  const { userToAssign } = useSelector(state => state.orderAssigner);
  const { invitations, addToQueue } = useInvitations();
  const debouncedTerm = useCallback(debounce(s => searchUsers(s), 300), []);
  const { t } = useTranslation();

  const updateSearchTerm = (s = '') => {
    setSearchTerm(s);
    debouncedTerm(s);

    if (!s) {
      formRef.current.reset();
    }
  };

  async function searchUsers (s) {
    await dispatch(getUsers(order, s));
  }

  async function loadMoreUsers () {
    setIsLoadingMore(true);
    await dispatch(getUsers(order, '', users.length));
    setIsLoadingMore(false);
  }

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  const filteredUsers = users.filter(user => !invitations.find(({ id }) => id === user.id));
  const localUsers = filteredUsers.filter(({ in_location: local }) => local);
  const nonLocalUsers = filteredUsers.filter(({ in_location: local }) => !local);

  function addAllLocalUsersToQueue () {
    localUsers.forEach(user => addToQueue(user));
  }

  return (
    <div className={classNames('user-assigner__user-list', { 'is-loading': isLoadingMore })}>
      <div className='user-assigner__user-list__search'>
        <form
          ref={formRef}
          onSubmit={e => e.preventDefault()}
        >
          <Input
            className='user-assigner__user-list__search__field'
            onChange={updateSearchTerm}
            value={searchTerm}
            placeholder={t('Search advisors...')}
          />
          <If condition={!!searchTerm}>
            <button
              className='clear-button'
              role='button'
              onClick={() => updateSearchTerm()}
            >
              <ReactSVG src='/icons/icon-close-black.svg' />
            </button>
          </If>
        </form>
      </div>
      <div className='user-assigner__user-list__group' data-cy='ua_local_users_list'>
        {searchTerm && !filteredUsers.length ? (
          <p style={{ margin: '40px 0 20px' }}>{t('No results found.')}</p>
        ) : (
          <>
            <UserListGroup
              order={order}
              users={localUsers}
              hasActiveQueue={hasActiveQueue}
              local
            />

            <If condition={localUsers.length > 0}>
              <div className='user-assinger__user-list__add-all'>
                <button
                  disabled={hasActiveQueue || userToAssign}
                  className='btn icon'
                  onClick={() => addAllLocalUsersToQueue()}
                >
                  <ReactSVG src='/icons/icon-plus-circle.svg' />
                  <span>{t('Add all local advisors')} ({localUsers.length})</span>
                </button>
              </div>
            </If>
          </>
        )}
      </div>
      <div className='user-assigner__user-list__group'>
        <UserListGroup
          order={order}
          users={nonLocalUsers}
          hasActiveQueue={hasActiveQueue}
        />
      </div>
      <LoadingButton
        onClick={() => loadMoreUsers()}
        isLoading={isLoadingMore}
      >
        {t('Load more')}
      </LoadingButton>
    </div>
  );
}

UserList.propTypes = {
  order: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  hasActiveQueue: PropTypes.bool
};

export default UserList;
