import API from '../../stores/API';

export const SET_RECORDS = 'SET_RECORDS';
export const SET_IS_LOADING_RECORDS = 'SET_IS_LOADING_RECORDS';
export const SET_HAS_LOADED_RECORDS = 'SET_HAS_LOADED_RECORDS';
export const SET_IS_ADDING_RECORD = 'SET_IS_ADDING_RECORD';
export const SET_RECORD = 'SET_RECORD';
export const SET_ACCESS_LOG = 'SET_ACCESS_LOG';
export const SET_IS_LOADING_ACCESS_LOG = 'SET_IS_LOADING_ACCESS_LOG';

function recieveRecords (payload) {
  return {
    type: SET_RECORDS,
    payload
  };
}

function recieveRecord (payload) {
  return {
    type: SET_RECORD,
    payload
  };
}

function setIsLoadingRecords (payload) {
  return {
    type: SET_IS_LOADING_RECORDS,
    payload
  };
}

function setHasLoadedRecords (payload) {
  return {
    type: SET_HAS_LOADED_RECORDS,
    payload
  };
}

function setIsAddingRecord (payload) {
  return {
    type: SET_IS_ADDING_RECORD,
    payload
  };
}

function setAccessLog (payload) {
  return {
    type: SET_ACCESS_LOG,
    payload
  };
}

function setIsLoadingAccessLog (payload) {
  return {
    type: SET_IS_LOADING_ACCESS_LOG,
    payload
  };
}

export function resetRecords () {
  return async (dispatch) => {
    dispatch(setHasLoadedRecords(false));
    dispatch(setAccessLog([]));
    return dispatch(recieveRecords([]));
  };
}

export function getRecords (customerId) {
  return async (dispatch) => {
    dispatch(setIsLoadingRecords(true));
    const { data } = await API.GET(`/medicalrecords/${customerId}?limit=0`);
    dispatch(setHasLoadedRecords(true));
    dispatch(setIsLoadingRecords(false));
    return dispatch(recieveRecords(data));
  };
}

export function addRecord (customerId, record) {
  return async (dispatch) => {
    dispatch(setIsAddingRecord(true));
    const { data } = await API.POST(`/medicalrecords/${customerId}`, record);
    dispatch(recieveRecord(data));
    dispatch(setHasLoadedRecords(true));
    return dispatch(setIsAddingRecord(false));
  };
}

export function updateRecord (record) {
  return async (dispatch) => {
    dispatch(setIsAddingRecord(true));
    const { data } = await API.PUT(`/medicalrecords/${record.customer_id}/${record.id}`, record);
    dispatch(recieveRecord(data));
    dispatch(setHasLoadedRecords(true));
    return dispatch(setIsAddingRecord(false));
  };
}

export function getAcccessLog (customerId) {
  return async (dispatch) => {
    dispatch(setIsLoadingAccessLog(true));
    const { data } = await API.GET(`/logrequests/${customerId}`);
    dispatch(setAccessLog(data));
    return dispatch(setIsLoadingAccessLog(false));
  };
}
