import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableRow = ({ children, className = '' }) => (
  <tr className={classNames('trow', className)}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

export default TableRow;
