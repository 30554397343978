import React from 'react';
import ReactSVG from 'react-svg';
import useAppState from '../Redux/hooks/useAppState';
import { useTranslation } from 'react-i18next';

function MenuActionIcon () {
  const { t } = useTranslation();
  const { toggleMenu, appState } = useAppState();
  const isOpen = appState.menuState === 'open';
  const iconPath = (isOpen) ? '/icons/icon-close-menu.svg' : '/icons/icon-menu-mobile.svg';
  const label = (isOpen) ? t('Close') : t('Menu');

  return (
    <button role='button' className='topbar__action-icon-button topbar__menu-icon-button' onClick={() => toggleMenu()}>
      <ReactSVG src={iconPath} />
      <div className='label'>{label}</div>
    </button>
  );
}

export default MenuActionIcon;
