import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';

function useOrderOffers () {
  const { order, orderActionCallback } = useSingleOrder();

  function getActiveOffer () {
    if (!order.offers.length) {
      return null;
    }

    const mostRecent = [...order.offers].pop();

    return (!mostRecent.is_order_confirmation && !mostRecent.confirmed_at) ? mostRecent : null;
  }

  function getOffers (isOrderConfirmation = false) {
    return order.offers.filter(offer => isOrderConfirmation === offer.is_order_confirmation);
  }

  function hasConfirmedOffer () {
    return getOffers().filter(offer => !!offer.confirmed_at).length;
  }

  async function confirm (offerId) {
    const response = await API.POST(`/offer/confirm/${offerId}`);

    return orderActionCallback(response);
  }

  return {
    confirm,
    getOffers,
    getActiveOffer,
    hasConfirmedOffer,
    hasOffers: order.offers.length > 0
  };
}

export default useOrderOffers;
