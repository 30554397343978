import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoryRow from '../CategoryRow';
import Spinner from '../Spinner';
import InvoiceOptions from '../InvoiceOptions';
import { getSingleCategory } from 'ReduxActions/products';
import OrderProducts from './OrderProducts';
import { getTombstoneOrderTermID, getLawOrderTermID, getTherapyOrderTermID, getTherapyOrderStripeTermID, getCeremonyOrderTermID } from 'Library/OrderHelperFuntions';
import { filterNonInvoiceableProducts, orderHasInvoicedAllInvoiceableProducts } from 'Library/ProductHelper';
import { formatCurrency } from 'Library/functions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import OrderTab from './OrderTab';
import Textarea from '../FormElements/Textarea';
import OrderBonus from '../OrderBonus';
import ProductsPackages from '../ProductsPackages';
import ChangeProducts from './ChangeProducts';
import OrderFees from '../OrderFees';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import { Notice } from 'Layout/index';
import OrderNonBillableProducts from './OrderNonBillableProducts';

function ProductsForm () {
  const { t } = useTranslation();
  const { order, updateField, isLawyerOrder, isTombstoneOrder, isFuneralOrder, isTherapyOrder, isCeremonyOrder, getCountryCode } = useSingleOrder();
  const { productCategory } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const nonBillableProductsList = order.products.filter(product => filterNonInvoiceableProducts(product));
  const [showPackages, setshowPackages] = useState(isFuneralOrder && !order.products.length);

  useEffect(() => {
    if (!isFuneralOrder) {
      let termId;
      switch (true) {
        case isLawyerOrder:
          termId = getLawOrderTermID(order);
          break;
        case isTombstoneOrder:
          termId = getTombstoneOrderTermID(order);
          break;
        case isTherapyOrder:
          if (order?.user?.has_stripe_connect) {
            termId = getTherapyOrderStripeTermID(order);
          } else {
            termId = getTherapyOrderTermID(order);
          }
          break;
        case isCeremonyOrder:
          termId = getCeremonyOrderTermID(order);
          break;
        default:
          termId = getLawOrderTermID(order);
          break;
      }
      dispatch(getSingleCategory(order.country_id, termId));
    }
  }, []);

  function isDisabled () {
    if (order.is_partially_invoiced) {
      return false;
    }

    return orderHasInvoicedAllInvoiceableProducts(order);
  }

  function showPackageSelector () {
    if (!['se'].includes(getCountryCode()) || !isFuneralOrder) {
      return false;
    }

    if (order.products.length) {
      return false;
    }

    return showPackages;
  }

  return (
    <OrderTab
      tab='products'
      header={t('Products')}
      afterTasks={(
        <>
          <div className='row'>
            <Textarea
              className='col-12'
              value={order.products_note || ''}
              onChange={value => updateField('products_note', value)}
              label={t('Other')}
              data-cy='order.products_note'
              floatingLabel
              rows={10}
            />
          </div>

          {isFuneralOrder ? (
            <div className='products-form__products-picker'>
              <h3>{t('Selection')}</h3>
              <ChangeProducts />
            </div>
          ) : (
            <div className='product-table category-table'>
              <div className='table-header'>
                <div className='col category-col'><span className='label'>{t('Category')}</span></div>
                <div className='col action-col'><span className='label'> </span></div>
              </div>

              <If condition={isDisabled()}>
                <Notice>
                  <h4>{t('NB!')}</h4>
                  <p>{t('It is not currently possible to add products as all products have been invoiced.')}</p>
                  <p>{t('If you wish to add more products to the order you can mark the order as partially invoiced.')}</p>
                </Notice>
              </If>

              {!productCategory ? (
                <Spinner />
              ) : (
                <CategoryRow
                  tombstoneOrder={isTombstoneOrder}
                  lawyerOrder={isLawyerOrder}
                  category={productCategory}
                  isDisabled={isDisabled()}
                />
              )}
            </div>
          )}
        </>
      )}
    >
      <>
        {showPackageSelector() ? (
          <ProductsPackages
            countryCode={getCountryCode()}
            onChange={() => setshowPackages(false)}
          />
        ) : (
          <div className='products-form'>
            <OrderProducts />

            <If condition={nonBillableProductsList.length}>
              <div className='order-non-billable-products'>
                <h4>{t('The following products will not be inoviced:')}</h4>
                <OrderNonBillableProducts />
              </div>
            </If>

            <OrderFees />
            <p><strong>{t('Total')}: {formatCurrency(order.total, null, order.country.code, order?.currency)}</strong></p>

            <OrderBonus />

            <InvoiceOptions />
          </div>
        )}
      </>
    </OrderTab>
  );
}

export default ProductsForm;
