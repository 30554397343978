import { SET_TASK } from '../actions/tasks';

const initialState = {
  task: null
};

export default function tasks (state = initialState, { type, payload }) {
  switch (type) {
    case SET_TASK: {
      return {
        ...state,
        task: payload
      };
    }

    default:
      return state;
  }
}
