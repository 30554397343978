import React from 'react';
import PropTypes from 'prop-types';
import AttributePicker from './AttributePicker';

const Attributes = ({
  type,
  attributes,
  defaultAttributes,
  update
}) => {
  if (type !== 'variable' || attributes.length === 0) {
    return null;
  }

  const getDefaultValue = (currentAttr) => {
    const defaultAttr = defaultAttributes.find(({ name }) => name === currentAttr.name);
    return defaultAttr ? defaultAttr.option : '';
  };

  return (
    <div className='single-product__attributes'>
      {attributes.map((attr) => (
        <AttributePicker
          key={attr.name}
          defaultValue={getDefaultValue(attr)}
          attr={attr}
          index={attr.position}
          classes={'single-product__select'}
          update={update}
        />
      ))}
    </div>
  );
};

Attributes.propTypes = {
  type: PropTypes.string,
  attributes: PropTypes.array,
  defaultAttributes: PropTypes.array,
  update: PropTypes.func
};

export default Attributes;
