import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageListItem from './ImageListItem';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { blobToFile } from 'Library/ImageProcessorHelpers';

const ImageList = ({ isCardImageList = false }) => {
  const { updateField, order } = useSingleOrder();
  const { defaultImages, attachImage } = useMemorialPage();
  const imageKey = (key) => isCardImageList ? `card${key}` : key;
  const name = isCardImageList ? 'card-bg' : 'chosen-bg';
  const className = isCardImageList ? 'memorial-page-images__card-bg-images' : 'memorial-page-images__bg-images';
  const key = (isCardImageList) ? 'wordpress_card_bg_image_id' : 'wordpress_card_image_id';

  async function setWPImage ({ id, src }) {
    await updateField(`memorial_page.${key}`, id);
    const blobName = `wordpress_background_${id}`;
    const cacheBustingSrc = src + '?not-from-cf-cache-please';

    const blob = await fetch(cacheBustingSrc).then(r => r.blob());
    const file = blobToFile(blob, blobName);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', 'WordPress_Image');
    await attachImage(formData);
  }

  return (
    <ul className={classNames('image-list memorial-page-images__list', className)}>
      {defaultImages.map((image, index) => (
        <ImageListItem
          key={imageKey(image.id)}
          id={imageKey(image.id)}
          image={image}
          index={index}
          wpBgImage={order.memorial_page[key]}
          setWpBgImage={image => setWPImage(image)}
          name={name}
        />
      ))}
    </ul>
  );
};

ImageList.propTypes = {
  isCardImageList: PropTypes.bool
};

export default ImageList;
