import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';
import LoggedTime from './LoggedTime';
import LoggedTimeSummary from './LoggedTimeSummary';
import CreateLoggedTime from './CreateLoggedTime';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useLoggedTimes from '../../hooks/useLoggedTimes';

const LoggedTimeList = () => {
  const { order } = useSingleOrder();
  const { all, create, update, destroy, times } = useLoggedTimes();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasLoadedTimes, setHasLoadedTimes] = useState(false);

  useEffect(() => {
    if (hasLoadedTimes || isLoading) {
      return;
    }

    loadLoggedTimes();
  }, [order.id]);

  const persist = async (data = {}, id = null) => {
    setIsUpdating(true);

    if (id) {
      await update(id, data);
    } else {
      await create(data);
    }

    setIsUpdating(false);
  };

  const remove = async id => {
    setIsUpdating(true);
    await destroy(id);
    setIsUpdating(false);
  };

  async function loadLoggedTimes () {
    setIsLoading(true);
    await all();
    setIsLoading(false);
    setHasLoadedTimes(true);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classNames('logged-time-list', { updating: isUpdating })}>
      <LoggedTimeSummary
        times={times}
      />

      <CreateLoggedTime
        persist={persist}
      />

      {times.map(loggedTime => (
        <LoggedTime
          key={`${loggedTime.id}${loggedTime.resource_id}`}
          loggedTime={loggedTime}
          persist={persist}
          remove={remove}
          updating={isUpdating}
        />
      ))}
    </div>
  );
};

export default LoggedTimeList;
