import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ROLES } from 'ReduxActions/users';
import { translateRole } from 'Library/UserRoles';

const RolesDropdown = ({ user, onChange }) => {
  const roles = getRoles();

  function getRoles () {
    return Object.values(ROLES).map(role => {
      return {
        label: translateRole(role),
        value: role
      };
    });
  }

  function getSelectedRoles () {
    return roles.filter(({ value }) => user.roles.includes(value));
  }

  const update = values => {
    values = values || [];
    const roles = values.map(({ value }) => value);

    onChange(roles);
  };

  return (
    <Select
      id='role-select'
      options={roles}
      defaultValue={getSelectedRoles()}
      onChange={update}
      placeholder='-- Välj roller --'
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      isClearable
      isMulti
    />
  );
};

RolesDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default RolesDropdown;
