export function getTranslatedTerm (term, locale = 'se') {
  return availableTerms[term][locale];
}

const availableTerms = {
  coffins: {
    se: 'kistor',
    dk: 'kister',
    no: 'kister',
    fi: 'arkut'
  },
  urns: {
    se: 'urnor',
    dk: 'urner',
    no: 'urner',
    fi: 'uurnat'
  }
};
