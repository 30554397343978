import React from 'react';
import PropTypes from 'prop-types';
import { If } from 'Components/Conditionals';
import ReactSVG from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Pagination = ({ currentPage, setCurrentPage, numberOfPages, isAtTop = false }) => {
  const { t } = useTranslation();
  return (
    <If condition={numberOfPages > 1}>
      <div className={classNames('pagination listing-pagination', {
        'at-top': isAtTop
      })}
      >
        <div className={classNames('pagination__item pagination__item--prev', {
          disabled: currentPage < 2
        })}
        >
          <button className='pagination__link' onClick={() => setCurrentPage(currentPage - 1)}>
            <ReactSVG src='/icons/icon-arrow-left.svg' />
            {t('Back')}
          </button>
        </div>
        <p>{currentPage}/{numberOfPages}</p>

        <div className={classNames('pagination__item pagination__item--next', {
          disabled: currentPage === numberOfPages
        })}
        >
          <button className='pagination__link' onClick={() => setCurrentPage(currentPage + 1)}>
            {t('Next')}
            <ReactSVG src='/icons/icon-arrow-right.svg' />
          </button>
        </div>
      </div>
    </If>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  isAtTop: PropTypes.bool
};

export default Pagination;
