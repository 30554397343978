import { useDispatch, useSelector } from 'react-redux';
import { getOrderEvents } from '../actions/order';

function useOrderEvents () {
  const { events } = useSelector(state => state.order);
  const dispatch = useDispatch();

  async function getEvents () {
    dispatch(getOrderEvents());
  }

  return {
    events,
    getEvents
  };
}

export default useOrderEvents;
