import React from 'react';
import Dropzone from 'react-dropzone';
import store from '../stores/RootStore';
import Spinner from './Spinner';
import PropTypes from 'prop-types';

const types = [
  'image/png',
  'image/jpg',
  'image/gif',
  'image/jpeg',
  'image/svg+xml'
];

const NewFileUpload = ({ onChange, isLoading = false, mimeTypes = types, ...dropzoneProps }) => {
  /**
   * Get an error message detailing the names of rejected files.
   * @param {Array} files
   * @return {String}
   */
  function showRejectedFiles (files) {
    store.noticeStore.showWarningNotice(
      'Följande filer gick inte att ladda upp: ' +
      files.map(({ name }) => name).join(', ')
    );
  }

  /**
   * Upload files to the server.
   *
   * @param {Array} acceptedFiles
   * @param {Array} rejectedFiles
   */
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      showRejectedFiles(rejectedFiles);

      return;
    }

    onChange(acceptedFiles);
  };

  return (
    <div className="file-upload">
      {isLoading ? (
        <div className="loading__container">
          <Spinner/>
        </div>
      ) : null}

      <Dropzone
        accept={mimeTypes.join(',')}
        onDrop={onDrop}
        disabled={isLoading}
        activeClassName="dropzone-box__active"
        acceptClassName="dropzone-box__accepted"
        disabledClassName="dropzone-box__disabled"
        {...dropzoneProps}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: 'dropzone-box' })}>
              <input {...getInputProps()} />
              <p>Dra in filer hit eller klicka här för att välja från datorn.</p>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

NewFileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  mimeTypes: PropTypes.array
};

export default NewFileUpload;
