import API from './API';
import { getQueryParams } from 'Library/functions';

const appointments = {
  resourceUrl: '/appointments',

  list (params = {}) {
    return API.GET(`${this.resourceUrl}?include${getQueryParams(params)}`);
  },

  create (data) {
    return API.POST(`${this.resourceUrl}`, data);
  },

  update (id, data) {
    return API.PUT(`${this.resourceUrl}/${id}`, data);
  },

  destroy (id) {
    return API.DELETE(`${this.resourceUrl}/${id}`);
  }
};

export default appointments;
