import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

function NumActiveOrders ({ user }) {
  const { t } = useTranslation();

  if (!user.num_active_orders) {
    return null;
  }

  const text = user.num_active_orders === 1 ? t('1 ongoing order') : sprintf(t('%s ongoing orders'), user.num_active_orders);

  return (
    <div className='user-image-with-details__num-active-orders'>
      {text}
    </div>
  );
}

NumActiveOrders.propTypes = {
  user: PropTypes.object.isRequired
};

export default NumActiveOrders;
