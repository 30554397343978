import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import UserGroupedTimeSlots from './UserGroupedTimeSlots';
import { useTranslation } from 'react-i18next';

function UserTimeSlots ({ user }) {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation();

  return (
    <div className='user-time-slots'>
      <span className={classNames('time-slots__container', { 'is-open': isVisible })}>
        {user.schedule ? (
          <>
            <button
              className='btn icon icon-right'
              type='button'
              onClick={() => {
                setIsVisible(!isVisible);
              }}
            >
              <span>{isVisible ? t('Hide time slots') : t('Show time slots')}</span>
              <ReactSVG src='/icons/icon-chevron.svg' />
            </button>
            <UserGroupedTimeSlots
              schedule={user.schedule || []}
            />
            <UserGroupedTimeSlots
              schedule={user.schedule || []}
              type='phone'
              header={t('Phone and video meetings only')}
            />
          </>
        ) : (
          <span>{t('No available meeting times')}</span>
        )}
      </span>
    </div>
  );
}

UserTimeSlots.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserTimeSlots;
