import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ActiveInvitationListItem from './ActiveInvitationListItem';
import classNames from 'classnames';
import Spinner from '../../Spinner';
import { LoadingButton } from 'FormElements';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { animateOpacity } from 'Library/motion';
import { useTranslation } from 'react-i18next';

function ActiveInvitationList ({
  isLoading = false,
  hasActiveQueue = false,
  cancelQueue
}) {
  const { t } = useTranslation();
  const { queue } = useSelector(state => state.invitations);
  const [isCanceling, setIsCanceling] = useState(false);
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (isConfirmed) {
      cancel();
    }
  }, [isConfirmed]);

  const cancel = async () => {
    setIsCanceling(true);
    await cancelQueue();
    setShouldConfirm(false);
    setIsCanceling(false);
  };

  function queueExists () {
    if (!queue) {
      return false;
    }

    if (!Array.isArray(queue.queued) || !Array.isArray(queue.sent)) {
      return false;
    }

    return queue.queued.length || queue.sent.length;
  }

  if (!queueExists()) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div {...animateOpacity}>
        <div className='active-invitation-list'>
          <div className={classNames('user-list-group__table', { 'is-loading': isLoading })}>
            <Spinner loading={isLoading}/>

            <div className='user-list-group__table__header'>
              <div>{t('Sent to')} {queue.queued.length + queue.sent.length} {t('advisors')}</div>
              <div/>
              <div>{t('Status')}</div>
            </div>

            <div className='user-list-group__table__body'>
              {queue.sent.map((invitation, i) => (
                <ActiveInvitationListItem
                  invitation={invitation}
                  user={invitation.user}
                  key={`${i}-${invitation.user.id}`}
                />
              ))}
              {queue.queued.map(user => (
                <ActiveInvitationListItem
                  user={user}
                  key={user.id}
                />
              ))}
            </div>
          </div>

          <div className='active-invitation-list__actions'>
            {shouldConfirm ? (
              <div className='invitation-queue-message'>
                <span>
                  {t('Are you sure you want to cancel the queue?')}
                </span>
                &nbsp;
                &nbsp;
                <LoadingButton
                  onClick={() => {
                    setIsConfirmed(true);
                  }}
                  isLoading={isCanceling}
                >
                  {t('Cancel queue')}
                </LoadingButton>
                &nbsp;
                &nbsp;
                <button
                  className='btn white'
                  type='button'
                  disabled={isCanceling}
                  onClick={() => {
                    setShouldConfirm(false);
                    setIsConfirmed(false);
                  }}
                >{t('Do not cancel')}
                </button>
              </div>
            ) : (
              <LoadingButton
                onClick={() => {
                  setIsConfirmed(false);
                  setShouldConfirm(true);
                }}
                disabled={!hasActiveQueue}
              >
                {t('Cancel queue')}
              </LoadingButton>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

ActiveInvitationList.propTypes = {
  isLoading: PropTypes.bool,
  hasActiveQueue: PropTypes.bool,
  cancelQueue: PropTypes.func.isRequired
};

export default ActiveInvitationList;
