import { useDispatch, useSelector } from 'react-redux';
import { createTask, deleteTask, getTask, updateTask } from '../actions/tasks';
import API from '../../stores/API';
import { showNotice } from 'Library/notifications';
import i18n from 'i18next';

function useTask () {
  const dispatch = useDispatch();
  const { task } = useSelector(state => state.tasks);

  const get = async (id) => {
    return dispatch(getTask(id));
  };

  const create = async (data) => {
    const task = await dispatch(createTask(data));

    showNotice(i18n.t('The task was created'));

    return task;
  };

  const update = async (id, data) => {
    await dispatch(updateTask(id, data));

    showNotice(i18n.t('The task was updated'), 'success', 3500);
  };

  const destroy = async (id) => {
    await dispatch(deleteTask(id));

    showNotice(i18n.t('The task was deleated'));
  };

  const detachFile = async (id) => {
    await API.DELETE(`/files/delete/${id}`);
  };

  return {
    task,
    get,
    create,
    update,
    destroy,
    detachFile
  };
}

export default useTask;
