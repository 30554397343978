export default class TextHelpers {
  /**
  * Capitalize a string.
  *
  * @param string
  * @return {string}
  */
  static capitalize (string) {
    if (!string || string.length < 2) {
      return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
  * Convert a truthy value to a string representative.
  *
  * @param truthy
  * @return {string}
  */
  static convertBool (truthy) {
    return truthy ? 'Ja' : 'Nej';
  }
}
