import { findDarkFundLogo, findLightFundLogo } from '../../../library/MemorialPageHelpers';

const drawLogo = (
  ctx,
  {
    x,
    y,
    bottomMargin,
    width,
    textColor,
    memorialFund
  }
) => {
  // Since the parent canvas has to mount first, it's *possible* that the context may not be
  // injected by the time this render function runs the first time.
  if (!ctx) {
    return;
  }

  if (!memorialFund || !memorialFund.files || !memorialFund.files.length) {
    return;
  }

  // Set up logo
  let logo;

  if (memorialFund.files.length === 1) {
    logo = memorialFund.files[0].url;
  } else if (textColor === 'white') {
    logo = findLightFundLogo(memorialFund.files).url;
  } else {
    logo = findDarkFundLogo(memorialFund.files).url;
  }

  if (!logo) {
    return;
  }

  // Set up new image element for the logo.
  const image = new Image(); // eslint-disable-line no-undef

  /**
   * Draw logo to canvas
   *
   * @param {Number} imageAspectRatio
   */
  const drawImage = (imageAspectRatio) => {
    const height = width * imageAspectRatio;
    const computedY = y - bottomMargin - height;

    ctx.drawImage(image, x, computedY, width, height);
  };

  /**
   * To proparly calculate the aspect ratio for the svg logo in IE11
   * we need to append the image to the DOM and get the height and width
   * values from this appended element.
   */
  const drawImageIE11 = () => {
    document.body.appendChild(image);

    // Get the aspect ratio from the image element in the DOM
    const imageAspectRatio = image.offsetHeight / image.offsetWidth;

    drawImage(imageAspectRatio);

    // Remove the image element from the DOM
    document.body.removeChild(image);
  };

  /**
   * Image onload callback function.
   */
  const draw = () => {
    if (!image.complete) return;

    // Check if Internet Explorer 11 is being used
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      drawImageIE11();
      return;
    }

    const imageAspectRatio = image.height / image.width;
    drawImage(imageAspectRatio);
  };

  image.crossOrigin = 'anonymous';

  // Make sure the image is loaded then draw it out
  image.onload = draw;

  image.src = ''; // Clear the image src to init new onload when src is set, basically only needed to work in Safari.
  image.src = logo;
};

export default drawLogo;
