import Modal from 'react-modal';
import OrderCreationForm from './OrderCreationForm';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '../FormElements/LoadingButton';
import ReactSVG from 'react-svg';
import API from '../../stores/API';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showWarningNotice } from 'Library/notifications';
import { getEndpoint } from 'Library/OrderHelperFuntions';

function OrderCreator ({
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false,
  title
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function getOrderLink (id, notAssigned) {
    let uri = '/ordrar/order';

    switch (true) {
      case isLawyerOrder:
        uri = '/juridik';
        break;
      case isTombstoneOrder:
        uri = '/gravstenar';
        break;
      case isTherapyOrder:
        uri = '/therapy';
        break;
      case isCeremonyOrder:
        uri = '/ceremonies';
        break;
    }

    uri = `${uri}/${id}`;

    if (isAdmin && notAssigned) {
      uri += '?assign=1';
    }

    return uri;
  }

  const onSubmit = async (data) => {
    const endpoint = getEndpoint({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder });
    setIsLoading(true);
    const response = await API.POST(endpoint, data);
    setIsLoading(false);

    if (response.status === 'error') {
      showWarningNotice(`💔 ${response.errors.error_message}`);
      return;
    }

    const { id } = response.data;

    // Go directly to the order upon creation
    history.push(getOrderLink(id, data.not_assigned));
  };

  return (
    <div className='order-creator'>
      <LoadingButton
        data-cy='order-creator-button'
        onClick={() => setIsOpen(true)}
        text={title || t('Create new')}
      />
      <Modal
        className='order-creator__modal'
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel=''
      >
        <button
          role='button'
          className='close-modal'
          onClick={() => setIsOpen(false)}
        >
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>
        <>
          <h4>{title || t('Create new')}</h4>
          <OrderCreationForm
            isLawyerOrder={isLawyerOrder}
            isTombstoneOrder={isTombstoneOrder}
            isTherapyOrder={isTherapyOrder}
            isCeremonyOrder={isCeremonyOrder}
            isLoading={isLoading}
            isOpen={isOpen}
            onSubmit={onSubmit}
          />
        </>
      </Modal>
    </div>
  );
}

OrderCreator.propTypes = {
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool,
  title: PropTypes.string
};

export default OrderCreator;
