import React from 'react';
import TaskPrioHeader from './TaskPrioHeader';
import { generateId } from 'Library/functions';
import OrderTask from './OrderTask';
import useOrderTasks from 'Hooks/useOrderTasks';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

const TaskList = () => {
  const { t } = useTranslation();
  const { getSortedTasksByPrio, isMissingRequiredFields } = useOrderTasks();
  const sorted = getSortedTasksByPrio();

  const renderTask = (task) => (
    <OrderTask
      key={generateId()}
      task={task}
      canSendTasks={!isMissingRequiredFields()}
    />
  );

  return (
    <div className='task-list'>
      <If condition={sorted.highPrio.length}>
        <div className='task-list__sorted-section'>
          <TaskPrioHeader />
          {sorted.highPrio.map(renderTask)}
        </div>
      </If>

      <If condition={sorted.normalPrio.length}>
        <div className='task-list__sorted-section'>
          <TaskPrioHeader priority={2} />
          {sorted.normalPrio.map(renderTask)}
        </div>
      </If>

      <If condition={sorted.completed.length}>
        <div className='task-list__sorted-section completed'>
          <h4 className='task-prio-header completed'>{t('Completed')}</h4>
          {sorted.completed.map(renderTask)}
        </div>
      </If>
    </div>
  );
};

export default TaskList;
