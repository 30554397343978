import API from '../../stores/API';

export const SET_BONUS_AMOUNTS = 'SET_BONUS_AMOUNTS';
export const SET_REPRESENTATIVE_PRODUCTS = 'SET_REPRESENTATIVE_PRODUCTS';

function recieveBonusAmounts (payload) {
  return {
    type: SET_BONUS_AMOUNTS,
    payload
  };
}

function recieveRepresentativeProducts (payload) {
  return {
    type: SET_REPRESENTATIVE_PRODUCTS,
    payload
  };
}

export function getBonusAmounts (countryId) {
  return async (dispatch) => {
    const { data } = await API.GET(`/bonus-amounts/${countryId}?limit=0`);
    return dispatch(recieveBonusAmounts(data));
  };
}

export function saveBonusAmounts (countryId, bonusAmounts) {
  return async (dispatch) => {
    const { data } = await API.POST(`/bonus-amounts/${countryId}`, bonusAmounts);

    return dispatch(recieveBonusAmounts(data));
  };
}

export function getRepresentativeProducts (countryId) {
  return async (dispatch) => {
    const { data } = await API.GET(`/productTypes/representativeProducts/${countryId}?limit=0`);
    return dispatch(recieveRepresentativeProducts(data));
  };
}
