export const locales = [
  'da_dk',
  'sv_se',
  'de_de'
];

export const languagesList = {
  da_dk: 'Danish',
  sv_se: 'Swedish',
  de_de: 'German'
};
