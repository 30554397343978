import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { If } from 'Conditionals';
import { AnimatePresence, motion } from 'framer-motion';
import { modalMotionProps, overlayMotionProps } from 'Library/motion';

function MemorialPageModal ({
  children,
  closeModal,
  isOpen = false,
  header = '',
  wide = false
}) {
  return (
    <AnimatePresence>
      <If condition={isOpen}>
        <motion.div
          {...overlayMotionProps}
          className='upload-image-modal memorial-modal__base'
          onClick={closeModal}
        >
          <motion.div
            {...modalMotionProps}
            className='memorial-modal__wrapper'
            onClick={(e) => e.stopPropagation()}
          >
            <div className={classNames('memorial-modal__box', { wide })}>
              <div className='memorial-modal__header'>
                <h2 className='sub-heading'>
                  {header}
                </h2>

                <button className='close-button' onClick={closeModal}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                    <path d='M18.4 19.6c-.3 0-.5-.1-.7-.3l-7.8-7.8-7.8 7.8c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l7.8-7.8L.7 2.3C.3 1.9.3 1.3.7.9s1-.4 1.4 0l7.8 7.8L17.7.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-7.8 7.8 7.8 7.8c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z' fill='#192d5a'/>
                  </svg>
                </button>
              </div>

              {children}
            </div>
          </motion.div>
        </motion.div>
      </If>
    </AnimatePresence>
  );
}

MemorialPageModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  header: PropTypes.string,
  wide: PropTypes.bool
};

export default MemorialPageModal;
