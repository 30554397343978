class SupplierRelations {
  constructor (suppliers = []) {
    this.suppliers = suppliers || [];
  }

  getSupplierOfType (type, returnValue = '') {
    const supplier = this.suppliers.find(supplier => supplier.type === type);

    if (!supplier) {
      return null;
    }

    if (returnValue === 'id') {
      return supplier.id;
    }

    return supplier;
  }

  getSupplierById (id) {
    return this.suppliers.find(supplier => parseInt(supplier.id) === parseInt(id));
  }

  getSuppliersOfType (type, returnValue = '') {
    const suppliers = this.suppliers.filter(supplier => supplier.type === type);

    if (!suppliers) {
      return [];
    }

    if (returnValue === 'id') {
      return suppliers.map(({ id }) => parseInt(id));
    }

    return suppliers;
  }
}

export default SupplierRelations;
