import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';
import { getToken } from 'Components/localstorage';
export const SET_WILL = 'SET_WILL';
export const SET_WILLS = 'SET_WILLS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export function receiveWill (payload) {
  return {
    type: SET_WILL,
    payload
  };
}

function receiveWills (payload) {
  return {
    type: SET_WILLS,
    payload
  };
}

function receiveWillsPagination (payload) {
  return {
    type: SET_PAGINATION,
    payload
  };
}

export function setIsLoading (payload) {
  return {
    type: SET_IS_LOADING,
    payload
  };
}

export function getWill (id) {
  return async (dispatch) => {
    dispatch(receiveWill(null));

    const { errors, data } = await API.GET(`/wills/${id}`);

    if (errors && errors.code === 404) {
      alert('Will not found!'); // This will have to do for now...
      return;
    }

    dispatch(receiveWill(data));
  };
}

export function downloadWillFile (id, fileId) {
  return async (dispatch) => {
    // fix for staging api-address
    const host = window && window.location && window.location.host;
    let apiUrlParam = API_URL;

    if (host.includes('netlify.app') || host.includes('localhost')) {
      apiUrlParam = localStorage.getItem('backend') || 'https://stage.api.lavendla.com/v1';
    }

    return fetch(apiUrlParam + `/wills/${id}/download-file/${fileId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken()
      },
      method: 'GET'
    })
      .then(response => response.blob())
      .then(response => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        return true;
      });
  };
}

export function getWills ({ search = '', page = 1, archived = false }) {
  return async (dispatch) => {
    let url = `/wills?limit=30&page=${page}`;

    if (archived) {
      url += '&archived=true';
    }
    if (search) {
      url += `&search=${search}`;
    }

    const { data, status, errors, meta = {} } = await API.GET(url);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(receiveWillsPagination(meta.pagination || null));
    dispatch(receiveWills(data));
  };
}

export function persistWill () {
  return async (dispatch, getState) => {
    let response;
    const { wills } = getState();
    const will = { ...wills.will };

    if (!will.id) {
      response = await API.POST('/wills', will);
    } else {
      response = await API.PUT(`/wills/${will.id}`, will);
    }

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);
      return response;
    }

    if (will.newFiles) {
      response = await API.POST(`/wills/${response.data.id}/attach-files`, will.newFiles, false);
    }

    parseResponseAndUpdateWill(response, dispatch);

    return response;
  };
}

export function deleteWill (id) {
  return async () => {
    await API.DELETE(`/wills/${id}`);
  };
}

export function archiveWill (id) {
  return async (dispatch) => {
    const response = await API.POST(`/wills/${id}/archive`);

    parseResponseAndUpdateWill(response, dispatch);
  };
}

export function markWillPrinted (id) {
  return async (dispatch) => {
    const response = await API.POST(`/wills/${id}/mark-printed`);

    parseResponseAndUpdateWill(response, dispatch);
  };
}

export function markWillStored (id) {
  return async (dispatch) => {
    const response = await API.POST(`/wills/${id}/mark-stored`);

    parseResponseAndUpdateWill(response, dispatch);
  };
}

export function updateWill (data) {
  return async (dispatch) => {
    dispatch(receiveWill(data));
  };
}

function parseResponseAndUpdateWill (response, dispatch) {
  const { data, status, errors } = response;

  if (status !== 'ok') {
    showWarningNotice(errors.error_message);
    return;
  }

  dispatch(receiveWill(data));
}
