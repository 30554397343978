import React from 'react';
import PropTypes from 'prop-types';

function UserScheduleDashboardTime ({ scheduledTime }) {
  const { startTime, endTime } = scheduledTime;

  return (
    <div className='user-schedule-dashboard__day__time'>
      <div>{startTime} - {endTime}</div>
    </div>
  );
}

UserScheduleDashboardTime.propTypes = {
  scheduledTime: PropTypes.object.isRequired
};

export default UserScheduleDashboardTime;
