import { generateId } from '../../../library/functions';
import React from 'react';
import PropTypes from 'prop-types';

function CustomTooltip ({ active, payload, label }) {
  if (!active) {
    return null;
  }

  return (
    <div className='custom-tooltip'>
      <p className='label'>{label}</p>
      {payload.map(item => {
        const ordersSum = item.payload.orders;
        const x = ((item.value / ordersSum) * 100).toFixed(1);
        const renderX = (ordersSum === item.value) ? '' : `(${x}%)`;
        return <p key={generateId()} className='row' style={{ color: item.color }}>{`${item.name} : ${item.value} ${renderX}`}</p>;
      })}
    </div>
  );
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

export default CustomTooltip;
