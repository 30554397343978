import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import classNames from 'classnames';
import { formatProductPrice } from 'Library/functions';
import LoadingButton from './FormElements/LoadingButton';
import { Input, Textarea } from './FormElements';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { showNotice, showWarningNotice } from 'Library/notifications';
import ProductUserDropdown from './Funnel/ProductParts/ProductUserDropdown';
import { useTranslation, Trans } from 'react-i18next';

function ProductNonBillableRow ({ product }) {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { updateProduct, detachProduct, getImgSrc } = useOrderProducts();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(parseInt(product.quantity));
  const [price, setPrice] = useState(product.price);
  const [comment, setComment] = useState(product.comment);
  const [userId, setUserId] = useState(product.pivot.user_id);

  const saveProductData = async () => {
    const data = {
      quantity,
      price,
      comment,
      user_id: userId,
      meta: product.meta
    };

    if (data.quantity === '' || data.price === '') {
      showWarningNotice(t('Please provide a quanity and a price'));
      return;
    }

    setIsLoading(true);
    await updateProduct(product.pivot.product_resource_id, data);
    setIsLoading(false);
    setIsModalOpen(false);

    const { name } = product;

    showNotice(
      <Trans name={name}>
        The product <strong>{{ name }}</strong> has been updated
      </Trans>
    );
  };

  const removeProduct = async () => {
    setIsLoading(true);
    await detachProduct(product.pivot.product_resource_id);
    setIsLoading(false);

    const { name } = product;

    showNotice(
      <Trans name={name}>
        The product <strong>{{ name }}</strong> has been removed
      </Trans>
    );
  };

  const openModal = e => {
    e.preventDefault();

    setIsModalOpen(true);
  };

  const handleQuantityChange = (value) => {
    let q = value.replace(',', '.');
    q = q.replace(/[^0-9.]+/g, '');

    setQuantity(q);
  };

  return (
    <div className={classNames('product-row')}>
      <div className='col product-col'>
        <div className='thumbnail-container'>
          {typeof product.media[0] !== 'undefined'
            ? <img className='product-thumbnail' src={encodeURI(getImgSrc(product.media[0]))} />
            : <div className='no-thumbnail' />}
        </div>
        <div className='product-name'>
          {product.name}
        </div>
      </div>
      <div className='col quantity-col'>
        <span className='quantity'>× {product.quantity}</span>
      </div>
      <div className='col price-col'>
        <span className='price'>{formatProductPrice(product, order.country.code, order?.currency)}</span>
      </div>
      <div className='col comment-col'>
        {product.comment}
      </div>
      <div className='col action-col'>
        <a href='#' className='alter-product' onClick={openModal}>{t('Change')}</a>
        <a className={isLoading ? 'delete-product loading' : 'delete-product'} onClick={removeProduct}>
          <ReactSVG src='/icons/icon-remove.svg' />
        </a>
      </div>

      <Modal
        className='order-product-modal'
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel=''
      >
        <button className='close-modal' onClick={() => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        <h4>{t('Change')} {product.name}</h4>

        <form className='order-tab__form'>
          <div className='row'>
            <Input
              onChange={value => setPrice(value)}
              value={price}
              label={t('Price')}
              data-cy='product-row.price'
              className='col-12'
              floatingLabel
              required
            />
          </div>
          <div className='row'>
            <Input
              onChange={handleQuantityChange}
              value={quantity}
              label={t('Quantity')}
              data-cy='product-row.quantity'
              className='col-12'
              floatingLabel
              required
            />
          </div>
          <div className='row'>
            <Textarea
              onChange={value => setComment(value)}
              value={comment}
              data-cy='product-row.comment'
              label={t('Comment')}
              className='col-12'
              floatingLabel
              rows={5}
            />
          </div>
          <ProductUserDropdown
            userId={userId}
            product={product}
            onChange={value => setUserId(value)}
          />
          <div className='row'>
            <div className='col-12'>
              <LoadingButton onClick={saveProductData} isLoading={isLoading}>
                {t('Save')}
              </LoadingButton>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}

ProductNonBillableRow.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductNonBillableRow;
