import React from 'react';
import AttendeeForm from './Attendees/AttendeeForm';
import { useTranslation } from 'react-i18next';

function NewAttendee () {
  const { t } = useTranslation();
  return (
    <div className='new-attendee'>
      <h4>{t('New attendee')}</h4>
      <div className='new-attendee-form'>
        <AttendeeForm />
      </div>
    </div>
  );
}

export default NewAttendee;
