import { SET_USER_TO_ASSIGN, SET_SHOULD_REMOVE_ASSIGNED_USER } from '../actions/orderAssigner';

const initialState = {
  userToAssign: null,
  shouldRemoveAssignedUser: false
};

export default function orderAssigner (state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER_TO_ASSIGN: {
      return {
        ...state,
        userToAssign: payload
      };
    }

    case SET_SHOULD_REMOVE_ASSIGNED_USER: {
      return {
        ...state,
        shouldRemoveAssignedUser: payload
      };
    }

    default:
      return state;
  }
}
