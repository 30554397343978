import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getStarsUrl, getStarLabel } from 'Library/TrustpilotHelpers';
import { useTranslation } from 'react-i18next';

const TrustPilot = ({ user }) => {
  const { t } = useTranslation();

  if (!user || !user.reviews.length || user.number_of_reviews === '') {
    return null;
  }

  // Filter out reviews of type 'custom'
  user.reviews = user.reviews.filter(({ source }) => source !== 'custom');

  // Recalculate the number of reviews
  user.number_of_reviews = user.reviews.length;

  return (
    <div className='trustpilot__wrapper'>
      <p className='dashboard__section-small-heading'>{t('Your')} <strong>{user.reviews.length} {t('latest reviews')}</strong>  •  <span className='light'>{t('You have a total of')} {user.number_of_reviews} {t('reviews')}.</span></p>
      <div className='trustpilot__container'>
        {user.reviews.map(({ title, rating, review_date: date, review, customer_name: customer }, index) => {
          return (
            <div key={index} className='trustpilot__single'>
              <div className='trustpilot__single-header'>
                <img
                  src={getStarsUrl(rating)}
                  alt={getStarLabel(rating)}
                />
                <span className='date'>{moment(date).format('D MMMM YYYY')}</span>
              </div>
              <h3>{title}</h3>
              <p>{review}</p>
              <span className='customer'>{customer}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TrustPilot.propTypes = {
  user: PropTypes.object.isRequired
};

export default TrustPilot;
