import { isInvoiceTask } from 'Library/Tasks';
import PropTypes from 'prop-types';
import React from 'react';
import { Notice } from 'Layout';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const RequiredCatsWarning = ({ order, task = null }) => {
  const { t } = useTranslation();
  const { getCountryCode, isFuneralOrder } = useSingleOrder();

  function getRequiredTerms () {
    switch (getCountryCode()) {
      case 'dk':
        return ['Kørsel', 'Ilægning i kiste', 'Honorar', 'Kister'];

      case 'se':
        return ['Transport', 'Omhändertagande', 'Administration', 'Ceremonivärd', 'Kista'];
    }

    return [];
  }

  if (!isFuneralOrder) {
    return null;
  }

  if (!task || !isInvoiceTask(task)) {
    return null;
  }

  const requiredTerms = getRequiredTerms();
  if (!requiredTerms.length) {
    return null;
  }

  const productCategoriesNames = [];
  order.products.map(({ categories }) => categories.map(category => {
    productCategoriesNames.push(category.name);
  }));

  const filtered = requiredTerms.filter(requiredCat => !productCategoriesNames.includes(requiredCat));
  if (!filtered.length) {
    return null;
  }

  const message = filtered.length === 1
    ? t('The offer/invoice is missing a product in this category that is usually obligatory.')
    : t('The offer/invoice is missing a product in these categories that is usually obligatory.');

  return (
    <Notice heading={t('Did your forget something?')}>
      <p>{message}</p>
      <ul>
        {filtered.map((cat, i) => (
          <li key={i}>{cat}</li>
        ))}
      </ul>
    </Notice>
  );
};

RequiredCatsWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object
};

export default RequiredCatsWarning;
