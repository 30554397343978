import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

const Checkbox = ({
  label,
  onChange,
  checked = false,
  id = uniqueId('input_'),
  className = '',
  ...rest
}) => (
  <div className={classNames('input', 'type-checkbox', className)}>
    <label htmlFor={id}>
      <input
        id={id}
        type='checkbox'
        defaultChecked={!!checked}
        onChange={({ target }) => onChange(target.checked)}
        {...rest}
      />
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </label>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ])
};

export default Checkbox;
