import { getID } from 'Components/localstorage';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function useOrderArchivesHistory ({ history, isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder }) {
  const { loggedInUser, isAdmin, isLegalAdvisor, isFuneralAdvisor, isTombstoneAdvisor, isTherapist, isOfficiant } = useLoggedInUser();

  function getUserId () {
    if (isAdmin && !isFuneralAdvisor && !(isLawyerOrder || isTombstoneOrder)) {
      return -1;
    }

    if (isAdmin && !isLegalAdvisor && isLawyerOrder) {
      return -1;
    }

    if (isAdmin && !isTombstoneAdvisor && isTombstoneOrder) {
      return -1;
    }

    if (isAdmin && !isTherapist && isTherapyOrder) {
      return -1;
    }

    if (isAdmin && !isOfficiant && isCeremonyOrder) {
      return -1;
    }

    return getID();
  }

  function getHistoryParams () {
    const searchParams = new URLSearchParams(history.location.search);

    const params = {
      page: 1,
      status: [],
      locationIds: [],
      tagIds: [],
      competenceAreas: [],
      userId: getUserId(),
      search: searchParams.get('search') || '',
      favorite: loggedInUser.favorites_at_top || false,
      unpaid: false
    };

    Object.keys(params).forEach(key => {
      let value = searchParams.get(key);

      if (value) {
        if (['status', 'locationIds', 'tagIds', 'competenceAreas'].includes(key)) {
          value = value.split(',');
        }
        params[key] = value;
      }
    });

    return params;
  }

  function updateHistory (params) {
    const getParams = Object.entries({ ...getHistoryParams(), ...params })
      .map(([key, value]) => {
        value = (Array.isArray(value)) ? value.join(',') : value;

        return `${key}=${value}`;
      })
      .join('&');

    history.push(`${history.location.pathname}?${getParams}`);
  }

  return {
    getHistoryParams,
    updateHistory
  };
}

export default useOrderArchivesHistory;
