import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { LoadingButton } from 'FormElements';
import { getOrderTypeArchiveUrl } from 'Library/Orders';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showNotice } from 'Library/notifications';

function ArchiveButton ({ order }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { archive } = useSingleOrder();
  const resourceRoot = getOrderTypeArchiveUrl(order);
  const [isLoading, setIsLoading] = useState(false);

  async function archiveOrder () {
    if (!window.confirm(t('Are you sure you want to archive the order?'))) {
      return;
    }

    setIsLoading(true);
    const { status } = await archive(order);
    setIsLoading(false);

    if (status === 'ok') {
      showNotice(t('The order has been archived'));
      history.push(resourceRoot);
    }
  }

  // If the order isTherapyOrder and has status "passed", "paid_inquiry" or "invoiced" or "partially_invoiced" then show the button. If it's not a therapy order, then show the button if the status is "invoiced" or "partially_invoiced".
  if ((order.class === 'App\\TherapyOrder') && !['invoiced', 'partially_invoiced', 'passed', 'paid_inquiry', 'new_inquiry'].includes(order.status)) {
    return null;
  }

  if (!(order.class === 'App\\TherapyOrder')) {
    if (!['invoiced', 'partially_invoiced'].includes(order.status)) {
      return null;
    }
  }

  return (
    <LoadingButton
      isLoading={isLoading}
      className='archive-btn'
      onClick={() => archiveOrder()}
    >
      {t('Archive')}
    </LoadingButton>
  );
}

ArchiveButton.propTypes = {
  order: PropTypes.object
};

export default ArchiveButton;
