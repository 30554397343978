import { getCountries, setCountry, setCountrySettings, saveCountrySettings, getCountrySettings } from '../actions/countries';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function useCountries () {
  const dispatch = useDispatch();
  const { countries, country, countrySettings } = useSelector(({ countries }) => countries);
  const history = useHistory();

  const allowedCountries = ['se', 'dk'];

  const changeCountry = async (updatedCountry, redirect = false) => {
    await dispatch(setCountry(updatedCountry));

    if (redirect) {
      history.push('/');
    }
  };

  async function updateSettingsField (key, value) {
    dispatch(setCountrySettings({ ...countrySettings, ...{ [key]: value } }));
  }

  return {
    allowedCountries,
    country,
    countries,
    countrySettings,
    getCountrySettings: (id) => dispatch(getCountrySettings(id)),
    setCountrySettings: (countrySettings) => dispatch(setCountrySettings(countrySettings)),
    updateSettingsField,
    setCountry: changeCountry,
    saveCountrySettings: () => dispatch(saveCountrySettings()),
    fetchCountries: () => dispatch(getCountries())
  };
}

export default useCountries;
