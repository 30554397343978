import React from 'react';
import ReactSVG from 'react-svg';
import useSettings from 'ReduxHooks/useSettings';
import { useTranslation } from 'react-i18next';

function SearchActionIcon () {
  const { t } = useTranslation();
  const { getSetting, setSetting } = useSettings();

  function toggleSearchbar () {
    const open = getSetting('showSearchbar');

    setSetting({ showSearchbar: !open });

    if (!open) {
      document.body.classList.add('sidebar-open');
      document.body.classList.remove('sidebar-closed');
    }
  }

  return (
    <button className='topbar__action-icon-button topbar__search-icon-button' onClick={() => toggleSearchbar()}>
      <ReactSVG src='/icons/icon-search-blue.svg' />
      <div className='label'>{t('Search')}</div>
    </button>
  );
}

export default SearchActionIcon;
