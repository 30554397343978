// These are not translated as they will differ for the different markets
export default {
  se: [
    {
      name: 'Ceremoni med kista',
      description: '',
      products: [
        { id: 1328, name: 'Transport till ceremoni', quantity: 1 },
        { id: 62961, name: 'Administration, timdebitering', quantity: 3 },
        { id: 11804, name: 'Kistläggning', quantity: 1 },
        { id: 67467, name: 'Svepskjorta', quantity: 1 },
        { id: 68556, name: 'Ceremonivärd', quantity: 1 },
        { id: 101775, name: 'Hämtning och iordningställande av kista', quantity: 1 }
      ]
    },
    {
      name: 'Ceremoni med urna',
      description: '',
      products: [
        { id: 1328, name: 'Transport till ceremoni', quantity: 1 },
        { id: 62961, name: 'Administration, timdebitering', quantity: 3 },
        { id: 11804, name: 'Kistläggning', quantity: 1 },
        { id: 67467, name: 'Svepskjorta', quantity: 1 },
        { id: 98685, name: 'Ceremonivärd', quantity: 1 },
        { id: 96910, name: 'Transportkista', quantity: 1 },
        { id: 101775, name: 'Hämtning och iordningställande av kista', quantity: 1 }
      ]
    },
    {
      name: 'Direktkremation',
      description: '',
      products: [
        { id: 5200, name: 'Transport till kremation', quantity: 1 },
        { id: 62961, name: 'Administration, timdebitering', quantity: 1 },
        { id: 67471, name: 'Kistläggning', quantity: 1 },
        { id: 67467, name: 'Svepskjorta', quantity: 1 },
        { id: 96910, name: 'Transportkista', quantity: 1 },
        { id: 101775, name: 'Hämtning och iordningställande av kista', quantity: 1 }
      ]
    },
    {
      name: 'Skräddarsy själv',
      description: 'Börja välja produkter och tillval utan några förval',
      products: []
    }
  ],
  no: [
    {
      name: 'Personlig tilpasset',
      description: '',
      products: [
        { id: 1806, name: 'Bringing av tomkiste', quantity: 1 },
        { id: 1238, name: 'Ubehandlet kiste', quantity: 1 },
        { id: 1844, name: 'Nedlegging i kiste', quantity: 1 },
        { id: 1828, name: 'Overføring til seremoni', quantity: 1 },
        { id: 1306, name: 'Klargjøring til seremoni', quantity: 1 },
        { id: 3962, name: 'Vertskap til seremonien', quantity: 1 },
        { id: 1622, name: 'Programhefte', quantity: 1 },
        { id: 1869, name: 'Byråets honorar, avgiftsfri del', quantity: 1 },
        { id: 1870, name: 'Byråets honorar, avgiftspliktig del', quantity: 1 },
        { id: 1866, name: 'Rådgivningstimer', quantity: 1 },
        { id: 1480, name: 'Kistedekorasjon', quantity: 1 },
        { id: 4300, name: 'Blomsterbukett til kisten', quantity: 1 }
      ]
    },
    {
      name: 'Enkel gravferd sykehjemskapell',
      description: '',
      products: [
        { id: 1806, name: 'Bringing av tomkiste', quantity: 1 },
        { id: 1238, name: 'Ubehandlet kiste', quantity: 1 },
        { id: 1844, name: 'Nedlegging i kiste', quantity: 1 },
        { id: 1831, name: 'Overføring til krematorium', quantity: 1 },
        { id: 1869, name: 'Byråets honorar, avgiftsfri del', quantity: 1 },
        { id: 1870, name: 'Byråets honorar, avgiftspliktig del', quantity: 1 },
        { id: 1866, name: 'Rådgivningstimer', quantity: 1.5 },
        { id: 3615, name: 'Syning', quantity: 1 }
      ]
    },
    {
      name: 'Direkte til kremasjon',
      description: '',
      products: [
        { id: 1806, name: 'Bringing av tomkiste', quantity: 1 },
        { id: 1212, name: 'Kremasjonskiste', quantity: 1 },
        { id: 1844, name: 'Nedlegging i kiste', quantity: 1 },
        { id: 1831, name: 'Overføring til krematorium', quantity: 1 },
        { id: 2961, name: 'Byråets honorar', quantity: 1 }
      ]
    },
    {
      name: 'Gjør det selv',
      description: 'Velg produkter uten forhåndsvalg',
      products: []
    }
  ],
  dk: [
    {
      name: 'Klassisk Ceremoni',
      description: '',
      products: [
        { id: 13559, name: 'Afhenting', quantity: 1 },
        { id: 2867, name: 'Kørsel til ceremoni', quantity: 1 },
        { id: 2869, name: 'Kørsel fra ceremoni', quantity: 1 },
        { id: 2705, name: 'Ilægning i kiste', quantity: 1 },
        { id: 2816, name: 'Kiste Duet', quantity: 1 },
        { id: 2682, name: 'Deltagelse vid ceremoni', quantity: 1 },
        { id: 2673, name: 'Honorar', quantity: 4 },
        { id: 2676, name: 'Udgivelse af dødsannonce', quantity: 1 },
        { id: 2675, name: 'Udarbejdelse af dødsannonce', quantity: 1 },
        { id: 3315, name: 'Udarbejdelse af program/sanghæfte', quantity: 1 },
        { id: 3316, name: 'Tryk af program / sanghæfte', quantity: 1 }
      ]
    },
    {
      name: 'Lille Ceremoni',
      description: '',
      products: [
        { id: 13559, name: 'Afhentning', quantity: 1 },
        { id: 2869, name: 'Kørsel fra ceremoni', quantity: 1 },
        { id: 2705, name: 'Ilægning i kiste', quantity: 1 },
        { id: 2831, name: 'Ubehandlet kiste', quantity: 1 },
        { id: 2682, name: 'Deltagelse vid ceremoni', quantity: 1 },
        { id: 2673, name: 'Honorar', quantity: 4 }
      ]
    },
    {
      name: 'Uden Ceremoni',
      description: '',
      products: [
        { id: 13559, name: 'Afhentning till krematorium', quantity: 1 },
        { id: 2705, name: 'Ilægning i kiste', quantity: 1 },
        { id: 2831, name: 'Ubehandlet kiste', quantity: 1 },
        { id: 2673, name: 'Honorar', quantity: 1 }
      ]
    },
    {
      name: 'Lav selv',
      description: 'Vælg produkter uden forvalg',
      products: []
    }
  ],
  gb: [
    {
      name: 'Service at crematorium',
      description: '',
      products: [
        { id: 8611, name: 'Collection of the person who has died in-hours', quantity: 1 },
        { id: 8603, name: 'Care at our premises (15 days)', quantity: 1 },
        { id: 10978, name: "Doctor's paperwork fee", quantity: 1 },
        { id: 8703, name: 'New black hearse to chapel (25 miles / 2h)', quantity: 1 },
        { id: 10950, name: '4 pallbearers', quantity: 3 },
        { id: 9743, name: 'Tresco coffin', quantity: 1 },
        { id: 10874, name: 'Your funeral organiser - hourly rate', quantity: 6 },
        { id: 11235, name: 'Personal Funeral Organiser conducting', quantity: 1 }
      ]
    },
    {
      name: 'Church service with burial',
      description: '',
      products: [
        { id: 8611, name: 'Collection of the person who has died in-hours', quantity: 1 },
        { id: 8603, name: 'Care at our premises (15 days)', quantity: 1 },
        { id: 8703, name: 'New black hearse to chapel (25 miles / 2h)', quantity: 1 },
        { id: 11358, name: 'Driver and vehicle excess hours and mileage', quantity: 1 },
        { id: 10950, name: '4 pallbearers', quantity: 3 },
        { id: 8682, name: '4 pallbearers, 1 excess hour', quantity: 3 },
        { id: 9751, name: 'Leith coffin', quantity: 1 },
        { id: 10874, name: 'Funeral Organiser', quantity: 6 },
        { id: 11236, name: 'Personal Funeral Organiser conducting', quantity: 1 }
      ]
    },
    {
      name: 'Direct cremation',
      description: '',
      products: [
        { id: 8611, name: 'Collection of the person who has died in-hours', quantity: 1 },
        { id: 11988, name: 'Care at our premises (7 days)', quantity: 1 },
        { id: 11067, name: 'Crematorium fee: Croydon Crematorium', quantity: 1, price: 285 },
        { id: 10978, name: "Doctor's paperwork fee", quantity: 1 },
        { id: 10686, name: 'Older/Direct cremation hearse (25 miles / 2h)', quantity: 1 },
        { id: 10950, name: '2 pallbearers', quantity: 2 },
        { id: 11990, name: 'Cardboard cremation coffin', quantity: 1 },
        { id: 11989, name: 'Removal of Medical devices', quantity: 1 },
        { id: 10874, name: 'Advisor fees', quantity: 2 }
      ]
    },
    {
      name: 'Bespoke funeral',
      description: 'Customized',
      products: []
    }
  ]
};
