import UserDropdown from '../../UserDropdown';
import PropTypes from 'prop-types';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

const OTHER_USER_PRODUCTS = {
  '0401005-1': {
    label: 'Välj andra förrättningsman',
    selectParams: {
      lawyers: true
    }
  }
};

function ProductUserDropdown ({ product, onChange, userId = null }) {
  const data = OTHER_USER_PRODUCTS[product.sku];
  const id = uniqueId('user-dropdown');

  if (data === undefined) {
    return null;
  }

  return (
    <div className='product-user-dropdown'>
      <label htmlFor={id}>
        {data.label}
      </label>
      <UserDropdown
        id={id}
        isClearable
        user={userId}
        onChange={value => onChange(value)}
        {...data.selectParams}
      />
    </div>
  );
}

ProductUserDropdown.propTypes = {
  product: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.any
};

export default ProductUserDropdown;
