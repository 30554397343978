import { SET_LOCATIONS, SET_LOCATION_FILTERS } from '../actions/locations';

const initialState = {
  locations: [],
  locationFilters: {}
};

export default function locations (state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOCATIONS: {
      return {
        ...state,
        locations: payload
      };
    }

    case SET_LOCATION_FILTERS: {
      return {
        ...state,
        locationFilters: payload
      };
    }

    default:
      return state;
  }
}
