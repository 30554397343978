import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import UserScheduleDashboardTime from './UserScheduleDashboardTime';
import useMeetingTimes from 'Hooks/useMeetingTimes';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Toggle } from 'FormElements';
import { showWarningNotice } from 'Library/notifications';
import API from '../../../stores/API';

function UserScheduleDashboardDay ({ scheduledTimes, dayOfWeek }) {
  const { getDayOfWeek } = useMeetingTimes();
  const { t } = useTranslation();
  const { updateLoggedInUser } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);

  async function toggleDay (inactive) {
    setIsLoading(true);
    // yeah, legacy shit.
    scheduledTimes.forEach(async time => {
      await toggleTime(time.id, inactive);
    });

    setIsLoading(false);
  }

  const toggleTime = async (id, inactive) => {
    const { status, data, errors } = await API.PUT(`/users/schedule/${id}`, {
      inactive
    });

    if (status === 'error') {
      showWarningNotice(errors.error_message);
    }

    return updateLoggedInUser(data);
  };

  const isInactive = scheduledTimes.every(time => time.inactive);

  return (
    <div className='user-schedule-dashboard__day'>
      <h4 className='user-schedule-dashboard__day__header'>{getDayOfWeek(dayOfWeek)}
        <Toggle
          small
          label={t('Available')}
          isDisabled={isLoading}
          defaultChecked={!isInactive}
          onChange={checked => toggleDay(!checked)}
        />
      </h4>

      {scheduledTimes.length ? (
        scheduledTimes.map(scheduleTime => (
          <UserScheduleDashboardTime
            scheduledTime={scheduleTime}
            key={scheduleTime.id}
          />
        ))
      ) : (
        <span className='meeting-day-group__placeholder'>{t('No meeting times')}</span>
      )}
    </div>
  );
}

UserScheduleDashboardDay.propTypes = {
  scheduledTimes: PropTypes.array.isRequired,
  dayOfWeek: PropTypes.number.isRequired
};

export default UserScheduleDashboardDay;
