import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';
import { setCountry } from 'ReduxActions/countries';

export const SET_TASK = 'SET_TASK';

function recieveTask (payload) {
  return {
    type: SET_TASK,
    payload
  };
}

export function getTask (id) {
  return async (dispatch) => {
    dispatch(recieveTask(null));

    const { data, errors } = await API.GET(`/tasks/${id}?include=country`);

    if (errors && errors.code === 404) {
      alert('Task not found!'); // This will have to do for now...

      return;
    }

    dispatch(setCountry(data.country));
    dispatch(recieveTask(data));
  };
}

export function createTask (formData) {
  return async () => {
    const response = await API.POST('/tasks', formData, false);

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);

      return;
    }

    return response.data;
  };
}

export function updateTask (id, formData) {
  return async (dispatch) => {
    const response = await API.POST(`/tasks/${id}`, formData, false);

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);

      return;
    }

    dispatch(recieveTask(response.data));
  };
}

export function deleteTask (id) {
  return async () => {
    await API.DELETE(`/tasks/${id}`);
  };
}
