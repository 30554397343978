import React from 'react';
import UserImage from './UserImage';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useTranslation } from 'react-i18next';

function UserBar () {
  const { loggedInUser } = useLoggedInUser();
  const { t } = useTranslation();

  return (
    <div className='userbar'>
      <UserImage
        user={loggedInUser}
        className='userbar__image'
        width={40}
        height={40}
      />
      <p>
        <span className='userbar__site-name'>Lavendla Admin</span>
        <span><strong>{t('Hi')},</strong> {loggedInUser.name}!</span>
      </p>
    </div>
  );
}

export default UserBar;
