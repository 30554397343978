import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function renderOptions (options) {
  if (!options) {
    return null;
  }

  if (Array.isArray(options)) {
    return options.map((option, i) => (
      <option key={i} value={option}>{option}</option>
    ));
  }

  return Object.entries(options).map(([key, value]) => (
    <option key={key} value={key}>{value}</option>
  ));
}

const Select = ({
  onChange,
  label = null,
  value = null,
  defaultOption = null,
  options,
  id = uniqueId('select_'),
  className = '',
  floatingLabel = false,
  showRequiredIcon = false,
  ...rest
}) => {
  const additionalClasses = {
    'floating-label': floatingLabel,
    'has-required-icon': showRequiredIcon && !value
  };

  return (
    <div className={classNames('select', additionalClasses, className)}>
      {label ? (
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
      ) : null}
      <select
        defaultValue={value || ''}
        id={id}
        onChange={({ target }) => onChange(target.value)}
        {...rest}
      >
        {defaultOption && (<option value=''>{defaultOption}</option>)}
        {renderOptions(options)}
      </select>
      {showRequiredIcon && !value ? (
        <span className='input__icon-required'>
          <FontAwesomeIcon
            icon={['fal', 'exclamation-triangle']}
            color='#ea8b00'
            size='lg'
          />
        </span>
      ) : null}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  defaultOption: PropTypes.string,
  floatingLabel: PropTypes.bool,
  showRequiredIcon: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Select;
