import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { sv, da, nb, fi, enGB } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = ({ country }) => {
  const { t } = useTranslation();
  const countryCode = country ? country.code : 'se';
  const locale = {
    se: sv,
    dk: da,
    no: nb,
    gb: enGB,
    fi
  };

  const todaysDay = format(new Date(), 'ccc', { locale: locale[countryCode] });
  const todaysDate = format(new Date(), 'dd', { locale: locale[countryCode] });
  const formatedWeek = format(new Date(), 'ww', { locale: locale[countryCode] });
  const monthYear = format(new Date(), "MMMM ' ' yyyy", { locale: locale[countryCode] });

  return (
    <div className='mini-calendar__header'>
      <div className='mini-calendar__header-left'>
        <svg xmlns='http://www.w3.org/2000/svg' width='60' height='64' viewBox='0 0 60 64'>
          <defs>
            <linearGradient id='a' x1='6.055%' x2='93.945%' y1='0%' y2='100%'>
              <stop offset='0%' stopColor='#23A692' />
              <stop offset='100%' stopColor='#437783' />
            </linearGradient>
          </defs>
          <path fill='url(#a)' fillRule='evenodd' d='M1171.882 140c1.13 0 2.056.878 2.137 1.993l.006.16v3.469h-4.286v5.144l.006.161a2.147 2.147 0 002.137 1.993 2.148 2.148 0 002.143-2.154v-5.144h4.916c3.898 0 7.059 3 7.059 6.7V197.3c0 3.7-3.16 6.699-7.059 6.699h-45.882c-3.898 0-7.059-3-7.059-6.7V152.32c0-3.7 3.16-6.699 7.059-6.699h4.915v-3.469c0-1.189.96-2.153 2.144-2.153 1.13 0 2.055.878 2.137 1.993l.005.16v3.469h-4.286v5.144l.007.161a2.147 2.147 0 002.137 1.993 2.148 2.148 0 002.143-2.154l-.001-5.144h27.479v-3.469c0-1.189.96-2.153 2.143-2.153z' transform='translate(-1126 -140)' />
        </svg>
        <span className='mini-calendar__header-day'>{todaysDay}</span>
        <span className='mini-calendar__header-date'>{todaysDate}</span>
      </div>
      <div className='mini-calendar__header-right'>
        <div className='mini-calendar__header-week-month'>
          <span>{t('Week')} {formatedWeek}</span>
          <span>&nbsp;&nbsp;•&nbsp;&nbsp;{monthYear}</span>
        </div>
        <div className='mini-calendar__next-day'>
          <Link to='/kalender' className='forward-link'><span>{t('Tomorrow')}</span></Link>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  country: PropTypes.object
};

export default Header;
