import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserCompetenceAreas ({ order, user }) {
  if (order.class !== 'App\\Order' || !user.competence_areas.length) {
    return null;
  }

  const userAreas = order.competence_areas.filter(({ id }) => user.competence_areas.includes(id));

  return (
    <div className='user-competence-areas'>
      {userAreas.map(({ id, name }) => (
        <div
          key={id}
          className='user-competence-areas__area'
        >
          {name}
          <FontAwesomeIcon
            icon={['fal', 'check']}
            color='#437783'
          />
        </div>
      ))}
    </div>
  );
}

UserCompetenceAreas.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default UserCompetenceAreas;
