import React, { useState } from 'react';
import Imgix from 'react-imgix';
import { USER_DECEASED_IMG } from 'Library/MemorialPageHelpers';
import { blobToFile, lookForCroppedImageName } from 'Library/ImageProcessorHelpers';
import ImageUpload from '../ImageUpload';
import { If } from 'Conditionals';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { LoadingButton } from 'FormElements';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

function DeceasedImage () {
  const { t } = useTranslation();
  const { attachImage, detachImage, findImageOfType, memorialPage } = useMemorialPage();
  const userDeceasedImage = findImageOfType(USER_DECEASED_IMG);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const uploadImageBtnText = !userDeceasedImage ? t('Add image') : t('Change image');

  const closeModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const closeSave = (e, croppedCanvas) => {
    e.preventDefault();

    setIsLoading(true);

    croppedCanvas.toBlob(async (blob) => {
      const file = blobToFile(blob, `memorial_page_deceased_${memorialPage.id}`);
      const formData = new FormData();

      formData.append('file', file);
      formData.append('type', USER_DECEASED_IMG);

      await attachImage(formData);
      setIsLoading(false);
    }, 'image/jpeg', 1);

    setIsOpen(false);
  };

  const removeImage = async () => {
    setIsLoading(true);
    await detachImage(userDeceasedImage.id);
    setIsLoading(false);
    setIsOpen(false);
  };

  function getImgixParams () {
    if (userDeceasedImage && !lookForCroppedImageName(userDeceasedImage.src)) {
      return {
        fixed: true,
        fit: 'facearea',
        facepad: 4
      };
    }

    return {
      fit: 'crop'
    };
  }

  return (
    <div className='memorial-page-deceased-image'>
      {isLoading ? (
        <span className='memorial-page-deceased-image__skeleton'>
          <Skeleton circle />
        </span>
      ) : (
        userDeceasedImage ? (
          <Imgix
            className='memorial-page-deceased-image__image'
            src={userDeceasedImage.url}
            width={232}
            height={232}
            imgixParams={{
              ...getImgixParams(),
              w: 232,
              h: 232
            }}
          />
        ) : (
          <img
            className='memorial-page-deceased-image__image svg-fallback'
            src='/icons/avatar-deceased.svg'
            alt='avatar'
            width={232}
            height={232}
          />
        )
      )}

      <LoadingButton
        disabled={isLoading}
        data-cy='deceased-bg-button'
        className='white upload-image-trigger'
        onClick={() => setIsOpen(true)}
      >
        {uploadImageBtnText}
      </LoadingButton>

      <div className='memorial-page-deceased-image__actions'>
        <If condition={userDeceasedImage}>
          <LoadingButton
            className='btn btn-link danger'
            disabled={isLoading}
            onClick={removeImage}
          >
            {t('Remove image')}
          </LoadingButton>
        </If>

        <If condition={!userDeceasedImage}>
          <p className='memorial-page-deceased-image__no-image-text'><em>{t('No image chosen')}</em></p>
        </If>
      </div>

      <ImageUpload
        chosenImage={userDeceasedImage}
        closeModal={closeModal}
        closeSave={closeSave}
        description={t('For the best result – use a close-up with good quality and sharpness.')}
        modalHeading={t('Upload a photo of the deceased')}
        isOpen={isOpen}
        removeImage={removeImage}
      />
    </div>
  );
}

export default DeceasedImage;
