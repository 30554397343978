import { buildURL } from 'react-imgix';

const drawBackground = (
  ctx,
  {
    width,
    height,
    wpCardBgImageObj,
    userCardBgImage
  }
) => {
  return new Promise((resolve, reject) => {
    // Since the parent canvas has to mount first, it's *possible* that the context may not be
    // injected by the time this render function runs the first time.
    if (!ctx) {
      reject(new Error('No canvas context!'));
      return;
    }

    /**
     * Check the aspect ratio of the image and make sure it
     * gets width and heigth to cover the canvas.
     *
     * @param {Image} bg
     */
    const canvasImageCover = bg => {
      let newW, newH;
      const dA = width - bg.width;
      const dB = height - bg.height;

      if (dA < dB) {
        newH = height;
        newW = bg.width + (bg.width * ((dB * 100) / bg.height)) / 100;
      } else {
        newW = width;
        newH = bg.height + (bg.height * ((dA * 100) / bg.width)) / 100;
      }

      return {
        width: newW,
        height: newH
      };
    };

    /**
     * Function to draw the image onto the canvas and to resolve its Promise.
     *
     * This function should not be called until the image has been loaded (img.onload)
     * since it resolves the promise and therefore also triggers the rest of the canvas
     * drawing functions.
     */
    const drawImage = () => {
      const bgCover = canvasImageCover(bg);

      ctx.drawImage(bg, 0, 0, bgCover.width, bgCover.height);

      // Resolve the Promise once the image has been drawn.
      resolve(true);
    };

    // Create new HTMLImageElement
    const bg = new Image(); // eslint-disable-line no-undef

    // Set image cross origin
    bg.crossOrigin = 'anonymous';

    // Set up function to be triggered once image has a src set.
    bg.onload = drawImage;

    bg.src = ''; // Clear the image src to init new onload when src is set, basically only needed to work in Safari.

    if (wpCardBgImageObj && wpCardBgImageObj.id) {
      bg.src = wpCardBgImageObj.src;
    } else if (userCardBgImage.url) {
      bg.src = buildURL(userCardBgImage.url, {
        width: 670,
        height: 540,
        fit: 'crop',
        crop: 'edges'
      });
    }
  });
};

export default drawBackground;
