import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import DatePicker, { registerLocale } from 'react-datepicker';
import { parse, isValid, format } from 'date-fns';
import { sv, da } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCountries from 'ReduxHooks/useCountries';

registerLocale('se', sv);
registerLocale('dk', da);

const LavendlaDatePicker = ({ date, onChange, id, className, placeholder, maxDate, required }) => {
  const datepickerId = id || uniqueId('datepicker_');
  const { country, allowedCountries } = useCountries();
  const countryCode = country && allowedCountries.includes(country.code) ? country.code : 'se';

  const [selectedDate, setSelectedDate] = useState(isValid(parse(date, 'yyyy-MM-dd', new Date())) ? parse(date, 'yyyy-MM-dd', new Date()) : null);

  const handleChange = (date) => {
    setSelectedDate(date);

    if (typeof onChange === 'function') {
      const returnDate = date ? format(date, 'yyyy-MM-dd') : null;
      onChange(returnDate);
    }
  };

  return (
    <>
      <DatePicker
        id={datepickerId}
        selected={selectedDate !== 'Invalid Date' ? selectedDate : ''}
        onChange={handleChange}
        placeholderText={placeholder || null}
        minDate={new Date('2017-04-01')}
        maxDate={maxDate || null}
        isClearable
        dateFormat='P'
        locale={countryCode}
      />

      {required && !selectedDate ? (
        <span className='input__icon-required'>
          <FontAwesomeIcon
            icon={['fal', 'exclamation-triangle']}
            color='#ea8b00'
            size='lg'
          />
        </span>
      ) : null}
    </>
  );
};

LavendlaDatePicker.propTypes = {
  date: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool
};

export default LavendlaDatePicker;
