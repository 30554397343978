import React, { useState } from 'react';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { USER_BG_IMG } from 'Library/MemorialPageHelpers';
import { lookForCroppedImageName } from 'Library/ImageProcessorHelpers';
import Imgix, { Picture, Source } from 'react-imgix';
import { LoadingButton } from 'FormElements';
import HeaderBackgroundSelectorModal from './HeaderBackgroundSelectorModal';
import { useTranslation } from 'react-i18next';

function HeaderBackgroundImage () {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { defaultImages, memorialPage, findImageOfType } = useMemorialPage();
  const wpImageId = memorialPage.wordpress_card_image_id;
  const customImage = findImageOfType(USER_BG_IMG);
  const uploadImageBtnText = (customImage || wpImageId) ? t('Change background image') : t('Select background image');
  const imgixParams = {
    auto: 'format',
    fit: 'crop',
    w: '992',
    h: '270',
    ...getImgixCropParams()
  };
  const imgixSizes = {
    1400: {
      w: '1170',
      h: '270'
    },
    1200: {
      w: '1140',
      h: '270'
    },
    992: {
      w: '930',
      h: '270'
    },
    720: {
      w: '720',
      h: '270'
    },
    480: {
      w: '768',
      h: '170'
    },
    320: {
      w: '480',
      h: '170'
    }
  };

  function getImgixCropParams () {
    if (customImage && !lookForCroppedImageName(customImage.src)) {
      return { crop: 'faces, edges' };
    }

    return { crop: 'center' };
  }

  function createPicture (src) {
    const params = {};

    for (const key in imgixSizes) {
      if (Object.hasOwnProperty.call(imgixSizes, key)) {
        const sizes = imgixSizes[key];
        params[key] = { ...imgixParams, ...sizes };
      }
    }

    return (
      <Picture>
        {Object.keys(params).sort().map(key => (
          <Source
            key={key}
            src={src}
            width={Number(params[key].w)}
            imgixParams={params[key]}
            htmlAttributes={{ media: `(min-width: ${key}px)` }}
          />
        ))}

        <Imgix
          src={src}
          imgixParams={imgixParams}
          className='obituary-single__hero-background__image'
        />
      </Picture>
    );
  }

  function renderBgImage () {
    if (wpImageId) {
      const image = defaultImages.find(({ id }) => id === wpImageId);

      return (image) ? (
        <img
          src={image.src}
          alt='bakgrundsbild'
          className='obituary-single__hero-background__image not-imgix'
        />
      ) : null;
    }

    if (customImage) {
      return createPicture(customImage.url);
    }

    return null;
  }

  return (
    <>
      {renderBgImage()}
      <LoadingButton
        data-cy='header-bg-button'
        className='white upload-image-trigger'
        onClick={() => setIsOpen(true)}
      >
        {uploadImageBtnText}
      </LoadingButton>

      <HeaderBackgroundSelectorModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
    </>
  );
}

export default HeaderBackgroundImage;
