import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'FormElements';
import { If } from 'Conditionals';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import { useTranslation } from 'react-i18next';

function Reminders ({ updatedTask, updateValue }) {
  const { t } = useTranslation();
  const [reminder, setReminder] = useState(updatedTask.reminder || {
    when: '',
    days: 0,
    reminder_days: 0
  });

  useEffect(() => {
    updateValue({ reminder });
  }, [reminder]);

  function updateReminder (args = {}) {
    setReminder({
      ...reminder,
      ...args
    });
  }

  function getReminderOptions () {
    const options = {
      '': t('No reminders')
    };

    if (updatedTask && updatedTask.type === 'order') {
      options.before = t('Before the ceremony date');
      options.after = t('After the ceremony date');
    }

    options.afterOrderDate = t('After orderdate');

    return options;
  }

  return (
    <>
      <Select
        floatingLabel
        data-cy='task-reminder-when'
        value={reminder.when}
        label={t('Select a reminder')}
        options={getReminderOptions()}
        onChange={when => updateReminder({ when })}
      />

      <AnimatePresence>
        <If condition={reminder.when !== ''}>
          <motion.div {...animateTop}>
            <Input
              data-cy='task-reminder-days'
              label={`${t('Reminder 1')}<br/>${t('Number of days before/after the ceremony date or order date')}<small></small>`}
              type='number'
              max={100}
              min={1}
              value={reminder.days || ''}
              onChange={days => updateReminder({ days })}
            />
            <Input
              data-cy='task-reminder-reminder_days'
              label={`${t('Reminder 2')}<br/>${t('Number of days before/after the ceremony date or order date')}<small></small>`}
              type='number'
              max={100}
              min={1}
              value={reminder.reminder_days || ''}
              onChange={reminderDays => updateReminder({ reminder_days: reminderDays })}
            />
          </motion.div>
        </If>
      </AnimatePresence>
    </>
  );
}

Reminders.propTypes = {
  updatedTask: PropTypes.object,
  updateValue: PropTypes.func
};

export default Reminders;
