import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import SuggestedSelectedSupplier from './SuggestedSelectedSupplier';
import SupplierModalPicker from './SupplierModalPicker';
import useOrderSuppliers from '../../hooks/useOrderSuppliers';
import useOrderTasks from '../../hooks/useOrderTasks';
import { If } from 'Conditionals';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';
import { renderTaskInformation, renderAddress } from 'Library/SupplierFunctions';

function SupplierPicker ({ updateTabs, type = 'transportoer' }) {
  const { t, i18n } = useTranslation();
  const { order } = useSingleOrder();
  const { getTaskForSupplier } = useOrderTasks();
  const { toggleSupplier, getSortedSuppliers, getSupplierOfType } = useOrderSuppliers();
  const orderSupplier = getSupplierOfType(type);

  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const suggestedSupplier = (suppliers.length) ? [...suppliers].shift() : null;
  const task = getTaskForSupplier(type);

  useEffect(() => {
    fetchSuppliers();
  }, [order.id]);

  async function fetchSuppliers () {
    setIsLoading(true);
    const data = await getSortedSuppliers(type);
    setIsLoading(false);

    if (data) {
      setSuppliers(data);
    }
  }

  const attachSupplierToOrder = (supplier = null) => {
    setIsOpen(false);
    toggleSupplier(type, supplier);
  };

  let countryCode = 'se';
  switch (i18n.language) {
    case 'de_de':
      countryCode = 'de';
      break;
    case 'da_dk':
      countryCode = 'dk';
      break;
    case 'sv_se':
      countryCode = 'se';
      break;
  }

  const TaskInfo = renderTaskInformation(task, countryCode);
  const address = renderAddress(orderSupplier);

  return (
    <>
      <div className='supplier-picker'>
        {!isLoading && orderSupplier ? (
          <SuggestedSelectedSupplier
            key={orderSupplier.id}
            supplier={orderSupplier}
            isSelectedSupplier
            task={task}
            openModal={() => setIsOpen(true)}
            taskInfo={TaskInfo}
            address={address}
          />
        ) : (
          <>
            <p className='supplier-picker__none'>
              {t('No transportation selected')}
              <If condition={!order.location}>
                &nbsp;(<LoadingButton onClick={() => updateTabs(2)} className='btn-link'>{t('Please select a city')}</LoadingButton> {t('in order to show available tranportation providers')})
              </If>
            </p>
            <If condition={!isLoading && !!order.location}>
              <SuggestedSelectedSupplier
                supplier={suggestedSupplier}
                openModal={() => setIsOpen(true)}
                attachSupplierToOrder={attachSupplierToOrder}
              />
            </If>
          </>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel=''
        className='user-assigner-modal'
      >
        <SupplierModalPicker
          closeModal={() => setIsOpen(false)}
          attachSupplierToOrder={attachSupplierToOrder}
          selectedSupplier={orderSupplier}
          suppliers={suppliers}
        />
      </Modal>
    </>
  );
}

SupplierPicker.propTypes = {
  updateTabs: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default SupplierPicker;
