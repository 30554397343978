import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { If } from 'Conditionals';

const Notice = ({
  children,
  classlist = '',
  type = 'info',
  heading = '',
  onClose = null,
  hideIcon = false
}) => {
  const noticeType = ['notice', 'warning', 'error', 'success'].includes(type) ? type : 'notice';
  const icons = { notice: 'info-circle', warning: 'question-circle', error: 'exclamation-triangle', success: 'check-circle' };

  return (
    <div className={classNames('lav-notice', [`lav-notice__type-${noticeType}`, classlist])}>
      <div className='lav-notice__container'>
        <If condition={!hideIcon}>
          <div className='lav-notice__icon'>
            <FontAwesomeIcon icon={['fal', icons[noticeType]]} />
          </div>
        </If>
        <div className='lav-notice__content'>
          <If condition={!!heading}>
            <h4 className='lav-notice__heading'>
              {heading}
            </h4>
          </If>
          {children}
        </div>
        <If condition={!!onClose}>
          <div className='lav-notice__close'>
            <button onClick={() => onClose()} role='button'>
              <FontAwesomeIcon icon={['fal', 'times']} />
            </button>
          </div>
        </If>
      </div>
    </div>
  );
};

Notice.propTypes = {
  classlist: PropTypes.string,
  heading: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hideIcon: PropTypes.bool
};

export default Notice;
