import React, { useEffect } from 'react';
import PartialInvoiceToggle from './PartialInvoiceToggle';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Checkbox } from 'FormElements';
import ToggleShowBonuses from './ToggleShowBonuses';
import { useTranslation } from 'react-i18next';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import { showWarningNotice } from 'Library/notifications';
import { If } from 'Conditionals';

function InvoiceOptions () {
  const { t } = useTranslation();
  const { order, persist, updateField, isTherapyOrder } = useSingleOrder();
  const { toggleExtendPaymentProduct, togglePaperInvoiceProduct } =
    useOrderProducts();
  const { invoice_options: options } = order;

  useEffect(() => {
    if (isCustomerEmail(options.email)) {
      showWarningNotice(
        t('NB! The email address for the invoice copy cannot be the same as the recipient of the invoice.')
      );
    }
  }, [options.email]);

  async function onExtendedPaymentChange (checked) {
    await updateField('invoice_options', {
      ...options,
      ...{ is_extend_payment: checked ? 1 : 0 }
    });

    await persist();
    await toggleExtendPaymentProduct(!checked);
  }

  async function onPaperInvoiceChange (checked) {
    await updateField('invoice_options', {
      ...options,
      ...{ is_paper: checked ? 1 : 0 }
    });

    await persist();
    await togglePaperInvoiceProduct(!checked);
  }

  function isCustomerEmail (email) {
    return email !== '' && email === order.customer.email;
  }

  return (
    <div className='checkbox-grouped-container'>
      <If condition={!isTherapyOrder}>
        <ToggleShowBonuses />
      </If>

      <PartialInvoiceToggle />

      <If condition={order.country.code === 'se' && !isTherapyOrder}>
        <Checkbox
          data-cy='invoice_options.is_paper'
          desc={t('Send invoice via paper')}
          label={t('Send invoice via paper')}
          onChange={(checked) => onPaperInvoiceChange(checked)}
          defaultChecked={!!options.is_paper}
        />
      </If>
      <If condition={!isTherapyOrder}>
        <Checkbox
          data-cy='invoice_options.is_extend_payment'
          desc={t('(30 days)')}
          label={t('Extended payment')}
          onChange={(checked) => onExtendedPaymentChange(checked)}
          defaultChecked={!!options.is_extend_payment}
        />
      </If>
    </div>
  );
}

export default InvoiceOptions;
