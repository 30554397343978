import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function AdminOnly ({ children }) {
  const { isAdmin } = useLoggedInUser();

  if (!isAdmin) {
    return null;
  }

  return children;
}

AdminOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default AdminOnly;
