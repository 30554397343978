import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';

function useFlowerOrderProducts () {
  const { order, orderActionCallback } = useSingleOrder();

  function getFlowers () {
    if (!order) {
      return [];
    }

    const { products = [] } = order;
    const flowerRootTermIds = [32, 362, 395, 1582, 1589, 1598, 1602, 1604, 1608, 1623, 1632, 1636, 1648, 1653];

    return products.filter(product => product.categories.find(({ id, parent }) => {
      return flowerRootTermIds.includes(id) || flowerRootTermIds.includes(parent);
    })).filter(({ slug }) => !['band', 'band-till-begravningsblommor', 'blomsterbaand'].includes(slug));
  }

  function getFlowerShopOrders () {
    return order ? (order.flower_shop_orders || []) : [];
  }

  function hasFlowers () {
    return getFlowers().length || getFlowerShopOrders().length;
  }

  function getFlowersTotal () {
    return getFlowers().reduce((carry, { quantity, price }) => carry + (quantity * price), 0);
  }

  async function updateProduct (fowerShopOrderId, productId, data = {}) {
    const response = await API.POST(`/flower-shop-orders/${fowerShopOrderId}/${productId}`, data);

    return orderActionCallback(response);
  }

  async function toggleFlowerProductCertified (flowerShopOrderId, productId, invoiced = true) {
    return API.POST(`/flower-shop-orders/${flowerShopOrderId}/${productId}/toggle-invoiced`, { invoiced });
  }

  return {
    order,
    flowers: getFlowers(),
    flowerShopOrders: getFlowerShopOrders(),
    hasFlowers,
    getFlowersTotal,
    updateProduct,
    toggleFlowerProductCertified
  };
}

export default useFlowerOrderProducts;
