import {
  ADD_PARTIAL_INVOICE_PRODUCT,
  ADD_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORY,
  ADD_PRODUCTS,
  REMOVE_PARTIAL_INVOICE_PRODUCT,
  REMOVE_PARTIAL_INVOICE_PRODUCTS
} from '../actions/products';

const initialState = {
  partialInvoiceProducts: [],
  productCategories: [],
  productCategory: null,
  products: []
};

export default function products (state = initialState, { type, payload }) {
  switch (type) {
    case ADD_PARTIAL_INVOICE_PRODUCT: {
      if (state.partialInvoiceProducts.includes(payload)) {
        return state;
      }

      const products = [...state.partialInvoiceProducts];
      products.push(payload);

      return {
        ...state,
        partialInvoiceProducts: products
      };
    }

    case REMOVE_PARTIAL_INVOICE_PRODUCT: {
      if (!state.partialInvoiceProducts.includes(payload)) {
        return state;
      }

      const index = state.partialInvoiceProducts.findIndex(id => id === payload);
      const products = [...state.partialInvoiceProducts];

      products.splice(index, 1);

      return {
        ...state,
        partialInvoiceProducts: products
      };
    }

    case REMOVE_PARTIAL_INVOICE_PRODUCTS: {
      return {
        ...state,
        partialInvoiceProducts: []
      };
    }

    case ADD_PRODUCT_CATEGORIES: {
      return {
        ...state,
        productCategories: payload
      };
    }

    case ADD_PRODUCT_CATEGORY: {
      return {
        ...state,
        productCategory: payload
      };
    }

    case ADD_PRODUCTS: {
      return {
        ...state,
        products: payload
      };
    }

    default:
      return state;
  }
}
