import API from '../../stores/API';
import useSingleOrder from './useSingleOrder';

function useOrderTasks () {
  const { order, orderActionCallback } = useSingleOrder();

  async function markTaskCompleted (taskId) {
    const response = await API.POST(`/order-tasks/${order.id}/${taskId}/mark-completed?order_type=${order.class}`);

    return orderActionCallback(response);
  }

  async function markTaskIncomplete (taskId) {
    const response = await API.POST(`/order-tasks/${order.id}/${taskId}/mark-incomplete?order_type=${order.class}`);

    return orderActionCallback(response);
  }

  async function sendTaskEmail (taskId, data) {
    const response = await API.POST(`/order-tasks/${order.id}/${taskId}/send-email?order_type=${order.class}`, data, false);

    return orderActionCallback(response);
  }

  async function updateTask (taskId, data = {}) {
    const response = await API.PUT(`/order-tasks/${order.id}/${taskId}?order_type=${order.class}`, data);

    return orderActionCallback(response);
  }

  async function updateMetadataForTask (taskId, data = {}) {
    const response = await API.POST(`/order-tasks/${order.id}/${taskId}/update-metadata?order_type=${order.class}`, data);

    return orderActionCallback(response);
  }

  return {
    markTaskCompleted,
    markTaskIncomplete,
    sendTaskEmail,
    updateTask,
    updateMetadataForTask
  };
}

export default useOrderTasks;
