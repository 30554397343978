/* global File, FileReader */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

const UploadDropzone = ({
  chosenImage,
  file,
  imageSrc,
  setFile,
  setImageSrc,
  progress,
  setProgress
}) => {
  const { t } = useTranslation();
  const maxSize = 5242880 * 2; // 10 MB

  const addFile = (newFile) => {
    // Check if user actually selected a file
    if (!newFile) {
      return;
    }

    setFile(newFile);

    const reader = new FileReader();
    reader.readAsDataURL(newFile);

    reader.onloadstart = () => {
      setProgress(true);
    };

    reader.onloadend = () => {
      if (!reader.result) {
        return;
      }

      setImageSrc(reader.result);
      setProgress(false);
    };
  };

  const showErrorNotice = (msg) => {
    showWarningNotice(`💔 ${msg}`);
  };

  const validateImage = (imageFile, name = imageFile.name, type = imageFile.type, size = imageFile.size) => {
    if (!type.match(/image\/(jpeg|png)/g) && maxSize < size) {
      // Check whether the upload is in the allowed format and if it's size is greater then the size limit
      return showErrorNotice(sprintf(t('%s is larger than the allowed file size and is not an allowed file format.'), `<strong>${name}</strong>`));
    } else if (!type.match(/image\/(jpeg|png)/g)) {
      // Check whether the upload is in the allowed format
      return showErrorNotice(sprintf(t('%s is not an allowed file format.'), `<strong>${name}</strong>`));
    } else if (maxSize < size) {
      // Check whether the upload size is greater than the size limit
      return showErrorNotice(sprintf(t('%s is larger than the allowed file size.'), `<strong>${name}</strong>`));
    }
  };

  /**
   * Function to handle droped files.
   */
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      return rejectedFiles.forEach(file => validateImage(file));
    }

    return acceptedFiles.forEach(file => addFile(file));
  }, []);

  // Animation options
  const motionProps = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20, transition: { duration: 0.15 } },
    transition: { duration: 0.15, delay: 0.15 }
  };

  /**
   * Hook for dropzone with settings
   */
  const {
    isDragAccept,
    isDragReject,
    getInputProps,
    getRootProps
  } = useDropzone({
    onDrop,
    multiple: false,
    maxSize,
    disabled: progress,
    accept: 'image/jpg, image/jpeg, image/png',
    preventDropOnDocument: true,
    activeClassName: 'dropzone-box__active',
    acceptClassName: 'dropzone-box__accepted',
    disabledClassName: 'dropzone-box__disabled'
  });

  return (
    <motion.div {... motionProps} className='memorial-modal__dropzone'>
      <ul className='checkmark-list'>
        <li>{t('Allowed file formats are .JPG and .PNG')}</li>
        <li>{t('Max 10MB file size')}</li>
      </ul>

      <div {...getRootProps({ className: 'dropzone-box' })}>
        <input {...getInputProps()} />
        {isDragAccept && (<p className='dropzone-box__accepted-format'>{t('This file format is allowed!')}</p>)}
        {isDragReject && (<p className='dropzone-box__rejected-format'>{t('This file format is not allowed!')}</p>)}

        {(!chosenImage || !chosenImage.url)
          ? <p>{t('Drag an image file here, or click here to select a file from the computer.')}</p>
          : <p>{t('Replace the selected image file by dragging a new file here, or click here to select a new file from the computer.')}</p>}

        {/* Fake button */}
        <div className='btn'>{t('Choose File')}</div>
      </div>

    </motion.div>
  );
};

UploadDropzone.propTypes = {
  chosenImage: PropTypes.object,
  file: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(File)
  ]),
  imageSrc: PropTypes.string,
  progress: PropTypes.bool,
  setFile: PropTypes.func.isRequired,
  setImageSrc: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

export default UploadDropzone;
