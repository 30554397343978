export function filterInvoiceableProducts ({ exclude_from_fortnox: excludeFromFortnox }) {
  return !excludeFromFortnox;
}

export function filterNonInvoiceableProducts (product) {
  return !filterInvoiceableProducts(product);
}

/**
 * Check whether or not all products belonging to an order
 * has been invoiced.
 *
 * There are some products here that cannot be invoiced
 * and will be therefore excluded.
 *
 * @param order
 * @returns {Boolean}
 */
export function orderHasInvoicedAllInvoiceableProducts (order) {
  const products = order.products.filter(product => {
    return filterInvoiceableProducts(product);
  });

  if (!products.length) {
    return false;
  }

  return products.every(({ pivot }) => !!pivot.invoice_id);
}
