import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import API, { WEB_URL_DK, WEB_URL_FI, WEB_URL_NO, WEB_URL_SE, WEB_URL_GB } from '../../stores/API';
import { getResouceRootUri } from 'Library/Orders';
import { maybeShowPhoneNumberError } from 'Library/PhoneNumber';
import {
  archiveOrder,
  deleteOrder,
  getOrder,
  getOrderEvents,
  persistOrder,
  resetOrder,
  set,
  updateOrder
} from '../actions/order';
import { showNotice, showWarningNotice } from 'Library/notifications';

function useSingleOrder () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { order, originalOrder, isLoading, isUpdating, events } = useSelector(state => state.order);

  function get (id, orderType = 'App\\LawyerOrder') {
    dispatch(getOrder(id, orderType));
  }

  async function persist (loadEvents = true, message = null) {
    await dispatch(persistOrder(loadEvents));

    if (message === false) {
      return;
    }

    showNotice(message || t('The order has been saved'));
  }

  function reset () {
    dispatch(resetOrder());
  }

  function destroy () {
    dispatch(deleteOrder());
  }

  async function restore (order) {
    const { data, status, errors } = await API.POST(`${getResouceRootUri(order)}/${order.id}/restore`, order);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return null;
    }

    return data;
  }

  const archive = async (order = null) => {
    return dispatch(archiveOrder(order));
  };

  const toggleFavorite = async (order) => {
    const { status } = await API.POST(`${getResouceRootUri(order)}/${order.id}/favorite`);
    if (status === 'ok') {
      return true;
    }
    return false;
  };

  async function updateOrderStatus (status, isAdmin) {
    await persist();
    const route = order.class === 'App\\Order' ? 'update-order-status' : 'update-status';
    const response = await API.POST(`${getResouceRootUri(order)}/${order.id}/${route}`, { status, isAdmin });

    return orderActionCallback(response);
  }

  async function createFromOrder (orderType = 'App\\LawyerOrder', order, data = {}) {
    let resourceRoot;
    switch (orderType) {
      case 'App\\LawyerOrder':
        resourceRoot = '/lawyer_orders';
        break;
      case 'App\\TombstoneOrder':
        resourceRoot = '/tombstone_orders';
        break;
      case 'App\\TherapyOrder':
        resourceRoot = '/therapy_orders';
        break;
      default:
        resourceRoot = '/orders';
        break;
    }

    const response = await API.POST(`${resourceRoot}/create/${order.id}`, data);

    return orderActionCallback(response);
  }

  async function duplicate (order) {
    let resourceRoot = '/orders';

    switch (order.class) {
      case 'App\\LawyerOrder':
        resourceRoot = '/lawyer_orders';
        break;
      case 'App\\TombstoneOrder':
        resourceRoot = '/tombstone_orders';
        break;
      case 'App\\TherapyOrder':
        resourceRoot = '/therapy_orders';
        break;
      default:
        resourceRoot = '/orders';
        break;
    }

    const { data, status, errors } = await API.POST(`${resourceRoot}/${order.id}/duplicate`);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return null;
    }

    return data;
  }

  async function markAsAvailable () {
    const response = await API.POST(`${getResouceRootUri(order)}/${order.id}/mark-available`);

    return orderActionCallback(response);
  }

  function orderActionCallback (response, loadEvents = true) {
    if (response.status === 'ok') {
      if (Array.isArray(response.data.user)) {
        response.data.user = null;
      }

      dispatch(set(response.data));

      if (loadEvents) {
        dispatch(getOrderEvents());
      }
    } else {
      showWarningNotice(response.errors.error_message);
    }

    return response;
  }

  async function updateField (name, value) {
    const clonedOrder = cloneDeep(order);
    const [keyOne, keyTwo] = name.split('.');

    if (typeof keyTwo === 'undefined') {
      clonedOrder[keyOne] = value;
    } else {
      const field = { ...clonedOrder[keyOne] };
      field[keyTwo] = value;
      clonedOrder[keyOne] = field;

      if (keyTwo === 'phone') {
        maybeShowPhoneNumberError(value);
      }
    }

    return dispatch(updateOrder(clonedOrder));
  }

  async function updateDateField (date, field) {
    date = date || null;
    return updateField(field, date);
  }

  async function updateFields (fields) {
    return dispatch(updateOrder({ ...order, ...fields }));
  }

  function isDirty () {
    return !isEqual(originalOrder, order);
  }

  function getExternalApiUrl () {
    switch (order.country_id) {
      case 2:
        return WEB_URL_NO;

      case 3:
        return WEB_URL_DK;

      case 4:
        return WEB_URL_FI;

      case 6:
        return WEB_URL_GB;

      case 1:
      default:
        return WEB_URL_SE;
    }
  }

  function getCountryCode (defaultCountry = 'se') {
    return (order && order.country) ? order.country.code : defaultCountry;
  }

  return {
    order,
    events,
    isLoading,
    isUpdating,
    confirmOffer: () => updateOrderStatus('in_progress'),
    updateOrderStatus,
    orderActionCallback,
    toggleFavorite,
    get,
    persist,
    reset,
    destroy,
    restore,
    archive,
    duplicate,
    markAsAvailable,
    createFromOrder,
    updateField,
    updateDateField,
    updateFields,
    isDirty,
    getExternalApiUrl,
    getCountryCode,
    isFuneralOrder: order && order.class === 'App\\Order',
    isLawyerOrder: order && order.class === 'App\\LawyerOrder',
    isTombstoneOrder: order && order.class === 'App\\TombstoneOrder',
    isTherapyOrder: order && order.class === 'App\\TherapyOrder',
    isCeremonyOrder: order && order.class === 'App\\CeremonyOrder'
  };
}

export default useSingleOrder;
