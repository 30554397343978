/**
 * Set up canvas settings
 */
export const canvasSettings = {
  ceremonyY: 320 * 2,
  deceasedY: 148 * 2,
  pixelDensity: 2,
  textY: 380 * 2
};

/**
 * Make sure the pixel density is good enough for HD quality.
 */
export const pd = value => {
  return value * canvasSettings.pixelDensity;
};

/**
 * Join deceased info into a single string
 * Canvas can only handle a single string
 */
export const deceasedOutput = ({
  name,
  birthYear,
  deceasedYear
}) => {
  if (!name || !birthYear || !deceasedYear) {
    return '';
  }

  return `${name}\n${birthYear || '-'} - ${deceasedYear}`;
};

/**
 * Format ceremony date for presentation
 */
export const formatedDate = date => {
  if (!date) {
    return '';
  }

  const format = [
    'sv-SE',
    {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    }
  ];

  return date.toLocaleString(...format);
};

/**
 * Format ceremony time for presentation
 */
export const formatedTime = time => {
  if (!time) {
    return '';
  }

  return `kl. ${time}`;
};

/**
 * Join ceremony info into a single string
 * Canvas can only handle a single string
 */
export const ceremonyOutput = (
  {
    ceremony,
    place,
    date,
    time
  },
  hideCeremonyInfo = false
) => {
  if (!ceremony || hideCeremonyInfo) {
    return '';
  }

  return `${place}\n${formatedDate(date)} ${formatedTime(time)}`;
};

/**
 * Text align y reducer
 */
export const alignYReducer = (state, action) => {
  if (!action || !action.type) {
    throw new Error();
  }

  const newState = {};
  for (const [key, value] of Object.entries(state)) {
    if (key === action.type) {
      switch (action.type) {
        case 'ceremonyY':
          newState[key] = action.value + (pd(14));
          newState.altTextY = action.value + (pd(24));
          break;
        default:
          newState[key] = action.value + (pd(32));
          break;
      }
    } else {
      newState[key] = value;
    }
  }

  return newState;
};
