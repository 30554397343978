import React from 'react';
import PropTypes from 'prop-types';
import UserListGroupItem from './UserListGroupItem';
import { useTranslation } from 'react-i18next';

/**
 * @needst10ns
 */
function UserListGroup ({
  order,
  users = [],
  local = false,
  hasActiveQueue = false
}) {
  const { t } = useTranslation();

  if (!users.length) {
    return null;
  }

  return (
    <div className='user-list-group__table'>
      <div className='user-list-group__table__header'>
        <div>{local ? t('Local advisors') : t('Other advisors')} ({users.length})</div>
        <div>{t('Distance to customer')}</div>
        <div>{t('Available for meeting')}</div>
        <div>{t('SMS dispatch')}</div>
      </div>
      <div className='user-list-group__table__body'>
        {users.map(user => (
          <UserListGroupItem
            order={order}
            key={user.id}
            user={user}
            hasActiveQueue={hasActiveQueue}
          />
        ))}
      </div>
    </div>
  );
}

UserListGroup.propTypes = {
  order: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  local: PropTypes.bool,
  hasActiveQueue: PropTypes.bool
};

export default UserListGroup;
