import { Input } from 'FormElements';
import Editor from '../../Editor';
import React from 'react';
import PropTypes from 'prop-types';
import { getSupplierTypes } from 'Library/functions';
import { useTranslation } from 'react-i18next';

function Email ({ updatedTask, updateValue }) {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label={t('Subject')}
        floatingLabel
        data-cy='task-email_subject'
        value={updatedTask.email_subject}
        onChange={value => updateValue({ email_subject: value })}
      />

      <div className='editor'>
        <label htmlFor='email-subject'>
          {t('Message')}
        </label>
        <Editor
          data={updatedTask.emailbody}
          onChange={value => updateValue({ emailbody: value })}
        />
      </div>

      <Input
        data-cy='task-email_to'
        value={updatedTask.email_to}
        onChange={value => updateValue({ email_to: value })}
        label={`${t('Recipient')}<br/><small>${t('Provide en email address or one of the following codes')}</small>`}
      />

      <div className='grey-box input'>
        <h4>{t('Available shortcodes for the recipient field')}</h4>
        <ul className='shortcodes-list'>
          <li>
            <strong>[customer.email]</strong> <span>{t("The customer's email address")}</span>
          </li>
          <li>
            <strong>[customer.contacts]</strong> <span>{t("The customer's email address and all contact persons email addresses")}</span>
          </li>
          <li>
            <h5>{t('Supplier codes')}</h5>
            <ul>
              {getSupplierTypes().map(({ value, label }, i) => (
                <li key={`${i}-${value}`}>
                  <strong>[suppliers.{value}]</strong>&nbsp;&mdash;&nbsp;<span>{label}</span>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}

Email.propTypes = {
  updatedTask: PropTypes.object,
  updateValue: PropTypes.func
};

export default Email;
