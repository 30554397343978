import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getSuppliers } from '../actions/suppliers';

function useSuppliers () {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { suppliers, pagination } = useSelector(state => state.suppliers);

  async function fetchSuppliers (filters) {
    setIsLoading(true);
    await dispatch(getSuppliers(filters));
    setIsLoading(false);
  }

  return {
    fetchSuppliers,
    isLoading,
    suppliers,
    pagination
  };
}

export default useSuppliers;
