import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
import store from '../stores/RootStore';
import API from '../stores/API';

const defaultFields = [
  'id',
  'market',
  'name',
  'description',
  'contact_person',
  'email',
  'url',
  'phone',
  'cities',
  'price',
  'type',
  'updated',
  'latitude',
  'longitude',
  'assembly',
  'imported_id'
];

const SuppliersExport = ({ search, type, location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fields, setFields] = useState(defaultFields);

  const onFieldsChange = e => {
    const newFields = [...fields];

    if (e.target.checked) {
      newFields.push(e.target.id);
    } else {
      newFields.splice(fields.indexOf(e.target.id), 1);
    }

    setFields(newFields);
  };

  const submit = e => {
    const filter = {
      type: 'suppliers',
      search,
      supplier_type: type,
      location,
      fields
    };

    API.POST('/export/orders', filter).then(res => {
      if (res.status === 'ok') {
        store.noticeStore.showNotice(res.data.response);
      } else {
        store.noticeStore.showWarningNotice('Det uppstod ett fel');
      }
    });
  };

  return (
    <div>
      <button className='btn' onClick={e => setIsModalOpen(true)}>Export</button>

      <Modal className='order-product-modal' isOpen={isModalOpen} onRequestClose={e => setIsModalOpen(false)} contentLabel=''>
        <button className='close-modal' onClick={e => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        {defaultFields.map((item, i) => (
          <div key={i}>
            <label>
              <input
                type='checkbox'
                checked={fields.includes(item)}
                onChange={onFieldsChange}
                id={item}
              />
              {item}
            </label>
          </div>
        ))}
        <br />
        <button
          className='btn' onClick={e => {
            setIsModalOpen(false);
            submit(e);
          }}
        >Export
        </button>
      </Modal>
    </div>
  );
};

SuppliersExport.propTypes = {
  search: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.string
};

export default SuppliersExport;
