import React, { useState } from 'react';
import { LoadingButton } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import { showNotice } from 'Library/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function MarkAsAvailable () {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const { isTombstoneOrder, markAsAvailable, order } = useSingleOrder();
  const [isLoading, setIsLoading] = useState(false);

  if (!isAdmin || isTombstoneOrder) {
    return null;
  }

  async function markAvailable () {
    setIsLoading(true);
    const { status } = await markAsAvailable();
    setIsLoading(false);

    if (status === 'ok') {
      showNotice(t('The order was successfully marked as available for everyone'));
    }
  }

  return (
    <div className='mark-as-duplicate'>
      {order.available_at ? (
        <span>
          <FontAwesomeIcon
            icon={['fal', 'globe']}
            size='lg'
          />
          &nbsp;
          {(t('Available for everyone'))}
        </span>
      ) : (
        <LoadingButton
          className='btn-link'
          disabled={isLoading || !!order.available_at}
          onClick={() => markAvailable()}
        >
          {t('Make available to everyone')}
        </LoadingButton>
      )}
    </div>
  );
}

export default MarkAsAvailable;
