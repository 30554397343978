import API from '../../stores/API';
import { getResouceRootUri } from 'Library/Orders';
import { showWarningNotice } from 'Library/notifications';
import { setCountry } from 'ReduxActions/countries';

export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_EVENTS = 'SET_ORDER_EVENTS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_UPDATING = 'SET_IS_UPDATING';
export const RESET_ORDER = 'RESET_ORDER';

export function set (payload = null) {
  return {
    type: SET_ORDER,
    payload
  };
}

export function setEvents (payload = null) {
  return {
    type: SET_ORDER_EVENTS,
    payload
  };
}

function update (payload) {
  return {
    type: UPDATE_ORDER,
    payload
  };
}

export function resetOrder (payload = null) {
  return {
    type: RESET_ORDER,
    payload
  };
}

function setIsLoading (payload = true) {
  return {
    type: SET_IS_LOADING,
    payload
  };
}

function setIsUpdating (payload = true) {
  return {
    type: SET_IS_UPDATING,
    payload
  };
}

function getApiUrl (orderType = 'App\\LawyerOrder') {
  switch (orderType) {
    case 'App\\LawyerOrder':
      return '/lawyer_orders';

    case 'App\\TombstoneOrder':
      return '/tombstone_orders';

    case 'App\\TherapyOrder':
      return '/therapy_orders';

    case 'App\\CeremonyOrder':
      return '/ceremony_orders';

    case 'App\\Order':
    default:
      return '/orders';
  }
}

function getIncludes (orderType = 'App\\LawyerOrder') {
  const defaultIncludes = [
    'location',
    'customer',
    'deceased',
    'products',
    'files',
    'tasks',
    'notes',
    'user',
    'invoice_options',
    'appointments.supplier',
    'invoices',
    'order_offer_invitations',
    'offers',
    'country',
    'tags',
    'users',
    'contacts'
  ];

  switch (orderType) {
    case 'App\\LawyerOrder':
      return [
        ...defaultIncludes,
        ...[
          'funeral',
          'advisor',
          'suppliers',
          'competence_areas',
          'order.appointments'
        ]
      ].join(',');

    case 'App\\TombstoneOrder':
      return [
        ...defaultIncludes,
        ...[
          'funeral',
          'advisor',
          'entombment',
          'suppliers',
          'order.appointments'
        ]
      ].join(',');

    case 'App\\TherapyOrder':
      return [
        ...defaultIncludes,
        ...[
          'fees',
          'site',
          'sessions',
          'products',
          'records',
          'advisor',
          'competence_areas',
          'suppliers',
          'user.user_profiles',
          'stripePayments.items',
          'expenses.stripePaymentItem',
          'appointments.stripePaymentItem'
        ]
      ].join(',');

    default:
      return [
        ...defaultIncludes,
        ...[
          'fees',
          'site',
          'funeral',
          'entombment',
          'viewing',
          'memorial',
          'memorial_page',
          'transport',
          'attendees',
          'flower_shop_orders',
          'competence_areas',
          'funeral.suppliers.addresses',
          'funeral.suppliers.files'
        ]
      ].join(',');
  }
}

export function getOrder (id, orderType = 'App\\LawyerOrder') {
  return async (dispatch) => {
    dispatch(set());
    dispatch(setIsLoading());

    const { data, status, errors } = await API.GET(`${getApiUrl(orderType)}/${id}?include=${getIncludes(orderType)}`);

    dispatch(setIsLoading(false));

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return null;
    }

    if (Array.isArray(data.user)) {
      data.user = null;
    }

    dispatch(setCountry(data.country));
    dispatch(set(data));
    dispatch(getOrderEvents());
  };
}

export function getOrderEvents () {
  return async (dispatch, getState) => {
    const { order: { order } } = getState();

    const { data } = await API.GET(`${getResouceRootUri(order)}/${order.id}/events`);

    dispatch(setEvents(data));
  };
}

export function updateOrder (data) {
  return async (dispatch) => {
    dispatch(update(data));
  };
}

export function persistOrder (loadEvents = true) {
  return async (dispatch, getState) => {
    const { order: { order } } = getState();

    dispatch(setIsUpdating());
    const { data } = await API.PUT(`${getResouceRootUri(order)}/${order.id}?new=1&include=${getIncludes(order.class)}`, order);
    dispatch(setIsUpdating(false));

    if (Array.isArray(data.user)) {
      data.user = null;
    }

    dispatch(set(data));

    if (loadEvents) {
      dispatch(getOrderEvents());
    }
  };
}

export function deleteOrder () {
  return async (dispatch, getState) => {
    const { order: { order } } = getState();

    dispatch(setIsUpdating());
    const response = await API.DELETE(`${getResouceRootUri(order)}/${order.id}`, order);
    dispatch(setIsUpdating(false));
    dispatch(set(null));

    return response;
  };
}

export function archiveOrder (order = null) {
  return async (dispatch, getState) => {
    if (!order) {
      const state = getState();
      order = state.order.order;
    }

    dispatch(setIsUpdating());
    const { data, status, errors } = await API.POST(`${getResouceRootUri(order)}/${order.id}/archive`, order);
    dispatch(setIsUpdating(false));

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
    } else {
      dispatch(set(data));
    }

    return { status };
  };
}
