import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './SummaryCard';
import { useTranslation } from 'react-i18next';

const MemorialFundInfo = ({ memorialFundDetails }) => {
  const { t } = useTranslation();
  const donationSupportText = memorialFundDetails?.donationSupport ? '' : ' - ' + t('Does not support donations on the memorial page');
  const message = (memorialFundDetails) ? memorialFundDetails.name + donationSupportText : t('No memorial fund selected');
  const details = [{ message }];

  return (
    <SummaryCard
      heading={t('Memorial fund')}
      subHeading={t('These details can be edited on the Ceremony tab.')}
      details={details}
    />
  );
};

MemorialFundInfo.propTypes = {
  memorialFundDetails: PropTypes.object
};

export default MemorialFundInfo;
