import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import API from '../stores/API';
import { LoadingButton } from 'FormElements';
import { getBasePath, getEndpoint } from 'Library/OrderHelperFuntions';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import If from 'Conditionals/If';

function ConfirmOffer ({
  order,
  history,
  isFuneralOrder = false,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false
}) {
  const { t } = useTranslation();
  const { isAdmin, loggedInUser } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);

  function getLatestUnconfirmedOffer () {
    if (!order.offers.length) {
      return null;
    }

    const mostRecent = [...order.offers].pop();
    return (!mostRecent.is_order_confirmation && !mostRecent.confirmed_at) ? mostRecent : null;
  }

  const confirm = async () => {
    setIsLoading(true);

    let response;
    const offer = getLatestUnconfirmedOffer();
    if (offer) {
      response = await API.POST(`/offer/confirm/${offer.id}`);
    } else {
      const endpoint = (isFuneralOrder) ? 'update-order-status' : 'update-status';
      const uri = `${getEndpoint({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder })}/${order.id}/${endpoint}`;
      response = await API.POST(uri, { status: 'in_progress' });
    }

    setIsLoading(false);

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);
      return;
    }

    const path = getBasePath({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder, isFuneralOrder });

    if (isFuneralOrder) {
      history.push(`/ordrar/order/${order.id}`);
      return;
    }

    history.push(`${path}/${order.id}`);
  };

  function userCanConfirm () {
    if (isAdmin) {
      return true;
    }

    return order.user && order.user.id === loggedInUser.id;
  }

  if (!userCanConfirm()) {
    return null;
  }

  return (
    <If condition={!isTherapyOrder}>
      <LoadingButton
        onClick={confirm}
        className='confirm-offer'
        text={t('Confirm offer')}
        disabled={isLoading}
      />
    </If>
  );
}

ConfirmOffer.propTypes = {
  order: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isFuneralOrder: PropTypes.bool,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default ConfirmOffer;
