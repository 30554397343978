import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SupplierDropdown from './SupplierDropdown';
import useOrderSuppliers from '../hooks/useOrderSuppliers';
import useSingleOrder from '../Redux/hooks/useSingleOrder';

function OrderSupplierDropdown ({
  type,
  className = 'col-4',
  label = null,
  isMulti = false,
  onChange = null,
  ...rest
}) {
  const { order } = useSingleOrder();
  const { getSupplierOfType, getSuppliersOfType, toggleSupplier, toggleSuppliers } = useOrderSuppliers();
  const id = `supplier-${type}`;

  const onSupplierChange = (type, supplier = null) => {
    if (Array.isArray(supplier)) {
      toggleSuppliers(type, supplier);
    } else {
      toggleSupplier(type, supplier);
    }

    if (onChange) {
      onChange(type, supplier);
    }
  };

  const props = {
    ...{
      isMulti,
      type,
      locationId: order.location_id,
      supplier: (isMulti) ? getSuppliersOfType(type) : getSupplierOfType(type),
      id,
      onChange: onSupplierChange
    },
    ...rest
  };

  return (
    <div
      data-cy={`supplier-${type}`}
      className={classNames('react-select__floating-label', className)}
    >
      <SupplierDropdown {...props} />
      {label ? (
        <label htmlFor={id}>{label}</label>
      ) : null}
    </div>
  );
}

OrderSupplierDropdown.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default OrderSupplierDropdown;
