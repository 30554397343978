import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FlowerRow from './FlowerRow';
import ToggleInvoicedProduct from './ToggleInvoicedProduct';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { formatCurrency } from 'Library/functions';
import { showNotice } from 'Library/notifications';
import { Trans, useTranslation } from 'react-i18next';

function FlowerEditor ({ product }) {
  const { t } = useTranslation();
  const { updateProduct } = useOrderProducts();
  const { order } = useSingleOrder();
  const [attributes, setAttributes] = useState(getMeta());
  const [isLoading, setIsLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);

  /**
   * This will always ensure the attributes passed through
   * will match the quantity of the current product.
   *
   * @return {Array}
   */
  function getMeta () {
    let attributes;

    if (product.meta === '') {
      attributes = [];
    } else {
      attributes = JSON.parse(product.meta);
    }

    // Sanity check. If for some reason the attribute is not an array convert it here.
    if (!Array.isArray(attributes)) {
      attributes = [];
    }

    // If there are more attributes than quanity of the flowers, get rid of the differnence
    if (attributes.length > product.quantity) {
      attributes.splice(product.quantity);

      // If there are not enough properties, add the extra properties
    } else if (attributes.length < product.quantity) {
      const diff = product.quantity - attributes.length;

      for (let i = 0; i < diff; i++) {
        attributes.push({
          type: '',
          text: ''
        });
      }
    }

    return attributes;
  }

  async function persist (params = {}) {
    const attributesCopy = [...attributes];

    if (attributesCopy.length > product.quantity) {
      attributesCopy.splice(product.quantity);

      // If there are not enough properties, add the extra properties
    } else if (attributesCopy.length < product.quantity) {
      const diff = product.quantity - attributesCopy.length;

      for (let i = 0; i < diff; i++) {
        attributesCopy.push({
          type: '',
          text: ''
        });
      }
    }

    attributesCopy[params.index] = {
      type: params.type,
      text: params.text.trim()
    };

    setAttributes(attributesCopy);
    setIsLoading(true);
    setLoadingIndex(`${product.id}-${params.index}`);

    await updateProduct(product.pivot.product_resource_id, {
      quantity: product.quantity,
      price: product.price,
      comment: product.comment,
      meta: JSON.stringify(attributesCopy)
    });

    setIsLoading(false);
    setLoadingIndex(null);

    const productName = product.name;

    showNotice(
      <Trans productName={productName}>
       🌹 The product <strong>{{ productName }}</strong> has been updated
      </Trans>
    );
  }

  const flowers = [];
  for (let i = 0; i < product.quantity; i++) {
    const key = product.id + i;
    const loading = isLoading && `${product.id}-${i}` === loadingIndex;
    let type = '';
    let text = '';

    if (typeof attributes[i] !== 'undefined') {
      type = attributes[i].type;
      text = attributes[i].text;
    }

    flowers.push(
      <FlowerRow
        key={key}
        product={product}
        index={i}
        type={type}
        text={text}
        isLoading={loading}
        update={(params) => persist(params)}
      />
    );
  }

  const toggleInvoiceProps = {
    order,
    product,
    type: 'order'
  };

  return (
    <div className='flower'>
      <div className='flower-header flower-tab-row'>
        <div className='image'>
          {typeof product.media[0] !== 'undefined' ? (
            <img
              className='product-thumbnail'
              src={encodeURI(product.media[0].sizes.medium)}
            />
          ) : (
            <div className='no-thumbnail' />
          )}
        </div>

        <div className='name'>
          <h4>{product.name},</h4>
        </div>

        <div className='quantity'>
          <h4>x {product.quantity},</h4>
        </div>

        <div className='price'>
          <h4>{formatCurrency(product.pivot.price || product.price, null, order.country.code, order?.currency)}</h4>
        </div>

        <div className='flower-toggle-invoiced'>
          <ToggleInvoicedProduct
            {...toggleInvoiceProps}
          />
          <small>
            {t('INVOICED')}
            <br />
            {t('(Accounting)', {
              description: 'Ekonomiavd.'
            })}
          </small>
        </div>
      </div>

      {flowers.map(flower => flower)}
    </div>
  );
}

FlowerEditor.propTypes = {
  product: PropTypes.object.isRequired
};

export default FlowerEditor;
