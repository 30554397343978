import React from 'react';
import PropTypes from 'prop-types';
import useCountries from 'ReduxHooks/useCountries';
import { formatDate } from 'Library/functions';

const Event = ({ event }) => {
  const { country } = useCountries();

  return (
    <li className='single-event'>
      <div className='single-event__data'>
        <time>{formatDate(event.created_at, country.code, 'P pp')}</time>
        <div className='event-action-text' dangerouslySetInnerHTML={{ __html: event.action }} />
      </div>
    </li>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired
};

export default Event;
