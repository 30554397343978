/**
 * Fetch prefered Images from Lavendla.se
 */
const fetchPreferedImages = (apiUrl = 'https://lavendla.se') => {
  return fetch(`${apiUrl}/wp-json/lavendla_obituary/v1/images/prefered/`)
    .then(response => response.json())
    .catch(error => console.error(error));
};

export function getPreferedImages (apiUrl = 'https://lavendla.se') {
  return async (dispatch, getState) => {
    const defaultImages = getState();

    if (defaultImages.length) {
      return;
    }

    const images = await fetchPreferedImages(apiUrl);

    dispatch({ type: 'defaultImages', payload: images });

    return true;
  };
}
