import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useOrderDeletion from 'ReduxHooks/useOrderDeletion';
import { LoadingButton } from 'FormElements';
import RemoveOrderForm from '../../components/RemoveOrderForm';
import Spinner from '../../components/Spinner';
import { getOrderTypeArchiveUrl } from 'Library/Orders';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showNotice } from 'Library/notifications';

function DeleteOrderButton ({
  buttonLabel = null,
  confirmHeadline = null
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { order, isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder, updateField, persist, destroy } = useSingleOrder();
  const { sendDeletionRequest, createOrderReponse } = useOrderDeletion();
  const { isAdmin } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function getOrderType () {
    switch (true) {
      case isLawyerOrder:
        return 'lawyer_order';
      case isTombstoneOrder:
        return 'tombstone_order';
      case isTherapyOrder:
        return 'therapy_order';
      case isCeremonyOrder:
        return 'ceremony_order';
      default:
        return 'funeral_order';
    }
  }

  async function sentDeleteOrderRequest () {
    setIsLoading(true);
    await sendDeletionRequest();
    setIsLoading(false);
    setIsModalOpen(false);
  }

  async function markAsDuplicate () {
    await updateField('duplicate', true);
    await persist();

    return true;
  }

  async function deleteOrder () {
    const resourceRoot = getOrderTypeArchiveUrl(order);
    await destroy();

    showNotice(t(`The order has been deleted.`));
    history.push(resourceRoot);
  }

  async function submitResponse (args) {
    if (!window.confirm(t(`Are you sure you want to delete this order? This cannot be undone.`))) {
      return;
    }

    setIsLoading(true);
    await createOrderReponse({
      order_id: order.id,
      order_type: getOrderType(),
      ...args
    });

    if (typeof markAsDuplicate === 'function' && args.duplicate === true) {
      await markAsDuplicate(order.id);
    }

    await deleteOrder();
  }

  function showDeleteForm () {
    return (isAdmin || order.status === 'offer' || order.status === 'inquiry' || order.status === 'awaiting_payment');
  }

  return (
    <div className='deletebutton'>
      <button className='btn order-delete' onClick={() => setIsModalOpen(true)}>
        <ReactSVG src='/icons/icon-trash.svg' />
        <span className='btn-text'>
          {buttonLabel || t('Delete')}
        </span>
      </button>
      <Modal className='delete-modal' isOpen={isModalOpen} contentLabel=''>
        <a href='#' className='close-modal' onClick={() => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </a>
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            <h3>{confirmHeadline || t('Delete order')}</h3>
            {showDeleteForm() ? (
              <RemoveOrderForm
                order={order}
                submitResponse={(args) => submitResponse(args)}
              />
            ) : (
              <div>
                <p>{t('You do not have permission to delete an order where the quote has been approved. Request removal from administrator.')}</p>
                <br /><br />

                <LoadingButton
                  id='send-order-deletion-request'
                  onClick={() => sentDeleteOrderRequest()}
                >{t('Request removal')}
                </LoadingButton>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}

DeleteOrderButton.propTypes = {
  buttonLabel: PropTypes.string,
  confirmHeadline: PropTypes.string
};

export default DeleteOrderButton;
