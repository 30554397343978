import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import Spinner from '../../Spinner';
import useGraphs from '../../../hooks/useGraphs';
import CustomTooltip from './CustomTooltip';
import GraphFilters from './GraphFilters';
import { useTranslation } from 'react-i18next';

function MemorialPages ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { data, isLoading, setParam, params } = useGraphs(
    '/dashboard/get-memorial-pages-stats',
    {
      start_date: moment().subtract(9, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      end_date: null,
      user_id: null,
      sortBy: null,
      orderType
    }
  );

  return (
    <div className='charts-container'>
      <h4>{t('Memorial pages created')}</h4>
      <GraphFilters
        params={params}
        setParam={setParam}
      />
      {isLoading && <Spinner />}

      {(!isLoading && data && data.length)
        ? (
          <ResponsiveContainer height={500}>
            <AreaChart data={data}>
              <XAxis dataKey='name' />
              <CartesianGrid strokeDasharray='3 3' />
              <YAxis />
              <Tooltip cursor={false} content={CustomTooltip} />
              <Legend iconType='circle' iconSize={10} />
              <Area
                name={t('Orders') + '*'}
                stackId='a'
                dataKey='orders'
                stroke='#480640'
                fill='#480640'
                activeDot={{ r: 5 }}
                fillOpacity={0.2}
                strokeWidth='4'
              />
              <Area
                name={t('Memorial pages')}
                stackId='b'
                dataKey='memorial_pages'
                stroke='#437783'
                fill='#437783'
                activeDot={{ r: 7 }}
                strokeWidth='4'
                fillOpacity={0.6}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          t('No data to for this period')
        )}
      <p className='legend-misc'>*{t('Order count is based on all orders for that period that are not archived or lost')}.</p>
    </div>
  );
}

MemorialPages.propTypes = {
  orderType: PropTypes.string
};

export default MemorialPages;
