import React from 'react';
import ProductRow from '../ProductRow';
import classNames from 'classnames';
import { filterInvoiceableProducts } from 'Library/ProductHelper';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

const OrderProducts = () => {
  const { t } = useTranslation();
  const { order, isLawyerOrder, isTherapyOrder } = useSingleOrder();
  const products = order.products.filter(product => filterInvoiceableProducts(product));

  return (
    <div
      id='order-products-table'
      className={classNames('product-table', {
        'is-partially-invoiced': order.is_partially_invoiced
      })}
    >
      <div className='cart'>
        <div className='table-header'>
          <div className='col product-col'><span className='label'>{t('Product')}</span></div>
          <div className='col quantity-col'><span className='label'>{t('Quantity')}</span></div>
          <div className='col price-col'><span className='label'>{t('Price')}</span></div>
          <div className='col comment-col'><span className='label'>{t('Comment')}</span></div>
          <If condition={!isLawyerOrder && !isTherapyOrder}>
            <div className='col mark-invoiced-col'>
              <span className='label'>
                {t('Certify', {
                  description: 'Attestera'
                })}
              </span>
            </div>
          </If>
          <div className='col partial-invoice-col'>
            <span className='label'>
              {order.is_partially_invoiced ? t('Partially invoice') : ''}
            </span>
          </div>
          <div className='col action-col'><span className='label'>{isTherapyOrder ? t('Payment request') : t('Actions') }</span></div>
        </div>

        {products.map(product => (
          <ProductRow
            key={product.product_resource_id}
            product={product}
          />
        ))}
      </div>
    </div>
  );
};

export default OrderProducts;
