export const formatLocation = ({ name: label, id: value }) => {
  return { label, value };
};

export const getFormattedLocationById = (id, locations = []) => {
  id = parseInt(id);

  const locationObject = locations.find(location => parseInt(location.id) === id);

  return locationObject ? formatLocation(locationObject) : null;
};
