import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { If } from 'Conditionals';
import ActiveScheduleIcon from './ActiveScheduleIcon';
import VisibleOnWebIcon from './VisibleOnWebIcon';
import GoogleCalendarAccessIcon from './GoogleCalendarAccessIcon';
import ActiveInCallQueueIcon from './ActiveInCallQueueIcon';

const UserRegistryListRow = ({
  user: {
    id,
    name,
    tel,
    roles,
    locations,
    archived_at: archivedAt,
    competence_areas: competenceAreas = [],
    latest_active_date: latestActiveDate = null,
    is_currently_active: isCurrentlyActive = false,
    is_active_in_queue: isActiveInCallQueue = false,
    no_schedule: noSchedule = true,
    google_calendar_sync: googleCalendarAccess = false,
    user_profiles: userProfiles = [],
    paused_at: pausedAt
  },
  showLastActive,
  translatedRoles,
  isAdmin
}) => {
  const { t } = useTranslation();
  const activeProfiles = userProfiles.filter(profile => profile.active === true);

  const renderRoles = () => {
    if (archivedAt) {
      return <strong>[{t('Disabled')}]</strong>;
    }

    if (pausedAt) {
      return <strong>[{t('Paused')}]</strong>;
    }

    return roles.map(role => translatedRoles[role]).join(', ');
  };

  return (
    <tr>
      <td>
        {isAdmin ? (
          <>
            <Link to={`/anvandare/id/${id}`}><strong>{name}</strong></Link>
            <div className='registry__registry-list__table_icons'>
              {!noSchedule && <ActiveScheduleIcon id={id} />}
              {activeProfiles.length > 0 && <VisibleOnWebIcon id={id} activeProfiles={activeProfiles} />}
              {isActiveInCallQueue && <ActiveInCallQueueIcon isCurrentlyActive={isCurrentlyActive} id={id} />}
              {googleCalendarAccess && <GoogleCalendarAccessIcon />}
            </div>
          </>
        ) : (
          <span>{name}</span>
        )}
      </td>
      <td>{competenceAreas.map(({ name }) => name).join(', ')}</td>
      <td><a href={`tel:${tel}`}>{tel}</a></td>
      <td>
        {renderRoles()}
      </td>
      <td>{locations.map(({ name }) => name).join(', ')}</td>
      <If condition={showLastActive}>
        <td>{latestActiveDate ? moment(latestActiveDate).fromNow() : ' -- '}</td>
      </If>
    </tr>
  );
};

UserRegistryListRow.propTypes = {
  user: PropTypes.object.isRequired,
  showLastActive: PropTypes.bool,
  translatedRoles: PropTypes.object,
  isAdmin: PropTypes.bool
};

export default UserRegistryListRow;
