import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './SummaryCard';
import { useTranslation } from 'react-i18next';

const MemorialOSAInfo = ({
  memorialInfo: {
    advisor_handles_rsvp: handlesRSVP
  }
}) => {
  const { t } = useTranslation();

  const message = (handlesRSVP)
    ? t('I as advisor will handle the RSVPs')
    : t('Customer will handle the RSVPs themselves');

  return (
    <SummaryCard
      heading={t('Information regarding memorial service and RSVP')}
      subHeading={t('These details can be edited on the Memorial tab.')}
      details={[{ message }]}
    />
  );
};

MemorialOSAInfo.propTypes = {
  memorialInfo: PropTypes.object
};

export default MemorialOSAInfo;
