import API from '../../stores/API';
import i18n from 'i18next';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { getToken } from 'Components/localstorage';

export const SET_BONUS_REPORT = 'SET_BONUS_REPORT';
export const SET_BONUS_REPORTS = 'SET_BONUS_REPORTS';
export const SET_RESOLVED_REPORT = 'SET_RESOLVED_REPORT';
export const SET_REPORT_TOTALS = 'SET_REPORT_TOTALS';

function recieveResolvedReport (payload) {
  return {
    type: SET_RESOLVED_REPORT,
    payload
  };
}

function recieveBonusReport (payload) {
  return {
    type: SET_BONUS_REPORT,
    payload
  };
}

function recieveBonusReports (payload) {
  return {
    type: SET_BONUS_REPORTS,
    payload
  };
}

function recieveTotals (payload) {
  return {
    type: SET_REPORT_TOTALS,
    payload
  };
}

export function getResolvedReport (id) {
  return async (dispatch) => {
    dispatch(recieveBonusReport(null));

    const { status, data, errors } = await API.GET(`/bonus-reports/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(recieveBonusReport({ ...data.report }));
    return dispatch(recieveResolvedReport(data));
  };
}

export function setBonusReport (data) {
  return async (dispatch) => dispatch(recieveBonusReport(data));
}

export function updateBonusReport () {
  return async (dispatch, getState) => {
    const { bonusReports: { bonusReport } } = getState();
    const { status, data, errors } = await API.PUT(`/bonus-reports/${bonusReport.id}`, bonusReport);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    showNotice(i18n.t('The report has been updated'));

    dispatch(recieveBonusReport({ ...data.report }));
    return dispatch(recieveResolvedReport(data));
  };
}

export function generateBonusInvoice () {
  return async (dispatch, getState) => {
    const { bonusReports: { bonusReport } } = getState();
    const { status, errors } = await API.POST(`/bonus-reports/${bonusReport.id}/create-invoice`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    showNotice(i18n.t('The invoice has been created'));
    return true;
  };
}

export function downloadInvoice (id) {
  return async (dispatch) => {
    // fix for staging api-address
    const host = window && window.location && window.location.host;
    let apiUrlParam = API_URL;

    if (host.includes('netlify.app') || host.includes('localhost')) {
      apiUrlParam = localStorage.getItem('backend') || 'https://stage.api.lavendla.com/v1';
    }

    return fetch(apiUrlParam + `/bonus-reports/${id}/download-invoice`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken()
      },
      method: 'GET'
    })
      .then(response => response.blob())
      .then(response => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        return true;
      });
  };
}

export function getReportsForUser (userId) {
  return async (dispatch) => {
    dispatch(recieveBonusReports([]));

    const { status, data, errors } = await API.GET(`/bonus-reports/get-reports-for-user/${userId}?limit=all`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    return dispatch(recieveBonusReports(data));
  };
}

export function generateReport (userId, includeCurrentMonth = false) {
  return async () => {
    const { status, data, errors } = await API.POST(`/bonus-reports/generate-report/${userId}`, { includeCurrentMonth });

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    return data;
  };
}

export function generateCSVReport () {
  return async () => {
    const { status, data, errors } = await API.POST('/bonus-reports/generate-reports');

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    return data;
  };
}

export function updateReportStatus (verb) {
  return async (dispatch, getState) => {
    const { bonusReports: { bonusReport } } = getState();
    const route = (verb === 'paid') ? 'mark-paid' : 'mark-confirmed';
    const { status, data, errors } = await API.POST(`/bonus-reports/${bonusReport.id}/${route}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    dispatch(recieveBonusReport({ ...data.report }));
    return dispatch(recieveResolvedReport(data));
  };
}

export function getUnpaidReportTotals () {
  return async (dispatch) => {
    const { status, data, errors } = await API.GET('/bonus-reports/get-totals');

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return null;
    }

    return dispatch(recieveTotals(data));
  };
}
