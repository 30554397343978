import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Notice } from 'Layout';
import API from '../stores/API';
import { Input } from './FormElements';
import store from '../stores/RootStore';
import LoadingButton from './FormElements/LoadingButton';

function HasLawyerOrder ({ orderId }) {
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasLawyerOrder, setHasLawyerOrder] = useState(null);

  const onControlBtnClick = async () => {
    setIsLoading(true);
    setHasLawyerOrder(null);

    const res = await API.POST('/customers/has-lawyer-order', { social_security_number: socialSecurityNumber, order_id: orderId });

    setIsLoading(false);

    if (res.status === 'ok') {
      setHasLawyerOrder(res.has_lawyer_order);
    } else {
      store.noticeStore.showNotice(res.errors.error_message, 'error');
    }
  };

  return (
    <div className='has-lawyer-order'>
      <h4>Kontroll av eventuell motpart för jävsituation</h4>

      <form>
        <Input
          label='Personnummer'
          value={socialSecurityNumber}
          onChange={value => setSocialSecurityNumber(value)}
        />

        <LoadingButton
          role='button'
          className='btn'
          onClick={() => onControlBtnClick()}
          disabled={!socialSecurityNumber}
          isLoading={isLoading}
        >
          Kontrollera
        </LoadingButton>
      </form>

      {(hasLawyerOrder !== null && !hasLawyerOrder) && (
        <Notice type='success'>
          <h4>Ingen misstanke om jäv</h4>
          <p>Din kunds motpart finns inte i Lavendlas databas och ingen misstanke om jäv finns.</p>
        </Notice>
      )}

      {(hasLawyerOrder !== null && hasLawyerOrder) && (
        <Notice type='warning'>
          <h4>Möjlig jävsituation</h4>
          <p>
            Din kunds motpart finns i Lavendlas databas, vänligen
            {' '}<a href='https://support.lavendla.se/' target='_blank' rel='noopener noreferrer'>kontakta Lavendla Support</a>
            {' '}för utredning av jäv.
          </p>
        </Notice>
      )}
    </div>
  );
}

HasLawyerOrder.propTypes = {
  orderId: PropTypes.number.isRequired
};

export default HasLawyerOrder;
