import { useDispatch, useSelector } from 'react-redux';
import { getCallRoutes, saveCallRoutes } from '../actions/callRoutes';

function useCallRoutes () {
  const dispatch = useDispatch();
  const { callRoutes } = useSelector(state => state.callRoutes);

  return {
    callRoutes,
    getCallRoutes: (countryId) => dispatch(getCallRoutes(countryId)),
    saveCallRoutes: (countryId, callRoutes) => dispatch(saveCallRoutes(countryId, callRoutes))
  };
}

export default useCallRoutes;
