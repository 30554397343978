import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { formatUser, generateId } from 'Library/functions';
import useUsers from 'ReduxHooks/useUsers';
import { useTranslation } from 'react-i18next';

function SupervisorDropdown ({
  id = generateId(),
  selected = [],
  onChange = null,
  isMulti = false,
  locationId = null,
  isClearable = true,
  withArchived = false,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { dropdownUsers, loadDropdownUsers } = useUsers();
  const { t } = useTranslation();

  useEffect(() => {
    if (selected && selected.length > 0) {
      fetchUsers();
    }
  }, [selected]);

  async function fetchUsers () {
    if (isLoading) {
      return;
    }

    const filters = {
      withArchived
    };

    if (locationId) {
      filters.locationId = locationId;
    }

    setIsLoading(true);
    await loadDropdownUsers(filters);
    setIsLoading(false);
  }

  function normalizeValue (data) {
    if (!data) {
      return [];
    }

    if (!Array.isArray(data)) {
      data = [data];
    }

    data = data.map(item => {
      if (typeof item === 'string' || typeof item === 'number') {
        return parseInt(item);
      }

      if (typeof item.id !== 'undefined') {
        return parseInt(item.id);
      }

      if (typeof item.value !== 'undefined') {
        return parseInt(item.value);
      }

      return null;
    }).filter(data => data);

    if (!data.length) {
      return [];
    }

    return dropdownUsers.filter(user => data.includes(user.id));
  }

  const update = values => {
    onChange(normalizeValue(values));
  };

  function getValue () {
    if (!dropdownUsers.length) {
      return null;
    }

    return normalizeValue(selected).map(formatUser);
  }

  function getOptions () {
    return [...dropdownUsers].map(formatUser);
  }

  return (
    <Select
      id={id}
      onFocus={() => fetchUsers()}
      isMulti={isMulti}
      onChange={update}
      value={getValue()}
      options={getOptions()}
      placeholder={t('Choose an advisor')}
      isClearable={isClearable}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      {...rest}
    />
  );
}

SupervisorDropdown.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  locationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  withArchived: PropTypes.bool
};

export default SupervisorDropdown;
