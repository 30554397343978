import React from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Checkbox } from 'FormElements';
import { useTranslation } from 'react-i18next';

function PartialInvoiceToggle () {
  const { t } = useTranslation();
  const { order, updateField, updateOrderStatus, isTherapyOrder } = useSingleOrder();
  const isDisabled = order.is_partially_invoiced && order.invoices.length;

  async function toggle (checked) {
    await updateField('is_partially_invoiced', checked);

    if (checked) {
      updateOrderStatus('partially_invoiced');
    }
  }

  let label = t('Partially invoice') + ' <small>(' + t('Invoice this order over several occasions') + ')</small>';

  if (isTherapyOrder) {
    label = t('Partially invoice') + ' <small>(' + t('Pay this order over several occasions') + ')</small>';
  }

  return (
    <Checkbox
      data-cy='invoice-toggle'
      label={label}
      defaultChecked={!!order.is_partially_invoiced}
      onChange={checked => toggle(checked)}
      disabled={isDisabled}
    />
  );
}

export default PartialInvoiceToggle;
