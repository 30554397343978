import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PriceEditor = ({
  canEditPrice,
  setPrice,
  price
}) => {
  const { t } = useTranslation();

  if (!canEditPrice) {
    return null;
  }

  const handlePriceChange = ({ target }) => {
    let changedPrice = target.value.replace(',', '.');

    changedPrice = changedPrice.replace(/[^0-9.]+/g, '');

    setPrice(changedPrice);
  };

  return (
    <div className='popup-quantity-container'>
      <label htmlFor='price'>{t('Price')}</label>
      <input type='text' name='price' onChange={handlePriceChange} value={price} placeholder={t('Provide a price')}/>
    </div>
  );
};

PriceEditor.propTypes = {
  canEditPrice: PropTypes.bool,
  setPrice: PropTypes.func,
  price: PropTypes.string
};

export default PriceEditor;
