import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LoadingButton = ({
  onClick,
  text = null,
  isLoading = false,
  disabled = false,
  className = '',
  children = null,
  ...rest
}) => (
  <button
    role="button"
    aria-disabled={isLoading}
    disabled={disabled || isLoading}
    className={classNames('btn', { loading: isLoading }, className)}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
    {...rest}
  >
    <span className="btn-text">{children || text}</span>
    <div className="btn-loading"/>
  </button>
);

LoadingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default LoadingButton;
