import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'Library/functions';

export default function useTextParserProducts () {
  const { order } = useSingleOrder();
  const { t } = useTranslation();

  function getProductInTerms (searchTerms) {
    if (!Array.isArray(searchTerms)) {
      searchTerms = [searchTerms];
    }

    const product = order.products.find(({ categories }) => {
      if (!Array.isArray(categories) || !categories.length) {
        return false;
      }

      for (const term of categories) {
        if (searchTerms.includes(term.slug)) {
          return true;
        }
      }

      return false;
    });

    return (product) ? product.name : '';
  }

  function getFlowersList () {
    return order.products
      .filter(({ categories }) => {
        if (!Array.isArray(categories) || !categories.length) {
          return false;
        }

        return !!categories.find(({ slug }) => ['begravningsblommor', 'blomster', 'hautajaiskukat'].includes(slug));
      })
      .map(({ categories, name, pivot }) => {
        const childTerm = categories.find(({ parent }) => parent > 0);
        let text = name;

        if (childTerm) {
          text += ` (${childTerm.name})`;
        }

        text += ` x ${pivot.quantity}`;

        return text;
      })
      .join('<br>');
  }

  function getHeadstone () {
    const headstoneTerms = [
      'Gravstenar, gravkors & gravlyktor',
      'Breda gravstenar',
      'Höga Gravstenar',
      'Liggande gravstenar',
      'Förnya befintlig gravsten',
      'Gravkors',
      'Gravlyktor',
      'Gravsten',
      'Liggande',
      'Stående'
    ];

    const headstone = order.products.find(({ categories }) => {
      if (!Array.isArray(categories) || !categories.length) {
        return false;
      }

      return !!categories.find(({ name }) => headstoneTerms.includes(name));
    });

    if (!headstone) {
      return '';
    }

    let childTerm = headstone.categories.find(({ parent }) => parent > 0);
    childTerm = (childTerm) ? ` (${childTerm.name})` : '';

    return headstone.name + childTerm;
  }

  function hasProgram () {
    const hasProgram = order.products.find(({ name }) => name.indexOf('Programblad') > -1);

    return (hasProgram) ? t('Yes') : t('No');
  }

  function hasHost () {
    const hasProduct = !!order.products.find(({ categories }) => {
      if (!Array.isArray(categories) || !categories.length) {
        return false;
      }

      return !!categories.find(({ sku }) => sku === '0501001-1');
    });

    return (hasProduct) ? t('Yes') : t('No');
  }

  function getFeeForCivicOfficial () {
    const civicOfficialNames = ['Borgerlig officiant', 'Ceremonileder', 'Seremonileder', 'Siviilivirkailija'];
    const product = order.products.find(({ name }) => civicOfficialNames.includes(name));

    return (product) ? formatCurrency(product.pivot.price * 0.7, null, order.country.code, order?.currency) : '';
  }

  return {
    getProductInTerms,
    getHeadstone,
    getFlowersList,
    hasProgram,
    hasHost,
    getFeeForCivicOfficial
  };
}
