import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getOrderLink } from 'Library/functions';

function ActiveUserOrders ({ orders = [] }) {
  if (!orders.length) {
    return null;
  }

  return (
    <div className='active-user-orders'>
      <h4>Pågående ärenden</h4>

      <p>Följande ärenden behöver omdelegeras, raderas eller arkiveras innan användaren kan inaktiveras.</p>

      {orders.map(order => (
        <span key={order.id}>
          <Link to={getOrderLink(order)}>#{order.number}</Link> ({order.status_translated})
        </span>
      ))}
    </div>
  );
}

ActiveUserOrders.propTypes = {
  orders: PropTypes.array
};

export default ActiveUserOrders;
