import React, { useState } from 'react';
import { LoadingButton } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Notice } from 'Layout';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { animateTop } from 'Library/motion';
import { useTranslation } from 'react-i18next';

function DuplicateOrderLink () {
  const { t } = useTranslation();
  const { duplicate, order, isLawyerOrder, isTherapyOrder } = useSingleOrder();
  const [isLoading, setIsLoading] = useState(false);
  const [duplicatedOrder, setDuplicatedOrder] = useState(null);

  async function duplicateOrder () {
    setIsLoading(true);
    const response = await duplicate(order);
    setIsLoading(false);

    if (!response) {
      return;
    }

    setDuplicatedOrder(response);
  }

  if (!isLawyerOrder && !isTherapyOrder) {
    return null;
  }

  const redirectRoot = isLawyerOrder ? 'juridik' : 'therapy';

  return (
    <div className='duplicate-order-link'>
      <LoadingButton
        className='btn-link'
        disabled={isLoading || duplicatedOrder}
        onClick={() => duplicateOrder()}
      >
        {t('Copy and create a new order')}
      </LoadingButton>
      <AnimatePresence>
        {duplicatedOrder ? (
          <motion.div
            {...animateTop}
          >
            <Notice
              type='success'
              heading={`${t('The order')} ${duplicatedOrder.number} ${t('was created')}`}
            >
              <Link to={`/${redirectRoot}/${duplicatedOrder.id}`}>{t('View order')}</Link>
            </Notice>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default DuplicateOrderLink;
