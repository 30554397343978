import React from 'react';
import { Fieldset } from 'FormElements';
import ImageList from './ImageList';
import ImageListUploadButton from './ImageListUploadButton';
import { useTranslation } from 'react-i18next';

function CardBgImage () {
  const { t } = useTranslation();
  return (
    <Fieldset
      legend={`1. ${t('Select card bacground')}`}
      className='memorial-page-images'
      subField
    >
      <p className='memorial-page__section_description'>
        {t('Start by choosing the background image. If you have already selected a background image for the memorial page, this image is also pre-selected for the card (does not apply to uploaded images). However, you can select a completely different image for the card if desired.')}
      </p>

      <div className='image-list__wrapper'>
        <ImageListUploadButton isCardImageList />
        <ImageList isCardImageList />
      </div>
    </Fieldset>
  );
}

export default CardBgImage;
