import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ModalHeader from './ModalHeader';

const LavendlaModal = ({
  children,
  className,
  close,
  headerTitle,
  headerNode,
  isOpen,
  isProduct,
  parentSelector
}) => (
  <Modal
    className={className}
    isOpen={isOpen}
    parentSelector={parentSelector}
    onRequestClose={close}
    contentLabel=''
  >
    <ModalHeader
      title={headerTitle}
      titleNode={headerNode}
      close={close}
      isProduct={isProduct}
    />
    <div className='modal-container modal-box-main'>
      {children}
    </div>
  </Modal>
);

LavendlaModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  close: PropTypes.func,
  headerTitle: PropTypes.string,
  headerNode: PropTypes.node,
  isOpen: PropTypes.bool,
  isProduct: PropTypes.bool,
  parentSelector: PropTypes.func
};

export default LavendlaModal;
