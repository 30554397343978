import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Product from './Product';
import ProductList from './ProductList';
import Spinner from '../Spinner';
import API from '../../stores/API';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

const getProductFromExternalApi = async (id, apiUrl = 'https://lavendla.se') => {
  if (!id) {
    return null;
  }

  const apiProduct = await API.GET(`/products/${id}`, true, apiUrl);

  return apiProduct;
};

const Funnel = ({
  orderInfo,
  products,
  categoryName,
  setIsProduct
}) => {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentProducts, setCurrentProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getExternalApiUrl } = useSingleOrder();

  useEffect(() => {
    setIsLoading(true);
    setCurrentProducts(products);
    setIsLoading(false);
    setIsProduct(false);
  }, []);

  useEffect(() => {
    if (!currentProductId) {
      return;
    }
    // Get new product data from Lavendla Funnel API.
    setProduct();
    setIsProduct(true);
  }, [currentProductId]);

  const setProduct = async () => {
    setIsLoading(true);

    const product = await getProductFromExternalApi(currentProductId, getExternalApiUrl());

    setCurrentProduct(product);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  if (currentProduct) {
    return (
      <Product
        product={currentProduct}
        countryCode={orderInfo.order.country.code}
        orderInfo={orderInfo}
      />
    );
  }

  return (
    <ProductList
      categoryName={categoryName}
      orderInfo={orderInfo}
      products={currentProducts}
      selectProduct={productId => setCurrentProductId(productId)}
    />
  );
};

Funnel.propTypes = {
  orderInfo: PropTypes.object,
  products: PropTypes.array,
  categoryName: PropTypes.string,
  setIsProduct: PropTypes.func
};

export default Funnel;
