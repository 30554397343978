import React from 'react';
import Imgix from 'react-imgix';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const UserImage = ({
  user = null,
  className = '',
  width = 75,
  height = 75,
  imgixParams = {
    fit: 'facearea',
    auto: 'format',
    facepad: 6
  }
}) => {
  const { t } = useTranslation();
  className += ' user-image';

  if (user && user.profile_image) {
    return (
      <Imgix
        className={className}
        src={user.profile_image}
        alt={user.name}
        width={width}
        height={height}
        imgixParams={imgixParams}
      />
    );
  }

  return (
    <img
      width={width}
      height={height}
      className={className}
      src='/icons/users-dark.svg'
      alt={user ? user.name : t('unknown advisor')}
    />
  );
};

UserImage.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  imgixParams: PropTypes.object
};

export default UserImage;
