import React from 'react';
import useSingleOrder from '../Redux/hooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const RequestedPlace = () => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { requested_place: requestedPlace } = order.funeral || {};

  if (!requestedPlace) {
    return null;
  }

  return (
    <div className='requested-place'>
      <h4 className='requested-place__title'>{t('Requested time and place')}</h4>

      <ul className='requested-place__list'>
        {requestedPlace.map(place => Place(place))}
      </ul>
    </div>
  );
};

const Place = place => (
  <li className='requested-place__list-item' key={place.postId}>
    <div className='requested-place__list-item-wrapper'>
      <div className='requested-place__image'>
        <img src={place.postImgUrl} alt={place.postTitle} />
      </div>

      <div className='requested-place__content'>
        <h5>{place.postTitle}</h5>

        {place.times.map(time => Time(time))}
      </div>
    </div>
  </li>
);

const Time = time => (
  <span className='requested-place__single-time' key={time.sortableTime}>
    {time.presentationFormat}
  </span>
);

export default RequestedPlace;
