import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import classNames from 'classnames';
import ToggleInvoicedProduct from './ToggleInvoicedProduct';
import { formatDate, formatProductPrice } from 'Library/functions';
import MarkProductForPartialInvoicing from './MarkProductForPartialInvoicing';
import LoadingButton from './FormElements/LoadingButton';
import { Input, Textarea } from './FormElements';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { If } from 'Conditionals';
import ProductUserDropdown from './Funnel/ProductParts/ProductUserDropdown';
import { useTranslation, Trans } from 'react-i18next';

function ProductRow ({ product }) {
  const { t } = useTranslation();
  const { order, isLawyerOrder, isTombstoneOrder, getCountryCode, isTherapyOrder } = useSingleOrder();
  const { updateProduct, detachProduct, getImgSrc } = useOrderProducts();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(parseInt(product.quantity));
  const [price, setPrice] = useState(product.price);
  const [comment, setComment] = useState(product.comment);
  const [userId, setUserId] = useState(product.pivot.user_id);
  const isDisabled = !!product.pivot.invoice_id;

  const saveProductData = async () => {
    const data = {
      quantity,
      price,
      comment,
      user_id: userId,
      meta: product.meta
    };

    if (data.quantity === '' || data.price === '') {
      showWarningNotice(t('Please provide a quanity and a price'));
      return;
    }

    setIsLoading(true);
    await updateProduct(product.pivot.product_resource_id, data);
    setIsLoading(false);
    setIsModalOpen(false);

    const { name } = product;

    showNotice(
      <Trans name={name}>
        The product <strong>{{ name }}</strong> has been updated
      </Trans>
    );
  };

  const removeProduct = async () => {
    setIsLoading(true);
    await detachProduct(product.pivot.product_resource_id);
    setIsLoading(false);

    const { name } = product;

    showNotice(
      <Trans name={name}>
        The product <strong>{{ name }}</strong> has been removed
      </Trans>
    );
  };

  const openModal = e => {
    e.preventDefault();

    setIsModalOpen(true);
  };

  const handleQuantityChange = (value) => {
    let q = value.replace(',', '.');
    q = q.replace(/[^0-9.]+/g, '');

    setQuantity(q);
  };

  function getInvoicedDate () {
    if (!product.invoice_id) {
      return null;
    }

    const invoice = order.invoices.find(({ id }) => id === product.invoice_id);
    if (!invoice) {
      return null;
    }

    const invoiceId = invoice.fortnox_invoice_id || invoice.vismanet_invoice_id;
    const isStripePayment = invoice.stripe_id !== null;

    if (isTherapyOrder) {
      if (isStripePayment) {
        return (
          <small>
            {invoice.offer_id ? <span>#{invoice.offer_id}</span> : null}
            {invoice.paid_at ? <span>{t('Paid')}: {invoice.paid_at}</span> : null}
            <span>{invoice.coupon_code ? <>{t('Coupon')}: {invoice.coupon_code}</> : null} ({invoice.coupon_code ? <>{invoice.coupon_value}</> : null}{invoice.coupon_code && invoice.coupon_type === 'procent' ? '%' : ' ' + order?.currency.symbol})</span>
          </small>
        );
      } else {
        return (
          <small>
            <span>{t('Waiting for payment')}</span>
            <span />
          </small>
        );
      }
    }

    return (
      <small>
        <span>{t('Invoiced')} (#{invoiceId})</span>
        <span>{formatDate(invoice.created_at, getCountryCode())}</span>
      </small>
    );
  }

  const isLabel = ['band', 'band-till-begravningsblommor', 'blomsterbaand'].includes(product.slug); // TODO: FIX?

  return (
    <div className={classNames('product-row', { 'is-disabled': isDisabled })}>
      <div className='col product-col'>
        <div className='thumbnail-container'>
          {typeof product.media[0] !== 'undefined'
            ? <img className='product-thumbnail' src={encodeURI(getImgSrc(product.media[0]))} />
            : <div className='no-thumbnail' />}
        </div>
        <div className='product-name'>
          {product.name} {(product.sku && product.sku.includes('0401005-1'))
            ? product.pivot.user ? <span className='other-lawyer'><br />{product.pivot.user.name}</span> : <span className='other-lawyer'><br />{t('No lawyer set')}</span>
            : null}
        </div>
      </div>
      <div className='col quantity-col'>
        <span className='quantity'>× {product.quantity}</span>
      </div>
      <div className='col price-col'>
        <span className='price'>{formatProductPrice(product, order.country.code, order?.currency)}</span>
      </div>
      <div className='col comment-col'>
        {product.comment}
      </div>
      <If condition={!isLawyerOrder && !isTherapyOrder}>
        <div className='col mark-invoiced-col'>
          <ToggleInvoicedProduct
            type={isTombstoneOrder ? 'tombstoneOrder' : 'order'}
            product={product}
            order={order}
          />
        </div>
      </If>
      <div className='col partial-invoice-col'>
        <div className='mark-product-for-partial-invoicing'>
          <If condition={order.is_partially_invoiced}>
            <MarkProductForPartialInvoicing
              product={product}
              order={order}
              isDisabled={isDisabled}
            />
          </If>
        </div>
      </div>
      <div className='col action-col'>
        {!isDisabled ? (
          <a href='#' className='alter-product' onClick={openModal}>{t('Change')}</a>
        ) : (
          getInvoicedDate()
        )}
        {isLabel || isDisabled
          ? null
          : (
            <a className={isLoading ? 'delete-product loading' : 'delete-product'} onClick={removeProduct}>
              <ReactSVG src='/icons/icon-remove.svg' />
            </a>
          )}
      </div>

      <Modal
        className='order-product-modal'
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel=''
      >
        <button className='close-modal' onClick={() => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        <h4>{t('Change')} {product.name}</h4>

        <form className='order-tab__form'>
          <div className='row'>
            <Input
              onChange={value => setPrice(value)}
              value={price}
              label={t('Price')}
              data-cy='product-row.price'
              className='col-12'
              floatingLabel
              required
            />
          </div>
          <div className='row'>
            <Input
              onChange={handleQuantityChange}
              value={quantity}
              label={t('Quantity')}
              data-cy='product-row.quantity'
              className='col-12'
              floatingLabel
              required
            />
          </div>
          <div className='row'>
            <Textarea
              onChange={value => setComment(value)}
              value={comment}
              data-cy='product-row.comment'
              label={t('Comment')}
              className='col-12'
              floatingLabel
              rows={5}
            />
          </div>
          <ProductUserDropdown
            userId={userId}
            product={product}
            onChange={value => setUserId(value)}
          />
          <div className='row'>
            <div className='col-12'>
              <LoadingButton onClick={saveProductData} isLoading={isLoading}>
                {t('Save')}
              </LoadingButton>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}

ProductRow.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductRow;
