/**
 * Fieldset component for forms.
 *
 * Uses best practices for accessibility: https://a11y-style-guide.com/style-guide/section-forms.html
 * Using div with role="group" since fieldset does not support flexbox:
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1047590
 * https://bugzilla.mozilla.org/show_bug.cgi?id=984869
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

const Fieldset = ({
  children,
  className,
  legend,
  subField = false,
  ...rest
}) => {
  const legendId = uniqueId('legend_');

  const heading = subField
    ? <h4 id={legendId} className='legend'>{legend}</h4>
    : <h3 id={legendId} className='legend'>{legend}</h3>;

  return (
    <div
      role='group'
      aria-labelledby={legendId}
      className={classNames('fieldset', className)}
      {...rest}
    >
      {legend ? (
        heading
      ) : null}

      {children}
    </div>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  legend: PropTypes.string,
  subField: PropTypes.bool
};

export default Fieldset;
