import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';
import moment from 'moment';

function useOrderAttendees () {
  const { order, orderActionCallback } = useSingleOrder();
  const { attendees = [] } = order;

  async function create (data) {
    const response = await API.POST(`/orders/${order.id}/attendees`, data);

    return orderActionCallback(response);
  }

  async function update (id, data) {
    const response = await API.PUT(`/orders/${order.id}/attendees/${id}`, data);

    return orderActionCallback(response);
  }

  async function remove (attendeeId) {
    const response = await API.DELETE(`/orders/${order.id}/attendees/${attendeeId}`);

    return orderActionCallback(response);
  }

  function getNumAttendees (queued = false) {
    return getAttendees(queued)
      .reduce((carry, { num_attendees: numAttendees }) => carry + numAttendees, 0);
  }

  function getAttendees (queued = false) {
    return attendees.filter(attendee => (queued) ? attendee.queued : !attendee.queued);
  }

  const sortQueuedAttendees = (a, b) => moment(a.queued).valueOf() - moment(b.queued).valueOf();

  function hasAttendees (queued = false) {
    return getAttendees(queued).length > 0;
  }

  return {
    create,
    update,
    remove,
    getNumAttendees,
    getAttendees,
    sortQueuedAttendees,
    hasAttendees
  };
}

export default useOrderAttendees;
