import React from 'react';
import { useTranslation } from 'react-i18next';
import FlowerShopOrder from './FlowerShopOrder';
import useFlowerOrderProducts from '../Redux/hooks/useFlowerOrderProducts';

function FlowerShopOrders () {
  const { t } = useTranslation();
  const { flowerShopOrders } = useFlowerOrderProducts();
  if (!flowerShopOrders.length) {
    return null;
  }

  return (
    <div className='flowershop-flowers'>
      <h3>{t('Flowers from guests')}</h3>

      {flowerShopOrders.map(flowerShopOrder => (
        <FlowerShopOrder
          key={flowerShopOrder.id}
          flowerShopOrder={flowerShopOrder}
        />
      ))}
    </div>
  );
}

export default FlowerShopOrders;
