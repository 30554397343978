import React from 'react';
import PropTypes from 'prop-types';
import CategoryRow from './CategoryRow';

const ChildCategories = ({ categories, ...rest }) => (
  <div className='product-categories-children'>
    {categories.map((category, i) => {
      return (
        <CategoryRow
          key={i}
          category={category}
          {...rest}
        />
      );
    })}
  </div>
);

ChildCategories.propTypes = {
  categories: PropTypes.array.isRequired
};

export default ChildCategories;
