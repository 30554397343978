import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useOrderFiles from 'ReduxHooks/useOrderFiles';
import API from '../stores/API';
import { LoadingButton } from 'FormElements';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals/index';

const File = ({ file }) => {
  const { t, Trans } = useTranslation();
  const { deleteFile } = useOrderFiles();
  const { isAdmin } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const deleteCurrentFile = async () => {
    setIsLoading(true);
    const response = await deleteFile(file.id);
    setIsLoading(false);

    if (response.status === 'error') {
      showWarningNotice(response.errors.details);
    }
  };

  const downloadfile = async (e) => {
    setIsLoadingPreview(true);

    const myWindow = window.open('');
    myWindow.document.write('\n\n<h1>Loading...</h1>');

    const response = await API.GET(`/files/get-download-link/${file.id}`);
    setIsLoadingPreview(false);

    if (response.status === 'ok') {
      myWindow.location = response.url;
      myWindow.focus();

      return;
    }

    const errorMessage = response.errors.title;

    showWarningNotice(
      <Trans errorMessage={errorMessage}>
        There was an error: {{ errorMessage }}
      </Trans>
    );
  };

  function userCanDeleteFile () {
    return !!isAdmin;
  }

  return (
    <tr className='single-file'>
      <td>{file.filename}</td>
      <td>{file.created_at}</td>
      <td>
        <a
          href='#'
          className={isLoadingPreview ? 'loading-preview' : ''}
          onClick={(e) => {
            e.preventDefault();
            downloadfile();
          }}
        >
          {t('Download')}
        </a>
        <If condition={userCanDeleteFile()}>
          <LoadingButton
            className='small delete'
            disabled={isLoading}
            onClick={() => deleteCurrentFile()}
          >
            {t('Delete')}
          </LoadingButton>
        </If>
      </td>
    </tr>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired
};

export default File;
