import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg';
import { formatCurrency } from 'Library/functions';
import API from '../stores/API';
import useSettings from 'ReduxHooks/useSettings';
import { Notice } from 'Layout';
import { AnimatePresence, motion } from 'framer-motion';
import { If } from 'Conditionals';
import { animateTop } from 'Library/motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const OrderBonus = () => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { getSetting } = useSettings();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [bonus, setBonus] = useState(0);

  useEffect(() => {
    getBonuses();
  }, [order.id, order.products]);

  async function getBonuses () {
    const response = await API.GET(`/bonuses/get-bonus-for-order/${order.id}?order_type=${order.class}`);

    setBonus(response);
  }

  if (getSetting('hideBonuses')) {
    return null;
  }

  return (
    <div className='order-bonus'>
      <div className='order-bonus__container'>
        <div className='order-bonus__inner'>
          <span className='order-bonus__inner-icon dark'>
            <ReactSVG src='/icons/icon-work.svg' />
          </span>
          {t('Preliminary compensation', {
            description: 'Preliminär upparbetad ersättning'
          })}&nbsp;
          <span className='order-bonus__inner-amount'>
            <strong>
              {t('approx.', {
                description: 'ca'
              })}
              &nbsp;
              {formatCurrency(bonus, 2, order.country.code, order?.currency)}
            </strong>
          </span>
          <button
            role='button'
            className='order-bonus__icon'
            onClick={e => {
              e.preventDefault();
              setShowDisclaimer(!showDisclaimer);
            }}
          >
            <FontAwesomeIcon
              size='lg'
              icon={['fal', 'question-circle']}
            />
          </button>
        </div>
      </div>

      <AnimatePresence>
        <If condition={showDisclaimer}>
          <motion.div
            className='row'
            {...animateTop}
          >
            <div className='col-12'>
              <Notice
                type='warning'
                heading={t('Regarding. "Preliminary compensation"')}
                onClose={() => setShowDisclaimer(false)}
              >
                <p>
                  {t('Compensation is a preliminary estimate for each case. This compensation excludes, amongst other things, fees for representative assignments, creation of legal or tombstone cases, etc.')}
                </p>
                <p>
                  {t('The final compensation is visible on your bonsus report and is calculated in a more reliable way than this estimate. It is therefore not unusual that this figure will differ from the final report.')}
                </p>
              </Notice>
            </div>
          </motion.div>
        </If>
      </AnimatePresence>
    </div>
  );
};

export default OrderBonus;
