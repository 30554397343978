import { SET_PAGINATION, SET_SUPPLIER, SET_SUPPLIERS, SET_PENNYBRIDGE_LIST } from '../actions/suppliers';

const initialState = {
  supplier: null,
  suppliers: [],
  pagination: null,
  pennyBridgeList: []
};

export default function suppliers (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SUPPLIER: {
      return {
        ...state,
        supplier: payload
      };
    }

    case SET_SUPPLIERS: {
      return {
        ...state,
        suppliers: payload
      };
    }

    case SET_PAGINATION: {
      return {
        ...state,
        pagination: payload
      };
    }

    case SET_PENNYBRIDGE_LIST: {
      return {
        ...state,
        pennyBridgeList: payload
      };
    }

    default:
      return state;
  }
}
