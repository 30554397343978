import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageListUploadButton from './ImageListUploadButton';
import ImageList from './ImageList';
import { LoadingButton } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import MemorialPageModal from './MemorialPageModal';
import { useTranslation } from 'react-i18next';

function HeaderBackgroundImage ({
  isOpen = false,
  closeModal
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { persist } = useSingleOrder();

  async function closePersist () {
    setIsLoading(true);
    await persist(false, false);
    setIsLoading(false);
    closeModal();
  }

  return (
    <MemorialPageModal
      closeModal={closeModal}
      isOpen={isOpen}
      header={t('Select background (mandatory)')}
      wide
    >
      <div className='memorial-page-images'>
        <ImageListUploadButton/>
        <ImageList/>
      </div>
      <br/>
      <br/>
      <LoadingButton
        isLoading={isLoading}
        onClick={() => closePersist()}
        data-cy='select-header-bg-button'
      >
        {t('Save selected image')}
      </LoadingButton>
    </MemorialPageModal>
  );
}

HeaderBackgroundImage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default HeaderBackgroundImage;
