import { RESET_ORDER, SET_IS_LOADING, SET_IS_UPDATING, SET_ORDER, SET_ORDER_EVENTS, UPDATE_ORDER } from '../actions/order';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  order: null,
  originalOrder: null,
  isLoading: false,
  isUpdating: false,
  events: []
};

export default function orders (state = initialState, { type, payload }) {
  switch (type) {
    case SET_ORDER:
      return {
        ...state,
        order: payload,
        originalOrder: cloneDeep(payload)
      };

    case UPDATE_ORDER:
      return {
        ...state,
        order: payload
      };

    case RESET_ORDER:
      return {
        ...state,
        order: cloneDeep(state.originalOrder)
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_IS_UPDATING:
      return {
        ...state,
        isUpdating: payload
      };

    case SET_ORDER_EVENTS:
      return {
        ...state,
        events: payload
      };

    default:
      return state;
  }
}
