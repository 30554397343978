import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox as FormCheckbox } from 'FormElements';
import { isOfferTask, isPaymentTask } from 'Library/Tasks';
import { useTranslation } from 'react-i18next';

function Checkbox ({
  task,
  toggleStatus,
  canSendTasks = true,
  isLoading = false
}) {
  const { t } = useTranslation();
  const canSend = (task.allowlist) ? true : canSendTasks;

  if (task.hide_completed_button || isOfferTask(task) || isPaymentTask(task)) {
    return null;
  }

  return (
    <div className={classNames('checkbox-container', { 'no-confirm': !task.pivot.confirm_received_at })}>
      <div className='task__has-checkbox'>
        <FormCheckbox
          className='task-input-checkbox'
          label={!task.pivot.completed ? t('Mark as done') : t('Marked as done')}
          onChange={toggleStatus}
          checked={!!task.pivot.completed}
          disabled={!canSend || isLoading}
        />
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  task: PropTypes.object.isRequired,
  toggleStatus: PropTypes.func.isRequired,
  canSendTasks: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Checkbox;
