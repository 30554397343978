import {
  isCreateLawyerOrderTask,
  isCreateMemorialPageTask,
  isCreateTherapyOrderTask,
  isInvoiceTask,
  isTransportTask
} from 'Library/Tasks';
import { removePartialInvoiceProducts } from '../Redux/actions/products';
import { useDispatch, useSelector } from 'react-redux';
import useOrderTasks from 'ReduxHooks/useOrderTasks';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import API from '../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

function useTaskActions (task) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { partialInvoiceProducts } = useSelector(state => state.products);
  const { sendTaskEmail, updateTask, markTaskCompleted } = useOrderTasks();
  const { createFromOrder, order } = useSingleOrder();

  function hasRequiredClientDocumentation () {
    if (order.class === 'App\\LawyerOrder' && isInvoiceTask(task)) {
      return !!order.files.find(({ type }) => type === 'client_documentation');
    }

    return true;
  }

  function getResponseMessageForTask () {
    if (isInvoiceTask(task)) {
      return '🎉 ' + t('The invoice has been created!');
    }

    if (isCreateMemorialPageTask(task)) {
      return '🎉 ' + t('The memorial page has been created!');
    }
    return sprintf('📨  ' + t('%s has been sent.'), `<strong>${task.name}</strong>`);
  }

  function canSend () {
    if (!hasRequiredClientDocumentation()) {
      // TODO: Only used in Sweden as of now, so we can hardcode the message.
      showWarningNotice(
        '<strong>Dokument fattas!</strong><br/>' +
        "För att kunna fakturera ärendet, behöver du ladda upp det dokument som du levererat till din klient under 'fliken'." +
        'Om uppdraget inte resulterade i ett slutligt dokument, vänligen ladda upp annat motsvarigt underlag.',
        12000
      );

      return false;
    }

    if (isTransportTask(task) && ['inquiry', 'offer'].includes(order.status)) {
      showWarningNotice(
        t('The offer needs to be approved in order for us to book the transport. The offer is approved by the customer via the email the customer received.') +
        '<br/><br/>' +
        t('If the customer approved the offer in another way in writing that is alright. We can manually approve the quote by clicking on Approve in the order list.'),
        12000
      );

      return false;
    }

    return true;
  }

  const sendEmail = async (values = {}, files = [], offerFiles = []) => {
    const formData = new FormData();

    if (!canSend()) {
      return;
    }

    formData.append('sendcopy', '1');
    formData.append('partial_invoice_products', partialInvoiceProducts);

    for (const [key, value] of Object.entries(values)) {
      if (!['partial_invoice_products', 'files', 'offer_files', 'customerContactRecipients'].includes(key) && value) {
        formData.append(key, value.toString());
      }
    }

    for (const id of files) {
      formData.append('files[]', id);
    }

    for (const id of offerFiles) {
      formData.append('offer_files[]', id);
    }

    for (const recipient of (values.customerContactRecipients || [])) {
      formData.append('customer_contact_recipients[]', recipient.value);
    }

    const { status } = await sendTaskEmail(task.id, formData);

    if (status === 'error') {
      return false;
    }

    showNotice(getResponseMessageForTask());
    dispatch(removePartialInvoiceProducts());

    return true;
  };

  const saveEmail = async (values) => {
    const data = {
      email_content: values
    };

    const { status, errors } = await updateTask(task.id, data);

    if (status === 'ok') {
      showNotice('📥 ' + t('The e-mail has been saved.'), 'success', 8000);
      return;
    }

    showWarningNotice(
      t('Something went wrong, the task could not be sent. Please contact Lavendla support') + '<br/><br/>' +
      errors.error_message,
      15000
    );
  };

  const generateCoffinLabelPreview = async () => {
    const myWindow = window.open('');
    myWindow.document.write('\n\n<h1>' + t('Loading') + '...</h1>');

    const { status, url, errors } = await API.POST(`/orders/${order.id}/generate-coffin-label`);

    if (status === 'ok') {
      myWindow.location = url;
      myWindow.focus();

      return true;
    }

    showWarningNotice(t('An error occurred') + `: ${errors.title}`);
    return false;
  };

  const createCaseTypeOrder = async (data = {}, files) => {
    const orderType = isCreateLawyerOrderTask(task) ? 'App\\LawyerOrder' : isCreateTherapyOrderTask(task) ? 'App\\TherapyOrder' : 'App\\TombstoneOrder';

    const response = await createFromOrder(orderType, order, {
      ...data,
      files
    });

    if (response.status === 'error') {
      showWarningNotice('😬 ' + t('An error occurred while creating the order') + `: <strong>${response.errors.error_message}</strong>`);
      return false;
    }

    await markTaskCompleted(task.id);

    showNotice(sprintf('🎉' + t('The order %s was created.'), `<strong>#${response.data.number}</strong>`));

    return true;
  };

  return {
    sendEmail,
    saveEmail,
    generateCoffinLabelPreview,
    createCaseTypeOrder
  };
}

export default useTaskActions;
