import React from 'react';
import PropTypes from 'prop-types';
import NewFileUpload from '../NewFileUpload';
import { LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import useSupplier from 'ReduxHooks/useSupplier';
import { LOGO_BLACK, LOGO_WHITE } from '../../Redux/actions/suppliers';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const mimeTypes = [
  'image/svg+xml',
  'image/png',
  'image/jpg',
  'image/jpeg'
];

function SupplierImageUpload ({ type = LOGO_BLACK }) {
  const { t } = useTranslation();
  const { supplier, attachFile, detachFile } = useSupplier();
  const logo = supplier.files.find(file => file.type === type);

  const attachImage = (files) => {
    const data = new FormData();

    data.append('type', type);
    data.append('file', files.pop());

    attachFile(data);
  };

  return (
    <div className={classNames('supplier-image-upload', { dark: type === LOGO_WHITE })}>
      <h4>
        {t('Logo')}&nbsp;
        {(type === 'logo_black')
          ? t('dark (for light backgrounds)')
          : t('light (for dark backgrounds)')}
      </h4>
      <NewFileUpload
        mimeTypes={mimeTypes}
        onChange={attachImage}
        multiple={false}
      />
      <If condition={logo}>
        <div className='supplier-image'>
          <div className='supplier-image__image-wrapper'>
            <img
              width='300'
              height='auto'
              src={logo ? logo.url : null}
              alt={`${t('Supplier logo')} ${type}`}
            />
          </div>
          <LoadingButton
            onClick={() => detachFile({ type })}
            className='delete'
          >{t('Remove image')}</LoadingButton>
        </div>
      </If>
    </div>
  );
}

SupplierImageUpload.propTypes = {
  type: PropTypes.string
};

export default SupplierImageUpload;
