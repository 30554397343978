import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuggestedSelectedSupplier from './SuggestedSelectedSupplier';
import ReactSVG from 'react-svg';
import SuppliersList from './SuppliersList';
import LoadingButton from '../FormElements/LoadingButton';
import { AnimatePresence, motion } from 'framer-motion';
import { If } from 'Conditionals';
import { renderAddress } from 'Library/SupplierFunctions';

function SupplierModalPicker ({
  closeModal,
  attachSupplierToOrder,
  selectedSupplier = null,
  suppliers = []
}) {
  const [showAll, setShowAll] = useState(false);
  if (!suppliers || !suppliers.length) {
    return null;
  }

  const sortedSuppliers = [...suppliers];
  const suggestedSupplier = sortedSuppliers.shift();

  if (selectedSupplier) {
    const index = sortedSuppliers.find(({ id }) => selectedSupplier.id === id);
    sortedSuppliers.splice(index, 1);
  }

  const highlightedSuppliers = sortedSuppliers.splice(0, 5);

  function showSelectedSupplier () {
    if (!selectedSupplier) {
      return true;
    }

    return selectedSupplier.id !== suggestedSupplier.id;
  }

  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  function renderHeader () {
    return (selectedSupplier) ? 'Byt transportör' : 'Välj transportör';
  }

  const address = renderAddress(suggestedSupplier);

  return (
    <>
      <button
        role='button'
        className='close-modal'
        onClick={closeModal}
      >
        <ReactSVG src='/icons/icon-close-black.svg' />
      </button>

      <div className='supplier-modal-picker'>
        <h4>{renderHeader()}</h4>

        <If condition={showSelectedSupplier()}>
          <SuggestedSelectedSupplier
            supplier={suggestedSupplier}
            attachSupplierToOrder={attachSupplierToOrder}
            address={address}
          />
        </If>

        <If condition={!!selectedSupplier}>
          <SuppliersList
            attachSupplierToOrder={attachSupplierToOrder}
            suppliers={[selectedSupplier]}
            selectedSupplier={selectedSupplier}
            headerText='Vald leverantör'
          />
        </If>

        <SuppliersList
          attachSupplierToOrder={attachSupplierToOrder}
          suppliers={highlightedSuppliers}
        />

        {showAll ? (
          <AnimatePresence>
            <motion.div {...motionProps} className='all-suppliers'>
              <SuppliersList
                attachSupplierToOrder={attachSupplierToOrder}
                suppliers={sortedSuppliers}
                showHeader={false}
              />
            </motion.div>
          </AnimatePresence>
        ) : (
          <LoadingButton
            onClick={() => setShowAll(true)}
            text='Visa fler'
            className='btn-link show-all-suppliers'
          />
        )}
      </div>
    </>
  );
}

SupplierModalPicker.propTypes = {
  closeModal: PropTypes.func.isRequired,
  attachSupplierToOrder: PropTypes.func.isRequired,
  selectedSupplier: PropTypes.object,
  suppliers: PropTypes.array.isRequired
};

export default SupplierModalPicker;
