import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { If } from 'Conditionals';

function Input ({
  onChange = null,
  label = null,
  type = 'text',
  id = uniqueId('input_'),
  className = '',
  description = null,
  required = false,
  showRequiredIcon = false,
  floatingLabel = false,
  defaultValue,
  value,
  ...rest
}) {
  const [hasValue, setHasValue] = useState(value !== undefined && value !== null && value !== '');

  useEffect(() => {
    setHasValue(value !== undefined && value !== null && value !== '');
  }, [value]);

  const props = {
    id,
    type,
    ...rest
  };

  if (typeof defaultValue === 'undefined') {
    props.value = value !== undefined && value !== null ? value : '';
  } else {
    props.defaultValue = defaultValue !== undefined && defaultValue !== null ? defaultValue : '';
  }

  const additionalClasses = {
    'floating-label': floatingLabel,
    'has-content': hasValue
  };

  const onChangeCallback = ({ target }) => {
    if (onChange) {
      onChange(target.value);
    }
    setHasValue(target.value !== undefined && target.value !== null && target.value !== '');
  };

  return (
    <div className={classNames('input', `type-${type}`, className, additionalClasses)}>
      <If condition={label}>
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
      </If>
      <input
        onFocus={() => setHasValue(true)}
        onBlur={() => setHasValue(value !== '')}
        onChange={onChangeCallback}
        {...props}
      />
      <If condition={showRequiredIcon && !value}>
        <span className='input__icon-required'>
          <FontAwesomeIcon
            icon={['fal', 'exclamation-triangle']}
            color='#ea8b00'
            size='lg'
          />
        </span>
      </If>
      <If condition={description}>
        <span className='description'>{description}</span>
      </If>
    </div>
  );
}

Input.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  showRequiredIcon: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Input;
