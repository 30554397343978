import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import packages from 'Library/productPackages';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import { useTranslation } from 'react-i18next';

const ProductsPackages = ({ countryCode, onChange }) => {
  const [packageId, setPackageId] = useState(null);
  const { attachProduct } = useOrderProducts();
  const { t } = useTranslation();

  function addProducts () {
    if (packages[countryCode][packageId].products.length > 0) {
      packages[countryCode][packageId].products.map(({ id, quantity, price }) => {
        attachProduct(id, { quantity, price });
      });
    }

    onChange();
  }

  useEffect(() => {
    if (packageId !== null) {
      addProducts();
    }
  }, [packageId]);

  const setManual = e => {
    onChange();
  };

  return (
    <div className='products-packages'>
      <h2>{t('Select a type of funeral')}</h2>
      <p>{t('Select one of the following alternatives, or')}
        <span className='chose-by-your-self' onClick={setManual}>
          {t('create your own without preselected products.')}
        </span>
      </p>

      <div className='packages-cards'>
        {packages[countryCode].map((item, i) => (
          <div className='card__wrapper' key={i}>
            <div className='card'>
              <div className='content'>
                <h5>{item.name}</h5>
                {item.description && <p className='description'>{item.description}</p>}
                <ul className='products-packages__products-list'>
                  {item.products.map(product => (
                    <li className='products-packages__single-product' key={product.id}>{product.name}</li>
                  ))}
                </ul>
              </div>
              <div>
                <button disabled={(packageId !== null)} className='btn large' onClick={e => setPackageId(i)}>{t('Add package')}</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ProductsPackages.propTypes = {
  onChange: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired
};

export default ProductsPackages;
