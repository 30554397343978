import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Input, Select, LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';
import useAppointments from 'Hooks/useAppointments';
import { sv, da } from 'date-fns/locale';
import { format } from 'date-fns';
import SupplierDropdown from 'Components/SupplierDropdown';

registerLocale('se', sv);
registerLocale('dk', da);

const SessionForm = ({ session, onChange, onSave, onCancel }) => {
  const { t } = useTranslation();
  const { getMeetingTypesForSelect } = useAppointments();
  const [editedDateTime, setEditedDateTime] = useState(session.start_date ?? null);

  const setDateTime = (date) => {
    setEditedDateTime(date);
    const parsedDate = date ? new Date(date) : null;
    const formattedDate = parsedDate ? format(parsedDate, 'yyyy-MM-dd HH:mm') : null;
    onChange({
      ...session,
      start_date: formattedDate
    });
  };

  const updateField = (field, value) => {
    onChange({
      ...session,
      [field]: value
    });
  };

  const updatePaymentItem = (field, value) => {
    onChange({
      ...session,
      stripePaymentItem: {
        ...session.stripePaymentItem,
        [field]: value
      }
    });
  };

  const disableSupplierDropdown = () => {
    return session.title !== 'meeting_at_our_offices';
  };

  const noSupplierLabel = () => {
    return disableSupplierDropdown()
      ? t('Please select "Meeting at our offices" as the type of meeting')
      : t('-- No meeting venue selected --');
  };

  return (
    <div className='therapy-edit-form'>
      <div className='form-group'>
        <label>{t('Date and time')}</label>
        <DatePicker
          selected={editedDateTime ? new Date(editedDateTime) : null}
          onChange={(date) => setDateTime(date)}
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={15}
          timeCaption='time'
          dateFormat='yyyy-MM-dd HH:mm'
          className='form-control'
          placeholderText={t('Select date and time')}
        />
      </div>
      <div className='form-group'>
        <Input
          label={t('Session length')}
          value={session.meeting_length}
          onChange={(value) => updateField('meeting_length', value)}
          floatingLabel
        />
      </div>
      <div className='form-group'>
        <Select
          label={t('Meeting type')}
          id='meeting-type'
          defaultOption={t('-- Select meeting type --')}
          value={session.title}
          options={getMeetingTypesForSelect()}
          onChange={(value) => updateField('title', value)}
          floatingLabel
        />
      </div>
      <div className='form-group'>
        <SupplierDropdown
          id='meeting-supplier-id'
          type='moteslokal'
          noValueLabel={noSupplierLabel()}
          isDisabled={disableSupplierDropdown()}
          supplier={session.supplier_id ? { id: session.supplier_id } : null}
          onChange={(type, supplier) => updateField('supplier_id', supplier?.id)}
          showSelectedInfo={false}
        />
      </div>
      <div className='form-group'>
        <Input
          label={t('Price')}
          value={session.stripePaymentItem?.amount}
          onChange={(value) => updatePaymentItem('amount', value)}
          floatingLabel
        />
      </div>
      <div className='form-group btn-group'>
        <LoadingButton className='btn' onClick={onSave}>
          {t('Save')}
        </LoadingButton>
        <button className='btn' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

SessionForm.propTypes = {
  session: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default SessionForm;
