import { useDispatch, useSelector } from 'react-redux';
import { setMenuState } from '../actions/appState';

function useAppState () {
  const dispatch = useDispatch();
  const appState = useSelector(state => state.appState);

  function toggleMenu () {
    const state = appState.menuState === 'open' ? 'closed' : 'open';
    dispatch(setMenuState(state));
  }

  return { appState, toggleMenu };
}

export default useAppState;
