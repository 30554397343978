import useSingleOrder from 'ReduxHooks/useSingleOrder';

function useContacts () {
  const { order, updateField } = useSingleOrder();
  const contacts = order ? [...order.contacts] : [];

  function updateContact (contact, index) {
    contacts.splice(index, 1, contact);
    updateField('contacts', contacts);
  }

  function deleteContact (index) {
    contacts.splice(index, 1);
    updateField('contacts', contacts);
  }

  function addNewContact () {
    updateField('contacts', [...contacts, {
      name: '',
      email: '',
      phone: ''
    }]);
  }

  return {
    contacts,
    updateContact,
    deleteContact,
    addNewContact
  };
}

export default useContacts;
