import React, { useEffect } from 'react';
import CategoryRow from '../CategoryRow';
import Spinner from '../Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategories } from 'ReduxActions/products';
import { orderHasInvoicedAllInvoiceableProducts } from 'Library/ProductHelper';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals';
import { Notice } from 'Layout';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

const ChangeProducts = () => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();

  const orderExcludedCategories = order.country.code === 'se' ? [
    246, // Gravsten
    33, // Juridiska tjänster
    844, // Terapi
    857, // Terapi (leg)
    845 // Ceremonier
  ] : [];

  const { productCategories } = useSelector(state => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategories(order.country_id));
  }, []);

  function getDiscountedCategories () {
    let categories = [...productCategories];
    const discountIndex = categories.findIndex(item => item.name === 'Rabatt');

    if (discountIndex !== -1) {
      categories.push(categories.splice(discountIndex, 1)[0]);
    }

    categories = categories.filter(item => !orderExcludedCategories.includes(item.wc_term_id));

    return categories;
  }

  function isDisabled () {
    if (order.is_partially_invoiced) {
      return false;
    }

    return orderHasInvoicedAllInvoiceableProducts(order);
  }

  return (
    <div className='product-table category-table'>
      <If condition={isDisabled()}>
        <Notice>
          <h4>{t('NB!')}</h4>
          <p>{t('It is not currently possible to add products as all products have been invoiced.')}</p>
          <p>{t('If you wish to add more products to the order you can mark the order as partially invoiced.')}</p>
        </Notice>
      </If>

      <div className='table-header'>
        <div className='col category-col'><span className='label'>{t('Category')}</span></div>
        <div className='col action-col'><span className='label'> </span></div>
      </div>
      {!productCategories.length ? (
        <Spinner />
      ) : (
        getDiscountedCategories().map(term => (
          <CategoryRow
            isDisabled={isDisabled()}
            order={order}
            key={term.id}
            category={term}
          />
        ))
      )}
    </div>
  );
};

export default ChangeProducts;
