import React, { useState } from 'react';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function OrderSecret () {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const [isOpen, setIsOpen] = useState(false);

  if (!order.secret) {
    return null;
  }

  return (
    <div className='order-secret'>
      <span className='icon'>
        <button
          onClick={() => setIsOpen(true)}
          role='button'
        >
          <ReactSVG src='/icons/icon-circle-question-red.svg'/>
        </button>
      </span>
      <strong>{t('Confidential')}</strong>

      <Modal className='mail-modal' isOpen={isOpen} contentLabel=''>
        <button role='button' className='close-modal' onClick={() => setIsOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg'/>
        </button>
        <h3>{t('Confidential')}</h3>

        <p>{t('Please observe: This case has the highest confidentiality. This means that no information may be disclosed in the case under any circumstances. If there are any questions regarding this matter, refer to the advisor and do not comment on anything else.')}</p>

        <p>
          <LoadingButton onClick={() => setIsOpen(false)}>
            {t('I understand')}
          </LoadingButton>
        </p>
      </Modal>
    </div>
  );
}

export default OrderSecret;
