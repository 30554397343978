import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AttributePicker = ({
  attr,
  defaultValue,
  index,
  classes,
  update
}) => {
  const [selectedAttr, setSelectedAttr] = useState(defaultValue);
  const selectId = `attribute-picker-${index}`;

  const changeHandler = e => {
    const value = e.target.value;

    setSelectedAttr(value);

    update({
      name: attr.name,
      option: value
    });
  };

  return (
    <div className={`attribute-picker funnel-select ${classes}`}>
      <label htmlFor={selectId}>{attr.name}</label>
      <select
        id={selectId}
        value={selectedAttr}
        onChange={(e) => changeHandler(e)}
      >
        <option value=''>-- Välj --</option>
        {attr.options.map(option => <option key={option} value={option}>{option}</option>)}

      </select>
      <span className='funnel-select__value'>{selectedAttr}</span>
    </div>
  );
};

AttributePicker.propTypes = {
  attr: PropTypes.object,
  defaultValue: PropTypes.string,
  index: PropTypes.number,
  classes: PropTypes.string,
  update: PropTypes.func
};

export default AttributePicker;
