import React from 'react';
import PropTypes from 'prop-types';
import ScheduledTime from './ScheduledTime';
import ScheduledPhoneHours from './ScheduledPhoneHours';
import moment from 'moment';
import { LoadingButton, Toggle } from 'FormElements';
import { showWarningNotice } from 'Library/notifications';
import useMeetingTimes from 'Hooks/useMeetingTimes';
import { useTranslation } from 'react-i18next';

const MeetingDayGroup = ({
  groupIndex,
  scheduledTimes,
  scheduledPhoneHours,
  updateGroup,
  noSchedule,
  allowPhoneHours,
  user
}) => {
  const { t } = useTranslation();
  const { getDayOfWeek, isDayActive, hasScheduledTimes } = useMeetingTimes();

  const add = () => {
    const times = [...scheduledTimes];
    times.push({
      startTime: '00:00',
      endTime: '00:00',
      meetingType: 'all'
    });

    updateGroup(groupIndex, times);
  };

  const addPhoneHours = () => {
    const times = [...scheduledPhoneHours];

    times.push({
      startTime: '00:00',
      endTime: '00:00',
      excludedRoles: []
    });

    updateGroup(groupIndex, times, true);
  };

  const update = (index, updatedValues, isPhoneHours = false) => {
    const times = isPhoneHours ? [...scheduledPhoneHours] : [...scheduledTimes];

    if (isOverlapping(updatedValues, index, isPhoneHours)) {
      if (isPhoneHours) {
        showWarningNotice(t('The phone hours you provided overlaps with other phone hours in the schedule. Please select another time'));
      } else {
        showWarningNotice(t('The time you provided overlaps with another time in the schedule. Please select another time'));
      }
      return;
    }

    const schedule = { ...times[index], ...updatedValues };

    times[index] = schedule;

    // eslint-disable-next-line no-prototype-builtins
    if (updatedValues.hasOwnProperty('startTime')) {
      times[index] = schedule;
    }

    updateGroup(groupIndex, times, isPhoneHours);
  };

  function isOverlapping (times, index, isPhoneHours = false) {
    const newTimeStartTime = moment(times.startTime, 'HH:mm');
    const newTimeEndTime = moment(times.endTime, 'HH:mm');
    const timesToCompare = isPhoneHours ? [...scheduledPhoneHours] : [...scheduledTimes];

    const clash = timesToCompare.findIndex(savedTime => {
      const savedTimeStartTime = moment(savedTime.startTime, 'HH:mm');
      const savedTimeEndTime = moment(savedTime.endTime, 'HH:mm');

      if (newTimeStartTime.isSameOrAfter(savedTimeStartTime) && newTimeStartTime.isBefore(savedTimeEndTime)) {
        return true;
      }

      if (newTimeEndTime.isAfter(savedTimeStartTime) && newTimeEndTime.isSameOrBefore(savedTimeEndTime)) {
        return true;
      }

      return false;
    });

    return clash > -1 && clash !== index;
  }

  const remove = (index, isPhoneHours) => {
    const times = isPhoneHours ? [...scheduledPhoneHours] : [...scheduledTimes];
    times.splice(index, 1);

    updateGroup(groupIndex, times, isPhoneHours);
  };

  const toggleGroup = (checked) => {
    toggleGroupItems(checked);
    toggleGroupItems(checked, true);
  };

  const toggleGroupItems = (checked, isPhoneHours) => {
    const times = isPhoneHours ? [...scheduledPhoneHours] : [...scheduledTimes];
    times.map(time => {
      time.inactive = !checked;

      return time;
    });

    updateGroup(groupIndex, times, isPhoneHours);
  };

  const noScheduledTimes = !hasScheduledTimes([...scheduledTimes, ...scheduledPhoneHours]);
  const isActive = isDayActive([...scheduledTimes, ...scheduledPhoneHours]);

  return (
    <div className={`meeting-day-group ${isActive ? '' : 'inactive'} ${!noSchedule ? '' : 'inactive-all'}`}>
      <div className='meeting-day-group__header-group'>
        <h4 className='meeting-day-group__header'>{getDayOfWeek(groupIndex)}</h4>

        {
          !noScheduledTimes &&
            <Toggle
              label={t('Activate day')}
              defaultChecked={isActive}
              isDisabled={noScheduledTimes}
              onChange={toggleGroup}
              small
            />
        }
      </div>

      <ol className='meeting-day-group__scheduled-times'>
        {scheduledTimes.map((scheduledTime, index) => (
          <ScheduledTime
            key={`${groupIndex}-${index}`}
            scheduledTime={scheduledTime}
            index={index}
            update={update}
            remove={remove}
            isActive={isActive}
          />
        ))}
        {scheduledPhoneHours.map((phoneHours, index) => (
          <ScheduledPhoneHours
            key={`${groupIndex}-${index}`}
            scheduledPhoneHours={phoneHours}
            index={index}
            update={update}
            remove={remove}
            isActive={isActive}
            user={user}
          />
        ))}
        {(scheduledTimes.length === 0 && scheduledPhoneHours.length === 0) && (
          <span className='meeting-day-group__placeholder'>{t('No working hours')}</span>
        )}
      </ol>

      <LoadingButton
        className='meeting-day-group__add-scheduled-time'
        onClick={() => add()}
        disabled={!isActive}
      >
        {t('Add working hours')}
      </LoadingButton>

      {!!allowPhoneHours &&
        <LoadingButton
          className='meeting-day-group__add-phone-hours'
          onClick={() => addPhoneHours()}
          disabled={!isActive}
        >
          {t('Add new phone hours')}
        </LoadingButton>}
    </div>
  );
};

MeetingDayGroup.propTypes = {
  user: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
  scheduledTimes: PropTypes.array.isRequired,
  updateGroup: PropTypes.func.isRequired,
  noSchedule: PropTypes.bool,
  allowPhoneHours: PropTypes.bool,
  scheduledPhoneHours: PropTypes.array
};

export default MeetingDayGroup;
