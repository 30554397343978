import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { getID } from './localstorage';
import { formatDate } from 'Library/functions';
import UserImage from './UserImage';
import { LoadingButton } from 'FormElements';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { deleteNote, updateNote } from 'ReduxActions/notes';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function Note ({ note, order }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState(note.note);
  const orderType = order.class;

  const update = async () => {
    setIsLoading(true);

    await dispatch(updateNote(order.id, note.id, { noteText: editedNote, orderType }));

    setIsLoading(false);
    setIsEditingNote(false);
  };

  const destroy = async () => {
    setIsLoading(true);

    await dispatch(deleteNote(order.id, note.id, orderType));

    setIsLoading(false);
  };

  return (
    <div className='single-note'>
      {(isEditingNote) ? (
        <textarea
          rows={4}
          name='edit_note'
          value={editedNote}
          onChange={({ target }) => setEditedNote(target.value)}
        />
      ) : note.note.split('\n').map((item, i) => {
        return (
          <p key={i}>{item}</p>
        );
      })}
      <div className='author'>
        <UserImage
          user={note.user}
          width={40}
          height={40}
        />
        <div className='author-info'>
          <div>
            <span className='author-name'>{note.user ? note.user.name : `(${t('unknown')})`}</span>
            <span className='date'>
              <span>{formatDate(note.created_at, order.country.code)}</span>
              {(note.updated_at !== note.created_at) ? (
                <span>({formatDate(note.updated_at, order.country.code)})</span>
              ) : null}
            </span>
          </div>

          {(parseInt(getID()) === parseInt(note.user_id) && !isEditingNote) && (
            <div>
              <button
                className='btn round'
                data-cy='update_note'
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditingNote(true);
                }}
              >
                <ReactSVG src='/icons/icon-edit-note.svg' />
              </button>

              <button
                className={classNames('btn round', { loading: isLoading })}
                type='button'
                data-cy='delete_note'
                onClick={destroy}
              >
                <span className='btn-text'>
                  <ReactSVG src='/icons/icon-delete-note.svg' />
                </span>
                <div className='btn-loading' />
              </button>
            </div>
          )}

          <If condition={isEditingNote}>
            <LoadingButton
              className='update-note'
              isLoading={isLoading}
              onClick={update}
              text={t('Update')}
            />
          </If>
        </div>
      </div>
    </div>
  );
}

Note.propTypes = {
  note: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
};

export default Note;
