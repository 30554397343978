import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const ActiveInCallQueueIcon = ({ id, isCurrentlyActive }) => {
  const { t } = useTranslation();

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('Active in CTM call queue')}</h3>

      <p className='tip-content__text'>{t('This user is active in the CTM call queue.')}</p>
    </div>
  );

  return (
    <div
      className={classNames('registry-icons', { active: isCurrentlyActive })}

    >
      <FontAwesomeIcon
        aria-label={t('Active in call queue')}
        icon={['fal', 'mobile-android-alt']}
        data-tip='tooltip'
        data-for={`active-call_queue-tooltip-${id}`}
        className='registry-icons__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`active-call_queue-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

ActiveInCallQueueIcon.propTypes = {
  id: PropTypes.number,
  isCurrentlyActive: PropTypes.bool
};

export default ActiveInCallQueueIcon;
