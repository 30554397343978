import React from 'react';
import PropTypes from 'prop-types';
import DashboardSidebar from 'Components/Dashboard/DashboardSidebar';
import DashboardContent from 'Components/Dashboard/DashboardContent';

const Dashboard = ({ orderType = 'App\\Order' }) => (
  <>
    <div className='dashboard__container'>
      <DashboardContent orderType={orderType} />
    </div>
    <DashboardSidebar />
  </>
);

Dashboard.propTypes = {
  orderType: PropTypes.string
};

export default Dashboard;
