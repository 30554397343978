export const USER_BG_IMG = 'obituary_bg_image';
export const USER_DECEASED_IMG = 'obituary_deceased_image';
export const USER_CARD_BG_IMG = 'obituary_card_bg_image';
export const CARD_IMG = 'obituary_card_image';
export const DARK_FUND_IMAGE = 'logo_black';
export const LIGHT_FUND_IMAGE = 'logo_white';
export const OBITUARY_IMAGE = 'obituary_image';
export const FUNERAL_PROGRAM_FILE = 'funeral_program_file';

export const findDarkFundLogo = files => files.find(({ type }) => type === DARK_FUND_IMAGE);
export const findLightFundLogo = files => files.find(({ type }) => type === LIGHT_FUND_IMAGE);
