import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FileUpload from './FileUpload';
import { Checkbox } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import { isOfferTask } from 'Library/Tasks';

function AttachFileToTask ({
  task,
  selectFilesUpdate,
  selectOfferFilesUpdate,
  singleFile,
  title = ''
}) {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const [selectedFiles, setSelectedFiles] = useState(getSelectedFilesDefault());
  const [selectedOfferFiles, setSelectedOfferFiles] = useState(getSelectedFilesDefault(true));

  useEffect(() => {
    selectFilesUpdate(selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    selectOfferFilesUpdate(selectedOfferFiles);
  }, [selectedOfferFiles]);

  function getSelectedFilesDefault (offer = false) {
    return getAttachedFiles(offer)
      .map(id => parseInt(id))
      .filter(id => order.files.find(file => file.id === id));
  }

  function getAttachedFiles (offer = false) {
    if (!task.pivot.email_content) {
      return [];
    }

    const { files: mailFiles = [], offer_files: offerFiles = [] } = task.pivot.email_content;

    const files = offer ? offerFiles : mailFiles;

    if (Array.isArray(files)) {
      return [...files];
    }

    if (typeof files === 'object') {
      return [...Object.values(files)];
    }

    return [];
  }

  const select = (file, isOffer) => {
    const selectedFilesCopy = singleFile ? [] : (isOffer ? [...selectedOfferFiles] : [...selectedFiles]);

    if (!selectedFilesCopy.find(id => id === file.id)) {
      selectedFilesCopy.push(file.id);

      if (isOffer) {
        setSelectedOfferFiles(selectedFilesCopy);
      } else {
        setSelectedFiles(selectedFilesCopy);
      }
    }
  };

  const remove = (file, isOffer) => {
    if (!file) {
      return;
    }

    const selectedFilesCopy = isOffer ? [...selectedOfferFiles] : [...selectedFiles];

    const index = selectedFilesCopy.findIndex(id => id === file.id);
    if (index > -1) {
      selectedFilesCopy.splice(index, 1);

      if (isOffer) {
        setSelectedOfferFiles(selectedFilesCopy);
      } else {
        setSelectedFiles(selectedFilesCopy);
      }
    }
  };

  function getFileLabel ({ type, filename }) {
    return (type === 'client_documentation')
      ? `${filename} <em>(${t('Document for delivery to client')})</em>)`
      : filename;
  }

  const filterExcludedFileTypes = ({ type }) => !['coffin_label'].includes(type);

  return (
    <div className='attach-file-to-task'>
      <h4>{title || t('Select a file from admin')}</h4>

      {order.files.length ? (
        <ul className='file-task__list'>
          {order.files.filter(filterExcludedFileTypes).map(file => {
            const selected = selectedFiles.find(id => id === file.id);

            return (
              <li key={file.id} className={selected ? 'selected' : ''}>
                <Checkbox
                  checked={selected}
                  label={getFileLabel(file)}
                  onChange={selected => {
                    selected ? select(file, false) : remove(file, false);
                  }}
                />
              </li>
            );
          })}
        </ul>

      ) : (
        <p className='no-files-message'>{t('There are no uploaded files')}</p>
      )}

      {isOfferTask(task) && !(order.country.code === 'se' && order.class === 'App\\TombstoneOrder')
        ? (
          <>
            <h4>{t('Select files to show on the offer page')}</h4>
            {order.files.length ? (
              <ul className='file-task__list'>
                {order.files.filter(filterExcludedFileTypes).map(file => {
                  const selected = selectedOfferFiles.find(id => id === file.id);

                  return (
                    <li key={file.id} className={selected ? 'selected' : ''}>
                      <Checkbox
                        checked={selected}
                        label={getFileLabel(file)}
                        onChange={selected => {
                          selected ? select(file, true) : remove(file, true);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className='no-files-message'>{t('There are no uploaded files')}</p>
            )}
          </>
        ) : null}

      <h4>{t('Upload a file from your computer')}</h4>

      <FileUpload
        onError={message => showWarningNotice(message)}
      />
    </div>
  );
}

AttachFileToTask.propTypes = {
  task: PropTypes.object.isRequired,
  selectFilesUpdate: PropTypes.func.isRequired,
  selectOfferFilesUpdate: PropTypes.func.isRequired,
  singleFile: PropTypes.bool,
  title: PropTypes.string,
  tombstoneOrder: PropTypes.bool,
  lawyerOrder: PropTypes.bool
};

export default AttachFileToTask;
