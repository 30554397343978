import React, { useEffect, useState } from 'react';
import { Input, Textarea, LoadingButton, Checkbox, Select } from 'FormElements';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { sprintf } from 'sprintf-js';
import useCountries from 'ReduxHooks/useCountries';
import useBonusAmounts from 'ReduxHooks/useBonusAmounts';
import useCallRoutes from 'ReduxHooks/useCallRoutes';
import PropTypes from 'prop-types';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import LavendlaDatePicker from 'Components/LavendlaDatePicker';
import { If } from 'Conditionals';
import CallRoutingSettings from 'Components/CallRoutingSettings';
import useCoupons from 'ReduxHooks/useCoupons';
import useFlowerFee from 'ReduxHooks/useFlowerFee';
import Collapsible from 'react-collapsible';
import UserDropdown from 'Components/UserDropdown';
import API from '../stores/API';
import { showNotice } from 'Library/notifications';
import i18n from 'i18next';

function CountrySettings ({ match = null }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRepresentativeProduct, setSelectedRepresentativeProduct] = useState('');
  const [editedBonusAmounts, setEditedBonusAmounts] = useState({});
  const [editedCoupons, setEditedCoupons] = useState({});
  const [editedCallRoutes, setEditedCallRoutes] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(null);
  const { updateSettingsField, countrySettings, getCountrySettings, saveCountrySettings } = useCountries();
  const { bonusAmounts, representativeProducts, getBonusAmounts, saveBonusAmounts, getRepresentativeProducts } = useBonusAmounts();
  const { saveCallRoutes } = useCallRoutes();
  const { coupons, getCoupons, saveCoupons } = useCoupons();
  const { flowerFee, updateFlowerFeeField, getFlowerFee, saveFlowerFee } = useFlowerFee();
  const { loggedInUser } = useLoggedInUser();
  const [weeklyReportUserId, setWeeklyReportUserId] = useState(null);
  const [weeklyReports, setWeeklyReports] = useState([]);

  useEffect(() => {
    const country = loggedInUser.countries.find(country => country.code === match.params.code);
    setCurrentCountry(country);
    getCountrySettings(country.id);
    getBonusAmounts(country.id);
    getCoupons(country.id);
    getFlowerFee(country.id);
    getRepresentativeProducts(country.id);
  }, [match.params.code]);

  useEffect(() => {
    setEditedBonusAmounts(bonusAmounts);
  }, [bonusAmounts]);

  useEffect(() => {
    setEditedCoupons(coupons);
  }, [coupons]);

  async function regenerateReports (event) {
    event.preventDefault();
    await API.POST(`/country-settings/${currentCountry.id}/regenerate-reports`);
    showNotice('🎉 ' + i18n.t('Reports are being regenerated. This may take a while (around five seconds per user).'));
  }

  async function getWeeklyReport (id) {
    setWeeklyReportUserId(id);

    if (!id) {
      setWeeklyReports([]);
      return;
    }

    const { data } = await API.GET(`/users/${id}/weekly-reports`);
    setWeeklyReports(data);
  }

  async function save () {
    const country = loggedInUser.countries.find(country => country.code === match.params.code);
    setIsLoading(true);
    await saveCallRoutes(country.id, editedCallRoutes);
    await saveCountrySettings();
    await saveBonusAmounts(country.id, editedBonusAmounts);
    await saveCoupons(country.id, editedCoupons);
    await saveFlowerFee(country.id);
    setIsLoading(false);
  }

  function addNewBonusAmountRow () {
    const newBonusAmounts = [...editedBonusAmounts];
    // get the product with id of selectedRepresentativeProduct
    const product = representativeProducts.find(product => product.id === parseInt(selectedRepresentativeProduct));
    newBonusAmounts.push({
      name: product.name,
      country_id: currentCountry.id,
      product_id: selectedRepresentativeProduct,
      bonus_resource_type: 'representative_product',
      bonus_amount: null,
      bonus_percentage: null,
      is_own_order: true,
      start_date: null,
      end_date: null,
      delete: false
    });

    setEditedBonusAmounts(newBonusAmounts);
  }

  function addNewCouponRow () {
    const coupons = [...editedCoupons];

    coupons.push({
      country_id: currentCountry.id,
      code: '',
      active: true,
      type: 'procent',
      value: '',
      currency: 'sek',
      max_uses: null,
      used: 0,
      expires_at: null
    });

    setEditedCoupons(coupons);
  }

  function updateCouponField (index, field, value) {
    const newCoupons = [...editedCoupons];
    newCoupons[index][field] = value;
    setEditedCoupons(newCoupons);
  }

  function updateBonusAmountField (index, field, value) {
    const newBonusAmounts = [...editedBonusAmounts];
    newBonusAmounts[index][field] = value;
    setEditedBonusAmounts(newBonusAmounts);
  }

  if (!currentCountry || !countrySettings || !bonusAmounts || !representativeProducts) {
    return null;
  }

  return (
    <div className='row order-tab__form'>
      <form className='col-12 user-profile'>

        <h2 className='page-title'>{sprintf(t('Settings for %s'), currentCountry.name)}</h2>
        <Tabs>
          <TabList>
            <Tab className='react-tabs__tab' key='visma'>
              {t('Visma')}
            </Tab>
            <Tab className='react-tabs__tab' key='self-invoice'>
              {t('Self invoicing')}
            </Tab>
            <Tab className='react-tabs__tab' key='bonus'>
              {t('Bonus and reports')}
            </Tab>
            <Tab className='react-tabs__tab' key='call-routing'>
              {t('Call routing')}
            </Tab>
            <Tab className='react-tabs__tab' key='misc'>
              {t('Misc')}
            </Tab>
            <Tab className='react-tabs__tab' key='coupons'>
              {t('Coupon codes (Therapy)')}
            </Tab>
            <Tab className='react-tabs__tab' key='ai-weekly-report'>
              {t('AI Weekly Report')}
            </Tab>
          </TabList>
          <TabPanel key='visma'>
            <div className='row new-form'>
              <div className='col-8'>
                <Input
                  floatingLabel
                  id='invoice_due_days'
                  onChange={value => updateSettingsField('invoice_due_days', value)}
                  label={t('Number of days before invoice is due')}
                  value={countrySettings.invoice_due_days}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='invoice_extended_due_days'
                  onChange={value => updateSettingsField('invoice_extended_due_days', value)}
                  label={t('Number of days before invoice is due with extended due date')}
                  value={countrySettings.invoice_extended_due_days}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='invoice_extended_due_date_product_id'
                  onChange={value => updateSettingsField('invoice_extended_due_date_product_id', value)}
                  label={t('Product ID for extended due date')}
                  value={countrySettings.invoice_extended_due_date_product_id}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='invoice_extended_due_days_cost'
                  onChange={value => updateSettingsField('invoice_extended_due_days_cost', value)}
                  label={t('Cost for extended due date')}
                  value={countrySettings.invoice_extended_due_days_cost}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='invoice_paper_cost'
                  onChange={value => updateSettingsField('invoice_paper_cost', value)}
                  label={t('Cost for paper invoice')}
                  value={countrySettings.invoice_paper_cost}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='invoice_paper_product_id'
                  onChange={value => updateSettingsField('invoice_paper_product_id', value)}
                  label={t('Product ID for paper invoice')}
                  value={countrySettings.invoice_paper_product_id}
                  type='text'
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel key='self-invoice'>
            <div className='row new-form'>
              <div className='col-8'>
                <Textarea
                  floatingLabel
                  id='self_invoice_info'
                  onChange={value => updateSettingsField('self_invoice_info', value)}
                  label={t('Information on the users economy tab about self invoice')}
                  value={countrySettings.self_invoice_info || ''}
                  type='text'
                />
                <Input
                  floatingLabel
                  id='self_invoice_due_date_days'
                  onChange={value => updateSettingsField('self_invoice_due_date_days', value)}
                  label={t('Number of days before self invoice is due')}
                  value={countrySettings.self_invoice_due_date_days || ''}
                  type='number'
                />

              </div>
            </div>
          </TabPanel>
          <TabPanel key='bonus'>
            <div className='row new-form'>
              <div className='col-12'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>{t('Representative product')}</th>
                      <th>{t('Bonus amount')}</th>
                      <th>{t('Bonus percentage')}</th>
                      <th>{t('Own order')}</th>
                      <th>{t('Start date')}</th>
                      <th>{t('End date')}</th>
                      <th>{t('Remove')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editedBonusAmounts.map((bonusAmount, index) => (
                      <If key={index} condition={!bonusAmount.delete}>
                        <tr key={index}>
                          <td>{bonusAmount.name}</td>
                          <td>
                            <Input
                              id={`bonus_amount_${index}`}
                              onChange={value => updateBonusAmountField(index, 'bonus_amount', value)}
                              value={bonusAmount.bonus_amount}
                              type='text'
                            />
                          </td>
                          <td>
                            <Input
                              id={`bonus_percentage_${index}`}
                              onChange={value => updateBonusAmountField(index, 'bonus_percentage', value)}
                              value={bonusAmount.bonus_percentage}
                              type='text'
                            />
                          </td>
                          <td>
                            <Checkbox
                              defaultChecked={bonusAmount.is_own_order}
                              label=''
                              onChange={value => updateBonusAmountField(index, 'is_own_order', value)}
                            />
                          </td>
                          <td>
                            <LavendlaDatePicker
                              id={`start_date_${index}`}
                              className='input'
                              placeholder={t('Start date')}
                              date={bonusAmount.start_date}
                              onChange={value => updateBonusAmountField(index, 'start_date', value)}
                            />
                          </td>
                          <td>
                            <LavendlaDatePicker
                              id={`end_date_${index}`}
                              className='input'
                              placeholder={t('End date')}
                              date={bonusAmount.end_date}
                              onChange={value => updateBonusAmountField(index, 'end_date', value)}
                            />
                          </td>
                          <td>
                            <LoadingButton className='btn small' onClick={() => updateBonusAmountField(index, 'delete', true)}>x</LoadingButton>
                          </td>
                        </tr>
                      </If>
                    ))}
                    <tr>
                      <td colSpan='2'>
                        <label>{t('Add new row')}
                          <select className='form-control' onChange={e => setSelectedRepresentativeProduct(e.target.value)} value={selectedRepresentativeProduct}>
                            <option value=''>{t('Select representative product')}</option>
                            {representativeProducts.map(({ id, name }) => (
                              <option key={id} value={id}>{name}</option>
                            ))}
                          </select>
                        </label>
                      </td>
                      <td colSpan='4'>
                        <div className='btn-group'>
                          <LoadingButton className='btn btn-primary' disabled={!selectedRepresentativeProduct} onClick={() => addNewBonusAmountRow()}>{t('Add')}</LoadingButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </TabPanel>
          <TabPanel key='call-routing'>
            <CallRoutingSettings country={currentCountry} setParentRoutes={setEditedCallRoutes} />
          </TabPanel>
          <TabPanel key='misc'>
            <div className='row new-form'>
              <div className='col-8'>
                <Input
                  floatingLabel
                  id='obituary_creator_slug'
                  onChange={value => updateSettingsField('obituary_creator_slug', value)}
                  label={t('Slug for the obituary creator page')}
                  value={countrySettings.obituary_creator_slug}
                  type='text'
                />
              </div>
            </div>
            <div className='row new-form'>
              <div className='col-8'>
                <div className='grey-box'>
                  <h3>{t('Flower transportation fee')}</h3>
                  <Input
                    floatingLabel
                    id='flower_transport_fee_label'
                    onChange={value => updateFlowerFeeField('name', value)}
                    label={t('Flower transportation fee label')}
                    value={flowerFee.name}
                    type='text'
                  />
                  <Input
                    floatingLabel
                    id='flower_transport_fee'
                    onChange={value => updateFlowerFeeField('total', value)}
                    label={t('Flower transportation fee cost (excl. tax)')}
                    value={flowerFee.total}
                    type='text'
                  />
                  <Input
                    floatingLabel
                    id='flower_transport_fee'
                    onChange={value => updateFlowerFeeField('totalTax', value)}
                    label={t('Flower transportation fee tax')}
                    value={flowerFee.totalTax}
                    type='text'
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel key='coupons'>
            <div className='row new-form'>
              <div className='col-12'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>{t('Code')}</th>
                      <th>{t('Type')}</th>
                      <th>{t('Value')}</th>
                      <th>{t('Currency')}</th>
                      <th>{t('Max uses')}</th>
                      <th>{t('Expires at')}</th>
                      <th>{t('# of uses')}</th>
                      <th>{t('Remove')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editedCoupons.map((coupon, index) => (
                      <If key={index} condition={!coupon.delete}>
                        <tr key={index}>
                          <td>
                            <Input
                              id={`code_${index}`}
                              onChange={value => updateCouponField(index, 'code', value)}
                              value={coupon.code}
                              type='text'
                              readOnly={coupon.id}
                            />
                          </td>
                          <td>
                            {coupon.id
                              ? coupon.type === 'procent' ? t('Procent') : t('Amount off')
                              : (
                                <Select
                                  value={coupon.type}
                                  options={{
                                    procent: t('Procent'),
                                    amount: t('Amount off')
                                  }}
                                  onChange={value => updateCouponField(index, 'type', value)}
                                />
                                )}
                          </td>
                          <td>
                            <Input
                              id={`value_${index}`}
                              onChange={value => updateCouponField(index, 'value', value)}
                              value={coupon.value}
                              type='text'
                              readOnly={coupon.id}
                            />
                          </td>
                          <td>
                            {coupon.id
                              ? coupon.currency
                              : (
                                <Select
                                  value={coupon.currency}
                                  options={{
                                    sek: t('SEK'),
                                    dek: t('DEK'),
                                    eur: t('EUR')
                                  }}
                                  onChange={value => updateCouponField(index, 'currency', value)}
                                />)}
                          </td>
                          <td>
                            <Input
                              id={`max_uses_${index}`}
                              onChange={value => updateCouponField(index, 'max_uses', value)}
                              value={coupon.max_uses}
                              type='text'
                              readOnly={coupon.id}
                            />
                          </td>
                          <td>
                            {coupon.id
                              ? coupon.expires_at ? new Date(coupon.expires_at).toISOString().split('T')[0] : null
                              : (
                                <LavendlaDatePicker
                                  id={`expires_at_${index}`}
                                  className='input'
                                  placeholder={t('Expires at')}
                                  date={coupon.expires_at}
                                  onChange={value => updateCouponField(index, 'expires_at', value)}
                                />)}
                          </td>
                          <td>
                            {coupon.used}
                          </td>
                          <td>
                            <LoadingButton
                              className='btn small'
                              onClick={() => updateCouponField(index, 'delete', true)}
                            >x
                            </LoadingButton>
                          </td>
                        </tr>
                      </If>
                    ))}
                  </tbody>
                </table>

                <div className='btn-group'>
                  <LoadingButton
                    className='btn btn-primary'
                    onClick={() => addNewCouponRow()}
                  >{t('Add')}
                  </LoadingButton>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel key='ai-weekly-report'>
            <div className='row new-form'>
              <div className='col-6'>
                <Textarea
                  floatingLabel
                  id='weekly_report_funeral_advisor'
                  onChange={value => updateSettingsField('weekly_report_funeral_advisor', value)}
                  value={countrySettings.weekly_report_funeral_advisor || ''}
                  label={t('Funeral')}
                  type='text'
                />

                <Textarea
                  floatingLabel
                  id='weekly_report_lawyer'
                  onChange={value => updateSettingsField('weekly_report_lawyer', value)}
                  value={countrySettings.weekly_report_lawyer || ''}
                  label={t('Law')}
                  type='text'
                />

                <Textarea
                  floatingLabel
                  id='weekly_report_tombstone'
                  onChange={value => updateSettingsField('weekly_report_tombstone', value)}
                  value={countrySettings.weekly_report_tombstone || ''}
                  label={t('Tombstone')}
                  type='text'
                />

                <Textarea
                  floatingLabel
                  id='weekly_report_therapist'
                  onChange={value => updateSettingsField('weekly_report_therapist', value)}
                  value={countrySettings.weekly_report_therapist || ''}
                  label={t('Therapy')}
                  type='text'
                />

                <Textarea
                  floatingLabel
                  id='weekly_report_ceremony'
                  onChange={value => updateSettingsField('weekly_report_ceremony', value)}
                  value={countrySettings.weekly_report_ceremony || ''}
                  label={t('Ceremony')}
                  type='text'
                />
              </div>

              <div className='col-6'>
                <h3>{t('Codes')}</h3>
                <p>{t('Copy and paste your code to automatically retrieve the correct information for the prompt.')}</p>

                <div className='list-shortcodes'>
                  <Collapsible
                    transitionTime={150}
                    trigger='User'
                  >
                    <ul>
                      <li>
                        <span>{t('Name of the user')}</span>
                        <span>[user_name]</span>
                      </li>
                      <li>
                        <span>{t('Week number')}</span>
                        <span>[week]</span>
                      </li>
                      <li>
                        <span>{t('Average Rating')}</span>
                        <span>[average_rating]</span>
                      </li>
                      <li>
                        <span>{t('Reviews from the past week')}</span>
                        <span>[reviews_last_week]</span>
                      </li>
                      <li>
                        <span>{t('Reviews from the past month')}</span>
                        <span>[reviews_last_month]</span>
                      </li>
                      <li>
                        <span>{t('Reviews for all users from the past week')}</span>
                        <span>[reviews_last_week_all]</span>
                      </li>
                      <li>
                        <span>{t('Reviews for all users from the past month')}</span>
                        <span>[reviews_last_month_all]</span>
                      </li>
                      <li>
                        <span>{t('Lost leads from the past week')}</span>
                        <span>[lost_leads_last_week]</span>
                      </li>
                      <li>
                        <span>{t('Lost leads from the past month')}</span>
                        <span>[lost_leads_last_month]</span>
                      </li>
                      <li>
                        <span>{t('Number of visitors for the last week')}</span>
                        <span>[visitors_last_week]</span>
                      </li>
                      <li>
                        <span>{t('Number of visitors for the last month')}</span>
                        <span>[visitors_last_month]</span>
                      </li>
                    </ul>
                  </Collapsible>

                  <Collapsible
                    transitionTime={150}
                    trigger='Orders'
                  >
                    <ul>
                      <li>
                        <span>Number of inquiries this week</span>
                        <span>[nr_personal_inquiries_last_week]</span>
                      </li>
                      <li>
                        <span>Number of inquiries this month</span>
                        <span>[nr_personal_inquiries_last_month]</span>
                      </li>
                      <li>
                        <span>Number of conversions this week</span>
                        <span>[nr_conversions_last_week]</span>
                      </li>
                      <li>
                        <span>Number of conversions this month</span>
                        <span>[nr_conversions_last_month]</span>
                      </li>
                      <li>
                        <span>Number of conversions for all users this week</span>
                        <span>[nr_conversions_last_week_all]</span>
                      </li>
                      <li>
                        <span>Number of conversions for all users this month</span>
                        <span>[nr_conversions_last_month_all]</span>
                      </li>
                      <li>
                        <span>{t('Number of memorial pages past week')}</span>
                        <span>[nr_memorial_pages_last_week]</span>
                      </li>
                      <li>
                        <span>{t('Number of memorial pages past month')}</span>
                        <span>[nr_memorial_pages_last_month]</span>
                      </li>
                      <li>
                        <span>{t('Number of memorial pages for all users past week')}</span>
                        <span>[nr_memorial_pages_last_week_all]</span>
                      </li>
                      <li>
                        <span>{t('Number of memorial pages for all users past month')}</span>
                        <span>[nr_memorial_pages_last_month_all]</span>
                      </li>
                      <li>
                        <span>{t('Number of meetings past week')}</span>
                        <span>[last_week_meetings]</span>
                      </li>
                      <li>
                        <span>{t('Number of meetings past month')}</span>
                        <span>[last_month_meetings]</span>
                      </li>
                      <li>
                        <span>{t('Number of returning customers')}</span>
                        <span>[nr_returning_customers]</span>
                      </li>
                      <li>
                        <span>{t('Number of unique customers last week')}</span>
                        <span>[nr_unique_customers_last_week]</span>
                      </li>
                      <li>
                        <span>{t('Number of unique customers for all users last week')}</span>
                        <span>[nr_unique_customers_last_week_all]</span>
                      </li>
                      <li>
                        <span>{t('Number of unique customers last month')}</span>
                        <span>[nr_unique_customers_last_month]</span>
                      </li>
                      <li>
                        <span>{t('Number of unique customers for all users last month')}</span>
                        <span>[nr_unique_customers_last_month_all]</span>
                      </li>
                    </ul>
                  </Collapsible>
                </div>

                <h3>{t('View User’s Latest Reports')}</h3>
                <p>{t('Select the user below to view their three latest reports.')}</p>

                <UserDropdown
                  all
                  user={weeklyReportUserId}
                  placeholder={t('All users')}
                  onChange={getWeeklyReport}
                />

                {weeklyReports && weeklyReports.length > 0
                  ? weeklyReports.map((report, index) => {
                    const date = new Date(report.created_at);
                    const formattedDate = date.toLocaleString();

                    return (
                      <div key={index}>
                        <h4><time>{formattedDate}:</time></h4>

                        <p>Prompt:</p>
                        <pre>
                          {report.prompt}
                        </pre>

                        <p>Response:</p>
                        <pre>
                          {report.content}
                        </pre>
                      </div>
                    );
                  })
                  : <br />}

                <button className='btn' onClick={regenerateReports}>Regenerate the latest report for all users</button>
              </div>
            </div>
          </TabPanel>
        </Tabs>

        <div className='btn-group'>
          <LoadingButton
            onClick={() => save()}
            text={t('Save')}
            data-cy='persist-user'
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>

  );
}

CountrySettings.propTypes = {
  match: PropTypes.object.isRequired
};

export default CountrySettings;
