import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import UserScheduleDashboardDay from './UserScheduleDashboardDay';

function UserScheduleDashboard ({ schedule, noSchedule }) {
  const { t } = useTranslation();

  function getSchedule () {
    const today = moment().day();
    const tomorrow = moment().add(1, 'day').day();

    return {
      [today]: schedule[today],
      [tomorrow]: schedule[tomorrow]
    };
  }

  const meetingTimes = getSchedule();

  if (noSchedule) {
    return null;
  }

  return (
    <div className='user-schedule-dashboard'>
      <h3>{t('My working hours')}</h3>

      {Object.keys(meetingTimes).map(dayOfWeek => (
        <UserScheduleDashboardDay
          key={dayOfWeek}
          dayOfWeek={parseInt(dayOfWeek)}
          scheduledTimes={meetingTimes[dayOfWeek]}
        />
      ))}
    </div>
  );
}

UserScheduleDashboard.propTypes = {
  schedule: PropTypes.object.isRequired,
  noSchedule: PropTypes.bool.isRequired
};

export default UserScheduleDashboard;
