import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

// Components
import ImageProcessorActions from './ImageProcessorActions';
import ImageProcessorButtons from './ImageProcessorButtons';
import ImageProcessorPreviews from './ImageProcessorPreviews';

// Cropper css
import 'cropperjs/dist/cropper.css';

const ImageProcessor = ({
  file,
  isBanner = false,
  isCard = false,
  save,
  setFile,
  setImageSrc,
  src
}) => {
  const [cropper, setCropper] = useState(null);
  const { t } = useTranslation();

  const cropperSize = {
    height: isBanner ? 250 * 0.214 : 250,
    width: 250
  };

  // Animation options
  const motionProps = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
    transition: { duration: 0.15, delay: 0.15 }
  };

  return (
    <motion.div {...motionProps} className='image-processor'>
      <p>{t('Here you can edit your selected image. Drag the image to change what is in focus. Rotate and zoom if needed.')}</p>
      <p className='upload-image-content__file-info'>
        <span>{t('Selected file')}: </span><strong>{file.path || file.filename}</strong>
      </p>

      <Cropper
        src={src}
        className='image-processor__cropper'
        style={cropperSize}
        autoCropArea='1'
        cropBoxMovable={false}
        cropBoxResizable={false}
        dragMode='move'
        modal={false}
        preview='.image-processor__single-preview'
        restore={false}
        toggleDragModeOnDblclick={false}
        viewMode='3'
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />

      <ImageProcessorActions cropper={cropper} />

      <ImageProcessorPreviews
        isBanner={isBanner}
        isCard={isCard}
      />

      <ImageProcessorButtons
        cropper={cropper}
        file={file}
        save={save}
        setFile={setFile}
        setImageSrc={setImageSrc}
      />
    </motion.div>
  );
};

ImageProcessor.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(File)
  ]).isRequired,
  save: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  setImageSrc: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  isBanner: PropTypes.bool,
  isCard: PropTypes.bool
};

export default ImageProcessor;
