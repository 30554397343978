import { useDispatch, useSelector } from 'react-redux';
import { getBonusAmounts, saveBonusAmounts, getRepresentativeProducts } from '../actions/bonusAmounts';

function useBonusAmounts () {
  const dispatch = useDispatch();
  const { bonusAmounts, representativeProducts } = useSelector(state => state.bonusAmounts);

  return {
    bonusAmounts,
    representativeProducts,
    getBonusAmounts: (countryId) => dispatch(getBonusAmounts(countryId)),
    getRepresentativeProducts: (countryId) => dispatch(getRepresentativeProducts(countryId)),
    saveBonusAmounts: (countryId, bonusAmounts) => dispatch(saveBonusAmounts(countryId, bonusAmounts))
  };
}

export default useBonusAmounts;
