import { SET_CALL_ROUTES } from '../actions/callRoutes';

const initialState = {
  callRoutes: []
};

export default function callRoutes (state = initialState, { type, payload }) {
  switch (type) {
    case SET_CALL_ROUTES: {
      return {
        ...state,
        callRoutes: payload
      };
    }

    default:
      return state;
  }
}
