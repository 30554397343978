import React from 'react';
import PropTypes from 'prop-types';
import InfoModal from '../InfoModal';

const TaskInfoModal = ({ task }) => (
  <InfoModal title={task.name}>
    <div dangerouslySetInnerHTML={{ __html: task.description || 'Denna uppgift saknar beskrivning' }}/>
  </InfoModal>
);

TaskInfoModal.propTypes = {
  task: PropTypes.object.isRequired
};

export default TaskInfoModal;
