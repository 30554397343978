import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductListItem from './ProductListItem';

const ProductList = ({
  categoryName,
  orderInfo,
  products,
  selectProduct
}) => {
  // This should only be relevant for Swedish orders so is not translated here
  return (
    <div className='product-list'>
      {categoryName === 'Ceremonivärd' && (
        <div className='category-description'>
          Välj om du själv kommer vara värd på ceremonin, eller om du anlitar en ordinarie värd, normalt transportören. Om du själv kommer vara representant, välj <strong>Ceremonivärd rådgivare</strong>. Om du inte är representant välj, <strong>Ceremonivärd ordinarie</strong>. Kom ihåg att ange vem som är representant på <Link to={`/ordrar/order/${orderInfo.order.id}/ceremoni`}>ceremonifliken</Link>.
        </div>
      )}

      {products
        ? products.map(product => (
          <ProductListItem
            key={product.id}
            product={product}
            selectProduct={selectProduct}
            orderInfo={orderInfo}
            countryCode={orderInfo.order.country.code}
          />
        ))
        : null}
    </div>
  );
};

ProductList.propTypes = {
  categoryName: PropTypes.string,
  orderInfo: PropTypes.object,
  products: PropTypes.array,
  selectProduct: PropTypes.func
};

export default ProductList;
