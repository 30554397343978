import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const TOGGLE_ID = uniqueId('toggle-id-');

const Toggle = ({
  label,
  desc,
  defaultChecked = false,
  onChange,
  small,
  toggleLabel = true,
  isDisabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(!!defaultChecked);
  const classes = classnames('toggle-button', { small, 'is-disabled': isDisabled });

  function toggleSwitch () {
    if (isDisabled) {
      return;
    }

    onChange(!checked);
    setChecked(!checked);
  }

  return (
    <div className={classes}>
      <button
        aria-checked={checked}
        aria-labelledby={TOGGLE_ID}
        type='button'
        role='switch'
        title={label}
        onClick={toggleSwitch}
        disabled={isDisabled}
        {...rest}
      >
        <span>{t('Yes')}</span>
        <span>{t('No')}</span>
      </button>

      <span
        id={TOGGLE_ID} onClick={() => {
          if (toggleLabel) {
            toggleSwitch();
          }
        }}
      >
        {label && <strong>{label}</strong>}<br />
        {desc}
      </span>
    </div>
  );
};

Toggle.propTypes = {
  defaultChecked: PropTypes.any,
  desc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toggleLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  small: PropTypes.bool
};

export default Toggle;
