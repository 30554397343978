import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardBgImage from '../MemorialPageComponents/CardBgImage';
import TextColor from '../MemorialPageComponents/TextColor';
import TextContent from '../MemorialPageComponents/TextContent';
import ObituaryPreview from '../ObituaryPreview';
import { If } from 'Conditionals';
import { LoadingButton } from 'FormElements';
import { ceremonyTransformer, deceasedTransformer } from 'Library/MemorialPageTransformers';
import { CARD_IMG, USER_CARD_BG_IMG } from 'Library/MemorialPageHelpers';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

function Card ({
  previewIsLoading,
  setPreviewIsLoading,
  memorialFund,
  setCanvas,
  deleteCard
}) {
  const { t } = useTranslation();
  const { order, updateField } = useSingleOrder();
  const { memorialPage, findImageOfType } = useMemorialPage();

  const hideCeremonyInfo = !!memorialPage.hide_ceremony_info;
  const wpCardBgImageId = memorialPage.wordpress_card_bg_image_id;
  const deceasedDetails = deceasedTransformer(order.deceased);
  const ceremonyDetails = ceremonyTransformer(order, order.appointments);
  const userCardBgImage = findImageOfType(USER_CARD_BG_IMG);
  const cardImage = findImageOfType(CARD_IMG);
  const hasCardImage = !!cardImage || !!wpCardBgImageId || !!userCardBgImage;

  const title = memorialPage.card_title || t('Invitation to funeral service');
  const text = memorialPage.card_text || t('Welcome to attend the funeral');
  const textColor = memorialPage.card_text_color || 'black';
  const setTextColor = (value) => updateField('memorial_page.card_text_color', value);
  const wpBgImage = memorialPage.wordpress_card_image_id;
  const wpCardBgImage = memorialPage.wordpress_card_bg_image_id;
  const setWpCardBgImage = (id) => updateField('memorial_page.wordpress_card_bg_image_id', id);

  const legendId = uniqueId('legend_');
  const [showContent, setShowContent] = useState(hasCardImage);
  const [isLoading, setIsLoading] = useState(false);

  async function deleteCallback () {
    setIsLoading(true);
    await deleteCard();
    setIsLoading(false);
    setShowContent(false);
  }

  useEffect(() => {
    // Set memorial bg image if non is set, when bg image is updated
    if (showContent && !wpCardBgImage && !userCardBgImage) {
      setWpCardBgImage(wpBgImage);
    }
  }, [wpBgImage]);

  function triggerShowContent (e) {
    e.preventDefault();

    // Set memorial bg image from wp as pre chosen card bg
    if (wpBgImage) {
      setWpCardBgImage(wpBgImage);
    }

    setShowContent(!showContent);
  }

  // Animation props
  const buttonMotionProps = {
    exit: { opacity: 0, x: 5, transition: { duration: 0.15 } }
  };

  const contentMotionProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, transition: { duration: 0.15 } },
    transition: { duration: 0.15, delay: 0.15 }
  };

  return (
    <div
      role='group'
      aria-labelledby={legendId}
      className='fieldset memorial-page-card'
    >
      <div className='memorial-page-card__header'>
        <h3 id={legendId} className='legend'>
          {t('Create a memorial card for the deceased')}
        </h3>

        <AnimatePresence>
          <If condition={!showContent}>
            <motion.button
              {...buttonMotionProps}
              className='btn icon outline'
              onClick={triggerShowContent}
              data-cy='create-card-button'
            >
              <FontAwesomeIcon
                icon={['fal', 'plus']}
              />
              <span>{t('Create card')}</span>
            </motion.button>
          </If>
        </AnimatePresence>
      </div>

      <p className='memorial-page__section_description'>
        {t('If the customer wants a card to send out or as a complement to the memory page, this is where you can create it.')}
      </p>

      <AnimatePresence>
        <If condition={showContent}>
          <motion.div
            className='memorial-page-card__inner grey-box'
            {...contentMotionProps}
          >
            <CardBgImage />

            <If condition={(wpCardBgImage || userCardBgImage)}>
              <motion.div {...contentMotionProps} data-cy='card-fields-content'>
                <TextContent
                  heading={`2. ${t('Edit text content for card')}`}
                  text={text}
                  title={title}
                  setText={(value) => updateField('memorial_page.card_text', value)}
                  setTitle={(value) => updateField('memorial_page.card_title', value)}
                />

                <TextColor
                  heading={`3. ${t('Choose color mode for text + logos in the card')}`}
                  textColor={textColor}
                  setTextColor={setTextColor}
                />

                <ObituaryPreview
                  heading={`4. ${t('Preview the card')}`}
                  ceremonyDetails={ceremonyDetails}
                  deceasedDetails={deceasedDetails}
                  memorialFund={memorialFund}
                  previewIsLoading={previewIsLoading}
                  isLoading={isLoading}
                  setPreviewIsLoading={setPreviewIsLoading}
                  textColor={textColor}
                  text={text}
                  title={title}
                  hideCeremonyInfo={hideCeremonyInfo}
                  userCardBgImage={userCardBgImage}
                  wpCardBgImage={wpCardBgImage}
                  passCanvasToParent={provider => setCanvas(provider)}
                />

                <LoadingButton
                  className='delete'
                  data-cy='delete-card'
                  isLoading={isLoading}
                  onClick={() => deleteCallback()}
                >
                  {t('Delete card')}
                </LoadingButton>
              </motion.div>
            </If>
          </motion.div>
        </If>
      </AnimatePresence>
    </div>
  );
}

Card.propTypes = {
  previewIsLoading: PropTypes.bool,
  setPreviewIsLoading: PropTypes.func.isRequired,
  memorialFund: PropTypes.object,
  setCanvas: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired
};

export default Card;
