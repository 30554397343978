import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBonusReports from 'ReduxHooks/useBonusReports';
import { LoadingButton } from 'FormElements';

function UserReportsList ({ bonusReports = [] }) {
  const { t } = useTranslation();
  const { downloadInvoice } = useBonusReports();
  const [isDownloading, setIsDownloading] = useState(false);

  const getInvoice = async id => {
    setIsDownloading(true);
    await downloadInvoice(id);
    setIsDownloading(false);
  };

  if (!bonusReports.length) {
    return (
      <p>{t('No reports found for this user.')}</p>
    );
  }

  return (
    <div className='user-reports-list'>
      <h4>{t('Reports')}</h4>

      <table>
        <thead>
          <tr>
            <th>{t('Report ID')}</th>
            <th>{t('Created')}</th>
            <th>{t('Updated')}</th>
            <th>{t('Status')}</th>
            <th>{t('Links')}</th>
          </tr>
        </thead>
        <tbody>
          {bonusReports.map(report => (
            <tr key={report.id}>
              <td>#{report.id}</td>
              <td>{report.created_at}</td>
              <td>{report.updated_at}</td>
              <td>{report.status}</td>
              <td>
                <Link to={`/ersattningsrapporter/${report.id}`}>{t('Show report')}</Link>
                &nbsp;&nbsp;&nbsp;
                {report.self_invoice && (
                  <LoadingButton
                    className='btn-link'
                    disabled={isDownloading}
                    onClick={() => getInvoice(report.id)}
                  >
                    {t('Download invoice')}
                  </LoadingButton>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

UserReportsList.propTypes = {
  bonusReports: PropTypes.array.isRequired
};

export default UserReportsList;
