import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ActiveScheduleIcon = ({ id }) => {
  const { t } = useTranslation();

  const tooltip = () => (
    <div className='tip-content'>
      <h3>{t('Has an active schedule')}</h3>

      <p className='tip-content__text'>{t('This user has an active schedule on their profile. This doesn\'t guarantee that there are active times in the schedule.')}</p>
    </div>
  );

  return (
    <div className='registry-icons'>
      <FontAwesomeIcon
        aria-label={t('Active schedule')}
        icon={['fal', 'calendar-alt']}
        data-tip='tooltip'
        data-for={`active-schedule-tooltip-${id}`}
        className='registry-icons__excerpt'
      />
      <ReactTooltip
        multiline
        clickable
        type='light'
        effect='solid'
        delayHide={300}
        className='order-tooltip'
        id={`active-schedule-tooltip-${id}`}
      >{tooltip()}
      </ReactTooltip>
    </div>
  );
};

ActiveScheduleIcon.propTypes = {
  id: PropTypes.number
};

export default ActiveScheduleIcon;
