import React from 'react';
import PropTypes from 'prop-types';
import { getAllRoles } from 'Library/UserRoles';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useUsers from 'ReduxHooks/useUsers';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import UserRegistryListRow from './UserRegistryListRow';

const UserRegistryList = ({ showLastActive }) => {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const { users, pagination } = useUsers();
  const translatedRoles = getAllRoles();

  return (
    <div className='registry__registry-list'>
      <If condition={!!pagination}>
        <p><strong>{pagination.total}</strong> {t('total users')}</p>
      </If>
      <table className='registry__registry-list__table'>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Skills')}</th>
            <th>{t('Phone')}</th>
            <th>{t('Role')}</th>
            <th>{t('Location')}</th>
            <If condition={showLastActive}>
              <th>{t('Last active')}</th>
            </If>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserRegistryListRow
              key={user.id}
              user={user}
              showLastActive={showLastActive}
              translatedRoles={translatedRoles}
              isAdmin={isAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserRegistryList;

UserRegistryList.propTypes = {
  showLastActive: PropTypes.bool
};
