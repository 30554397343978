import { LoadingButton } from 'FormElements';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
import ActiveUserOrders from './ActiveUserOrders';
import { showNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';
import useUser from 'ReduxHooks/useUser';

const DeleteOrDisableUser = ({ user }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const { destroy, toggleInactive, fetchActiveOrders } = useUser();

  const deleteUser = async () => {
    if (!window.confirm(t('Are you sure?'))) {
      return;
    }

    setIsDeleting(true);
    await destroy(user.id);
    setIsDeleting(false);

    history.push('/anvandarregister');
  };

  async function fetchOrders () {
    const deactivate = !user.archived_at;

    setIsFetchingOrders(true);
    const data = await fetchActiveOrders(user.id);
    setIsFetchingOrders(false);
    setOrders(data);

    if (data.length) {
      setIsOpen(true);

      return;
    }

    if (deactivate && !window.confirm(t('Are you sure you want to disable the user?'))) {
      return;
    }

    const response = toggleInactive(user.id, deactivate);
    if (response) {
      if (deactivate) {
        showNotice(t('The user has been disabled.'));
      } else {
        showNotice(t('The user has been enabled.'));
      }

      setTimeout(() => history.push('/anvandarregister'), 300);
    }
  }

  return (
    <div className='profile-action-box delete-disable-user'>
      <p>
        <strong>{t('Delete or disable user')}</strong>
      </p>

      {user.archived_at &&
        <LoadingButton
          onClick={deleteUser}
          isLoading={isDeleting}
          className='delete'
          text={t('Delete')}
        />}

      <LoadingButton
        isLoading={isFetchingOrders}
        onClick={() => fetchOrders()}
        className='white'
      >
        {user.archived_at ? t('Enable') : t('Disable')}
      </LoadingButton>

      <Modal
        className='mail-modal'
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel=''
      >
        <button role='button' className='close-modal' onClick={() => setIsOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>
        <ActiveUserOrders orders={orders} />
      </Modal>
    </div>
  );
};

DeleteOrDisableUser.propTypes = {
  user: PropTypes.object.isRequired
};

export default DeleteOrDisableUser;
