import React from 'react';
import { getFuneralType, formatDate } from 'Library/functions';
import { useTranslation } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function OrderHeaderInfo () {
  const { t } = useTranslation();
  const { order, isLawyerOrder, isFuneralOrder, isTherapyOrder, getCountryCode } = useSingleOrder();

  let ceremony = null;

  if (!order || isLawyerOrder || isTherapyOrder) {
    return null;
  }

  function getBurialType () {
    if (!isFuneralOrder) {
      return null;
    }

    return getFuneralType(order.funeral.type);
  }

  if (isFuneralOrder) {
    ceremony = order.appointments.find(({ type }) => type === 'ceremony');
  } else {
    if (order.order) {
      ceremony = order.order.appointments.find(({ type }) => type === 'ceremony');
    }
  }

  return (
    <ul className='order-info-header__list'>
      <li className='order-info-header__location'>
        <span className='label'>{t('Location')}</span>
        <span className='info'>{order.location ? order.location.name : t('Not selected')}</span>
      </li>
      <li className='order-info-header__created_at'>
        <span className='label'>{t('Order date')}</span>
        <span className='info'>{formatDate(order.created_at, getCountryCode())}</span>
      </li>
      {order.entombent ? (
        <li className='order-info-header__location'>
          <span className='label'>{t('Burial place')}</span>
          <span className='info'>{order.entombent.place ? order.entombent.place : t('Not selected')}</span>
        </li>
      ) : null}
      {getBurialType() ? (
        <li className='order-info-header__type'>
          <span className='label'>{t('Type of burial')}</span>
          <span className='info'>{getBurialType()}</span>
        </li>
      ) : null}
      <li className='order-info-header__time'>
        <span className='label'>{t('Ceremony date')}</span>
        <span className='info'>{(ceremony) ? formatDate(ceremony.start_date, getCountryCode()) : t('No time set')}</span>
      </li>
    </ul>
  );
}

export default OrderHeaderInfo;
