import React from 'react';
import PropTypes from 'prop-types';
import { Input, LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';

const ExpenseForm = ({ expense, onChange, onSave, onCancel }) => {
  const { t } = useTranslation();

  const updateField = (field, value) => {
    onChange({
      ...expense,
      [field]: value
    });
  };

  const updatePaymentItem = (field, value) => {
    onChange({
      ...expense,
      stripePaymentItem: {
        ...expense.stripePaymentItem,
        [field]: value
      }
    });
  };

  return (
    <div className='therapy-edit-form'>
      <div className='form-group'>
        <Input
          label={t('Expense description')}
          value={expense.description}
          onChange={(value) => updateField('description', value)}
          floatingLabel
        />
      </div>
      <div className='form-group'>
        <Input
          label={t('Price')}
          value={expense.stripePaymentItem?.amount}
          onChange={(value) => updatePaymentItem('amount', value)}
          floatingLabel
        />
      </div>
      <div className='form-group btn-group'>
        <LoadingButton className='btn' onClick={onSave}>
          {t('Save')}
        </LoadingButton>
        <button className='btn' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

ExpenseForm.propTypes = {
  expense: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ExpenseForm;
