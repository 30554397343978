import React, { useState } from 'react';
import LavendlaModal from 'Components/Modal';
import { useTranslation } from 'react-i18next';
import { Table } from 'Layout';
import { formatCurrency, getTranslatedStripeStatus, formatDate } from 'Library/functions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useCountries from 'ReduxHooks/useCountries';
import usePaymentActions from 'Hooks/usePaymentActions';
import { If } from 'Conditionals';
import { sprintf } from 'sprintf-js';
import { LoadingButton } from 'FormElements';
import Notice from 'Layout/Notice';
import Select from 'react-select';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';

function PaymentInfoModal ({ stripePayment = {}, isOpen, onClose }) {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { country } = useCountries();
  const [newDeadline, setNewDeadline] = useState('48');
  const { refundPayment, generateReceipt, updateDeadline } = usePaymentActions(stripePayment);
  const [deadlineDate, setDeadlineDate] = useState(stripePayment.payment_deadline);
  const [missedDeadlineDate, setMissedDeadlineDate] = useState(stripePayment.has_missed_payment_at);
  const [isUpdatingDeadline, setIsUpdatingDeadline] = useState(false);
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);

  if (!stripePayment) {
    return null;
  }

  const formatAmount = (amount) => {
    return formatCurrency(amount, null, order.countryCode, order?.currency);
  };

  const formatVat = (vatAmount = 0) => {
    return sprintf(t('incl. %s VAT'), formatAmount(vatAmount));
  };

  const getItemDescription = (item) => {
    if (!item.resource_type) {
      return t('Unknown');
    }

    if (item.resource_type === 'App\\Appointment') {
      return formatAppointmentDate(item.resource.start_date, item.resource.meeting_length, order.customer?.timezone);
    }

    if (item.resource_type === 'App\\Expense') {
      return item.resource.description;
    }

    if (item.resource_type === 'App\\ProductResource') {
      return t('Product');
    }

    return t('Unknown');
  };

  const formatAppointmentDate = (startDate, meetingLength, customerTimezone) => {
    const dateFormat = 'yyyy-MM-dd HH:mm';
    const formattedStart = startDate ? format(utcToZonedTime(new Date(startDate), Intl.DateTimeFormat().resolvedOptions().timeZone), dateFormat) : '';
    const endDate = startDate && meetingLength ? new Date(startDate).setMinutes(new Date(startDate).getMinutes() + meetingLength) : '';
    const formattedEnd = endDate ? format(utcToZonedTime(new Date(endDate), Intl.DateTimeFormat().resolvedOptions().timeZone), dateFormat) : '';

    if (!formattedStart) {
      return t('Date not set');
    }

    let appointmentTimes = formattedStart;
    if (formattedEnd) {
      appointmentTimes = `${appointmentTimes} - ${formattedEnd}`;
    }

    if (customerTimezone) {
      try {
        const zonedStart = utcToZonedTime(new Date(startDate), customerTimezone);
        const zonedEnd = utcToZonedTime(new Date(endDate), customerTimezone);
        const formattedZonedStart = format(zonedStart, dateFormat);
        const formattedZonedEnd = format(zonedEnd, dateFormat);

        return (
          <>
            {appointmentTimes}
            <span className='customer-meeting-time'>
              <br />
              {t('Customer Time')}: ({formattedZonedStart} - {formattedZonedEnd})
            </span>
          </>
        );
      } catch (error) {
        console.error("Error formatting date in customer's timezone:", error);
      }
    }

    return <>{appointmentTimes}</>;
  };

  const deadlines = [
    { value: '1', label: t('1 hour') },
    { value: '3', label: t('3 hours') },
    { value: '5', label: t('5 hours') },
    { value: '10', label: t('10 hours') },
    { value: '24', label: t('1 day') },
    { value: '48', label: t('2 days') },
    { value: '72', label: t('3 days') },
    { value: '96', label: t('4 days') }
  ];

  const getDeadlineSelectValue = (value) => {
    return deadlines.find(deadline => deadline.value === value);
  };

  const changeDeadline = async () => {
    setIsUpdatingDeadline(true);
    const { success, newDate } = await updateDeadline(newDeadline ? newDeadline.value : 48);
    setIsUpdatingDeadline(false);

    if (success) {
      setDeadlineDate(newDate);
      setMissedDeadlineDate(null);
      setShowDeadlineModal(false);
    }
  };

  const { offer } = stripePayment || {};

  if (!offer) {
    return null;
  }

  const openDeadlineModal = () => {
    setShowDeadlineModal(true);
  };

  const getReceipt = async () => {
    await generateReceipt();
  };

  const doRefund = async (stripePayment) => {
    if (stripePayment.stripe_id && stripePayment.stripe_id.includes('pi_')) {
      alert(t('You need to refund this payment directly in Stripe Admin. If you need any help contact Support.'));
      return;
    }

    if (!window.confirm(t('Are you sure you want to refund this payment?'))) {
      return;
    }

    await refundPayment();
  };

  return (
    <>
      <LavendlaModal
        className='category-modal'
        isOpen={isOpen}
        close={onClose}
        headerNode={
          <span className='payment-info__header'><h3>{t('Payment')} <a href={`${offer.link}/no-track`} target='_blank' rel='noopener noreferrer'>#{stripePayment.offer_id}</a></h3> - <span className={`payment-info__status ${stripePayment.status}`}>{getTranslatedStripeStatus(stripePayment.status)}</span></span>
        }
      >
        <div id='deadline-modal-container' />
        <div className="payment-info__content">
          <strong>{t('Created')}</strong> {formatDate(stripePayment.created_at, country.code)}
          <If condition={offer.viewed_at}>
            <br/>
            <strong>{t('Viewed')}</strong> {formatDate(offer.viewed_at, country.code)}
          </If>
          <br/>
          <strong>{t('Status')}</strong> {getTranslatedStripeStatus(stripePayment.status)}
          <br/>
          <strong>{t('Stripe ID')}</strong> {stripePayment.stripe_id}
          <br/>
          <If condition={stripePayment.payment_intent}>
            <strong>{t('Stripe Payment ID')}</strong> {stripePayment.payment_intent}
            <br/>
          </If>
          <strong>{t('Payment deadline')}</strong> {formatDate(deadlineDate, country.code)}
          <If condition={missedDeadlineDate}>
            <If condition={missedDeadlineDate}>
              <br/>
              <strong>{t('Missed deadline')}</strong> {formatDate(missedDeadlineDate, country.code)}
            </If>
          </If>
          <If condition={stripePayment.paid_at}>
            <br/>
            <strong>{t('Paid')}</strong> {formatDate(stripePayment.paid_at, country.code)}
          </If>
          <If condition={offer.verified}>
            <br/>
            <strong>{t('Customer verified')}</strong> {formatDate(offer.verified, country.code)}
          </If>
          <If condition={stripePayment.refunded_at}>
            <br/>
            <strong>{t('Payment refunded')}</strong> {formatDate(stripePayment.refunded_at, country.code)}
          </If>
        </div>
        <div className="payment-info__actions btn-group">
          <a className="btn small" href={`${offer.link}/no-track`} target='_blank' rel='noopener noreferrer'>{t('Show payment')}</a>
          <If condition={stripePayment.status !== 'paid' && !stripePayment.refunded_at}>
            <a className='btn small' onClick={() => openDeadlineModal()}>{t('Change deadline')}</a>
          </If>
          <If condition={stripePayment.status === 'paid' || stripePayment.status === 'refunded'}>
            <a className='btn small' onClick={() => getReceipt()}>{t('Get receipt')}</a>
            <If condition={!stripePayment.refunded_at}>
              <a className='btn small delete' onClick={() => doRefund(stripePayment)}>{t('Refund payment')}</a>
            </If>
          </If>
        </div>
        <Table
          isLoading={false}
          className='payment-info__table'
          thead={[
            t('Type'),
            t('Amount')
          ]}
        >
          {stripePayment.items.map(item => (
            <tr key={item.id}>
              <td>{getItemDescription(item)} {item.resource.quantity > 1 ? 'x ' + item.resource.quantity : null}</td>
              <td>{formatAmount(item.resource.quantity > 1 ? item.amount * item.resource.quantity : item.amount)}</td>
            </tr>
          ))}
          <tr>
            <td
              colSpan={1}
            />
            <td>
              {stripePayment.vatAmount > 0 || stripePayment.coupon_code ? (
                <>
                  <div>
                    {stripePayment.coupon_code && (
                      <span>{stripePayment.coupon_code ? <>{t('Coupon')}: {stripePayment.coupon_code}</> : null} ({stripePayment.coupon_code ? <>{stripePayment.coupon_value}</> : null}{stripePayment.coupon_code && stripePayment.coupon_type === 'procent' ? '%' : ' ' + order?.currency.symbol})</span>
                    )}
                  </div>
                  {stripePayment.vatAmount > 0 ? (
                    <div>
                      {formatVat(stripePayment.vatAmount)}
                    </div>
                  ) : null}
                </>
              ) : null}
              <div className='sessions-creator__total'>
                <strong>{t('Total')}: {formatAmount(stripePayment.amount)}</strong>
              </div>
            </td>
          </tr>
        </Table>
        <LavendlaModal
          className='category-modal'
          close={() => setShowDeadlineModal(false)}
          headerTitle={t('Change deadline')}
          isOpen={showDeadlineModal}
          parentSelector={() => document.querySelector('#deadline-modal-container')}
        >
          <div className='form-group'>
            <label htmlFor='payment_deadline'>{t('Payment deadline')}</label>
            <Select
              value={getDeadlineSelectValue(newDeadline)}
              onChange={paymentDeadline => setNewDeadline(paymentDeadline)}
              className='select'
              options={deadlines}
              placeholder={t('Select offer deadline')}
            />
          </div>
          <br />
          <Notice>
            <p>{t('The deadline will be calculated from the current time')}</p>
          </Notice>
          <br />
          <LoadingButton
            onClick={() => changeDeadline()}
            text={t('Update')}
            isLoading={isUpdatingDeadline}
          />
        </LavendlaModal>
      </LavendlaModal>
    </>
  );
}

PaymentInfoModal.propTypes = {
  stripePayment: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default PaymentInfoModal;
