import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FrontendTranslations from 'Components/Translations/FrontendTranslations';
import { HeaderWithFilters } from 'Layout';
import { If } from 'Components/Conditionals';
import { Toggle, LoadingButton } from 'FormElements';
import API from '../stores/API';
import Spinner from 'Components/Spinner';
import { languagesList } from 'Library/languages';
import PropTypes from 'prop-types';

const SingleLocale = ({ match }) => {
  const { t } = useTranslation();
  const [hideTranslated, setHideTranslated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    setIsLoading(true);
    async function fetchTranslations () {
      const result = await API.GET(`/translations/${match.params.fileName}`);

      if (result.status === 404) {
        setTranslations({});
        setIsLoading(false);
        return;
      }

      setTranslations(JSON.parse(result.data));
      setIsLoading(false);
    }

    fetchTranslations();
  }, []);

  const updateFromSource = async () => {
    setIsSyncing(true);
    const source = await fetch(`/locales/source.json`).then(res => res.json());
    setTimeout(() => {
      const newTranslations = { ...source, ...translations };
      setTranslations(newTranslations);
      setIsSyncing(false);
    }, 1000);
  };

  return (
    <>
      <If condition={!isLoading}>
        <div className='row align-top'>
          <div className='col-12'>
            <HeaderWithFilters header={`${t('Translate into')} ${languagesList[match.params.fileName]}`}>
              <div className='header-with-filters__filters__filter'>
                <LoadingButton isLoading={isSyncing} onClick={updateFromSource} text={t('Sync from source')} />
              </div>
              <div className='header-with-filters__filters__filter'>
                <Toggle
                  label={t('Hide translated')}
                  defaultChecked={!!hideTranslated}
                  onChange={() => setHideTranslated(!hideTranslated)}
                />
              </div>
            </HeaderWithFilters>
            <If condition={translations}>
              <FrontendTranslations hideTranslated={hideTranslated} fileName={match.params.fileName} translations={translations} />
            </If>
            <If condition={Object.keys(translations).length < 1}>
              <p>{t('In order to start translating you need to sync from source.')}</p>
            </If>
          </div>
        </div>
      </If>
      <If condition={isLoading}>
        <Spinner />
      </If>
    </>
  );
};

SingleLocale.propTypes = {
  match: PropTypes.object.isRequired
};

export default SingleLocale;
