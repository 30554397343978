import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const config = {
  toolbar: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'link']
};

const Editor = ({ data, onChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      config={config}
      onChange={(event, editor) => {
        if (editor.ui.focusTracker.isFocused) {
          onChange(editor.getData());
        }
      }}
    />
  );
};

Editor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func
};

export default Editor;
