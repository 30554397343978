/* global FormData */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Spinner from 'Components/Spinner';
import { RadioButtons } from 'FormElements';
import { If } from 'Conditionals';
import { OBITUARY_IMAGE, FUNERAL_PROGRAM_FILE } from 'Library/MemorialPageHelpers';
import { showNotice, showWarningNotice } from 'Library/notifications';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { useTranslation } from 'react-i18next';

function MemorialPageFileUpload () {
  const { t } = useTranslation();
  const { attachImage } = useMemorialPage();
  const [isLoading, setIsLoading] = useState(false);
  const [isFuneralProgram, setIsFuneralProgram] = useState(false);
  const fileType = isFuneralProgram ? FUNERAL_PROGRAM_FILE : OBITUARY_IMAGE;

  function showRejectedFiles (files) {
    return t('The following files failed to upload:') + ' ' + files.map(({ name }) => name).join(', ');
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const data = new FormData();

    data.append('type', fileType);

    if (rejectedFiles.length > 0) {
      showWarningNotice(showRejectedFiles(rejectedFiles));

      return;
    }

    for (const file of acceptedFiles) {
      data.append('file', file);
    }

    attachFile(data);
  };

  /**
   * Attach the file to the memorial pages file directory.
   * @param data
   */
  const attachFile = async (data) => {
    setIsLoading(true);
    const response = await attachImage(data);
    setIsLoading(false);

    if (response.status === 'error') {
      showWarningNotice(response.errors.details);

      return;
    }

    showNotice(t('The upload was successful'));
  };

  function getAcceptedMimeTypes () {
    let types;

    if (isFuneralProgram) {
      types = [
        // Misc application formats.
        'application/pdf'
      ];
    } else {
      types = [
        // Image formats.
        'image/png',
        'image/jpg',
        'image/jpeg',
        // Misc application formats.
        'application/pdf'
      ];
    }

    return types.join(',');
  }

  return (
    <div className='file-upload'>

      <RadioButtons
        legend={t('Choose filetype')}
        subField
        choice={isFuneralProgram ? '1' : '0'}
        updateChoice={choice => setIsFuneralProgram(parseInt(choice) === 1)}
        name='memorial-page-file-upload'
        choices={[
          {
            id: '0',
            label: t('Obituary')
          },
          {
            id: '1',
            label: t('Funeral program')
          }
        ]}
      />

      <div className='file-upload__inner'>
        <If condition={isLoading}>
          <div className='loading__container'>
            <Spinner />
          </div>
        </If>

        <Dropzone
          accept={getAcceptedMimeTypes()}
          onDrop={onDrop}
          disabled={isLoading}
          multiple={false}
          activeClassName='dropzone-box__active'
          acceptClassName='dropzone-box__accepted'
          disabledClassName='dropzone-box__disabled'
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: 'dropzone-box' })}>
                <input {...getInputProps()} />
                <p>{t('Drag files here or click to select from your computer')}</p>
                <p><em>{isFuneralProgram ? t('Allowed filetype is PDF') : t('Allowed filetypes are PDF, JPG, JPEG, and PNG')}</em></p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

export default MemorialPageFileUpload;
