import React from 'react';
import PropTypes from 'prop-types';
import { Input, RadioButtons } from 'FormElements';
import { useTranslation } from 'react-i18next';

const ScheduledTime = ({ scheduledTime, index, update, remove, isActive }) => {
  const { t } = useTranslation();
  const meetingTypes = [{
    id: 'all',
    label: t('All meeting times')
  },
  {
    id: 'phone',
    label: t('Only phone and video meetings')
  }];

  return (
    <li className='scheduled-time'>
      <span className='scheduled-time__inputs-wrapper'>
        <strong className='scheduled-time__label'>{t('Work hours')} {index + 1}:</strong>
        <Input
          type='time'
          disabled={!isActive}
          className='scheduled-time__time-input'
          value={scheduledTime.startTime || ''}
          onChange={value => update(index, { startTime: value })}
        />
        <span className='scheduled-time__time-spacing'>{t('until')}</span>
        <Input
          type='time'
          disabled={!isActive}
          className='scheduled-time__time-input'
          value={scheduledTime.endTime || ''}
          onChange={value => update(index, { endTime: value })}
        />
      </span>
      <span className='scheduled-time__options-labels-wrapper'>
        <span className='scheduled-time__radio-labels'>
          <RadioButtons
            choice={scheduledTime.meetingType}
            disabled={!isActive}
            choices={meetingTypes}
            className='scheduled-time__radio-inputs'
            updateChoice={value => update(index, { meetingType: value })}
          />
        </span>

        <button
          className='scheduled-time__remove-time'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            remove(index);
          }}
          disabled={!isActive}
        >
          {t('Remove')}
        </button>
      </span>
    </li>
  );
};

ScheduledTime.propTypes = {
  scheduledTime: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default ScheduledTime;
