import React, { useEffect, useState } from 'react';
import useUser from 'ReduxHooks/useUser';
import { If } from 'Conditionals';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserProfileTab from './UserProfileTab';

const UserProfileMarkets = () => {
  const { user: { countries } } = useUser();
  const [sites, setSites] = useState([]);

  useEffect(() => {
    if (countries) {
      setSites(getSites());
    }
  }, [countries]);

  const getSites = () => countries.flatMap(country => {
    if (!country?.sites) {
      return [];
    }

    return country.sites.map(site => ({
      ...site,
      country: country.name,
      countryId: country.id
    }));
  });

  if (!sites.length) {
    return null;
  }

  return (
    <>
      <If condition={sites.length <= 1}>
        <UserProfileTab country={countries[0]} sites={sites} />
      </If>
      <If condition={sites.length > 1}>
        <Tabs>
          <TabList className='react-tabs__tab-list react-tabs__sub-tab-parents-list'>
            {countries.map((country, index) => (
              <Tab key={index}>{country.name}</Tab>
            ))}
          </TabList>
          {countries.map((country, index) => (
            <TabPanel key={index}>
              <UserProfileTab country={country} sites={sites.filter(site => site.countryId === country.id)} />
            </TabPanel>
          ))}
        </Tabs>
      </If>
    </>
  );
};

export default UserProfileMarkets;
