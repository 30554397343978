import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Canvas = ({
  width,
  height,
  setProvider
}) => {
  const mounted = useRef(false); // Set up referens for component lifecycle mounted
  const obituaryCanvas = useRef(null); // Set up referens for canvas DOMelement

  useEffect(() => {
    if (!mounted.current) {
      setProvider({ context: obituaryCanvas.current.getContext('2d') });
      mounted.current = true;
    }
  }, []);

  return (
    <div className="obituary-canvas-wrapper">
      <canvas
        ref={obituaryCanvas}
        width={width}
        height={height}
      />
    </div>
  );
};

Canvas.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  provider: PropTypes.object.isRequired,
  setProvider: PropTypes.func.isRequired,
  render: PropTypes.bool.isRequired,
  setRender: PropTypes.func.isRequired
};

export default Canvas;
