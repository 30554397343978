import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const singleFlowerTerms = ['kistdekorationer', 'urndekorationer'];

const quantityOptions = [];
for (let i = 1; i < 11; i++) {
  quantityOptions.push(<option value={i} key={i}>{i}</option>);
}

const QtyPicker = ({
  mainCategory,
  quantity,
  setQuantity
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(() => {
      if (!mainCategory) {
        return false;
      }

      if (singleFlowerTerms.find(term => term === mainCategory.slug)) {
        return false;
      }

      return (mainCategory.parent && mainCategory.parent.slug === 'begravningsblommor');
    });
  }, [mainCategory]);

  if (!show) {
    return null;
  }

  return (
    <div className='single-product__qty funnel-select'>
      <label htmlFor='product-quantity'>{t('Quantity')}</label>
      <select
        id='product-quantity'
        value={quantity}
        onChange={e => setQuantity(e.target.value)}
      >
        {quantityOptions}
      </select>
      <span className='funnel-select__value'>{quantity}</span>
    </div>
  );
};

QtyPicker.propTypes = {
  mainCategory: PropTypes.object,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func
};

export default QtyPicker;
