import React from 'react';
import PropTypes from 'prop-types';
import { Fieldset, Input, Textarea } from 'FormElements';
import { useTranslation } from 'react-i18next';

const TextContent = ({
  heading,
  children,
  text,
  title,
  setText,
  setTitle
}) => {
  const { t } = useTranslation();
  return (
    <Fieldset
      legend={heading}
      subField
    >
      {children}
      <Input
        label={t('Heading')}
        value={title}
        onChange={value => setTitle(value)}
      />
      <br /><br />
      <Textarea
        label={t('Text section')}
        rows='4'
        value={text}
        onChange={value => setText(value)}
      />
    </Fieldset>
  );
};

TextContent.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  setText: PropTypes.func,
  setTitle: PropTypes.func
};

export default TextContent;
