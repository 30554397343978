import React from 'react';
import ProductNonBillableRow from '../ProductNonBillableRow';
import classNames from 'classnames';
import { filterNonInvoiceableProducts } from 'Library/ProductHelper';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const OrderNonBillableProducts = () => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const products = order.products.filter(product => filterNonInvoiceableProducts(product));

  return (
    <div
      id='order-products-table'
      className={classNames('product-table', {
        'is-partially-invoiced': order.is_partially_invoiced
      })}
    >
      <div className='cart'>
        <div className='table-header'>
          <div className='col product-col'><span className='label'>{t('Product')}</span></div>
          <div className='col quantity-col'><span className='label'>{t('Quantity')}</span></div>
          <div className='col price-col'><span className='label'>{t('Price')}</span></div>
          <div className='col comment-col'><span className='label'>{t('Comment')}</span></div>
          <div className='col action-col'><span className='label'>{t('Actions')}</span></div>
        </div>

        {products.map(product => (
          <ProductNonBillableRow
            key={product.product_resource_id}
            product={product}
          />
        ))}
      </div>
    </div>
  );
};

export default OrderNonBillableProducts;
