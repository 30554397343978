import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = window.location.hostname !== 'localhost' ? Sentry.createReduxEnhancer({}) : f => f;
const devToolsExtension = window.devToolsExtension ? window.devToolsExtension() : f => f;

export default createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    sentryReduxEnhancer,
    devToolsExtension
  )
);
