import React from 'react';
import { useTranslation } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';

function OrderAdvisorCTA () {
  const { t } = useTranslation();
  const { order } = useSingleOrder();

  const dict = {
    inquiry: t('send the offer to the customer'),
    offer: t('seek confirmation for the offer'),
    in_progress: t('administrative tasks'),
    passed: t('get feedback and invoice'),
    invoiced: t('entombment and archival'),
    partially_invoiced: t('entombment and archival')
  };

  const cta = dict[order.status] || null;
  if (order.class !== 'App\\Order' || !cta) {
    return null;
  }

  return (
    <div className='order-advisor-cta'>
      {t('Todo')}:&nbsp;<strong>{cta}</strong>
    </div>
  );
}

export default OrderAdvisorCTA;
