import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'Components/Spinner';
import ListShortcodes from 'Components/Tasks/ListShortcodes';
import useTask from 'ReduxHooks/useTask';
import TaskCreator from 'Components/Tasks/TaskCreator';
import { useTranslation } from 'react-i18next';

function SingleAdministration ({ match }) {
  const { t } = useTranslation();
  const { task, get } = useTask();

  useEffect(() => {
    get(match.params.id);
  }, [match.params.id]);

  if (!task) {
    return (
      <Spinner />
    );
  }

  return (
    <div className='row align-top'>
      <div className='col-6'>
        <h2 className='page-title'>{t('Admin task')}</h2>
        <TaskCreator
          task={task}
        />
      </div>
      <div className='col-6'>
        <h3>{t('Codes')}</h3>
        <p>{t('Copy and paste your codes to automatically retrieve the correct order information in this email.')}</p>
        <ListShortcodes
          type={task.type}
        />
      </div>
    </div>
  );
}

SingleAdministration.propTypes = {
  match: PropTypes.object
};

export default SingleAdministration;
