import React from 'react';
import OrderTab from './OrderTab';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Input, RadioButtons, Textarea } from 'FormElements';
import OrderSupplierDropdown from '../OrderSupplierDropdown';
import OrderAppointments from '../Appointments/OrderAppointments';
import OrderDatePicker from '../OrderDatePicker';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import Attendees from '../Attendees/Attendees';
import { useTranslation } from 'react-i18next';

function MemorialForm () {
  const { t } = useTranslation();
  const { toggleAdvisorOsaProduct } = useOrderProducts();
  const { order, updateField, persist, getCountryCode } = useSingleOrder();
  const { memorial } = order;

  const inputProps = {
    className: 'col-4',
    floatingLabel: true
  };

  async function updateOsaChoice (handlesOsa) {
    await updateField('memorial.advisor_handles_rsvp', handlesOsa);

    if (getCountryCode() === 'se') {
      await persist();
      await toggleAdvisorOsaProduct(!handlesOsa);
    }
  }

  function getAdvisorHandlesOsaLabel () {
    if (getCountryCode() === 'se') {
      return t('I as advisor will handle the RSVPs (the cost for this will be added to the cart)', {
        description: 'Jag som rådgivare hanterar OSA (kostnaden för kund läggs till i varukorgen)'
      });
    }

    return t('I as advisor will handle the RSVPs');
  }

  return (
    <OrderTab
      tab='memorial'
      header={t('Memorial service')}
      afterTasks={(
        <div className='row'>
          <Textarea
            className='col-12'
            value={memorial.note}
            onChange={value => updateField('memorial.note', value)}
            label={t('Other')}
            data-cy='memorial.note'
            floatingLabel
            rows={10}
          />
        </div>
      )}
    >
      <div className='row'>
        <div className='col-12'>
          <RadioButtons
            choice={memorial.advisor_handles_rsvp ? '1' : '0'}
            updateChoice={choice => updateOsaChoice(parseInt(choice) === 1)}
            name='advisor-handles-rsvp'
            choices={[
              {
                id: '0',
                label: t('The customer will handle the RSVPs themselves', {
                  description: 'Kunden hanterar själv alla OSA-anmälningar'
                })
              },
              {
                id: '1',
                label: getAdvisorHandlesOsaLabel()
              }
            ]}
          />
        </div>
      </div>
      <div className='row'>
        <Input
          value={memorial.place}
          label={t('Memorial venue')}
          onChange={value => updateField('memorial.place', value)}
          data-cy='memorial.place'
          {...inputProps}
        />
        <Input
          value={memorial.food}
          label={t('Catering')}
          onChange={value => updateField('memorial.food', value)}
          data-cy='memorial.food'
          {...inputProps}
        />
        <Input
          value={memorial.max_attendees}
          label={t('Max number of attendees')}
          onChange={value => updateField('memorial.max_attendees', value)}
          data-cy='memorial.max_attendees'
          {...inputProps}
        />
      </div>
      <div className='row'>
        <OrderSupplierDropdown
          label={t('Catering supplier')}
          type='cateringleverantoer'
          noValueLabel={t('-- No supplier selected --')}
          className='col-8'
        />
        <OrderDatePicker
          field='memorial.rsvp'
          label={t('RSVP memorial')}
          value={memorial.rsvp}
        />
      </div>
      <OrderAppointments
        header={t('Memorial service')}
        type='memorial'
      />
      <Attendees/>
    </OrderTab>
  );
}

export default MemorialForm;
