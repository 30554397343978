import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import ToggleMemorialVisibility from '../MemorialPageComponents/ToggleMemorialVisibility';
import { USER_BG_IMG } from 'Library/MemorialPageHelpers';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { AnimatePresence, motion } from 'framer-motion';
import { animateOpacity } from 'Library/motion';
import { useTranslation } from 'react-i18next';

const MemorialSaveActions = ({
  memorialPage,
  publishOrUpdate,
  updateVisibility,
  isPublishing,
  isDirty = false,
  isPublished = false
}) => {
  const { t } = useTranslation();
  const { findImageOfType } = useMemorialPage();

  function isDisabled () {
    if (isDirty) {
      return true;
    }

    if (findImageOfType(USER_BG_IMG)) {
      return false;
    }

    return !memorialPage.wordpress_card_image_id;
  }

  return (
    <div className='memorial-save-actions'>
      <If condition={isPublished}>
        <ToggleMemorialVisibility
          memorialPage={memorialPage}
          updateVisibility={updateVisibility}
        />
      </If>

      <div className='memorial-save-actions__save'>
        <LoadingButton
          disabled={isDisabled()}
          isLoading={isPublishing}
          onClick={publishOrUpdate}
          data-cy='memorial-publish-button'
        >
          {isPublished ? t('Update') : t('Publish')} {t('memorial page')}
        </LoadingButton>
        <AnimatePresence>
          <If condition={isDisabled()}>
            <motion.p {...animateOpacity}>
              <em>{t('You must first save your changes')}</em>
            </motion.p>
          </If>
        </AnimatePresence>
      </div>
    </div>
  );
};

MemorialSaveActions.propTypes = {
  memorialPage: PropTypes.object.isRequired,
  publishOrUpdate: PropTypes.func.isRequired,
  updateVisibility: PropTypes.func.isRequired,
  isPublishing: PropTypes.bool,
  isDirty: PropTypes.bool,
  isPublished: PropTypes.bool
};

export default MemorialSaveActions;
