import React, { useState } from 'react';
import { showNotice } from 'Library/notifications';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import useUser from 'ReduxHooks/useUser';

// Components
import { LoadingButton } from 'FormElements';

const PauseUser = ({ user }) => {
  const { t } = useTranslation();
  const { togglePause } = useUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  async function togglePauseUser () {
    setIsLoading(true);
    const paused = !user.paused_at;
    const response = await togglePause(user.id, paused);
    setIsLoading(false);

    if (response) {
      if (paused) {
        showNotice(t('The user has been paused.'));
      } else {
        showNotice(t('The user is no longer paused.'));
      }

      setTimeout(() => history.push('/anvandarregister'), 300);
    }
  }

  return (
    <div className='profile-action-box pause-user'>
      <p>
        <strong>{t('Pause user')}</strong>
      </p>

      <LoadingButton
        onClick={togglePauseUser}
        isLoading={isLoading}
        className='pause-user__button'
        text={!user.paused_at ? t('Pause user') : t('Unpause user')}
      />
    </div>
  );
};

PauseUser.propTypes = {
  user: PropTypes.object.isRequired
};

export default PauseUser;
