import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, LoadingButton, Textarea } from 'FormElements';
import { useTranslation } from 'react-i18next';

function FlowerRow ({
  product,
  index,
  type,
  text,
  update,
  isLoading = false
}) {
  const { t } = useTranslation();
  const [values, setValues] = useState({ type, text });

  function persist () {
    update({
      ...values,
      id: product.id,
      index
    });
  }

  return (
    <div className='flower-row'>
      <div>
        <span className='index'>{index + 1}.</span>
      </div>

      <Select
        onChange={(value) => setValues({
          ...values,
          type: value
        })}
        defaultOption={t('-- Select type --')}
        options={{
          kort: t('Card'),
          band: t('Band')
        }}
        value={values.type}
        label={t('Type')}
        floatingLabel
      />
      <Textarea
        floatingLabel
        value={values.text}
        placeholder={t('Message')}
        onChange={(value) => setValues({
          ...values,
          text: value
        })}
      />

      <LoadingButton
        onClick={() => persist()}
        isLoading={isLoading}
      >
        {t('Save')}
      </LoadingButton>
    </div>
  );
}

FlowerRow.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string,
  text: PropTypes.string,
  update: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default FlowerRow;
