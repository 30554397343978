import React from 'react';
import { format, parse, isSameMinute } from 'date-fns';
import PropTypes from 'prop-types';

const AppointmentTitle = ({ appointment }) => {
  const { type, title, title_translated: translatedTitle } = appointment;

  // Parse start and end times from string dates
  const startTime = parse(appointment.start_date, 'yyyy-MM-dd HH:mm:ss', new Date());
  const endTime = parse(appointment.end_date, 'yyyy-MM-dd HH:mm:ss', new Date());

  const showTimes = format(startTime, 'HH:mm') !== '00:00';

  function getTitle () {
    if (type === 'customer_meeting' && appointment.resource && appointment.resource.customer) {
      if (title !== '') {
        return `${appointment.resource.customer.name} (${translatedTitle || title})`;
      }
      return appointment.resource.customer.name;
    }

    if (appointment.resource && appointment.resource.deceased) {
      return appointment.resource.deceased.name;
    }

    return translatedTitle || title;
  }

  function getTimes () {
    // Check if start and end times are the same minute, if so, return a single time
    if (isSameMinute(startTime, endTime)) {
      return format(startTime, 'HH:mm');
    }
    return `${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`;
  }

  return (
    <div className={type ? `appointment__title type-${type}` : 'appointment__title type-other'}>
      {showTimes && (
        <span className='appointment__title__time'>{getTimes()}</span>
      )}
      <span className='appointment__title__title'>{getTitle()}</span>
    </div>
  );
};

AppointmentTitle.propTypes = {
  appointment: PropTypes.object.isRequired
};

export default AppointmentTitle;
