import React from 'react';
import DownloadDonations from './DownloadDonations';
import DownloadImageArchive from './DownloadImageArhive';

function DownloadActions () {
  return (
    <div className='grey-box download-actions'>
      <DownloadDonations/>
      <DownloadDonations archives/>
      <DownloadImageArchive/>
    </div>
  );
}

export default DownloadActions;
