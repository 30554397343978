import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute } from 'react-router';
import { Helmet } from 'react-helmet';

const Route = ({ title, ...rest }) => {
  return (
    <>
      <Helmet>
        <title>Lavendla Admin{title ? ' | ' + title : ''}</title>
      </Helmet>
      <ReactRoute
        {...rest}
      />
    </>
  );
};

Route.propTypes = {
  title: PropTypes.string
};

export default Route;
