import React from 'react';
import PropTypes from 'prop-types';
import ReportTableRow from './ReportTableRow';
import { formatCurrency } from 'Library/functions';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function ReportsTable ({
  totals = {
    total: 0,
    totalTax: 0
  },
  rows = [],
  noVat = false,
  reportUser = {}
}) {
  const { t } = useTranslation();

  return (
    <div className='bonus-report__report'>
      <table className='bonus-report__report__table'>
        <thead>
          <tr>
            <th>{t('Source')}</th>
            <th>{t('ID')}</th>
            <th>{t('Type')}</th>
            <th>{t('Name')}</th>
            <th>{t('Product')}</th>
            <th>{noVat ? t('Total') : t('Total including tax')}</th>
          </tr>
        </thead>
        <tbody>
          {rows.filter(row => row.total !== 0).map((row, i) => (
            <ReportTableRow
              key={i}
              row={row}
              reportUser={reportUser}
            />
          ))}
        </tbody>
      </table>

      <div className='bonus-report__report__totals'>
        <If condition={noVat}>
          {t('Total amount to be invoiced:')} {formatCurrency(totals.total, null, reportUser?.country?.code)}
        </If>
        <If condition={!noVat}>
          {t('Net total')} {formatCurrency(totals.total - totals.totalTax, null, reportUser?.country?.code)},
          &nbsp;{t('Tax')} {formatCurrency(totals.totalTax, null, reportUser?.country?.code)},
          &nbsp;{t('Total amount to be invoiced:')} {formatCurrency(totals.total, null, reportUser?.country?.code)}
        </If>
      </div>
    </div>
  );
}

ReportsTable.propTypes = {
  totals: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  noVat: PropTypes.bool,
  reportUser: PropTypes.object
};

export default ReportsTable;
