import { useDispatch, useSelector } from 'react-redux';
import { getLocations } from '../actions/locations';

function useLocations () {
  const { locations } = useSelector(state => state.locations);
  const dispatch = useDispatch();

  return {
    locations,
    getLocations: (filters = {}) => dispatch(getLocations(filters))
  };
}

export default useLocations;
