import useSingleOrder from 'ReduxHooks/useSingleOrder';

export const EXPENSE_FILE_TYPE = 'expense';
export const CLIENT_DOCUMENTATION_FILE_TYPE = 'client_documentation';

function useOrderFiles () {
  const {
    order: {
      files = []
    }
  } = useSingleOrder();

  function getFilesOfType (type) {
    if (type === null) {
      return files.filter(file => file.type === null || file.type === '');
    }

    return files.filter(file => file.type === type);
  }

  return {
    files,
    getFilesOfType
  };
}

export default useOrderFiles;
