import React from 'react';
import PropTypes from 'prop-types';
import { canSendInvoiceTask, isInvoiceTask } from 'Library/Tasks';
import { Notice } from 'Layout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function ProductWarning ({ order, task }) {
  const { t } = useTranslation();
  const { partialInvoiceProducts } = useSelector(state => state.products);

  if (!isInvoiceTask(task)) {
    return null;
  }

  if (!canSendInvoiceTask(order, task)) {
    return (
      <Notice heading={t('Unable to create invoice')}>
        <p>{t('The cart is empty. Please check this before you attempt to create an invoice.')}</p>
      </Notice>
    );
  }

  if (order.is_partially_invoiced && !partialInvoiceProducts.length) {
    return (
      <Notice heading={t('Unable to create invoice')}>
        <p>{t('Please select one or more products to invoice.')}</p>
      </Notice>
    );
  }

  const productsWithZeroPrice = order.products.filter(({ sku, pivot }) => {
    if (['0703157', '0401001', '0201009'].includes(sku)) {
      return false;
    }

    return pivot.price < 1;
  });

  if (!productsWithZeroPrice.length) {
    return null;
  }

  return (
    <Notice heading={t('Before sending invoicing')}>
      <p>{t('Do you have a product with a price of 0? Please review and make any corrections before sending invoice.')}</p>
    </Notice>
  );
}

ProductWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default ProductWarning;
