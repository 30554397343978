import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtons } from '../FormElements';
import { useTranslation } from 'react-i18next';

const TextColor = ({
  heading,
  textColor,
  setTextColor
}) => {
  const { t } = useTranslation();
  const textColorChoices = [
    {
      id: 'black',
      label: t('Black text + logo in color')
    },
    {
      id: 'white',
      label: t('White text + white logo')
    }
  ];

  return (
    <RadioButtons
      legend={heading}
      name='textColor'
      choice={textColor}
      choices={textColorChoices}
      updateChoice={setTextColor}
      subField
    />
  );
};

TextColor.propTypes = {
  heading: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  setTextColor: PropTypes.func.isRequired
};

export default TextColor;
