import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getID } from './localstorage';
import { LoadingButton } from 'FormElements';
import { getResouceRootUri, getResourceUrl } from 'Library/Orders';
import API from '../stores/API';
import { useHistory } from 'react-router-dom';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function AcceptAvailableOrder ({ order }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const acceptOrder = async () => {
    setIsLoading(true);
    const { status, errors } = await API.POST(`${getResouceRootUri(order)}/${order.id}/assign`, {
      user_id: getID()
    });
    setIsLoading(false);

    if (status === 'error') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    showNotice(`🎉 ${t('The order was assigned to you!')}`);

    history.push(`${getResourceUrl(order)}`);
  };

  return (
    <LoadingButton
      onClick={acceptOrder}
      text={t('Assign to me')}
      className='accept-order-btn'
      isLoading={isLoading}
    />
  );
}

AcceptAvailableOrder.propTypes = {
  order: PropTypes.object.isRequired
};

export default AcceptAvailableOrder;
