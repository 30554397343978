import React from 'react';
import PropTypes from 'prop-types';
import CompetenceAreaForm from './CompetenceAreaForm';
import { useTranslation } from 'react-i18next';

const CreateCompetenceArea = () => {
  const { t } = useTranslation();

  return (
    <div className='competence-area'>
      <h2 className='page-title'>{t('Create competence area')}</h2>
      <CompetenceAreaForm />
    </div>
  );
};

CreateCompetenceArea.propTypes = {
  match: PropTypes.object.isRequired
};

export default CreateCompetenceArea;
