import { useDispatch, useSelector } from 'react-redux';
import { getTags } from '../actions/tags';

function useTags () {
  const dispatch = useDispatch();
  const { tags } = useSelector(state => state.tags);

  return {
    tags,
    getTags: (countryId, type) => dispatch(getTags(countryId, type))
  };
}

export default useTags;
