import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const InformationBox = ({ children, className = '' }) => {
  return (
    <div className={classNames('information-box__container', className)}>
      <div className='information-box__content'>
        {children}
      </div>
    </div>
  );
};

InformationBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

export default InformationBox;
